import { DataFrame } from "../../dashboard/DSL/DataFrame";
import React, { useState } from "react";
import { DebugContext } from "../DebugModeProvider";
import { DataDefinitionModal } from "./DataDefinitionView";
import { DataTableModal } from "./DataTableView";
import { CpuChipIcon, ArrowPathIcon } from "@heroicons/react/24/solid";
import { Button, ButtonGroup } from "react-daisyui";

export const DebugControls = ({ elementId, functionReload, data, dataDefinition, lastLoadingTime }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [keepOpen, setKeepOpen] = useState(false);

    const canReload = functionReload && true;
    const canShowData = data && (!(data instanceof DataFrame) || !data.isEmpty());
    const canShowDataDefinition = dataDefinition && true;

    const areControlsEmpty = !canReload && !canShowData && !canShowDataDefinition;
    let color = "neutral";
    if (!areControlsEmpty && !canShowDataDefinition && !canReload) {
        color = "primary";
    } else if (!areControlsEmpty) {
        color = "accent";
    }

    const style = {
        position: "absolute",
        padding: 0,
        zIndex: !keepOpen ? 50 : undefined
    };

    const modalSetOpen = (open) => {
        setIsExpanded(open);
        setKeepOpen(open);
    };

    const controls = (
        isExpanded || keepOpen
            ? <ButtonGroup
                className={`items-center flex flex-row rounded-box bg-${color}`}
                size='mini'
                compact
            >
                {canReload &&
                    <Button className="btn-ghost btn-sm" onClick={functionReload}>
                        <ArrowPathIcon className="w-auto h-full"/>
                    </Button>}
                {canShowData &&
                    <DataTableModal data={data} setKeepOpen={modalSetOpen}/>}
                {canShowDataDefinition &&
                    <DataDefinitionModal dataDefinition={dataDefinition} setKeepOpen={modalSetOpen}/>}
                {lastLoadingTime && <div className="btn btn-ghost btn-sm pointer-events-none">{lastLoadingTime} s</div>}
                <div className="btn btn-ghost btn-sm pointer-events-none">{elementId}</div>
            </ButtonGroup>
            : <CpuChipIcon className={`w-5 h-5 bg-${color} text-${color}-content p-1 rounded-box`}/>
    );

    return (
        <DebugContext.Consumer>
            {value => value.isEnabled() &&
                <div
                    style={style}
                    onMouseOver={() => setIsExpanded(true)}
                    onMouseEnter={() => setIsExpanded(true)}
                    onMouseLeave={() => setIsExpanded(false)}
                >
                    {controls}
                </div>
            }
        </DebugContext.Consumer>
    );
};
