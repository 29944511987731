import React, { useState } from "react";
import { DataFrame } from "../../../DSL/DataFrame";
import { Element } from "../../Element";
import { Card } from "react-daisyui";
import { CardPopup } from "./ElementCard";

const ElementCardPopupRow = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const { "data": dataArgs, style } = props;
    const { popups } = dataArgs;
    const unit = !(data.isEmpty()) ? data.getRow(0) : null; // new Map();

    if (unit !== null) {
        return (
            <Element
                {...props}
                primary
                setData={setData}>
                {popups !== undefined && (
                    <Card.Actions
                        key={unit.get(dataArgs.key)}
                        className="flex-grow"
                        // style={style ? style : {}}
                    >
                        <div
                            style={{
                                "display": "flex",
                                "justifyContent": "space-evenly",
                                "textAlign": "center",
                                ...style
                            }}>
                            {popups.map((popupContentProps, i) => (
                                <CardPopup
                                    key={i}
                                    content={popupContentProps.content}
                                    icon={popupContentProps.icon}
                                    conditionalIcon={popupContentProps.conditionalIcon}
                                    text={popupContentProps.text}
                                    data={unit}
                                    style={{ "marginLeft": "1px", "marginRight": "1px" }}
                                />))}
                        </div>
                    </Card.Actions>)
                }
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>
        );
    }
};

export { ElementCardPopupRow as CardPopupRow };
