class Unstack extends Function {
    constructor(wrapper) {
        super("...args", "return this.wrapper(...args)");
        this.wrapper = wrapper;
        const bind = this.bind(this);
        bind.wrapper = wrapper;
        return bind;
    }

    /**
     * After pivot, take the first of the aggregated elements.
     * @return {Object} - function object.
     */
    first() { return this.wrapper("first"); }

    /**
     * After pivot, take the last of the aggregated elements.
     * @return {Object} - function object.
     */
    last() { return this.wrapper("last"); }

    /**
     * After pivot, take the mean of the aggregated elements.
     * @return {Object} - function object.
     */
    mean() { return this.wrapper("mean"); }

    /**
     * After pivot, take the sum of the aggregated elements.
     * @return {Object} - function object.
     */
    sum() { return this.wrapper("sum"); }

    /**
     * After pivot, take the min of the aggregated elements.
     * @return {Object} - function object.
     */
    min() { return this.wrapper("min"); }

    /**
     * After pivot, take the max of the aggregated elements.
     * @return {Object} - function object.
     */
    max() { return this.wrapper("max"); }
}

export default Unstack;
