import React, { useState } from "react";
import { DataFrame } from "../../dashboard/DSL/DataFrame";
import { marked } from "marked";
import ReactJson from "react-json-view";
import { prettyPrintValue } from "../../UtilityFunctions";
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    EllipsisHorizontalIcon,
    MagnifyingGlassIcon,
    TableCellsIcon
} from "@heroicons/react/24/solid";
import { Button, Modal, Table } from "react-daisyui";
import TPagination from "../../components/TPagination";

export const DataTableModal = ({ data, setKeepOpen }) => {
    const [toggled, setToggled] = useState(false);
    const [page, setPage] = useState(0);
    const [filterTerm, setFilterTerm] = useState("");
    const pageItems = 50;

    const toggle = (val) => {
        setKeepOpen(val);
        setToggled(val);
    };

    data = data.copy();
    if (filterTerm !== "") {
        const loweredTerm = filterTerm.toLowerCase();
        data.filter((row) =>
            Array.from(row, ([_, val]) =>
                String(val).toLowerCase().includes(loweredTerm)
            ).some(v => v)
        );
    }

    let displayElement;
    if (data instanceof DataFrame) {
        const pages = Math.ceil((data.getLength() - 1) / pageItems);
        displayElement = (
            <>
                <div className="input-group">
                    <input
                        type="text"
                        placeholder="Otsi…"
                        className="input input-bordered"
                        value={filterTerm}
                        onInput={(event) => {
                            setFilterTerm(event.target.value);
                        }}
                    />
                    <button className="btn btn-square btn-sm">
                        <MagnifyingGlassIcon className="w-auto h-6"/>
                    </button>
                </div>
                <Table
                    zebra={true}
                    className={" mb-4 mt-4 w-full text-table"}
                    size="small"
                    compact={true}
                >
                    <thead className="text-base-content">
                        <tr>
                            {data.getColumns().map((h, i) => (
                                <th key={i} style={{ textTransform: "none" }}>
                                    <span>{h}</span>
                                </th>))}
                        </tr>
                    </thead>
                    <Table.Body className="text-base-content">
                        {data.getRange().slice(page * pageItems, (1 + page) * pageItems).map(
                            (j) => {
                                return <Table.Row key={j}>
                                    {data.getColumns().map((column, k) => {
                                        const value = prettyPrintValue(data.getRow(j).get(column));
                                        const parsed = marked(String(value));
                                        return (
                                            <span key={k}>
                                                <span dangerouslySetInnerHTML={{ __html: parsed }}/>
                                            </span>);
                                    })}
                                </Table.Row>;
                            }
                        )}
                    </Table.Body>
                    {pages > 1 &&
                        <TPagination
                            className="flex"
                            defaultActivePage={1}
                            ellipsisItem={{ "content": <EllipsisHorizontalIcon className="w-4 h-4"/>, "icon": true }}
                            firstItem={{ "content": <ChevronDoubleLeftIcon className="w-4 h-4"/>, "icon": true }}
                            lastItem={{ "content": <ChevronDoubleRightIcon className="w-4 h-4"/>, "icon": true }}
                            totalPages={pages}
                            page={page}
                            onPageChange={(i) => {
                                setPage(i);
                            }}
                        />}
                </Table>
            </>
        );
    } else {
        displayElement = <ReactJson
            src={data}
            indentWidth={3}
            displayDataTypes={false}
            quotesOnKeys={false}
        />;
    }

    return (
        <>
            <Button className="btn btn-ghost btn-sm" onClick={() => toggle(true)}>
                <TableCellsIcon className="w-auto h-full m-auto p-0"/>
            </Button>
            <Modal open={toggled}>
                <div
                    className="bg-base-100 rounded-box p-8 max-w-6xl flex flex-column items-stretch"
                    style={{
                        maxHeight: "80vh",
                        flexDirection: "column",
                        minWidth: "60vw"
                    }}
                >
                    <Modal.Header className="text-2xl font-bold mb-4">
                        Andmetabel
                    </Modal.Header>
                    <Modal.Body
                        className="overflow-auto"
                        style={{
                            minHeight: "60vh"
                        }}
                    >
                        {displayElement}
                    </Modal.Body>
                    <Modal.Actions>
                        <Button className="bg-primary" onClick={() => toggle(false)}>
                            Sulge
                        </Button>
                    </Modal.Actions>
                </div>
            </Modal>
        </>
    );
};
