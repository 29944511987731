import { data } from "./aggregatorModels";
import { AggregationBuilder, source as aggSource } from "./aggregatorModels2";
import { autoId, hashCode, parentId } from "../DashboardElementBuilder";
import { DataFrame } from "./DataFrame";

const meta = (title, subtitle, category, tags, image, userGroups, elementsModulePath, version) => {
    return {
        title,
        subtitle,
        category,
        tags,
        image,
        userGroups,
        elementsModulePath,
        version
    };
};

const elements = (...args) => {
    return args.map((element) => element.build());
};

class ElementBuilder {
    constructor(type, id, blocking) {
        this.json = {
            type,
            "props": {
                id,
                "data": {},
                "outputWith": id.includes(autoId) || id.includes(parentId) || id.includes(hashCode) ? undefined : id,
                blocking
            },
            "children": []
        };
    }

    /**
     * Define raw data for the element.
     *
     * @param {Object} elementData - can be a DataFrame or a subscription.
     */
    data = (elementData) => {
        this.json.props.data.definition = aggSource.raw(elementData).build();
        return this;
    };

    /**
     * Define element aggregation.
     *
     * @param {AggregationBuilder} aggregation
     */
    aggregation = (aggregation) => {
        this.json.props.data.definition = aggregation.build();
        return this;
    };

    definition = (source, actions) => {
        if (source instanceof AggregationBuilder) {
            this.json.props.data.definition = source.build();
        } else {
            // legacy DSL
            this.json.props.data.definition = data.definition(source, actions);
        }
        return this;
    };

    blocking = (isBlocking) => {
        this.json.props.blocking = isBlocking;
    };

    waitFor = (element) => {
        this.json.props.data.waitFor = element;
        return this;
    };

    args = (args) => {
        this.json.props.data = { ...this.json.props.data, ...args };
        return this;
    };

    inRowsToChildren = (childTemplate, sortBy = undefined, postfix = "_inDataChildren") => {
        return this.rowsToChildren(childTemplate, $parent.in, sortBy, postfix);
    };

    outRowsToChildren = (childTemplate, sortBy = undefined, postfix = "_outDataChildren") => {
        return this.rowsToChildren(childTemplate, $parent.out, sortBy, postfix);
    };

    rowsToChildren = (childTemplate, dataSource, sortBy, postfix) => {
        const dataChildrenElement = buildElement("DataChildren", `${parentId}${postfix}`, true)
            .data(dataSource);
        if (sortBy) {
            dataChildrenElement.json.props.data.sortBy = sortBy;
        }
        dataChildrenElement.json.props.childTemplate = childTemplate.build();
        this.json.children = [...this.json.children, dataChildrenElement.build()];
        return this;
    };

    props = (props) => {
        this.json.props = { ...this.json.props, ...props };
        return this;
    };

    style = (style) => {
        this.json.props.style = { ...this.json.props.style, ...style };
        return this;
    };

    /**
     * Add children to this element. The particular position of the children within the element is handled in the
     * particular element's definition.
     * @param {ElementBuilder[]} children
     * @return {ElementBuilder}
     */
    children = (children) => {
        this.json.children = [...this.json.children, ...children.map((child) => child.build())];
        return this;
    };

    outputWith = (elementId) => {
        this.json.props.outputWith = elementId;
        return this;
    };

    defaultInteractionState = (state) => {
        this.json.props.data.defaultInteractionState = state;
        return this;
    };

    /**
     * On submit/output, save the interactable (e.g. filter) state of this element to cookies.
     * @returns {ElementBuilder}
     */
    saveInteractionState = () => {
        this.json.props.saveInteractionState = true;
        return this;
    };

    keyColumn = (keyColumn) => {
        this.json.props.data.key = keyColumn;
        return this;
    };

    build = () => {
        return this.json;
    }
}

const buildElement = (type, id, blocking = true, args = {}) => {
    return new ElementBuilder(type, id, blocking, args);
};

const dataReferenceType = {
    in: "$in.",
    out: "$out.",
    col: "$col.",
    row: "$row."
};

class AggregationDataReference {
    constructor(type, id) {
        this.referenceType = type;
        this.id = id;
    }

    /**
     * Lazily applies a callback to the reference data.
     *
     * The callback is called in ElementDataWrapper getParsed, in turn called on subscription update. The callback, depending on its use, takes
     * in the data of the element and can return either more data, or aggregation instructions.
     * @param {function(*, *, *): *} callback
     * @return {AggregationDataReference}
     */
    apply(callback) {
        this.callback = callback;
        return this;
    }

    /**
     * If df is a DataFrame, lazily gets the first value in the first row. Throws error otherwise.
     * @return {AggregationDataReference}
     */
    firstValue() {
        this.callback = (df) => {
            if (!(df instanceof DataFrame)) {
                throw new Error(`attempted to get firstValue from non-DataFrame data, data=${df}`);
            }
            return df.getFirstNonNullValue();
        };
        return this;
    }

    getReferenceId() {
        return this.referenceType + this.id;
    }
}

class ActionsArray {
    /**
     * Creates an array of actions, which is then spliced into the general actions array of the aggregation.
     * @param {AggregationDataReference} ref
     */
    constructor(ref) {
        this.ref = ref;
    }
}

const $ref = {
    in: (elementId) => new AggregationDataReference(dataReferenceType.in, elementId),
    out: (elementId) => new AggregationDataReference(dataReferenceType.out, elementId),
    row: () => new AggregationDataReference(dataReferenceType.row),
    col: (colName) => new AggregationDataReference(dataReferenceType.col, colName)
};

const $in = (elementId) => `$in.${elementId}`;
const $out = (elementId) => `$out.${elementId}`;
const $col = (colName) => `$col.${colName}`;

const $parent = {
    in: "$parent.in",
    out: "$parent.out"
};

const dashboard = (meta, elements) => {
    return {
        meta,
        elements
    };
};

const sortBy = (sortingColumn, sortingOrder) => ({ sortingColumn, sortingOrder });

export {
    meta, elements, ElementBuilder, buildElement,
    $ref, $in, $out, $parent, $col, dashboard, sortBy, AggregationDataReference, dataReferenceType, ActionsArray
};
