import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";
import EchartsChart from "../../echarts/EchartsChart";
import _ from "lodash";

export const symbolType = {
    image: "image",
    circle: "circle",
    rect: "rect",
    none: "none"
};

const [xIndex, yIndex, infoIndex, sizeIndex, showIndex] = [0, 1, 2, 3, 4];

const valueFormatter = (value) => {
    return `${_.round(Math.abs(value) * 100, 2)}%`;
};

const getOption = (data, x, y, dataArgs) => {
    const {
        groupColumn, sizeColumn, sizeLabel, infoColumn, symbol = "circle", title, colorMap,
        xAxisRange = [0, 1], yAxisRange = [0, 1], axisDimensionFormatter,
        sampleThreshold, samplePostfix, sampleColumn,
        minPointSize, maxPointSize
    } = dataArgs;

    let series;
    if (groupColumn) {
        series = [...data.bucketize(groupColumn).entries()].map(([key, df]) => {
            const dataArray = new Array(5);
            dataArray[parseInt(xIndex, 10)] = df.getColumn(x);
            dataArray[parseInt(yIndex, 10)] = df.getColumn(y);
            if (infoColumn) dataArray[parseInt(infoIndex, 10)] = df.getColumn(infoColumn);
            if (sizeColumn) dataArray[parseInt(sizeIndex, 10)] = df.getColumn(sizeColumn);
            // by default, include all points
            dataArray[parseInt(showIndex, 10)] = df.getColumn(x).map(() => true);
            if (sampleThreshold) {
                // only include points that exceed the sample threshold
                if (samplePostfix) {
                    const xSampleCol = df.getColumn(x + samplePostfix);
                    const ySampleCol = df.getColumn(y + samplePostfix);
                    dataArray[parseInt(showIndex, 10)] = xSampleCol.map((e, i) => e >= sampleThreshold && ySampleCol[parseInt(i, 10)] >= sampleThreshold);
                } else if (sampleColumn) {
                    dataArray[parseInt(showIndex, 10)] = df.getColumn(sampleColumn).map((e) => e >= sampleThreshold);
                }
            }

            const e = {
                "type": "scatter",
                // transpose data
                "data": _.zip.apply(_, dataArray),
                "name": key,
                "symbol": (data) => {
                    // don't show symbol if data[parseInt(showIndex, 10)] === false
                    return (data[parseInt(showIndex, 10)] ? symbol : "none");
                },
                "label": {
                    "normal": {
                        "show": true,
                        "formatter": ({ data }) => data[parseInt(infoIndex, 10)],
                        "position": "top",
                        "distance": 0.5,
                        "fontSize": 16,
                        "textBorderColor": "#fff",
                        "textBorderWidth": 3

                    }
                },
                "labelLayout": {
                    "hideOverlap": true
                }
            };

            if (sizeColumn) {
                e.symbolSize = (data) => {
                    if (minPointSize) {
                        return minPointSize + (maxPointSize - minPointSize) * data[parseInt(sizeIndex, 10)] ** 2;
                    } else {
                        return 10 + 50 * data[parseInt(sizeIndex, 10)] ** 2;
                    }
                };
            } else {
                e.symbolSize = 50;
            }

            if (colorMap) {
                e.color = colorMap.get(key);
            }

            return e;
        });
    } else {
        series = [{
            "type": "scatter",
            "data": data.map((row) => [
                row.get(x),
                row.get(y)
            ])
        }];
    }

    const option = {
        "title": {
            "text": title,
            "textStyle": { "fontFamily": "Oswald", "fontWeight": 400 }
        },
        "legend": {},
        "grid": {
            "left": "3%",
            "right": "4%",
            "bottom": "3%",
            "containLabel": true
        },
        "toolbox": {
            "feature": {
                "saveAsImage": {}
            }
        },
        "tooltip": {
            "backgroundColor": ["rgba(255,255,255,0.7)"],
            "formatter": (obj) => {
                return obj.data && "<div style=\"border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px\">" +
                    obj.data[parseInt(infoIndex, 10)] + " - " + obj.seriesName + "<br>" +
                    (sizeLabel || sizeColumn) + ": " + valueFormatter(obj.data[parseInt(sizeIndex, 10)]) + "<br>" +
                    obj.marker + (axisDimensionFormatter ? axisDimensionFormatter(x, obj.data[parseInt(xIndex, 10)]) : x) + ": " + valueFormatter(obj.data[parseInt(xIndex, 10)]) + "<br>" +
                    obj.marker + (axisDimensionFormatter ? axisDimensionFormatter(y, obj.data[parseInt(yIndex, 10)]) : y) + ": " + valueFormatter(obj.data[parseInt(yIndex, 10)]) +
                    "</div>";
            }
        },
        "xAxis": {
            "type": "value",
            "min": xAxisRange[0],
            "max": xAxisRange[1],
            "name": axisDimensionFormatter ? (axisDimensionFormatter(x, xAxisRange[0]) + " - " + axisDimensionFormatter(x, xAxisRange[1])) : x,
            "nameLocation": "middle"
        },
        "yAxis": {
            "type": "value",
            "min": yAxisRange[0],
            "max": yAxisRange[1],
            "name": axisDimensionFormatter ? (axisDimensionFormatter(y, yAxisRange[0]) + " - " + axisDimensionFormatter(y, yAxisRange[1])) : y,
            "nameLocation": "middle"
        },
        series,
        "dataZoom": [
            {
                type: "inside",
                xAxisIndex: [0],
                startValue: xAxisRange[0],
                endValue: xAxisRange[1]
            },
            {
                type: "inside",
                yAxisIndex: [0],
                startValue: yAxisRange[0],
                endValue: yAxisRange[1]
            }
        ],
        ...dataArgs.options
    };
    return option;
};

const ElementChartScatter = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const [chartOptions, setChartOptions] = useState(null);
    const { "data": dataArgs, style } = props;

    const canRender = (data, chartOptions) => !data.isEmpty() && chartOptions != null;

    useEffect(() => {
        if (!data.isEmpty()) {
            const option = getOption(data, dataArgs.x, dataArgs.y, dataArgs);
            setChartOptions(option);
        }
    }, [data]);

    if (canRender(data, chartOptions)) {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}>
                <EchartsChart
                    style={{ "width": "1024px", "height": "1024px", ...style }}
                    option={chartOptions}/>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>);
    }
};

export { ElementChartScatter as ChartScatter };
