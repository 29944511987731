import React, { useContext, useEffect, useState } from "react";
import { Element } from "../Element";
import { Header } from "semantic-ui-react";
import { DataFrame } from "../../DSL/DataFrame";
import { CSVLink } from "react-csv";
import { marked } from "marked";
import {
    getColumnStyle,
    recalculateColumnStyle
} from "./ColumnStyles";
import { prettyPrintValue, sortingOrder } from "../../../UtilityFunctions";
import { Button, Table } from "react-daisyui";
import TPagination from "../../../components/TPagination";
import { NotificationContext } from "../../../screens/NotificationProvider";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import TIcon from "../../../components/TIcon";

const separatorStyle = {
    "padding": "0.2em"
};

const preprocessTabularValue = (val) => {
    if (!Number.isNaN(val)) {
        return val;
    }
    return prettyPrintValue(val);
};

const CSVDownloadButton = (props) => {
    const { df, getColumnName, getValueName, name } = props;
    const csvData = [];
    // headers
    csvData.push(df.getColumns().map(getColumnName));
    const orderValues = (entries) => {
        const cols = df.getColumns();
        return entries.sort(([aK, __], [bK, ___]) => cols.indexOf(aK) - cols.indexOf(bK));
    };
    // rows
    df.rows().forEach((row) => csvData.push(orderValues([...row.entries()]).map(([_, v]) => preprocessTabularValue(v)).map(getValueName)));

    return <CSVLink
        data={csvData} filename={`${name}.csv`}
        target="_blank"
        style={props.style}>
        <Button>
            <TIcon name="document-arrow-down" size={6}/>
            .csv
        </Button>
    </CSVLink>;
};

const ElementTable = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const [processedData, setProcessedData] = useState(data);
    const [page, setPage] = useState(0);
    const [column, setColumn] = useState(null);
    const [direction, setDirection] = useState(null);
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [hovered, setHovered] = useState(undefined);
    const [selected, setSelected] = useState(undefined);
    const {
        title, "data": dataArgs,
        hideHeader, columnAliasMap, valueAliasMap,
        downloadable, style, tableAsText,
        accordionPagination, separations, selectable, setOutput,
        children
    } = props;
    const [columnStyleConfigState, setColumnStyleConfigState] = useState(undefined);
    const { notify } = useContext(NotificationContext);

    const isSelectable = (column) => {
        if (selectable === true) {
            return true;
        } else if (Array.isArray(selectable)) {
            return selectable.includes(column);
        } else {
            return false;
        }
    };

    const orderAsIcon = (order) => {
        if (order === sortingOrder.ASC) {
            return "ᐱ";
        } else if (order === sortingOrder.DESC) {
            return "ᐯ";
        } else {
            return "";
        }
    };

    let onCellEnter, onCellClick;
    if (selectable) {
        onCellEnter = (reference, column) => {
            if (!isSelectable(column)) {
                return;
            }
            setHovered(reference);
        };
        onCellClick = (reference, j, column) => {
            if (!isSelectable(column)) {
                return;
            }
            if (selected !== reference) {
                const x = processedData.getRow(j)
                    .get(processedData.getColumns()[0]);
                setSelected(reference);
                setOutput([j, x, column]);
                console.log("selected", reference, "output", [j, x, column]);
            } else {
                setSelected(undefined);
            }
        };
    } else {
        onCellEnter = () => {};
        onCellClick = () => {};
    }

    const handleSort = (clickedColumn) => () => {
        if (column !== clickedColumn) {
            setColumn(clickedColumn);
            setDirection(sortingOrder.DESC);
            setData(data.copy().sortBy(clickedColumn, sortingOrder.DESC, true));
        } else {
            setDirection(direction === sortingOrder.ASC ? sortingOrder.DESC : sortingOrder.ASC);
            setData(data.copy().reverse());
        }
    };

    useEffect(() => {
        if (!data.isEmpty()) {
            let df = data.copy();
            if (dataArgs.columns) {
                df = df.selectColumns(dataArgs.columns);
                df.reorderColumns(dataArgs.columns);
            }
            if (dataArgs.columnPatterns) {
                const newOrder = [];
                dataArgs.columnPatterns.forEach(
                    pattern => {
                        const regEx = new RegExp(pattern);
                        df.getColumns()
                            .filter(col => regEx.test(col))
                            .filter(col => !newOrder.includes(col))
                            .forEach(col => newOrder.push(col));
                    }
                );
                df = df.selectColumns(newOrder);
                df.reorderColumns(newOrder);
            }
            setProcessedData(df);
        }
    }, [data]);

    useEffect(() => {
        const styleConfig = { columnStyles: dataArgs.columnStyles, sharedColorScheme: dataArgs.sharedColorScheme };
        setColumnStyleConfigState(recalculateColumnStyle(styleConfig, processedData));
    }, [processedData]);

    if (!processedData.isEmpty()) {
        // Page info
        let pageItems = 50;
        if (dataArgs.pageItems) {
            pageItems = dataArgs.pageItems;
        }
        const pages = Math.ceil((processedData.getLength() - 1) / pageItems);
        const getColumnName = (c) => {
            return columnAliasMap && columnAliasMap.has(c) ? columnAliasMap.get(c) : c;
        };
        const getValueName = (v) => {
            return valueAliasMap && valueAliasMap.has(v) ? valueAliasMap.get(v) : v;
        };

        return (
            <Element
                {...props}
                primary
                setData={setData}>
                {title &&
                    <Header as="h2">{title}</Header>}
                <Table
                    zebra={processedData.getLength() > 10}
                    className={" mb-4 mt-4 w-full " + (tableAsText && "text-table")}
                    size="small"
                    compact={tableAsText}
                    style={style}
                >
                    {!hideHeader && <thead className="text-base-content"><tr>
                        {processedData.getColumns().map((h, i) => (
                            <React.Fragment key={i}>
                                {separations && separations.includes(h) &&
                                    <th style={separatorStyle} key={`sep ${i}`}/>
                                }
                                <th
                                    key={i}
                                    onClick={handleSort(h)}
                                    className={`hover:cursor-pointer ${column === h && "bg-base-100"}`}
                                >
                                    <span>
                                        {getColumnName(h)} {column === h ? orderAsIcon(direction) : null}
                                    </span>
                                </th>
                            </React.Fragment>))}
                    </tr></thead>}
                    <Table.Body className="text-base-content">
                        {processedData.getRange().slice(page * pageItems, (1 + page) * pageItems).map(
                            (j) => {
                                if (accordionPagination !== undefined && processedData.getLength() > accordionPagination.rowsPerSide * 2) {
                                    if (!accordionOpen) {
                                        if (j === accordionPagination.rowsPerSide) {
                                            return <tr key={j} className="p-0" onClick={() => setAccordionOpen(true)}>
                                                <td className="text-center hover:cursor-pointer" colSpan={processedData.getColumns().length}>
                                                    [●●●]
                                                </td>
                                            </tr>;
                                        } else if (j > accordionPagination.rowsPerSide &&
                                            j < processedData.getLength() - accordionPagination.rowsPerSide) {
                                            return undefined;
                                        }
                                    }
                                }

                                return <tr key={j} className="p-0">
                                    {processedData.getColumns().map((column, k) => {
                                        const value = prettyPrintValue(processedData.getRow(j).get(column));
                                        let parsed = marked(String(getValueName(value)));
                                        if (value.toString() === "NaN") {
                                            parsed = "";
                                        }
                                        const style = getColumnStyle(columnStyleConfigState, column, parsed);
                                        const isComplex = Object.prototype.hasOwnProperty.call(style, "style") || isSelectable(column);
                                        const reference = `${j} - ${column}`;
                                        return (<>
                                            {separations && separations.includes(column) &&
                                                <td style={separatorStyle}/>
                                            }
                                            {k === 0 && <th
                                                key={k}
                                                {...style}
                                                onMouseEnter={() => onCellEnter(reference, column)}
                                                onClick = {(e) => {
                                                    if (!isComplex) {
                                                        navigator.clipboard.writeText(e.target.innerText);
                                                        notify("Lahtri sisu kopeeritud!", "info");
                                                    }
                                                    onCellClick(reference, j, column);
                                                }}
                                                active={hovered === reference || selected === reference}
                                                className={`
                                                    ${hovered === reference && "hover:cursor-pointer"}
                                                    ${isComplex && "complex"}
                                                `}
                                            >
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: parsed }}
                                                    title={parsed}
                                                />
                                            </th>}
                                            {k > 0 && <td
                                                key={k}
                                                {...style}
                                                onMouseEnter={() => onCellEnter(reference, column)}
                                                onClick = {(e) => {
                                                    if (!isComplex) {
                                                        navigator.clipboard.writeText(e.target.innerText);
                                                        notify("Lahtri sisu kopeeritud!", "info");
                                                    }
                                                    onCellClick(reference, j, column);
                                                }}
                                                active={hovered === reference || selected === reference}
                                                className={`
                                                    ${hovered === reference && "hover:cursor-pointer"}
                                                    ${isComplex && "complex"}
                                                `}
                                            >
                                                <span
                                                    title={parsed}
                                                    dangerouslySetInnerHTML={{ __html: parsed }}
                                                />
                                            </td>}
                                        </>);
                                    })}
                                </tr>;
                            }
                        ).filter(v => v !== undefined)}
                        {accordionPagination !== undefined && accordionOpen && processedData.getLength() > accordionPagination.rowsPerSide * 2 &&
                            <tr key="close" className="hover:cursor-pointer" onClick={() => setAccordionOpen(false)}>
                                <td className="text-center" colSpan={processedData.getColumns().length}>
                                    [Sulge]
                                </td>
                            </tr>
                        }
                    </Table.Body>
                </Table>
                <div style={{ display: "flex" }}>
                    {pages > 1 &&
                        <TPagination
                            {...props.pagination}
                            defaultActivePage={1}
                            ellipsisItem={{ "content": <EllipsisHorizontalIcon className="w-4 h-4"/>, "icon": true }}
                            firstItem={{ "content": <ChevronDoubleLeftIcon className="w-4 h-4"/>, "icon": true }}
                            lastItem={{ "content": <ChevronDoubleRightIcon className="w-4 h-4"/>, "icon": true }}
                            totalPages={pages}
                            page={page}
                            onPageChange={(i) => {
                                setPage(i);
                            }}
                        />}
                    {downloadable && <CSVDownloadButton
                        df={processedData}
                        getColumnName={getColumnName}
                        getValueName={getValueName}
                        name={props.id}
                        style={{ "float": "right" }}/>}
                    {children}
                </div>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>);
    }
};

export { ElementTable as Table };
