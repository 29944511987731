import React, { useState } from "react";
import { Element } from "../Element";
import { Image, Label, List } from "semantic-ui-react";
import { DataFrame } from "../../DSL/DataFrame";
import TIcon from "../../../components/TIcon";
import TStep, { TStepContent, TStepDescription, TStepTitle } from "../../../components/TStep";

const ElementStep = (props) => {
    const [data, setData] = useState([]);
    const [selection, setSelectionValue] = useState(undefined);
    const [isLoaded, setIsLoaded] = useState(false);
    const {
        setOutput, "data": dataArgs, children,
        activeIndex, setActiveIndex, index,
        ...otherProps
    } = props;

    const {
        title, description, icon, imageColumn, valueColumn
    } = dataArgs;
    let choiceColumn = dataArgs?.choiceColumn;

    const setSelection = (val) => {
        if (val) {
            setSelectionValue(val);
            setOutput(val);
            setTimeout(() => setActiveIndex(index + 1), 20);
        }
    };

    let innerHTML = <div>OOTEL!</div>;
    if (data instanceof DataFrame) {
        if (choiceColumn === undefined) {
            choiceColumn = data.getColumns()[0];
        }
        const values = data.getColumn(valueColumn);
        const images = data.getColumn(imageColumn);

        innerHTML = <List
            relaxed
            verticalAlign="middle"
            style={{ margin: "auto" }}
        >
            {data.getColumn(choiceColumn).map((name, i) =>
                <List.Item
                    key={name}
                    onClick={() => setSelection(name)}
                    style={selection === name ? {
                        "background": "rgba(0, 127, 0, 0.25)"
                    } : {}}
                >
                    {images && <Image avatar style={{ objectFit: "scale-down" }} src={images[i]} />}
                    {valueColumn && values && <List.Content floated='right'>
                        {values[i]}
                    </List.Content>}
                    <List.Content as="a">
                        {name}
                    </List.Content>
                </List.Item>
            )}
        </List>;
    }

    function broadcastFunction(elementId, state) {
        setIsLoaded(state === "finished");
    }

    const completed = activeIndex > index && selection !== undefined;
    const disabled = activeIndex < index - 1 && selection === undefined && false;

    return (
        <TStep
            completed={completed}
            disabled={disabled}
            active={activeIndex === index && isLoaded}
            onClick={activeIndex !== index ? () => {
                console.log("onclick");
                setActiveIndex(index);
            } : undefined }
        >
            <Element
                {...otherProps}
                setData={setData}
                broadcastChange={broadcastFunction}
                style={{
                    display: isLoaded ? "block" : "none"
                }}
            >
                {activeIndex === index
                    ? <div
                        style={{
                            display: activeIndex === index ? "flex" : "none",
                            overflowY: "auto",
                            height: "100%",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        {innerHTML}
                    </div>
                    : <React.Fragment>
                        <TIcon name={!completed ? icon : "check"} className="m-3 h-10 w-10"/>
                        <TStepContent>
                            <TStepTitle>{title}</TStepTitle>
                            <TStepDescription>{description}</TStepDescription>
                        </TStepContent>
                        {selection !== undefined && <Label attached="top" color="teal">{selection}</Label>}
                    </React.Fragment>
                }
                {children}
            </Element>
            {!isLoaded && <React.Fragment>
                <TIcon name={!completed ? icon : "check"} className="m-3 h-10 w-10"/>
                <TStepContent>
                    <TStepTitle>{title}</TStepTitle>
                    <TStepDescription>{description}</TStepDescription>
                </TStepContent>
            </React.Fragment>}
        </TStep>
    );
};

export { ElementStep as Step };
