import { MD5 as md5 } from "object-hash";
import { htmlStringToNumber, mapsEqual, sortingOrder, sumReduce, uniques } from "../../UtilityFunctions";
import _ from "lodash";

function assert(condition, message) {
    if (!condition) {
        throw message || "Assertion failed";
    }
}

const shallowCloneMap = (m) => {
    const newMap = new Map();
    for (const entry of m) newMap.set(...entry);
    return newMap;
};

class DataFrame {
    constructor(data = []) {
        if (data instanceof Map) {
            this.data = [shallowCloneMap(data)];
            this.columns = [...data.keys()];
        } else if (data instanceof Array) {
            if (data.length !== 0) {
                this.data = data.map(shallowCloneMap);
                this.columns = uniques(data.flatMap((m) => [...m.keys()])).sort();
            } else {
                this.data = [];
                this.columns = [];
            }
        } else {
            throw new Error("DataFrame initialized with data of unknown type. Accepting Array of Maps or Map.");
        }
        this.order = sortingOrder.NONE;
        this.sortCol = null;
    }

    setData(data) {
        this.data = data;
        this.updateColumnNames();
    }

    map(f) {
        return this.data.map((row, i) => f(row, i));
    }

    getLength() {
        return this.data.length;
    }

    getRange() {
        return [...Array(this.getLength()).keys()];
    }

    isEmpty() {
        return this.getLength() === 0 || this.getColumns().length === 0;
    }

    filter(fun) {
        this.data = this.data.filter(fun);
        return this;
    }

    replaceValues(column, valueMap) {
        if (this.getColumns().includes(column)) {
            this.setColumn(column, this.getColumn(column).map((currValue) => valueMap.has(currValue) ? valueMap.get(currValue) : currValue));
        } else {
            throw new Error(`Cannot find column "${column}" where values are to be replaced in data!`);
        }
        return this;
    }

    replaceValue(column, value, newValue) {
        if (this.getColumns().includes(column)) {
            this.setColumn(column, this.getColumn(column).map((currValue) => currValue === value ? newValue : currValue));
        } else {
            throw new Error(`Cannot find column "${column}" where values are to be replaced in data!`);
        }
        return this;
    }

    uniques(column) {
        return uniques(this.getColumn(column)).sort();
    }

    bucketize(column) {
        const uniques = this.uniques(column);
        const output = new Map();
        uniques.forEach((u) => output.set(u, new DataFrame(this.rows().filter((row) => row.get(column) === u))));
        return output;
    }

    sum(column) {
        if (this.getColumns().includes(column)) {
            return this.getColumn(column).reduce(sumReduce);
        } else {
            throw new Error(`Cannot find column "${column}" to be summed in data!`);
        }
    }

    divide(column, divisor) {
        if (this.getColumns().includes(column)) {
            this.setColumn(column, this.getColumn(column).map((dividend) => dividend / divisor));
        } else {
            throw new Error(`Cannot find column "${column}" to be divided in data!`);
        }
        return this;
    }

    multiply(column, factor) {
        if (this.getColumns().includes(column)) {
            this.setColumn(column, this.getColumn(column).map((value) => value * factor));
        } else {
            throw new Error(`Cannot find column "${column}" to be multiplied in data!`);
        }
        return this;
    }

    rows() {
        return this.data;
    }

    uniqueRows() {
        return _.uniqWith(this.data, mapsEqual);
    }

    append(mapElement) {
        this.columns = (this.data.length > 0 && this.getRow(0).size >= mapElement.size) ? this.columns : [...mapElement.keys()];
        this.data.push(mapElement);
        return this;
    }

    prepend(mapElement) {
        /* update columns: if current data is nonempty and the amount of columns in the first row element is greater
        * or equal than the amount of columns in the passed row, do not update columns, if not, update columns
        * from the passed row */
        this.columns = (this.data.length > 0 && this.getRow(0).size >= mapElement.size) ? this.columns : [...mapElement.keys()];
        this.data.unshift(mapElement);
        return this;
    }

    reorderByReference(column, reference) {
        if (this.getColumns().includes(column)) {
            this.data = this.data.sort((a, b) => {
                return reference.indexOf(a.get(column)) - reference.indexOf(b.get(column));
            });
        } else {
            throw new Error(`Cannot find column "${column}" to be scaled in data!`);
        }
        return this;
    }

    reorderColumns(columns) {
        assert(columns.length === this.columns.length, "columns must be the same length to reorder them.");
        assert(_.isEqual(new Set(columns), new Set(this.columns)), "columns are not equal, cannot reorder.");
        this.columns = columns;
        return this;
    }

    extend(listOfRows) {
        listOfRows.forEach((r) => this.append(r));
        return this;
    }

    setColumn(column, values) {
        assert(values.length === this.getLength() || this.getLength() === 0, "column " + column + " must be the same length as DataFrame_legacy!");
        if (this.getLength() === 0) {
            this.data = values.map((value) => new Map([[column, value]]));
        } else {
            this.data.forEach((row, i) => row.set(column, values[parseInt(i, 10)]));
        }
        if (this.columns.indexOf(column) === -1) {
            this.columns.push(column);
        }
        this.updateColumnNames();
        return this;
    }

    getFirstValue() {
        return this.getRow(0).get(this.columns[0]);
    }

    getFirstNonNullValue() {
        return this.getRow(0).get([...this.getRow(0).keys()][0]);
    }

    getColumn(column) {
        if (this.getColumns().includes(column)) {
            return this.data.map((row) => row.get(column));
        }
    }

    max() {
        return Math.max(...this.data.map((row) => Math.max(...row.values())));
    }

    min() {
        return Math.min(...this.data.map((row) => Math.min(...row.values())));
    }

    selectColumns(columns) {
        return new DataFrame(this.data.map((row) => {
            const newRow = new Map();
            columns.forEach((column) => newRow.set(column, row.get(column)));
            return newRow;
        }));
    }

    removeColumn(column) {
        this.data.forEach((row) => row.delete(column));
        this.updateColumnNames();
        return this;
    }

    renameColumns(columnRenameMap) {
        this.data.forEach((row) => {
            for (const [k, v] of columnRenameMap.entries()) {
                if (row.has(k)) {
                    row.set(v, row.get(k));
                    row.delete(k);
                }
            }
        });
        this.updateColumnNames();
        return this;
    }

    renameColumn(oldName, newName) {
        if (oldName === newName) {
            return this;
        }
        this.data.forEach((row) => {
            row.set(newName, row.get(oldName));
            row.delete(oldName);
        });
        this.updateColumnNames();
        return this;
    }

    updateColumnNames() {
        this.columns = uniques(this.data.flatMap((r) => [...r.keys()]));
        return this;
    }

    copyColumn(from, to) {
        if (from === to) {
            return this;
        }
        this.data.forEach((row) => {
            row.set(to, row.get(from));
        });
        this.columns.push(to);
        return this;
    }

    hasColumn(column) {
        return this.columns.includes(column);
    }

    getRow(i) {
        if (Math.abs(i) >= this.getLength()) {
            throw new Error(`Index Error: no row ${i} in DataFrame of length ${this.getLength()}`);
        }
        return this.data[parseInt(i >= 0 ? i : this.data.length + i, 10)];
    }

    notNullRows() {
        this.data = this.data.filter((row) => row.size !== 0);
        return this;
    }

    copy() {
        return new DataFrame(this.data);
    }

    shallowCopy() {
        const newDf = new DataFrame();
        newDf.data = this.data;
        newDf.columns = this.columns;
        return newDf;
    }

    getColumns() {
        return this.columns;
    }

    sortBy(column, order = sortingOrder.DESC, stringsToNumbers = false) {
        if (!this.columns.includes(column)) {
            throw new Error(`Cannot find sorting column ${column} in columns ${this.columns}!`);
        }

        let sCol;
        if (stringsToNumbers) {
            sCol = "__sorterCol";
            this.setColumn(sCol, this.getColumn(column).map(htmlStringToNumber));
        } else {
            sCol = column;
        }

        switch (order) {
        case sortingOrder.ASC:
            this.data = this.data.sort((a, b) =>
                a.get(sCol) > b.get(sCol) ? 1 : (a.get(sCol) === b.get(sCol) ? 0 : -1));
            break;
        case sortingOrder.DESC: // leave undefined at the bottom
            this.data = this.data.sort((a, b) => (
                a.get(sCol) === undefined ||
                b.get(sCol) > a.get(sCol)) ? 1 : (a.get(sCol) === b.get(sCol) ? 0 : -1));
            break;
        default:
            throw new Error("Incorrect sorting order specified!");
        }

        if (stringsToNumbers) { this.removeColumn(sCol); }

        this.sortCol = column;
        return this;
    }

    reverse() {
        this.data = this.data.reverse();
        return this;
    }

    toString() {
        return `DataFrame with length ${this.getLength().toString()} and columns ${this.getColumns().join()}`;
    }

    toJSON() {
        return this.map((row) => Object.fromEntries(row));
    }

    equals(anotherDF) {
        return _.isEqual(new Set(this.columns), new Set(anotherDF.columns)) && this.data.length === anotherDF.data.length && _.isEqual(this.data, anotherDF.data);
    }

    stringify() {
        return JSON.stringify(_.cloneDeep(this.data).map((row) => Object.fromEntries(row)));
    }

    hashCode(seed = "") {
        // DO NOT turn this into a simplified hashCode where only certain cells, heights etc. are checked
        // Even a single change must trigger change of hashCode, otherwise dashboards will start breaking.
        return md5(
            seed + this.stringify()
        );
    }
}

export { DataFrame };
