import React, { useState } from "react";
import { DataFrame } from "../../../DSL/DataFrame";
import { Element } from "../../Element";
import { Card } from "semantic-ui-react";
import styles from "./CardPopupBar.module.css";
import { Tooltip } from "react-daisyui";

const ElementCardPopupBar = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const { "data": dataArgs, style } = props;
    const {
        alignment, // float
        bars // array of objects with color and value
    } = dataArgs;
    let height = style?.height;
    if (!height) {
        height = "1.5em";
    }

    const outlineColor = "text-base-300";

    const unit = !(data.isEmpty()) ? data.getRow(0) : null; // new Map();

    if (unit == null || !bars) {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>
        );
    }

    let aligner;
    if (alignment === "float") {
        const mid = Math.ceil(bars.length / 2);
        const firstHalf = bars.slice(0, mid);
        const secondHalf = bars.slice(mid);
        const firstHalfSum = firstHalf
            .map(({ column }) => unit.get(column))
            .reduce((partialSum, a) => partialSum + a, 0);
        const secondHalfSum = secondHalf
            .map(({ column }) => unit.get(column))
            .reduce((partialSum, a) => partialSum + a, 0);
        const renderSums = (firstHalfSum + secondHalfSum) < 85;

        aligner = <div
            style={{
                position: "relative",
                width: "100%",
                height: height
            }}
        >
            <div style={{ float: "left" }} className={styles.floatedBar}>
                {firstHalf.map(({ column, color, label }) => {
                    const support = Math.round(unit.get(column));
                    return <div
                        style={{
                            width: `${support}%`,
                            backgroundColor: color,
                            height: "100%",
                            overflow: "visible"
                        }}
                        key={column}
                    >
                        <Tooltip
                            message={`${support}% - ${label}`}
                            className="z-40 relative"
                        >
                            <span
                                className={styles.rightBarText}
                                style={{
                                    visibility: support < 5 ? "hidden" : "visible",
                                    textShadow:
                                        `-1px -1px 0 ${color}, ` +
                                        `1px -1px 0 ${color}, ` +
                                        `-1px 1px 0 ${color}, ` +
                                        `1px 1px 0 ${color}`,
                                    transform: "translateY(-50%)",
                                    color: "white"
                                }}
                            >
                                {support}
                            </span>
                        </Tooltip>
                    </div>;
                })}
            </div>
            {renderSums && <span
                className="text-base-content"
                style={{
                    paddingLeft: "2px",
                    justifyContent: "left",
                    position: "absolute",
                    left: `${firstHalfSum}%`,
                    fontSize: "0.6em",
                    top: "50%",
                    transform: "translate(0, -50%)",
                    lineHeight: 1.5,
                    textShadow:
                        `-1px -1px 0 ${outlineColor}, ` +
                        `1px -1px 0 ${outlineColor}, ` +
                        `-1px 1px 0 ${outlineColor}, ` +
                        `1px 1px 0 ${outlineColor}, ` +
                        `0px 1px 0 ${outlineColor}, ` +
                        `0px -1px 0 ${outlineColor}, ` +
                        `1px 0px 0 ${outlineColor}, ` +
                        `-1px 0px 0 ${outlineColor}`
                }}
            >
                {Math.round(firstHalfSum)}
            </span>}
            <div
                style={{
                    float: "right",
                    marginTop: `-${height}`,
                    width: `${secondHalfSum}%`
                }}
                className={styles.floatedBar}
            >
                {renderSums && <span
                    className={
                        `${styles.rightBarText} 
                        text-base-content
                        `}
                    style={{
                        position: "absolute",
                        textAlign: "right",
                        paddingRight: "2px",
                        width: "2em",
                        left: "-2em",
                        textShadow:
                            `-1px -1px 0 ${outlineColor}, ` +
                            `1px -1px 0 ${outlineColor}, ` +
                            `-1px 1px 0 ${outlineColor}, ` +
                            `1px 1px 0 ${outlineColor}, ` +
                            `0px 1px 0 ${outlineColor}, ` +
                            `0px -1px 0 ${outlineColor}, ` +
                            `1px 0px 0 ${outlineColor}, ` +
                            `-1px 0px 0 ${outlineColor}`
                    }}
                >
                    {Math.round(secondHalfSum)}
                </span>}
                {secondHalf.map(({ column, color, label }) => {
                    const support = Math.round(unit.get(column));
                    return <div
                        style={{
                            width: `${support / (secondHalfSum / 100)}%`,
                            backgroundColor: color,
                            height: "100%"
                        }}
                        key={column}
                    >
                        <Tooltip
                            message={`${support}% - ${label}`}
                            className="z-40 relative"
                        >
                            <span
                                className={styles.leftBarText}
                                style={{
                                    visibility: support < 5 ? "hidden" : "visible",
                                    textShadow:
                                    `-1px -1px 0 ${color}, ` +
                                    `1px -1px 0 ${color}, ` +
                                    `-1px 1px 0 ${color}, ` +
                                    `1px 1px 0 ${color}`,
                                    transform: "translateY(-50%)",
                                    color: "white"
                                }}
                            >
                                {support}
                            </span>
                        </Tooltip>
                    </div>;
                })}
            </div>
        </div>;
    } else {
        aligner = <div>Unknown alignment {alignment}, should be float</div>;
    }

    if (bars) {
        return (
            <Element
                {...props}
                primary
                setData={setData}>
                <Card.Content
                    extra
                    style={{
                        padding: 0,
                        position: "relative"
                    }}
                >
                    {aligner}
                </Card.Content>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>
        );
    }
};

export { ElementCardPopupBar as CardPopupBar };
