import { meta } from "./DSL/dashboardModels";

const metadataMap = new Map([
    ["ratings", meta(
        "Erakondade reitingud",
        "Näitab vabalt valitavas sotsiaaldemograafiliste kombinatsioonlõikes erakondade reitinguid läbi aja.",
        "reitingud",
        ["reitingud", "demograafia"],
        "/media/dashboards/partyRatings.jpg",
        ["dev", "ekre", "isa"],
        "dashboardRatings",
        0)],
    ["parliamentSimulation", meta(
        "Riigikogu simulatsioon",
        "Kui praegu toimuksid valimised, milline näeks välja riigikogu?",
        "reitingud",
        ["reitingud", "riigikogu", "simulatsioon"],
        "/media/dashboards/parliamentSimulation.jpg",
        ["dev", "ekre", "isa"],
        "parliamentMandatesSimulation/dashboardParliamentSimulation",
        0)],
    ["partyRatingsOverview", meta(
        "Erakond rahvastiku lõikes",
        "Näitab valitud erakonna tõuse ja mõõnasid joongraafikuna vanusegrupiti, soo, piirkonna, hariduse jms järgi.",
        "reitingud",
        ["reitingud", "demograafia"],
        "/media/dashboards/partyRatingsOverview.jpg",
        ["dev", "ekre", "isa"],
        "partyRatingsOverview/dashboardPartyRatingsOverview",
        0)],
    ["dashboardRatingChanges", meta(
        "Reitingute muutused",
        "Näitab erakondade reitingute suurimaid muutusi demograafilistes gruppides",
        "reitingud",
        ["reitingud", "demograafia"],
        "/media/dashboards/ratingChanges.jpg",
        ["dev", "ekre", "isa"],
        "dashboardRatingChanges",
        0)],
    ["dashboardQuarterlyRatingsIndividual", meta(
        "Kvartaalsete reitingute fookusgrupp",
        "Näitab üksikvastajate arenguid uuringu lainetes koos nende sotsiaaldemograafilise taustaga.",
        "reitingud",
        ["reitingud", "demograafia"],
        "/media/dashboards/quarterlyRatingsIndividual.png",
        ["dev", "ekre", "isa"],
        "dashboardQuarterlyRatingsIndividual",
        0)],
    ["sankey", meta(
        "Häälte otseliikumised",
        "Töölaud, mis näitab häälte liikumist ühest erakonnast teise igas kvartalis.",
        "reitingud",
        ["reitingud", "sankey"],
        "/media/dashboards/sankey.jpg",
        ["dev", "ekre", "isa"],
        "sankey/dashboardSankey",
        2)],
    ["secpref", meta(
        "Teine eelistus",
        "Töölaud, mis näitab valijate teist eelistust ja nende jaotuseid eri erakondade vahel.",
        "reitingud",
        ["reitingud", "sankey"],
        "/media/dashboards/secpref.png",
        ["dev", "ekre", "isa"],
        "secondPref/dashboardSecondPref",
        1)],
    ["politiciansLeaderboard", meta(
        "Poliitikute edetabel",
        "Poliitikud järjestatud erakonna kaupa tuntuse, meeldivuse või muu järgi. Saab vaadata ka enda valitud sotsiaaldemograafilistes lõigetes.",
        "poliitikud",
        ["reitingud", "poliitik", "tuntus", "isiksus"],
        "/media/dashboards/politiciansLeaderboard.jpg",
        ["dev", "ekre", "isa"],
        "fame/dashboardPoliticiansLeaderboard",
        0)],
    ["politicianOverTime", meta(
        "Poliitik ajas",
        "Näitab ühe poliitiku tuntust, peavoolu, meeldivust ja erinevaid isiksusdimensioone ajaliste graafikutena",
        "poliitikud",
        ["reitingud", "poliitik", "tuntus", "isiksus"],
        "/media/dashboards/politicianOverTime.jpg",
        ["dev", "ekre", "isa"],
        "fame/dashboardPoliticianOverTime",
        0)],
    ["politiciansOverTime", meta(
        "Poliitikud ajas",
        "Näitab mitme poliitiku tuntust, peavoolu, meeldivust ja erinevaid isiksusdimensioone ajaliste graafikutena, rakendades vastajatele filtrit",
        "poliitikud",
        ["reitingud", "poliitik", "tuntus", "isiksus"],
        "/media/dashboards/politiciansOverTime.jpg",
        ["dev", "ekre", "isa"],
        "fame/dashboardPoliticiansOverTime",
        0)],
    ["profile", meta(
        "Poliitiku profiil",
        "Näitab valitud poliitiku profiili: tuntust, soositust ja talle omistatud isikuomadusi Eestis ja selle alagruppides.",
        "poliitikud",
        ["reitingud", "poliitik", "tuntus", "isiksus"],
        "/media/dashboards/profile.jpg",
        ["dev", "isa", "ekre"],
        "fame/politicianProfile/dashboardProfile",
        0)],
    ["politiciansGraph", meta(
        "Poliitikute graaf",
        "Näitab poliitikute omavahelist lähedust nii nende meeldivuse kui iseloomu hinnangute alusel.",
        "poliitikud",
        ["poliitik", "tuntus"],
        "/media/dashboards/politiciansGraph.jpg",
        ["dev"],
        "fame/dashboardPoliticiansGraph",
        1)],
    ["politiciansRatingChanges", meta(
        "Poliitikute reitingute muutus",
        "Näitab poliitikute reitingute hiljutisi tõuse ja mõõnu.",
        "poliitikud",
        ["poliitik", "tuntus", "reitingud"],
        "/media/dashboards/politiciansRatingChanges.png",
        ["dev"],
        "fame/dashboardPoliticiansRatingChanges",
        0)],
    ["valuesOverview", meta(
        "Väärtushinnangud rahvastikus",
        "Annab ülevaate väärtushinnangutest erinevates demograafilistes rühmades.",
        "väärtushinnangud",
        ["väärtushinnangud"],
        "/media/dashboards/valuesOverview.jpg",
        ["dev", "ekre", "isa"],
        "values/dashboardValuesOverview",
        0)],
    ["valuesFilter", meta(
        "Väärtushinnangute võrdlus",
        "Võrdleb väärtushinnanguid valitud demograafilises rühmas ja üldpopulatsioonis.",
        "väärtushinnangud",
        ["väärtushinnangud"],
        "/media/dashboards/valuesFilter.jpg",
        ["dev", "ekre", "isa"],
        "values/dashboardValuesFilter",
        0)],
    ["valuesChanges", meta(
        "Väärtushinnangud ajas",
        "Näitab väärtushinnangute muutusi üle aja",
        "väärtushinnangud",
        ["väärtushinnangud"],
        "/media/dashboards/valuesChanges.jpg",
        ["dev", "ekre", "isa"],
        "values/dashboardValuesChanges",
        1)],
    ["printMediaConsumption", meta(
        "Ajakirjanduse tarbimine",
        "Annab pildi eri ajakirjandusallikate tarbitavusest ja usaldusest eri demograafiates.",
        "meedia",
        ["meedia", "reitingud", "ajakirjandus"],
        "/media/dashboards/printMediaConsumption.jpg",
        ["dev", "ekre", "isa"],
        "media/dashboardPrintMediaConsumption",
        0)],
    ["broadcastMediaConsumption", meta(
        "Ringhäälingumeedia tarbimine",
        "Annab pildi eri ringhäälingumeedia tarbitavusest ja usaldusest eri demograafiates.",
        "meedia",
        ["meedia", "reitingud", "ringhääling"],
        "/media/dashboards/broadcastMediaConsumption.jpg",
        ["dev", "ekre", "isa"],
        "media/dashboardBroadcastMediaConsumption",
        0)],
    ["partyStrengths", meta(
        "Erakondade tugevused ja nõrkused",
        "Näitab kõigi erakondade poliitikute tugevusi ja nõrkusi.",
        "erakonnad",
        ["erakond", "poliitik", "isiksus", "iseloom"],
        "/media/dashboards/partyStrengths.jpg",
        ["dev"],
        "dashboardPartyStrengthWeakness",
        0)],
    ["partyIssues", meta(
        "Suurimad probleemid erakondade valijate lõikes",
        "Näitab valitud erakondade valijate arusaamu suurimatest probleemidest.",
        "erakonnad",
        ["erakond", "valija", "väärtushinnangud"],
        "/media/dashboards/biggestProblems.jpg",
        ["dev"],
        "dashboardPartyBiggestIssues",
        0)],
    ["politicalTopics", meta(
        "Poliitteemad",
        "Töölaud annab ülevaate maailma ja Eesti poliitiliste teemade olulisusest üldiselt kui ka grupiti.",
        "poliitteemad",
        [],
        "/media/dashboards/politicalTopics.jpg",
        ["dev", "ekre", "isa"],
        "politicalTopics/polTopics/dashboardPoliticalTopics",
        1)],
    ["politicalTopicsDemographic", meta(
        "Poliitteemade tähtsus",
        "Töölaud näitab eri demograafiates poliitteemade tähtsust.",
        "poliitteemad",
        [],
        "/media/dashboards/polTopicsDemographic.png",
        ["dev", "ekre", "isa"],
        "politicalTopics/dashboardPolTopicsDemographic",
        1)],
    ["politicalTopicsOverTime", meta(
        "Poliitteemad ajas",
        "Poliiteemade olulisuse muutumine eri ajahetkedel.",
        "poliitteemad",
        [],
        "/media/dashboards/politicalTopicsOverTime.png",
        ["dev", "ekre", "isa"],
        "politicalTopics/overTime/dashboardPolTopicsOverTime",
        0)],
    ["performanceTest", meta(
        "Kiiruse test",
        "Arendaja kiirusetestid!",
        "arendaja",
        ["arendaja"],
        "/media/decision_tree.jpg",
        ["dev"],
        "dev/dashboardPerformanceTest",
        0)],
    ["altValues", meta(
        "AltValuesi testi ülevaade",
        "Erinevad testist saadud tulemused demograafilistes ja ideoloogilistes lõigetes.",
        "arendaja",
        ["arendaja"],
        "/media/dashboards/altvalues.png",
        ["dev"],
        "dev/altValues/dashboardAltValues",
        0)],
    ["dataCatalogue", meta(
        "Andmekataloog",
        "Arendaja andmekataloog!",
        "arendaja",
        ["arendaja"],
        "/media/dashboards/dataCatalogue.jpg",
        ["dev"],
        "dev/dashboardDataCatalogue",
        0
    )],
    ["roadmap", meta(
        "Teeviit",
        "Praktiline sissejuhatus sisekeskkonda, mis annab viidad, mis teemadega, kus ja kellega püüda hääli.",
        "erakonnad",
        [],
        "/media/dashboards/partyRatings.jpg",
        ["dev", "ekre", "isa"],
        "dashboardRoadmap/dashboardRoadmap",
        0)],
    ["electorate", meta(
        "Valijaskonna ülevaade",
        "Ülevaade valijaskonnast demograafiliste rühmade ja valimiseelistuse lõikes.",
        "demograafia",
        ["demograafia"],
        "/media/dashboards/partyRatings.jpg",
        ["dev"],
        "electorate/dashboardElectorate",
        0)],
    ["politiciansScatterplot", meta(
        "Poliitikute hajuvusdiagramm",
        "Poliitikud hajuvus- ehk punktdiagrammil valitud telgede lõikes. Saab vaadata ka enda valitud sotsiaaldemograafilistes lõigetes.",
        "mitteaktiivne",
        ["reitingud", "poliitik", "tuntus", "isiksus"],
        "/media/dashboards/politiciansLeaderboard.jpg",
        ["dev", "ekre", "isa"],
        "fame/dashboardPoliticiansScatterplot",
        1)],
    ["mediaFrequency", meta(
        "Tekst meedias",
        "Näitab, kuidas ajas teksti esinevus meediatekstides muutub.",
        "mitteaktiivne",
        ["meedia", "tekst", "ajakirjandus"],
        "/media/dashboards/mediaFrequency.jpg",
        ["dev", "ekre", "isa"],
        "media/dashboardMediaFrequency",
        0)],
    ["mediaComparison", meta(
        "Tekstide võrdlus meedias",
        "Näitab, kuidas ajas tekstide esinevus üksteise võrdluses on meedias muutunud.",
        "mitteaktiivne",
        ["meedia", "tekst", "ajakirjandus"],
        "/media/dashboards/mediaComparison.jpg",
        ["dev", "ekre", "isa"],
        "media/dashboardMediaComparison",
        0)],
    ["polWorldviewCorrelation", meta(
        "Poliitikute isikukuvand",
        "Näitab, kuidas iseloomustavad poliitikud sarnase ja erinevate maailmavaatega valijad ning kuidas kuvand nende kahe grupi vahel erineb.",
        "mitteaktiivne",
        ["reitingud", "poliitik", "tuntus", "isiksus"],
        "/media/dashboards/polWorldviewCorrelation.jpg",
        ["dev"],
        "dashboardPolWorldviewCorrelation",
        1)],
    ["politiciansPersonalityScales", meta(
        "Poliitikute isiksusjärjestus",
        "Järjestab kõik poliitikud erinevatel iseloomudimensioonidel keskmise kaupa.",
        "mitteaktiivne",
        ["isiksus", "poliitik"],
        "/media/dashboards/politicianPersonalityScales.jpg",
        ["dev"],
        "fame/dashboardPoliticiansOnPersonalityScales",
        0)]
]);

for (const [ignored, val] of metadataMap.entries()) {
    if (val.userGroups.includes("ekre") || val.userGroups.includes("isa")) {
        val.tags.push("avaldatud");
    } else {
        val.tags.push("avaldamata");
    }
}

export const categoryColors = {
    "mitteaktiivne": "black",
    "reitingud": "#618d3d",
    "poliitikud": "#3d628d",
    "väärtushinnangud": "#723d8d",
    "meedia": "#af9932",
    "erakonnad": "#8d3d50",
    "poliitteemad": "#3d8d75",
    "arendaja": "black"
};

export default metadataMap;
