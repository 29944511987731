import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { action, fun } from "../../DSL/aggregatorModels";
import { DataFrame } from "../../DSL/DataFrame";
import { uniques } from "../../../UtilityFunctions";
import styles from "./ElementFilterTargetGroup.module.css";
import { actions, fi } from "../../DSL/aggregatorModels2";
import FormFieldHeader from "./FormFieldHeader";
import { Button, ButtonGroup } from "react-daisyui";
import TNestedMultiSelect from "../../../components/TNestedMultiSelect";
import { dataAccessGetCompetitors } from "../../../auth/AggregationSecurity";

export const targetGroup = (dim, label = dim) => {
    const tg = { dim, label };
    tg.setCategories = (values, labels = values) => {
        tg.categories = values.map((value, i) => (
            { value, "label": labels[parseInt(i, 10)] }
        ));
        return tg;
    };
    tg.setDim = (dim) => {
        tg.dim = dim;
        return tg;
    };
    return tg;
};

export const partyTgs = targetGroup("pol__first_party_preference", "Erakondade valijad")
    .setCategories(
        ["Eesti Konservatiivne Rahvaerakond", "Isamaa", "Sotsiaaldemokraatlik Erakond", "Eesti Reformierakond", "Eesti Keskerakond", "Eesti 200"],
        ["EKRE", "ISA", "SDE", "REF", "KESK", "E200"]
    );

export const partyTgsFiltered = (excludeParties) => {
    if (!excludeParties) {
        excludeParties = [...dataAccessGetCompetitors()];
    }
    let namesLong = ["Eesti Konservatiivne Rahvaerakond", "Isamaa", "Sotsiaaldemokraatlik Erakond", "Eesti Reformierakond", "Eesti Keskerakond", "Eesti 200"];
    let namesShort = ["EKRE", "ISA", "SDE", "REF", "KESK", "E200"];

    const namesKeep = namesLong.map((long, i) =>
        !(excludeParties.includes(long) || excludeParties.includes(namesShort[i])));
    namesLong = namesLong.filter((s, i) => namesKeep[i]);
    namesShort = namesShort.filter((s, i) => namesKeep[i]);

    return targetGroup("pol__first_party_preference", "Erakondade valijad")
        .setCategories(namesLong, namesShort);
};

export const ageTgs = targetGroup("demo__age_group", "Vanus")
    .setCategories(["18-24", "25-34", "35-49", "50-64", "65-74", "75+"]);

export const incTgs = targetGroup("demo__personal_net_income", "Netosissetulek")
    .setCategories(
        ["Kuni 500 €", "501-1000 €", "1001-1500 €", "1501-2000 €", "Üle 2000 €"],
        ["<500", "501-1000", "1001-1500", "1501-2000", ">2000"]
    );

export const nationalityTgs = targetGroup("demo__nationality_binary", "Rahvus")
    .setCategories(["Eestlane", "Muu"]);

export const childrenTgs = targetGroup("demo__children_total", "Laste arv")
    .setCategories(["0", "1", "2", "3+"]);

export const genderTgs = targetGroup("demo__gender", "Sugu")
    .setCategories(["Mees", "Naine"]);

export const constituencyTgs = targetGroup("demo__constituency", "Ringkond")
    .setCategories(
        ["Harju- (v.a Tallinn) ja Raplamaa", "Hiiu-, Lääne- ja Saaremaa", "Ida-Virumaa", "Järva- ja Viljandimaa",
            "Jõgeva- ja Tartumaa (v.a Tartu linn)", "Lääne-Virumaa", "Pärnumaa",
            "Tallinna Haabersti, Põhja-Tallinna ja Kristiine linnaosa", "Tallinna Kesklinna, Lasnamäe ja Pirita linnaosa",
            "Tallinna Mustamäe ja Nõmme linnaosa", "Tartu linn", "Võru-, Valga- ja Põlvamaa"],
        ["Harju- ja Rapla", "Hiiu, Lääne- ja Saare", "Ida-Viru", "Järva- ja Viljandi",
            "Jõgeva- ja Tartu", "Lääne-Viru", "Pärnu",
            "Haabersti, Põhja-Tallinna ja Kristiine", "Kesklinna, Lasnamäe ja Pirita",
            "Mustamäe ja Nõmme", "Tartu linn", "Võru-, Valga- ja Põlva"]
    );

export const valueTgs = {
    incomeTgs: targetGroup("demo__income_personal_net", "Netosissetulek")
        .setCategories(
            ["Kuni 200 €", "201 - 300 €", "301 - 400 €", "401 - 500 €", "501 - 650 €",
                "651 - 800 €", "801 - 1000 €", "1001 - 1300 €", "1301 - 1600 €", "Üle 1600 €"],
            ["<200", "201-300", "301-400", "401-500", "501-650",
                "651-800", "801-1000", "1001-1300", "1301-1600", ">1600"]
        ),
    maritalTgs: targetGroup("demo__marital_status", "Perekonnaseis")
        .setCategories(
            ["Abielus", "Lahutatud või abikaasast/varasemast elukaaslasest lahus elav",
                "Lesk", "Partner olemas, kuid koos ei ela", "Vabaabielus/registreerimata kooselus",
                "Vallaline, pole kunagi abielus olnud ega elanud koos kindla partneriga",
                "Vallaline, pole kunagi abielus olnud ega koos kindla partneriga elanud"],
            ["Abielus", "Lahutatud",
                "Lesk", "Partner, ei ela koos", "Vabaabielus",
                "Vallaline, pole koos elanud",
                "Vallaline, pole koos elanud"]
        ),
    libconTgs: targetGroup("libcon__self_identification", "Konservatiivsus")
        .setCategories(
            [1, 2, 3, 4, 5, 6, 7],
            ["1 lib", "2", "3", "4 neut", "5", "6", "7 kon"]
        )
};

const filterTargetGroupStates = {
    collapsed: "collapsed",
    opened: "opened",
    nested: "nested"
};

const targetFilterToDslFilterActions = (targetFilter) => {
    const usedDims = targetFilter.filter(tg => tg.categories.some(cat => cat.active)).map(tg => tg.dim);
    const filterActions = usedDims.map(dim => {
        const activeCategories = targetFilter.find(tg => tg.dim === dim).categories.filter(cat => cat.active).map(cat => cat.value);
        return fi.containsAny(dim, activeCategories);
    });
    return actions.filter(...filterActions);
};

const wrapperStyle = {
    marginBottom: "0px"
};

const ElementFilterTargetGroup = (props) => {
    const { "data": dataArgs, saveInteractionState } = props;
    const [data, setData] = useState(new DataFrame([]));
    const [elementState, setElementState] = useState(filterTargetGroupStates.collapsed);
    // this element has 2 filters: targetFilter, used in collapsed and expanded state and nestedFilter, used in
    // nested state
    const [nestedFilter, setNestedFilter] = useState(dataArgs.defaultInteractionState || {});
    const [nestedOptions, setNestedOptions] = useState([]);
    const nestedLabelMap = dataArgs.labelMap || {};

    const [targetFilter, setTargetFilter] = useState(dataArgs.targetGroups || []);

    const title = dataArgs.title || "Sihtgrupp";
    const titleElement = <FormFieldHeader>{title}</FormFieldHeader>;

    useEffect(() => {
        if (elementState !== filterTargetGroupStates.nested) {
            // set targetFilter output
            props.setOutput(targetFilterToDslFilterActions(targetFilter));
        }
    }, [targetFilter, elementState]);

    // const targetGroups = dataArgs.targetGroups;
    /*
        * Data attributes:
        * nestedLabelMap -
        *   name - name without prefix
        *   alias - name to use instead of name
        * */

    useEffect(() => {
        if (!data.isEmpty() && elementState !== filterTargetGroupStates.nested) {
            // try generating targetFilter out of data
            const df = data;
            if (targetFilter.length === 0) {
                let targetOptions = df.getColumns()
                    .map((column) => ({
                        "dim": column,
                        "label": Object.prototype.hasOwnProperty.call(nestedLabelMap, column) ? nestedLabelMap[column.toString()].alias || nestedLabelMap[column.toString()] : column,
                        "categories": uniques(df.getColumn(column).reduce((x, y) => x.concat(y), [])).sort().map(c => ({ "value": c }))
                    }));
                // remove non-categorical
                targetOptions = targetOptions.filter((option) => option.categories.length < 100);
                setTargetFilter(targetOptions);
            }
        }
    });

    // set nested filter options
    useEffect(() => {
        if (!data.isEmpty() && elementState === filterTargetGroupStates.nested) {
            const df = data;
            if (nestedOptions.length === 0) {
                let mainOptions = df.getColumns()
                    .map((column) => ({
                        "key": column,
                        "text": Object.prototype.hasOwnProperty.call(nestedLabelMap, column) ? nestedLabelMap[column.toString()].alias || nestedLabelMap[column.toString()] : column,
                        "value": column,
                        "options": uniques(df.getColumn(column).reduce((x, y) => x.concat(y), [])).sort().map((key) => ({
                            key,
                            "text": key,
                            "value": key
                        }))
                    }));
                // filter values with too many unique options
                mainOptions = mainOptions.filter((option) => option.options.length < 100);
                setNestedOptions(mainOptions);
            }
        }
    }, [data, elementState]);

    // use nested filter options to construct output
    useEffect(() => {
        if (!data.isEmpty() && elementState === filterTargetGroupStates.nested) {
            if (saveInteractionState !== undefined) {
                saveInteractionState(nestedFilter);
            }
            const out = action.filter(
                ...Object.keys(nestedFilter)
                    .filter((key) => nestedFilter[key.toString()].length > 0)
                    .map((key) => fun(key, "contains_any", nestedFilter[key.toString()]))
            );
            props.setOutput(out);
        }
    }, [nestedFilter, data]);

    // render non-nested filter category buttons
    const renderCategoryButton = (cat, tg) => (
        <Button
            className={
                `${styles.filterTgEl} 
                ${cat.active && styles.filterTgElSelected}
                ${cat.active && "bg-opacity-50"}`
            }
            key={`${cat.value}`}
            active={cat.active}
            onClick={() => {
                const tgIdx = targetFilter.findIndex(ftg => ftg.dim === tg.dim);
                const catIdx = tg.categories.findIndex(tgcat => tgcat.value === cat.value);
                const newCat = { ...cat, active: !cat.active };
                const newTgFilter = [...targetFilter];
                newTgFilter[parseInt(tgIdx, 10)].categories[parseInt(catIdx, 10)] = newCat;
                setTargetFilter(newTgFilter);
            }}>
            {cat.label || cat.value}
        </Button>
    );

    const actions = {
        expand: () => { setElementState(filterTargetGroupStates.opened); },
        collapse: () => { setElementState(filterTargetGroupStates.collapsed); },
        nested: () => { setElementState(filterTargetGroupStates.nested); }
    };

    const renderCategoryGroup = (tg) => (
        <div className={styles.filterTgRowWrapper} key={tg.dim}>
            <sub
                style={{ opacity: 0.5 }}>
                <b>{tg.label}</b>
            </sub>
            <ButtonGroup compact className={styles.filterTgRow} fluid>
                {tg.categories.map(c => renderCategoryButton(c, tg))}
            </ButtonGroup>
        </div>
    );

    const renderFilterCollapsed = () => {
        const elements = targetFilter.map(renderCategoryGroup);
        return <div style={wrapperStyle}>
            {titleElement}
            <div className={`${styles.filterTgField} ${styles.collapsed}`}>{elements}</div>
            <div className="flex gap-x-2">
                <div onClick={actions.expand} className="btn btn-ghost btn-xs text-base-content/50">Täpsem sihtrühm</div>
                <div onClick={actions.nested} className="btn btn-ghost btn-xs text-base-content/50">Vaba filter</div>
            </div>
        </div>;
    };

    const renderFilterOpened = () => {
        const elements = targetFilter.map(renderCategoryGroup);
        return <div style={wrapperStyle}>
            {titleElement}
            <div className={styles.filterTgField}>{elements}</div>
            <div
            >
                <div onClick={actions.collapse} className="btn btn-ghost btn-xs text-base-content/50">Vähem sihtrühmi</div>
                <div onClick={actions.nested} className="btn btn-ghost btn-xs text-base-content/50">Vaba filter</div>
            </div>
        </div>;
    };

    const changeNestedFilter = (selection) => {
        setNestedFilter((oldFilter) => {
            const newFilter = { ...oldFilter };
            if (selection.values.length === 0) {
                if (Object.prototype.hasOwnProperty.call(newFilter, selection.key)) {
                    delete newFilter[selection.key];
                }
            } else {
                newFilter[selection.key] = selection.values;
            }
            return newFilter;
        });
    };

    const renderFilterNested = () => (
        <div style={wrapperStyle}>
            {titleElement}
            {!data.isEmpty() &&
                <TNestedMultiSelect
                    defaultValue={dataArgs.defaultInteractionState ? dataArgs.defaultInteractionState : undefined}
                    options={nestedOptions}
                    onChange={changeNestedFilter}
                />}
            <div
            >
                <div onClick={actions.expand} className="btn btn-ghost btn-xs text-base-content/50">Lihtne filter</div>
            </div>
        </div>
    );

    const renderByFilterState = {
        [filterTargetGroupStates.collapsed]: renderFilterCollapsed,
        [filterTargetGroupStates.opened]: renderFilterOpened,
        [filterTargetGroupStates.nested]: renderFilterNested
    };

    return (
        <Element
            {...props}
            setData={setData}>
            {renderByFilterState[elementState.toString()]()}
        </Element>);
};

export { ElementFilterTargetGroup as FilterTargetGroup };
