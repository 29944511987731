import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { orderIcon, sortingOrder } from "../../../UtilityFunctions";
import FormFieldHeader from "../form/FormFieldHeader";
import { Button } from "react-daisyui";

const ElementSortingOrderToggle = (props) => {
    const [sortOrder, setSortingOrder] = useState(sortingOrder.DESC);

    const { setOutput } = props;

    useEffect(() => {
        setOutput(sortOrder);
    }, [sortOrder]);

    const handleSortOrderToggle = () => {
        if (sortOrder === sortingOrder.DESC) {
            setSortingOrder(sortingOrder.ASC);
        } else {
            setSortingOrder(sortingOrder.DESC);
        }
    };

    return (
        <Element
            {...props}
        >
            <FormFieldHeader/>
            <Button
                onClick={handleSortOrderToggle}
                {...props}
            >
                {orderIcon[sortOrder.toString()]}
            </Button>
        </Element>
    );
};

export { ElementSortingOrderToggle as SortingOrderToggle };
