import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Button, Header, Loader } from "semantic-ui-react";
import { DataFrame } from "../DSL/DataFrame";
import { DashboardElementBuilderContext } from "../DashboardElementBuilder";
import { $in, ActionsArray } from "../DSL/dashboardModels";
import { loadingMessageToPercentage, loadingStates } from "../stateConstants";
import { debugModeEnabled } from "../../debug/debugConfig";
import { DebugControls } from "../../debug/controls/DebugControls";
import { DATA_UNINITIALIZED } from "../subscriptions/Source";

const stateCanRequestAggregation = (loadingState) => {
    return [loadingStates.FINISHED, loadingStates.ERROR,
        loadingStates.WAITING_FOR_DEPENDENCIES].includes(loadingState.getLoadingState());
};

const renderStates = {
    "ELEMENT_AWAITING_DEPENDENCIES": "element awaiting dependencies",
    "ELEMENT_AWAITING_CONNECTION": "element awaiting connection",
    "ELEMENT_LOADING": "element loading",
    "ELEMENT_TRANSPARENT_LOADING": "element transparent loading",
    "ELEMENT_COMPLETE": "element complete",
    "ELEMENT_ERROR": "element error",
    "ELEMENT_UNKNOWN": "element unknown"
};

const parsedDataType = {
    "RAW_DATA": "Raw data",
    "AGGREGATION": "Aggregation instruction",
    "OTHER": "other"
};

const getParsedDataType = (parsedData) => {
    if ("definition" in parsedData) {
        const { source, actions } = parsedData.definition;
        if (source.type !== "RAW" || (actions && actions.length > 0)) {
            return parsedDataType.AGGREGATION;
        } else if ((source.data instanceof DataFrame && source.type === "RAW" && (!actions || actions.length === 0)) ||
            (Array.isArray(source.data) && source.type === "RAW")) {
            return parsedDataType.RAW_DATA;
        }
    }
    return parsedDataType.OTHER;
};

const getParsedDataDf = (parsedData) => {
    return parsedData.definition.source.data;
};

export class ElementLoadingState {
    constructor(id, updateElementCallback) {
        this.id = id;
        this.state = loadingStates.INITIALISED;
        this.stateMessage = null;
        this.updateElementCallback = updateElementCallback;
    }

    getLoadingState = () => this.state;
    getStateMessage = () => this.stateMessage;
    getLoadingPercentage = () => loadingMessageToPercentage.get(this.stateMessage);

    update = (state, message = null) => {
        this.state = state;
        this.stateMessage = message;
        this.updateElementCallback();
    }
}

const postprocessAggregation = (definition) => {
    const iterate = (obj, parentObject, parentObjectKey) => {
        if (obj instanceof ActionsArray) {
            const actionsArray = obj.ref;
            parentObject.splice(parseInt(parentObjectKey), 1, ...actionsArray);
            // eslint-disable-next-line no-prototype-builtins
        } else if (obj instanceof Object && obj.hasOwnProperty("type") && obj.type === "RAW") {
            if (obj.data instanceof DataFrame) {
                obj.data = obj.data.toJSON();
            }
        } else {
            Object.entries(obj).forEach(([key, value]) => {
                if (value instanceof Object) {
                    iterate(value, obj, key);
                }
            });
        }
    };
    iterate(definition, null, null);

    return definition;
};

const Element = (props) => {
    const [subscription, updateSubscription] = useState(null);
    const [lastHeight, ignored] = useState(0); // TODO: fix
    const [lastLoadingTime, setLastLoadingTime] = useState(null);
    const [lastLoadingStartTime, setLastLoadingStartTime] = useState(null);
    const [, updateState] = useState(0);
    const forceUpdate = useCallback(() => { updateState((tick) => tick + 1); }, []);
    const [elementLoadingState, _ignored] = useState(new ElementLoadingState(props.id, forceUpdate));

    const subscriptionHandler = useContext(DashboardElementBuilderContext).getSubscriptionHandler();

    const {
        "id": elementId,
        "setData": elementDataCallback,
        "blocking": isBlocking,
        dataWrapper,
        children,
        broadcastChange,
        "className": propsClassName
    } = props;

    const getSubscriptionsText = () => {
        return subscriptionHandler
            .getSubscriptions(elementId)
            .map((name) => name.replace("$out.", "").replace("$in.", ""))
            .join(", ");
    };

    const handleParsedData = {
        [parsedDataType.AGGREGATION]: (parsedData) => {
            // if the data type is an aggregation, then it has to be sent, if possible.
            if (!stateCanRequestAggregation(elementLoadingState)) {
                // TODO: consider async behavior, maybe turn this into an if/else with a .then(subscriptionHandler.requestAggregation) for this particular condition
                subscriptionHandler.cancelOngoingAggregation(elementId);
            }
            subscriptionHandler.requestAggregation(postprocessAggregation(parsedData.definition), elementId);
            elementLoadingState.update(loadingStates.WAITING_FOR_CONNECTION, "Arvutuskeskuse ühenduse ootel");
        },
        [parsedDataType.RAW_DATA]: (parsedData) => {
            // if the data type is raw data, it needs to be registered via the subscription handler.
            subscriptionHandler.registerRawData(elementId, getParsedDataDf(parsedData));
        },
        [parsedDataType.OTHER]: (_) => {
            elementLoadingState.update(loadingStates.FINISHED);
        }
    };

    const elementSourceProperties = {
        elementLoadingState,
        elementDataCallback,
        isBlocking
    };

    const initializeElement = () => {
        subscriptionHandler.setElementSourceProperties(elementId, elementSourceProperties);
        subscriptionHandler.setSubscriptionCallback(elementId, updateSubscription);
        elementLoadingState.update(loadingStates.INITIALISED);
    };

    const unloadElement = () => {
        subscriptionHandler.cancelOngoingAggregation(elementId);
        const message = `${elementId} on eemaldatud`;
        elementLoadingState.update(loadingStates.REMOVED, message);
    };

    const handleElementSubscriptionUpdate = () => {
        if (subscriptionHandler.elementSubscriptionsResolved(elementId)) {
            // If all the subscriptions of the element are resolved
            const parsedData = dataWrapper.getParsed();
            handleParsedData[getParsedDataType(parsedData)](parsedData);

            subscriptionHandler.sources.get($in(props.id))?.tellObserversFinished();
        } else {
            // If subscriptions have not yet been resolved, display text stating awaited subscriptions
            const message = `${elementId} ootab elemente: ${getSubscriptionsText()}`;
            elementLoadingState.update(loadingStates.WAITING_FOR_DEPENDENCIES, message);
        }
    };

    // constructor
    useEffect(() => {
        initializeElement();
        return unloadElement;
    }, []);

    // on subscription update
    useEffect(handleElementSubscriptionUpdate, [subscription]);

    if (broadcastChange) {
        // This is used in case a parent element needs to catch
        // updates on the child element. Example usage can be found
        // in ElementDataframeConcatenator
        broadcastChange(elementId, elementLoadingState.getLoadingState());
    }

    // Styling
    const style = {
        ...props.style,
        "flexGrow": props.grow,
        "width": props.width,
        "height": props.height
    };

    Object.entries(style).forEach((o) => (o[1] === null ? delete style[o[0]] : 0));

    if (props.scroll) {
        style.overflowY = "scroll";
        style.minWidth = "fit-content";
        style.whiteSpace = "nowrap";
    }
    if (props.primary) {
        style.padding = "1rem";
    }

    let debugControls;
    if (debugModeEnabled()) {
        if (subscriptionHandler.elementSubscriptionsResolved(elementId)) {
            // for debug statistics
            const time = new Date().getTime() / 1000;
            const loadingState = elementLoadingState.getLoadingState();
            if (lastLoadingStartTime === null && loadingState !== loadingStates.FINISHED) {
                setLastLoadingStartTime(time);
            } else if (loadingState === loadingStates.FINISHED && lastLoadingStartTime !== null) {
                const elapsed = Math.round((time - lastLoadingStartTime) * 10) / 10;
                setLastLoadingStartTime(null);
                setLastLoadingTime(elapsed);
            }
        }

        let parsedData, functionReload, dataDefinition;
        if (subscriptionHandler.elementSubscriptionsResolved(elementId)) {
            parsedData = dataWrapper.getParsed();
            const dataType = getParsedDataType(parsedData);
            functionReload = dataType !== parsedDataType.RAW_DATA &&
                (parsedData.data || parsedData.definition) &&
                elementLoadingState.getLoadingState() !== loadingStates.WAITING_FOR_DEPENDENCIES &&
                (() => handleParsedData[dataType](parsedData));
            dataDefinition = dataType !== parsedDataType.RAW_DATA && parsedData.definition;
        }

        const dataFrame = subscriptionHandler.getData($in(elementId));

        debugControls = <DebugControls
            functionReload={functionReload}
            dataDefinition={dataDefinition}
            data={dataFrame !== DATA_UNINITIALIZED && dataFrame}
            elementId={elementId}
            lastLoadingTime={lastLoadingTime}
        />;
    }

    const loadingStyle = {
        ...style,
        "minHeight": lastHeight,
        "minWidth": "150px",
        "width": "100%",
        "height": "auto",
        "position": "relative"
    };
    const className = "dashboard-element " + (props.primary ? "primary" : null) + " " + propsClassName;
    const renderByRenderState = (renderState) => {
        switch (renderState) {
        case renderStates.ELEMENT_AWAITING_DEPENDENCIES:
            return (
                <div
                    style={loadingStyle}
                    className={className + " rounded-box p-4 text-center"}
                    placeholder
                >
                    {debugControls}
                    {debugModeEnabled()
                        ? <Header
                            as="h5"
                            textAlign="center"
                            disabled
                            style={{
                                fontWeight: 500,
                                display: "inline",
                                wordWrap: "break-word",
                                whiteSpace: "normal"
                            }}
                        >
                            {elementLoadingState.getStateMessage()}
                        </Header>
                        : <div
                            style={{
                                height: 150,
                                width: "100%",
                                opacity: 0.5,
                                backgroundSize: "400% 400%",
                                animation: "gradient 5s ease infinite"
                            }}
                            className="bg-gradient-to-r from-primary/20 to-transparent rounded-box"
                        >
                        </div>
                    }
                </div>
            );
        case renderStates.ELEMENT_AWAITING_CONNECTION:
            return (
                <div
                    style={loadingStyle}
                    className={className + " bg-base-100 rounded-box p-4"}
                    placeholder
                >
                    {debugControls}
                    <Loader
                        active
                        size="medium"
                        style={{ "zIndex": 0 }}
                    >
                        <Header as="h4">{elementId + " ootab ühendust."}</Header>
                        {"Staatus: " + elementLoadingState.getStateMessage()}
                    </Loader>
                </div>
            );
        case renderStates.ELEMENT_LOADING: {
            const loadingPercentage = elementLoadingState.getLoadingPercentage();
            let progressType = "";
            if (loadingPercentage > 0) {
                progressType = "progress-info";
            } else if (loadingPercentage >= 80) {
                progressType = "progress-success";
            }
            return (
                <div
                    className={className + " flex items-center m-4 relative flex-col bg-base-100 rounded-box mb-2 mt-2 pt-2 pb-2 items-center"}
                    placeholder
                    style={style}
                >
                    {debugControls}
                    <progress
                        className={"progress " + progressType + " w-56"}
                        value={loadingPercentage}
                        max={100}
                    />
                    {elementLoadingState.getStateMessage()}
                </div>
            );
        }
        case renderStates.ELEMENT_TRANSPARENT_LOADING:
        case renderStates.ELEMENT_COMPLETE:
            return (
                <Fragment>
                    {debugControls}
                    {children}
                </Fragment>
            );
        case renderStates.ELEMENT_ERROR: {
            const parsedData = dataWrapper.getParsed();
            const dataType = getParsedDataType(parsedData);
            const message = elementLoadingState.getStateMessage();
            let messageStack = String(message.message)
                .replace(/\\n/g, "\n")
                .replace(/\\t/g, "\t")
                .replace(/\\\\\\/g, "")
                .replace(/\\"/g, "\"");
            // if the error message mainly consists of references to main.chunk.js, remove those. Unreadable
            const countChunks = messageStack.split(".chunk.js").length - 1;
            const countBundles = messageStack.split("/bundle.js").length - 1;

            messageStack = messageStack.split("\n")
                .filter((s, i) => (!s.includes(".chunk.js") && !s.includes("/bundle.js")) || i === 0)
                .map(s => s.includes(".chunk.js") ? `${s}\n[redacted lines chunk.js=${countChunks} bundle.js=${countBundles}]` : s)
                .join("\n");
            return (
                <div
                    className="alert alert-error shadow-lg max-w-fit w-fit gap-0 grid overflow-hidden"
                    style={{
                        ...style,
                        "minHeight": lastHeight
                    }}
                >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                            fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>
                        {debugControls}
                        <h2 className="font-bold text-lg text-error-content">{`Elemendi "${elementId}" andmete laadimisel esines viga.`}</h2>
                    </div>
                    <div style={{
                        "overflow": "auto",
                        "max-height": "40em",
                        "margin-top": "0px",
                        "margin-bottom": "8px",
                        "white-space": "pre-wrap",
                        "display": "block",
                        "height": "100%"
                    }}>
                        {message.message && <p style={{ width: "fit-content" }}>{message.message}</p>}
                    </div>
                    {dataType === parsedDataType.AGGREGATION && <Button onClick={() => handleParsedData[dataType](parsedData)}>Lae uuesti</Button>}
                </div>
            );
        }
        case renderStates.ELEMENT_UNKNOWN:
            return (
                <div
                    style={{ ...style, "minHeight": lastHeight, "display": "block" }}
                    className={className + " bg-base-100 rounded-box p-4"}
                    textAlign="center"
                    placeholder
                >
                    {debugControls}
                    <Header
                        as="h4"
                        textAlign="center"
                        disabled>
                        UNKNOWN LOADING STATE {elementLoadingState.getStateMessage()}
                    </Header>
                </div>
            );
        }
    };

    switch (elementLoadingState.getLoadingState()) {
    case loadingStates.FINISHED:
        return renderByRenderState(renderStates.ELEMENT_COMPLETE);
    case loadingStates.WAITING_FOR_CONNECTION:
    case loadingStates.WAITING_FOR_DATA:
        return renderByRenderState(renderStates.ELEMENT_LOADING);
    case loadingStates.STREAMING:
        if (isBlocking) {
            return renderByRenderState(renderStates.ELEMENT_LOADING);
        } else {
            return renderByRenderState(renderStates.ELEMENT_TRANSPARENT_LOADING);
        }
    case loadingStates.INITIALISED:
    case loadingStates.WAITING_FOR_DEPENDENCIES:
        return renderByRenderState(renderStates.ELEMENT_AWAITING_DEPENDENCIES);
    case loadingStates.ERROR:
        return renderByRenderState(renderStates.ELEMENT_ERROR);
    default:
        return renderByRenderState(renderStates.ELEMENT_UNKNOWN);
    }
};

export { Element };
