import React from "react";
import {
    permittedDashboardsMetadata,
    SelectFavoritesScreen
} from "./DashboardSelectScreen";
import LastUpdatedDatasets from "./LastUpdatedDatasets";
import ReleaseFeed from "../releaseNotes/ReleaseFeed";

const HomeScreen = () => (
    <main className="max-w-6xl pt-4">
        <h1>Sisekeskkond</h1>
        <h2>Lemmikud</h2>
        <div className="hero bg-base-300 rounded-box mb-4">
            <SelectFavoritesScreen
                permittedMeta={permittedDashboardsMetadata}
                columnCount={4}
            />
        </div>
        <h2>Keskkonna uuendused</h2>
        <ReleaseFeed/>
        <h2>Viimati uuendatud andmestikud</h2>
        <LastUpdatedDatasets/>
    </main>
);

export default HomeScreen;
