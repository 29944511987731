import React, { useEffect, useState } from "react";
import { Element } from "./Element";
import _ from "lodash";
import { DataChildren } from "./ElementDataChildren";

const ElementData = (props) => {
    const [updatedChildren, setUpdatedChildren] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const { children, showSteps, ...otherProps } = props;
    const [dataMap, setDataMap] = useState(new Map());

    function broadcastFunction(elementId, state) {
        setDataMap((prev) => {
            const newMap = new Map(prev);
            newMap.set(elementId, state);
            setIsLoaded([...newMap.values()].every(v => v === "finished"));
            return newMap;
        });
    }

    useEffect(() => {
        setUpdatedChildren(children.map(c => {
            let extraProps;
            if (c.type === DataChildren) {
                extraProps = {
                    "childTemplate": {
                        "props": {},
                        ...c.props.childTemplate
                    }
                };
                extraProps.childTemplate.props.broadcastChange = broadcastFunction;
            } else {
                extraProps = { "broadcastChange": broadcastFunction };
            }
            return React.cloneElement(c, extraProps);
        }));
    }, []);

    if (showSteps) {
        return (
            <Element
                {...otherProps}
                primary
                setData={_.noop}
            >
                {updatedChildren.map(c =>
                    <div
                        style={{
                            display: dataMap.get(c.props.id) !== "error" ? "none" : "block"
                        }}
                        key={c.props.id}
                    >
                        {c}
                    </div>
                )}
                {!isLoaded && <ul className="steps w-full">
                    {updatedChildren.map((c, i) => {
                        const state = dataMap.get(c.props.id);
                        const loading = state === "waiting for data";
                        return <li
                            data-content={
                                state === "finished" ? "✓" : (loading ? "●" : (i + 1).toString())
                            }
                            className={`step step-neutral
                                ${(state === "finished" || loading) && "step-accent"}
                            "`}
                            key={c.props.id}
                        >
                            {c.props.id.replaceAll("_", " ")}
                        </li>;
                    })}
                </ul>}
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                primary
                setData={_.noop}/>
        );
    }
};

export { ElementData as Data };
