import React, { useEffect, useState } from "react";
import { marked } from "marked";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";

const ElementBadge = ({ style, className, badgeType, ...props }) => {
    const [data, setData] = useState(undefined);
    const [text, setText] = useState("");

    useEffect(() => {
        console.log("data", data);
        if (data instanceof DataFrame) {
            if (data.getLength() > 0) {
                setText(marked(data.getColumn(data.getColumns()[0])[0]));
            }
        } else if (typeof data === "string") {
            setText(marked(data));
        }
    }, [data]);

    let textClass;
    if (["base-100", "base-200", "base-300"].includes(badgeType)) {
        textClass = "text-base-content";
    } else {
        textClass = `text-${badgeType}-content`;
    }

    return (
        <Element
            {...props}
            primary
            setData={setData}
        >
            {text && <div
                className={`badge bg-${badgeType} border-${badgeType} ${textClass} rounded-lg gap-4 ${className}`}
                style={style}
                dangerouslySetInnerHTML={{ __html: text }}
            />}
        </Element>
    );
};

export { ElementBadge as Badge };
