import React, { useContext, useEffect, useState } from "react";
import { Element } from "./Element";
import { DataFrame } from "../DSL/DataFrame";
import { DashboardElementBuilderContext } from "../DashboardElementBuilder";
import _ from "lodash";
import { allowedIterableConstructorNames } from "../ElementDataWrapper";
import { AggregationDataReference, dataReferenceType } from "../DSL/dashboardModels";

const assignDataToChild = (childTemplate, dataRow) => {
    const populatedChildTemplate = populateReferencesWithRowData(_.cloneDeep(childTemplate), dataRow);
    // if data but no definition, populate it with DataFrame:
    // if no data, then this means that DataFrame isn't used, since data or dataArgs assume existence of DataFrame
    const df = new DataFrame(dataRow);
    if (populatedChildTemplate.props.data) {
        if (populatedChildTemplate.props.data.key) {
            populatedChildTemplate.props.key = dataRow.get(populatedChildTemplate.props.data.key);
        }
        if (!populatedChildTemplate.props.data.definition) {
            populatedChildTemplate.props.data.definition = {
                source: {
                    data: new DataFrame(dataRow),
                    type: "RAW"
                }
            };
        }
    }
    populatedChildTemplate.hashCode = df.hashCode();
    return populatedChildTemplate;
};

/**
 * Fills out a child template json's row references, given the data row.
 * @param {Object} childTemplate - The rowsToChildren individual child definition.
 * @param {Map} dataRow - a js Map object.
 * @return {Object} - a childTemplate with the $col references instances filled with the data.
 */
const populateReferencesWithRowData = (childTemplate, dataRow) => {
    const dataGetterByRefType = {
        [dataReferenceType.col]: (value, dataRow) => {
            const colName = value.getReferenceId().substr(5);
            if (dataRow.has(colName)) {
                return dataRow.get(colName);
            } else {
                throw new Error(`${value} could not be populated from data row \n ${JSON.stringify(Object.fromEntries(dataRow))} \n in \n ${JSON.stringify(childTemplate)}`);
            }
        },
        [dataReferenceType.row]: (value, dataRow) => dataRow
    };
    const iterate = (obj) => {
        const colRegexp = /\$col\.\w+/g;
        Object.entries(obj).forEach(([key, value]) => {
            if (typeof value === "object" && value instanceof AggregationDataReference && [dataReferenceType.col, dataReferenceType.row].includes(value.referenceType)) {
                let data = dataGetterByRefType[value.referenceType](value, dataRow);
                if (value.callback !== undefined) {
                    data = value.callback(data);
                }
                obj[key.toString()] = data;
            } else if (typeof value === "object" && key !== "childTemplate") {
                // minification might cause trouble here, but it works well enough with primitives. Maybe replace with types later?
                if (allowedIterableConstructorNames.includes(value.constructor.name)) {
                    obj[key.toString()] = iterate(value);
                }
            } else if (typeof value === "string" && String(value).includes("$col.")) {
                // value is a string that can look something like `${$col("value")} - tugevused - ${$col("value")} - nõrkused`
                // the goal is to replace all instances of $col.{colname} with appropriate column values. Maybe remove this in the future, as we now use $refs?
                let finalString = value;
                [...value.matchAll(colRegexp)]
                    .map(match => match[0])
                    // sort matches by length descending, to avoid overlapping replacements on row 90; e.g. if we have cols like "value" and "values" or "valueCount"
                    .sort((a, b) => {
                        if (a.length > b.length) {
                            return -1;
                        } else if (a.length < b.length) {
                            return 1;
                        } else {
                            return 0;
                        }
                        // for each match, replace colName with colValue in this string
                    }).forEach((match) => {
                        const colName = match.substr(5);
                        let colValue;
                        if (dataRow.has(colName)) {
                            colValue = dataRow.get(colName).toString();
                        } else {
                            throw new Error(`${value} could not be populated from data row \n ${JSON.stringify(Object.fromEntries(dataRow))} \n in \n ${JSON.stringify(childTemplate)}`);
                        }
                        finalString = finalString.replace(match, colValue);
                    });
                obj[key.toString()] = finalString;
            }
        });
        return obj;
    };
    return childTemplate && iterate(childTemplate);
};

const PassThroughWrapper = ({ dataChildren }) => {
    return dataChildren;
};

const ElementDataChildren = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const [dataChildren, setDataChildren] = useState([]);
    const dashboardElementBuilder = useContext(DashboardElementBuilderContext);
    // childrenRenderFun must NOT be placed in a conditional, because it uses useState.
    const { childTemplate, "data": dataArgs, DataChildrenWrapper = PassThroughWrapper } = props;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        let usedData;
        if (Array.isArray(data)) {
            usedData = new DataFrame(data.map((value) => new Map([["value", value]])));
        } else if (data instanceof DataFrame && !data.isEmpty()) {
            usedData = dataArgs.sortBy ? data.copy().sortBy(dataArgs.sortBy.sortingColumn, dataArgs.sortBy.sortingOrder) : data.copy();
        }
        if (usedData) {
            setDataChildren(usedData.map((dataRow, idx) =>
                dashboardElementBuilder.build(assignDataToChild(childTemplate, dataRow), idx, loaded)));
        }
    }, [data, dataArgs]);

    return (
        <Element
            {...props}
            primary
            setData={setData}
            style={{ "display": "none" }}>
            <DataChildrenWrapper dataChildren={dataChildren}/>
        </Element>
    );
};

export { ElementDataChildren as DataChildren };
