import React from "react";
import { marked } from "marked";

const ElementText = (props) => {
    const { content, args, style, className } = props;
    return (
        <p
            className={`${className} pb-3`}
            style={style}
            {...args}
            dangerouslySetInnerHTML={{ __html: marked(String(content)) }}
        />
    );
};

export { ElementText as Text };
