import React, { useState } from "react";
import { DataFrame } from "../../../DSL/DataFrame";
import { Element } from "../../Element";
import { roundNumber } from "../../../../UtilityFunctions";
import { Card, Tooltip } from "react-daisyui";
import TIcon from "../../../../components/TIcon";

/**
 * @typedef {string} CardType
 */

/**
 * @enum {CardType}
 */
export const cardTypes = {
    "SMALL": "small",
    "SMALL_CARD": "small",
    "LARGE": "large",
    "LARGE_CARD": "large"
};

const CardPopup = (props) => {
    const { content, icon, conditionalIcon, text, data, style } = props;

    /*  for each popup:
    *           content - plaintext shown on mouse hover over popup
    *           icon    -
    *               type    - name of the icon
    *               color   - colour of the icon
    *               size    - size of the icon
    *           text    -
    *               column  - source of the value in the popup text field
    *               prefix  - prepended to the value in the text field. Optional.
    * */
    const numValue = roundNumber(data.get(text.column));
    const textValue = `${numValue === undefined ? "?" : numValue}%\n`;

    const getConditionalIcon = (conditionalIcon, number, precision = 0.5) => {
        if (typeof number !== "number") {
            return conditionalIcon.other;
        } else if (number > precision) {
            return conditionalIcon.positive;
        } else if (Math.abs(number) <= precision) {
            return conditionalIcon.neutral;
        } else {
            return conditionalIcon.negative;
        }
    };

    const trigger = <div className="flex-grow items-center flex">
        {icon !== undefined &&
            <TIcon
                name={icon.type}
                color={icon.color}
                outline={icon.outline}
                size={6}
                className="m-2"/>
        }
        {conditionalIcon !== undefined &&
            <TIcon
                {...getConditionalIcon(conditionalIcon, numValue)}
                size={6}
                className="m-2"
            />
        }
        {text &&
            <div
                style={{ "display": "inline" }}>
                <p
                    style={{ "display": "inline" }}>
                    {(text.prefix ? text.prefix : "")}
                </p>
                <p
                    style={{
                        "display": "inline"
                    }}
                    className="text-base-content"
                >
                    {textValue}
                </p>
            </div>
        }
    </div>;

    return (
        <Tooltip
            position="bottom center"
            className="z-40"
            message={content}
            style={style}
        >{trigger}</Tooltip>
    );
};

const ElementCard = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const { "data": dataArgs, children, style } = props;
    const unit = !(data.isEmpty()) ? data.getRow(0) : null; // new Map();

    /*
    * This is an informative card. The information is passed through the data Arguments in the dashboard.
    *
    * dataArgs:
    *   key         - key used by react to identify list elements
    *   colorMap    - a js Map, used to set the color of the card"s shadow
    *   style       - an optional element to overwrite the style
    *   content     - stores the main content of the card. Optional.
    *       image       - stores information pertaining to the image
    *           imageReferenceColumn        - column containing info on image name
    *           defaultImagePath            - URI path to default image, if not found
    *           type                        - decides image size. Either small or large.
    *       header      - Header written with large text.
    *           headerReferenceColumn - source of the value in the header field
    *       meta        -
    *           prefix      - prefix prepended to the value in meta field
    *           metaColumn  - source of the value in the meta field
    *       description - contains the description shown below header and meta.
    *           sentences   - dynamically generated sentences. See sentencesToString()
    *       popups  - list of popups
    *   extras      - list of extras
    *       popups      - list of popups for each extra
    *
    */

    if (unit) {
        return (
            <Element
                {...props}
                primary
                setData={setData}>
                <Card
                    data-testid="card"
                    wrapped
                    ui={false}
                    key={unit.get(dataArgs.key)}
                    className={`bg-base-100 rounded-box overflow-visible  
                        ${(dataArgs.type === cardTypes.LARGE_CARD ||
                            dataArgs.type === cardTypes.LARGE) && "mb-4 mt-4"} 
                        ${(dataArgs.type === cardTypes.SMALL_CARD ||
                            dataArgs.type === cardTypes.SMALL) && "mb-2 mt-2"} 
                            
                    `}
                    style={{
                        "width": "100%",
                        "minHeight": "8rem",
                        "display": "flex",
                        "borderRadius": dataArgs.type === cardTypes.SMALL && "5px",
                        ...style
                    }}>
                    {children}
                </Card>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>
        );
    }
};

export { ElementCard as Card, CardPopup };
