import React, { useState } from "react";
import { Element } from "./Element";
import { DataFrame } from "../DSL/DataFrame";

const ElementDummy = (props) => {
    const [ignored, setData] = useState(new DataFrame([]));
    const { children, style } = props;

    return (
        <Element
            {...props}
            primary
            setData={setData}
        >
            <div style={style}>{children}</div>
        </Element>
    );
};

export { ElementDummy as Dummy };
