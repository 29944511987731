import React from "react";

export default ({ children, ...otherProps }) => {
    return <div
        className="rounded-box bg-base-100 p-4 mb-4"
        {...otherProps}
    >
        {children}
    </div>;
};
