/*
* Handles Dashboard State:
* Stores data references, subscriptions and enforces subscription updates.
* */
import MessageAggregate from "../streaming/message/MessageAggregate.js";
import { $in, $out } from "../DSL/dashboardModels";
import { Source } from "./Source";
import AggregatorAPI from "../DSL/aggregatorAPI";
import { streamEventTypes } from "../stateConstants";
import { StreamMessage } from "../streaming/StreamHandler";

class Subscriber {
    constructor(id, source) {
        this.id = id;
        this.source = source;
        this.subscriptionCallback = null;
        this.subscriptions = new Set();
    }

    getSource = () => this.source;
    addSubscription = (source) => this.subscriptions.add(source);
    getSubscriptions = () => this.subscriptions;
    setSubscriptionCallback = (subscriptionCallback) => (this.subscriptionCallback = subscriptionCallback);
    subscriptionsResolved = () => {
        // console.log(`for id ${this.id} subscriptions ${Array.from(this.subscriptions).map((source) => [source.id, source.isLoaded()]).join(", ")} are loaded`);
        return Array.from(this.subscriptions).every((source) => source.isLoaded()) && this.subscriptionCallback !== null;
    }
}

class SubscriptionHandler {
    constructor(setDashboardLoadingState) {
        this.aggregator = new AggregatorAPI(setDashboardLoadingState, this);
        this.subscribers = new Map();
        this.sources = new Map();
    }

    addObserver(key, method) {
        if (!(this.sources.has(key))) {
            this.sources.set(key, new Source(key));
        }
        this.sources.get(key).observers.push(method);
    }

    addSubscription(from, to, channel) {
        if (!(this.sources.has(channel)) && from !== channel) {
            throw new Error(`outputChannel ${channel} must be initialised before the element using it. From: ${from}, To: ${to}`);
        }
        if (!(this.sources.has(to))) {
            throw new Error(`subscription target ${to} must be initialised before the element using it. From: ${from}`);
            // this.sources.set(to, new Source(to, this.sources.get(channel)));
        }

        if (!(this.subscribers.has(from))) {
            this.subscribers.set(from, new Subscriber(from, this.sources.get($in(from))));
        }

        this.sources.get(to).addSubscriber(this.subscribers.get(from));
        this.subscribers.get(from).addSubscription(this.sources.get(to));
    }

    setData = (key, val) => {
        if (!(this.sources.has(key))) {
            this.sources.set(key, new Source(key));
        }
        this.sources.get(key).setData(val);
    };

    propagateStreamMessageToSource = (elementId, streamMessage) => {
        const key = $in(elementId);
        if (!(this.sources.has(key))) {
            this.sources.set(key, new Source(key));
        }
        if (streamMessage.hasData) {
            this.sources.get(key).receiveStreamData(streamMessage.data);
        }
        this.sources.get(key).receiveStreamEvent(streamMessage.event);
    };

    closeStream = (elementId) => {
        const key = $in(elementId);
        this.sources.get(key).closeStream();
    };

    openStream = (elementId) => {
        this.sources.get($in(elementId)).openStream();
        this.sources.get($out(elementId)).loaded = false;
    };

    error = (elementId, error) => {
        const key = $in(elementId);
        return this.sources.get(key).error(error);
    };

    getData = (key) => {
        if (this.sources.has(key)) {
            return this.sources.get(key).getData();
        } else {
            throw new Error(`Requested element ${key} not found!`);
        }
    };

    elementSubscriptionsResolved = (elementId) => {
        return this.subscribers.get(elementId).subscriptionsResolved();
    };

    setSubscriptionCallback = (elementId, subscriptionCallback) => {
        this.subscribers.get(elementId).setSubscriptionCallback(subscriptionCallback);
    };

    getSubscriptions = (elementId) => {
        return [...this.subscribers.get(elementId).getSubscriptions()].map((source) => source.id);
    };

    registerSubscriptions = (elementId, outputChannel, subscriptions) => {
        outputChannel = outputChannel.startsWith("$") ? outputChannel : $out(outputChannel);

        const outKey = $out(elementId);
        const inKey = $in(elementId);
        this.sources.set(outKey, (this.sources.has(outKey) ? this.sources.get(outKey) : new Source(outKey, this.sources.get(outputChannel))));
        this.sources.set(inKey, (this.sources.has(inKey) ? this.sources.get(inKey) : new Source(inKey)));

        if (!(this.subscribers.has(elementId))) {
            this.subscribers.set(elementId, new Subscriber(elementId, this.sources.get($in(elementId))));
        }
        subscriptions.forEach((reference) => {
            this.addSubscription(elementId, reference.id, outputChannel);
        });
    };

    requestAggregation = (json, elementId) => {
        const messageAggregate = new MessageAggregate(json);
        return this.aggregator.subscribeToAggregation(messageAggregate, elementId);
    };

    setElementSourceProperties = (elementId, sourceProperties) => {
        const source = this.sources.get($in(elementId));
        source.setElementSourceProperties(sourceProperties);
    };

    registerRawData = (elementId, dataFrame) => {
        this.sources.get($in(elementId)).setData(dataFrame);
    };

    cancelOngoingAggregation = (elementId) => {
        this.aggregator.cancelAggregation(elementId);
        this.sources.get($in(elementId)).resetStreaming();
    }
}

export default SubscriptionHandler;
