
/*
* Wraps dashboard elements data definitions into a class which takes care of subscriptions in an efficient manner
* It stores pointers to every position in the json structure that must be changed.
* Using the function getParsed
* */

import { $in, $out, $parent, ActionsArray, AggregationDataReference } from "./DSL/dashboardModels";
import { DATA_UNINITIALIZED } from "./subscriptions/Source";
import { pipe } from "../UtilityFunctions";

export const allowedIterableConstructorNames = ["Object", "Array"];

class NestedReferenceError extends Error {
    constructor(...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NestedReferenceError);
        }
        this.name = "NestedReferenceError";
    };
}

const checkNestedReferences = (data) => {
    const iterate = (obj) => {
        Object.entries(obj).forEach(([_, value]) => {
            if (typeof value === "object" && value !== null) {
                if (value instanceof AggregationDataReference) {
                    throw new NestedReferenceError("NestedReferenceError: reference to output or input inside another reference. Review the aggregation.");
                } else {
                    iterate(value);
                }
            }
        });
    };
    data !== undefined && data !== null && iterate(data);
    return data;
};

class UnsafeKeyError extends Error {
    constructor(...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnsafeKeyError);
        }
        this.name = "UnsafeKeyError";
    };
}
const safeKey = (key) => !(["constructor", "prototype"].includes(key));
const checkKeySafety = (key) => {
    if (!safeKey(key)) throw new UnsafeKeyError(`UnsafeKeyError: Unsafe object key ${key} in DataWrapper. Review the aggregation.`);
};

const isParentId = (value) => value.startsWith("$parent.");

class ElementDataWrapper {
    constructor(json, parentId, dataCallback) {
        this.json = json;
        this.parentId = parentId;
        this.dataCallback = dataCallback;
        this.references = [];

        const populateParentId = (obj, key, value) => {
            let newId = value;
            if (!this.parentId) {
                // happens if $parent.in or .out is put into the topmost element
                throw new Error(`${value} not found; parent id not passed for obj ${obj} with key ${key}.`);
            }
            switch (value) {
            case $parent.in:
                newId = $in(this.parentId);
                break;
            case $parent.out:
                newId = $out(this.parentId);
                break;
            default:
                throw new Error(`${value} is an unsuitable parent id format for obj ${obj} with key ${key}. $parent.in and $parent.out are supported.`);
            }
            return newId;
        };

        const valueTypes = {
            NULL: "NULL",
            REF: "REF",
            DATAREF: "DATAREF",
            ITERABLE: "ITERABLE",
            OTHER: "OTHER"
        };

        const getValueType = (value) => {
            if (value === null) {
                return valueTypes.NULL;
            } else if (typeof value === "object" && value instanceof AggregationDataReference) {
                return valueTypes.DATAREF;
            } else if (typeof value === "object" && (value instanceof ActionsArray || allowedIterableConstructorNames.includes(value.constructor.name))) {
                return valueTypes.ITERABLE;
            } else if (typeof value === "string" && String(value).startsWith("$")) {
                return valueTypes.REF;
            }
            return valueTypes.OTHER;
        };

        const handleValueByType = {
            [valueTypes.NULL]: () => {},
            [valueTypes.OTHER]: () => {},
            [valueTypes.REF]: (obj, key, value) => {
                const newId = isParentId(value) ? populateParentId(obj, key, value) : value;
                this.references.push({
                    obj,
                    key,
                    id: newId
                });
            },
            [valueTypes.DATAREF]: (obj, key, value) => {
                this.references.push({
                    obj,
                    key,
                    id: value.getReferenceId(),
                    referenceCallback: pipe(value.callback, checkNestedReferences)
                });
            },
            [valueTypes.ITERABLE]: (obj, key, value) => {
                iterate(value, key, obj);
            }
        };

        const iterate = (obj) => {
            Object.entries(obj).forEach(([key, value]) => {
                handleValueByType[getValueType(value)](obj, key, value);
            });
        };

        iterate(this.json);
    }

    getParsed(ignoreEmpty = false) {
        for (const reference of this.references) {
            const { obj, key, id, referenceCallback = undefined } = reference;
            checkKeySafety(key);
            let data = this.dataCallback(id);
            // when initializing an element & pre-loading data from an uninitialized source, we don't want to populate obj[key] with it
            if (ignoreEmpty && data === DATA_UNINITIALIZED) continue;
            data = referenceCallback ? referenceCallback(data, id, this.parentId) : data;
            obj[key.toString()] = data;
        }
        return this.json;
    }

    getReferences() {
        return this.references;
    }
}

export default ElementDataWrapper;
