import React from "react";
import { user } from "../auth/User";
import { Dropdown, useTheme } from "react-daisyui";
import Cookies from "js-cookie";

import { DebugContext } from "../debug/DebugModeProvider";
import MenuItemHor from "./MenuItemHor";
import TIcon from "./TIcon";
import SidebarCountdown from "./SidebarCountdown";

const SidebarBottomMenu = ({
    width,
    isMobile,
    ...props
}) => {
    const { theme, setTheme } = useTheme();

    const themeTranslation = {
        "dark": "tume",
        "light": "hele"
    };

    return (
        <div className="bottom-0 mb-0 mt-auto"
            style={{ width }}
        >
            <DebugContext.Consumer>
                {value =>
                    <MenuItemHor
                        tabIndex={0}
                        className={
                            value.isEnabled() &&
                                "text-accent-content bg-accent hover:bg-accent-focus hover:text-accent-content"}
                        onClick={() => {
                            value.setEnabled(!value.isEnabled());
                        }}
                        icon="cpu-chip"
                        text="Debug"
                        isMobile={isMobile}
                    />
                }
            </DebugContext.Consumer>
            <div
                {...props}
                role="listbox"
                className="dropdown dropdown-right dropdown-end w-full"
            >
                <MenuItemHor
                    tabIndex={0}
                    icon="user"
                    text="Konto"
                    isMobile={isMobile}
                />
                <Dropdown.Menu
                    className="w-52"
                    style={{
                        zIndex: "101",
                        bottom: "0px",
                        position: "absolute",
                        width: "fit-content"
                    }}
                >
                    <Dropdown.Item>{user.getEmail()}</Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                            const newTheme = theme === "dark" ? "light" : "dark";
                            setTheme(newTheme);
                            Cookies.set("kuk-data-theme", newTheme);
                        }}
                    >Teema: {themeTranslation[theme]}</Dropdown.Item>
                    <Dropdown.Item onClick={user.logout}>
                        <TIcon name="arrow-left-on-rectangle" size={6}/>
                        Logi Välja
                    </Dropdown.Item>
                </Dropdown.Menu>
            </div>
            <SidebarCountdown/>
        </div>
    );
};

export default SidebarBottomMenu;
