import React, { useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";

const ElementHeader = (props) => {
    const [ignored, setData] = useState(new DataFrame([]));
    const { args, headerType, "data": dataArgs, map, children, style, underline } = props;
    const content = dataArgs.content;
    // incomplete, header should take data from df. or mayb create separate element
    const typeToSize = {
        "h1": "text-4xl pb-2 pt-4",
        "h2": "text-3xl pb-1 pt-4",
        "h3": "text-2xl pb-0.5 pt-3",
        "h4": "text-lg pb-0.25 pt-2"
    };
    return (
        <Element
            {...props}
            primary
            setData={setData}>
            <h1
                as={headerType}
                style={{
                    ...style,
                    borderBottom: underline && `1px solid ${underline}`
                }}
                className={headerType + " " + typeToSize[headerType || "h2 mb-2 mt-4"]}
                {...args}>
                {map ? map.get(content) : content}
            </h1>
            {children}
        </Element>
    );
};

export { ElementHeader as Header };
