import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";
import {
    colorGradient,
    findMatchingStackType,
    responseColorMap,
    stackTypes,
    uniques
} from "../../../UtilityFunctions";
import resizeObserver from "../../echarts/resizeObserver";
import EchartsChart from "../../echarts/EchartsChart";
import chartElementHelpers from "./chartElementHelpers";
import { useTheme } from "react-daisyui";
import { getEventsArray } from "./lineHelpers";

const ElementChartLineRanking = (props) => {
    /*
    * dataArgs attributes:
    *   period - the time variable
    *   prefix - if specified, prefix of dataFrame column names to be used. Mutually exclusive with displayedColumns and lineSplit!
    *   confidenceIntervalPrefix -
    *   labelMap -
    *       name - name of the line without prefix
    *       alias - name to use instead of column name (eg pop_ekre -> EKRE populaarsus)
    *       color - color for the line
    *
    * args attributes:
    *   scale - if specified, multiply all values in used columns by this value
    *   title - title of the bar chart
    *   options - echart options
    *   eventsArray - events to be shown on X axis
    *   verticalLines - labeled lines to be shown on Y axis
    *   height - chartHeight
    *   disableBackground - remove white background and shadow outline
    *   legend - whether legend is disabled or enabled
    * */
    const [data, setData] = useState(new DataFrame([]));
    const {
        "data": dataArgs, scale, title, "options": lineOptions, eventsArray, verticalLines,
        "height": chartHeight, disableBackground, legend, style, defaultOtherOption = "Ei oska öelda",
        xAxisType = chartElementHelpers.XAxisTypes.category, dataUnit = "%"
    } = props;
    const [chartOptions, setChartOptions] = useState(null);
    const { theme } = useTheme();

    const canRender = !data.isEmpty() && chartOptions != null;

    useEffect(() => {
        if (!data.isEmpty()) {
            const period = (dataArgs.period || "");

            const df = data.copy().filter((row) => row.has(period));

            // replace nans with -, echarts missing value
            df.getColumns().forEach(col => df.replaceValue(col, undefined, null));

            if (dataArgs.remapColumns) {
                df.renameColumns(dataArgs.remapColumns);
            }

            const labelMap = (dataArgs.labelMap ? dataArgs.labelMap : {});
            Object.keys(labelMap).forEach(
                (key) => {
                    const object = labelMap[key.toString()];
                    // makes alias optional
                    object.alias = (object.alias ? object.alias : key);
                }
            );

            // find the element column names, remove prefixes
            let elementRawNames = [];
            if (dataArgs.prefix !== undefined) {
                elementRawNames = df.getColumns().filter((key) => key.startsWith(dataArgs.prefix));
                elementRawNames = elementRawNames.map((name) => {
                    const newName = name.replace(dataArgs.prefix, "");

                    if (!df.hasColumn(newName)) {
                        df.renameColumn(name, newName);
                    } else {
                        const newVals = df.getColumn(newName);
                        const oldVals = df.getColumn(name);
                        df.setColumn(newName, oldVals.map((val, i) => val + newVals[parseInt(i, 10)]));
                        df.removeColumn(name);
                    }

                    return newName;
                });
            }

            // replace elements with their corresponding aliases
            const elementGetAlias = (name) => {
                name = dataArgs.prefix ? name.replace(dataArgs.prefix, "") : name;
                return Object.prototype.hasOwnProperty.call(labelMap, name) ? labelMap[name.toString()].alias : name;
            };

            const elementNames = uniques(elementRawNames.map(
                (name) => {
                    const newName = elementGetAlias(name);
                    if (newName !== name) {
                        if (Object.prototype.hasOwnProperty.call(labelMap, name)) {
                            labelMap[newName.toString()] = Object.assign({}, labelMap[name.toString()]);
                        }

                        if (!df.hasColumn(newName)) {
                            df.renameColumn(name, newName);
                        } else {
                            const newVals = df.getColumn(newName);
                            const oldVals = df.getColumn(name);
                            // in case the user wants to combine two aliases into one
                            df.setColumn(newName, oldVals.map((val, i) => val + newVals[parseInt(i, 10)]));
                            df.removeColumn(name);
                        }
                    }
                    return newName;
                }
            ));

            if (scale) {
                elementNames.forEach((column) => df.multiply(column, scale));
            }

            const xAxis = {
                "type": xAxisType || chartElementHelpers.XAxisTypes.category,
                "boundaryGap": true,
                "min": dataArgs.periodNameCol ? undefined : Math.min(...df.getColumn(period)),
                "max": dataArgs.periodNameCol ? undefined : Math.max(...df.getColumn(period)),
                "splitNumber": 1
            };

            if (xAxisType === chartElementHelpers.XAxisTypes.category) {
                // if categorical, set xAxis data to either aliased or raw period data
                xAxis.data = dataArgs.periodNameCol ? df.getColumn(dataArgs.periodNameCol) : df.getColumn(period);
            }

            let minValue = Number.MAX_SAFE_INTEGER;
            let maxValue = Number.MIN_SAFE_INTEGER;

            elementNames.forEach((name) => {
                const values = df.getColumn(name);
                values.forEach((val) => {
                    minValue = Math.min(minValue, val);
                    maxValue = Math.max(maxValue, val);
                });
            });
            minValue = Math.floor(minValue / 5) * 5;
            maxValue = Math.ceil(maxValue / 5) * 5;

            const times = df.getColumn(period);
            const lineOptionSeries = lineOptions?.series || [];

            let customGradient;
            let stacks;
            if (props.gradientOptions) {
                stacks = stackTypes[findMatchingStackType(elementNames, stackTypes).index];
                const stacksFound = stacks !== undefined;
                if (stacksFound) {
                    if (stacks.includes(defaultOtherOption)) {
                        const idx = stacks.indexOf(defaultOtherOption);
                        stacks.splice(idx, 1);
                        customGradient = colorGradient(props.gradientOptions.arr, stacks.length);
                        stacks.splice(idx, 0, defaultOtherOption);
                        customGradient.splice(idx, 0, responseColorMap.get(defaultOtherOption) || "#a5a5a5");
                    } else {
                        customGradient = colorGradient(props.gradientOptions.arr, stacks.length);
                    }
                } else {
                    stacks = elementNames;
                }
            }

            stacks = stacks || elementNames;

            const series = [...stacks.filter(s => df.hasColumn(s)).map((name, i) => {
                let color;
                if (Object.prototype.hasOwnProperty.call(labelMap, name)) {
                    color = labelMap[name.toString()].color;
                } else if (customGradient && stacks) {
                    color = customGradient[stacks.findIndex((n) => (n === name))];
                } else {
                    color = chartElementHelpers.genericPalette[i % chartElementHelpers.genericPalette.length];
                }

                if (responseColorMap.has(name)) {
                    color = responseColorMap.get(name);
                }
                const values = df.getColumn(name);

                const seriesData = values.map((value, j) => [times[j], value]);
                const element = {
                    name,
                    "type": "line",
                    "step": false,
                    "smooth": 0.34,
                    "showSymbol": true,
                    "symbolSize": 18,
                    "symbol": "circle",
                    "seriesLayoutBy": "row",
                    "data": seriesData,
                    "xAxisIndex": 0,
                    "yAxisIndex": 0,
                    "label": {
                        "show": true,
                        "position": "top",
                        "formatter": "{a}",
                        "color": theme === "dark" ? "white" : "black",
                        "textBorderColor": theme === "dark" ? "black" : "white",
                        "textBorderWidth": 1
                    },
                    "itemStyle": {
                        color
                    },
                    "lineStyle": {
                        "width": 4,
                        color
                    }
                };
                return element;
            }), ...lineOptionSeries];

            if (eventsArray && times.length >= 2) {
                series.push(getEventsArray(times, eventsArray));
            }

            if (verticalLines) {
                const lines = verticalLines.map(line => (
                    {
                        name: line.text,
                        yAxis: line.y,
                        label: {
                            formatter: "{b}",
                            position: line.alignment || "middle"
                        }
                    }
                ));
                series.push({
                    "type": "line",
                    "smooth": 0,
                    "step": "start",
                    "markLine": {
                        "symbol": ["none", "none"],
                        "label": { "show": true },
                        "data": lines,
                        "lineStyle": {
                            "type": "solid",
                            "color": "gray"
                        }
                    }
                });
            }

            const options = {
                "tooltip": {
                    "show": true,
                    "trigger": "axis"
                },
                "grid": {
                    "borderWidth": 25,
                    ...(!legend && { "top": "15px" }),
                    "left": "3%",
                    "right": "4%",
                    "bottom": "3%",
                    "containLabel": true
                },
                "legend": legend && {
                    "data": (stacks || elementNames).map((name) => dataArgs.prefix ? name.replace(dataArgs.prefix, "") : name)
                },
                "xAxis": xAxis,
                "yAxis": {
                    "type": "value",
                    "inverse": true,
                    "min": (v) => props.yMin !== undefined ? props.yMin : Math.round(Math.max(v.min, minValue)) - 0.25,
                    "max": (v) => props.yMax !== undefined
                        ? props.yMax
                        : Math.round(Math.min(v.max, maxValue)),
                    "splitNumber": maxValue - minValue > 50 ? 10 : 5
                },
                ...lineOptions,
                "series": series
            };

            setChartOptions(() => options);
        }
    }, [data]);

    if (canRender) {
        const onEvents = {
            "updateAxisPointer": (comp, chart) => {
                if (!Number.isNaN(comp.dataIndex)) {
                    chart.lastMouseX = comp.dataIndex;
                }

                if (!("lastMouseX" in chart)) {
                    return;
                }

                const x = chart.lastMouseX;
                if (!("renderedMouseX" in chart) || chart.renderedMouseX !== x) {
                    const option = chart.getOption();
                    const height = chart.getHeight();
                    const fontHeight = (12 + 6) / (height / 100) * 0.5;

                    const xCount = xAxisType !== "category" ? option.series[0].data.length : option.xAxis[0].data.length;

                    let xMin, xMax;
                    if (typeof option.xAxis[0].min === "number") {
                        // min and max are not present with dates, those use counts instead
                        xMin = option.xAxis[0].min;
                        xMax = option.xAxis[0].max;
                    } else {
                        xMin = 0;
                        xMax = xCount;
                    }

                    const seriesHasPoint = (s) => (
                        "markPoint" in s && "data" in s &&
                        s.markPoint.data.length > 0 && s.data.length > x);

                    let positions = option.series.map((s) => (seriesHasPoint(s) ? s.data[x][1] : -1000));
                    for (let j = 0; j < 8; j++) {
                        const forces = positions.map(p1 => {
                            return positions
                                .map(p2 => {
                                    return Math.abs(p1 - p2) < fontHeight ? (fontHeight - Math.abs(p2 - p1)) * Math.sign(p2 - p1) : 0;
                                })
                                .reduce((a, b) => (a + b), 0) / positions.length;
                        });

                        positions = positions.map((p, i) => p - forces[parseInt(i)]);
                    }

                    const xPos = ((x) / xCount * (xMax - xMin) + xMin);
                    const newSeries = option.series.map((s, i) => {
                        if (seriesHasPoint(s)) {
                            s.markPoint.data[0].label.show = true;
                            // x-coordinate of help text
                            s.markPoint.data[0].coord[0] = xAxisType !== "category" ? s.data[xPos][0] : xPos;
                            // y-coordinate of the help text
                            s.markPoint.data[0].coord[1] = positions[parseInt(i)];
                            if (s.data[xPos]) {
                                const yVal = Math.round(s.data[xPos][1] * 10) / 10;
                                s.markPoint.data[0].label.formatter = (c) => {
                                    return `${c.data.name} ${yVal}${dataUnit}`;
                                };
                            }
                        }
                        return s;
                    });
                    chart.setOption({
                        ...option,
                        newSeries
                    }, false, true);
                    chart.renderedMouseX = x;
                }
            },
            "globalOut": (ev, chart) => {
                const option = chart.getOption();

                const seriesHasPoint = (s) => (
                    "markPoint" in s && "data" in s &&
                    s.markPoint.data.length > 0);

                const newSeries = option.series.map((s) => {
                    if (seriesHasPoint(s)) {
                        s.markPoint.data[0].label.show = false;
                    }
                    return s;
                });
                chart.setOption({
                    ...option,
                    newSeries
                }, false, true);
            }
        };

        const chart = (
            <React.Fragment>
                {title && <h3 className="text-base-content text-2xl p-4">{title}</h3>}
                <EchartsChart
                    style={{
                        "width": "100%",
                        "height": chartHeight || "500px",
                        ...(disableBackground === true ? style : {})
                    }}
                    resizeObserver={resizeObserver}
                    option={chartOptions}
                    onEvents={onEvents}
                />
            </React.Fragment>
        );

        return (
            <Element
                width="100%"
                {...props}
                primary
                setData={setData}>
                {disableBackground === true
                    ? chart
                    : <div
                        style={style}
                        className="p-2 bg-base-100 rounded-box mt-4 mb-4"
                    >{chart}</div>}
            </Element>
        );
    } else {
        return (
            <Element
                width="100%"
                {...props}
                primary
                setData={setData}/>);
    }
};

export { ElementChartLineRanking as ChartLineRanking };
