import React, { useState } from "react";
import { Element } from "../Element";
import "echarts-gl";
import { DataFrame } from "../../DSL/DataFrame";
import resizeObserver from "../../echarts/resizeObserver";
import EchartsChart from "../../echarts/EchartsChart";

const maxLinkWidth = 4;
const minSymbolSize = 4;
const maxSymbolSize = 35;

const ElementChartGraph = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const { "data": dataArgs } = props;
    const { colorMap, title, subtitle, nodeCol = "value", edgeCol = "value", edgeThreshold = undefined } = dataArgs;
    if (!data.isEmpty()) {
        const buckets = data.bucketize("type");
        const edges = buckets.get("edge").filter((row) => row.get(edgeCol) !== undefined && (edgeThreshold ? row.get(edgeCol) >= edgeThreshold : true));
        const nodes = buckets.get("node").filter((row) => row.get(nodeCol) !== undefined);
        // filter out
        if (nodes && !nodes.isEmpty() && edges && !edges.isEmpty()) {
            const categories = nodes.uniques("category");
            const graph = {};
            const allEdgeNames = new Set([...edges.uniques("source"), ...edges.uniques("target")]);
            const nodeMinValue = Math.min(...nodes.getColumn(nodeCol));
            const nodeMaxValue = Math.max(...nodes.getColumn(nodeCol));
            graph.nodes = nodes.data
                .filter((row) => allEdgeNames.has(row.get("name")))
                .map((row, i) => {
                    const node = Object.fromEntries(row);
                    const normalized = (node.value - nodeMinValue) / (nodeMaxValue - nodeMinValue);
                    node.id = (i + 1).toString();
                    node.symbolSize = minSymbolSize + (normalized ** 2) * (maxSymbolSize - minSymbolSize);
                    node.label = {
                        show: normalized > 0.5,
                        position: "inside",
                        fontSize: 12 * normalized
                    };
                    return node;
                });
            const allNodeNames = new Set([...graph.nodes.map(n => n.name)]);
            const minValue = Math.min(...edges.getColumn(edgeCol));
            const maxValue = Math.max(...edges.getColumn(edgeCol));
            graph.edges = edges.data
                .filter((row) => allNodeNames.has(row.get("source")) && allNodeNames.has(row.get("target")))
                .map((row) => {
                    const link = Object.fromEntries(row);
                    const value = parseFloat(link.value);
                    const normValue = (value - minValue) / (maxValue - minValue);
                    const linkColor = "rgb(127, 127, 127)";

                    const [source, target] = [graph.nodes.find((n) => n.name === link.source), graph.nodes.find((n) => n.name === link.target)];

                    link.lineStyle = {
                        width: normValue * maxLinkWidth,
                        color: linkColor,
                        curveness: 0.2,
                        opacity: 0
                    };

                    if (source !== undefined && target !== undefined) {
                        const sourceNorm = (source.value - minValue) / (maxValue - minValue);
                        const targetNorm = (target.value - minValue) / (maxValue - minValue);
                        const val = normValue * (sourceNorm * targetNorm);
                        link.lineStyle.opacity = (normValue > 0.5 && val > 0.1 ? val : 0);
                        link.lineStyle.width = normValue * maxLinkWidth;
                        link.source = source.id;
                        link.target = target.id;
                        link.value = value;
                        return link;
                    } else {
                        return undefined;
                    }
                }).filter((row) => row !== undefined);

            graph.categories = categories
                .map((c) => {
                    return {
                        name: c,
                        base: c,
                        itemStyle: {
                            color: colorMap && colorMap.has(c) && colorMap.get(c)
                        }
                    };
                });

            const option = {
                "title": {
                    "text": title,
                    "subtext": subtitle,
                    "top": "bottom",
                    "left": "right",
                    "textStyle": { "fontFamily": "Oswald", "fontWeight": 400 }
                },
                "tooltip": {
                    "formatter": (params) => {
                        if (params.dataType === "node") {
                            let label = "";
                            if (dataArgs.nodelabel) {
                                label = dataArgs.nodelabel + " ";
                            }
                            return `<b>${params.name}</b><br/>${label}${Math.round(params.value * 100)}%`;
                        } else if (params.dataType === "edge") {
                            const source = graph.nodes[parseInt(params.data.source, 10) - 1];
                            const target = graph.nodes[parseInt(params.data.target, 10) - 1];
                            let label = "";
                            if (dataArgs.edgelabel) {
                                label = dataArgs.edgelabel + ": ";
                            }
                            return `<b>${source.name} - ${target.name}</b><br/>${label}${Math.round(params.data.value * 100)}%`;
                        }
                        return `UNKNOWN DATATYPE ${params.dataType}`;
                    }
                },
                "legend": [{
                    data: graph.categories
                }],
                "series": [
                    {
                        silent: false,
                        name: title,
                        type: "graph",
                        layout: "force",
                        nodes: graph.nodes,
                        edges: graph.edges,
                        categories: graph.categories,
                        roam: true,
                        label: {
                            show: true,
                            formatter: "{b}",
                            position: "right"
                        },
                        force: {
                            gravity: 0.1,
                            repulsion: 600,
                            edgeLength: 200,
                            friction: 0.25
                        },
                        emphasis: {
                            focus: "adjacency",
                            lineStyle: {
                                width: maxLinkWidth * 2.5
                            }
                        }
                    }
                ],
                "toolbox": {
                    "feature": {
                        "saveAsImage": {}
                    }
                },
                ...dataArgs.options
            };

            return (
                <Element
                    {...props}
                    width="100%"
                    primary
                    setData={setData}>
                    <EchartsChart
                        style={{
                            "height": "800px"
                        }}
                        option={option}
                        resizeObserver={resizeObserver}
                    />
                </Element>
            );
        }
    }
    return (
        <Element
            {...props}
            width="100%"
            primary
            setData={setData}/>);
};

export { ElementChartGraph as ChartGraph };
