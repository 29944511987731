import React, { useState } from "react";
import { Element } from "../Element";
import ReactEcharts from "echarts-for-react";
import { DataFrame } from "../../DSL/DataFrame";
import { findMatchingStackType, roundNumber, sortingOrder, uniques, uniquesOrdered } from "../../../UtilityFunctions";
import TSegment from "../../../components/TSegment";
import { useTheme } from "react-daisyui";

const ElementChartBar = (props) => {
    const { theme } = useTheme();
    const [data, setData] = useState(new DataFrame([]));
    const { chartOptions, "data": dataArgs, height } = props;

    /*
    * This is a bar chart, where:
    *
    * chartOptions:
    *
    *   remap           -   if present, a Map which remaps the x-axis column's values
    *   splitColumn     -   if present, the name of the column which splits different chart bars (if several bars are to be
    *   included per one category)
    *   normalize       -   if present & true, divide all values by their sum and multiply by 100
    *   maxYValue       -   if present, set the max y-axis value to this value.
    *   answerTypes     -   if present, map x-axis variables to their closest match from this list
    *   defaultAnswer   -   if present, a default answer option is used in the answerTypes logic
    *   title           -   Title of the bar chart
    *   options         -   React Echarts options.
    *   sortBySplitcolumn -
    *   maxCategories   -
    *
    * */

    if (!data.isEmpty()) {
        const df = data.copy();
        const xCol = dataArgs.x;
        const yCol = dataArgs.y;

        let dfBuckets;

        if (chartOptions.remap) {
            df.replaceValues(xCol, chartOptions.remap);
        }

        let bucketKeys;
        if (chartOptions.splitColumn) {
            bucketKeys = uniquesOrdered(df.getColumn(chartOptions.splitColumn));
            dfBuckets = df.bucketize(chartOptions.splitColumn);
        } else {
            bucketKeys = ["data"];
            dfBuckets = new Map([["data", df]]);
        }
        if (chartOptions.normalize === true) {
            for (const dfInBucket of dfBuckets.values()) {
                dfInBucket.divide(yCol, dfInBucket.sum(yCol)).multiply(yCol, 100);
            }
        }

        if (chartOptions.answerTypes) {
            const allUniqueX = uniques(df.getColumn(xCol));
            const bestXReference = findMatchingStackType(allUniqueX.filter((e) => chartOptions.defaultAnswer ? (e !== chartOptions.defaultAnswer) : true), chartOptions.answerTypes);
            if (bestXReference.index !== -1) {
                for (const [key, dfInBucket] of dfBuckets.entries()) {
                    const newX = chartOptions.answerTypes[bestXReference.index];
                    const currentX = dfInBucket.uniques(xCol);
                    const missingX = newX.filter((value) => !currentX.includes(value));
                    missingX.forEach((missing) => dfInBucket.append(new Map([
                        [xCol, missing],
                        [yCol, 0]
                    ])));
                    const newDf = dfInBucket.reorderByReference(xCol, newX);
                    dfBuckets.set(key, newDf);
                }
            }
        } else if (chartOptions.sortBySplitcolumn) {
            const newOrder = [];
            bucketKeys
                .reverse()
                .map(title => dfBuckets.get(title).getColumn(xCol).reverse())
                .forEach(cols =>
                    cols.forEach(col => !newOrder.includes(col) && newOrder.push(col))
                );
            for (const [key, dfInBucket] of dfBuckets.entries()) {
                const newDf = dfInBucket.reorderByReference(xCol, newOrder);
                dfBuckets.set(key, newDf);
            }
        }

        const getData = (df, chartOptions, title) => {
            if (!chartOptions.answerTypes && !chartOptions.sortBySplitcolumn) {
                df = df.sortBy(xCol, sortingOrder.ASC);
            }
            const labels = df.getColumn(xCol);
            let values = df.getColumn(yCol).map((value) => chartOptions.round ? roundNumber(value, chartOptions.round) : value);
            values = values.map((value, i) => {
                const label = labels[i];
                let color;
                if (chartOptions.colorMap && chartOptions.colorMap.has(title)) {
                    color = chartOptions.colorMap.get(title);
                } else if (chartOptions.colorMap && chartOptions.colorMap.has(label)) {
                    color = chartOptions.colorMap.get(label);
                }
                if (color !== undefined) {
                    return ({
                        value,
                        itemStyle: {
                            color
                        }
                    });
                } else {
                    return value;
                }
            });
            return [values, labels];
        };
        const orderDataByLabels = (data, labels) => {
            return [
                labels.map(l => data[0][data[1].indexOf(l)]),
                labels
            ];
        };
        let globalLabels = [];
        bucketKeys.forEach(title => {
            const dfContent = dfBuckets.get(title);
            const labels = getData(dfContent, chartOptions, title)[1];
            labels
                .filter(l => !globalLabels.includes(l))
                .forEach(l => globalLabels.push(l));
        });
        globalLabels = globalLabels.reverse();
        const categoryCount = chartOptions.maxCategories || globalLabels.length;

        const series = bucketKeys.reverse().map(title => {
            const dfContent = dfBuckets.get(title);
            return {
                "name": title,
                "type": "bar",
                "barGap": 0,
                "barCategoryGap": "15%",
                "data": orderDataByLabels(getData(dfContent, chartOptions, title), globalLabels)[0].slice(-categoryCount),
                "color": chartOptions.colorMap && chartOptions.colorMap.has(title) && chartOptions.colorMap.get(title)
            };
        });

        const categoryAxis = {
            "type": "category",
            "name": xCol,
            "data": globalLabels.slice(-categoryCount),
            "axisTick": {
                "interval": 0,
                "lineStyle": { "width": 0 }
            },
            "axisLabel": {
                "interval": 0,
                "rotate": chartOptions.orientation !== "horizontal" ? 10 : 0,
                "margin": 16
            },
            "axisLine": { "lineStyle": { "width": 0 } }
        };

        const valueAxis = {
            "type": "value",
            "name": yCol,
            "max": chartOptions.maxYValue || "dataMax",
            "axisLine": { "lineStyle": { "width": 0 } },
            "axisTick": { "lineStyle": { "width": 0 } }
        };

        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}>
                <TSegment>
                    <ReactEcharts
                        style={{ "width": "100%", "height": height || "500px" }}
                        theme={theme === "dark" ? "dark" : "default"}
                        option={{
                            "backgroundColor": "transparent",
                            "title": {
                                "text": chartOptions.title,
                                "textStyle": { "fontFamily": "Oswald", "fontWeight": 400 }
                            },
                            "legend": {},
                            "grid": {
                                "left": "3%",
                                "right": "4%",
                                "bottom": "3%",
                                "containLabel": true
                            },
                            "toolbox": {
                                "feature": {
                                    "saveAsImage": {}
                                }
                            },
                            "yAxis": chartOptions.orientation === "horizontal" ? categoryAxis : valueAxis,
                            "xAxis": chartOptions.orientation === "horizontal" ? valueAxis : categoryAxis,
                            "tooltip": {
                                "trigger": "axis",
                                "axisPointer": {
                                    "type": "shadow"
                                }
                            },
                            "series": series,
                            ...chartOptions.options
                        }}/>
                </TSegment>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>);
    }
};

export { ElementChartBar as ChartBar };
