import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { action, fun } from "../../DSL/aggregatorModels";
import { DataFrame } from "../../DSL/DataFrame";
import { uniques } from "../../../UtilityFunctions";
import FormFieldHeader from "./FormFieldHeader";
import TNestedMultiSelect from "../../../components/TNestedMultiSelect";

const ElementFilterNested = (props) => {
    const { "data": dataArgs, saveInteractionState } = props;
    const [data, setData] = useState(new DataFrame([]));
    const [filter, setFilter] = useState(dataArgs.defaultInteractionState || {});
    const [options, setOptions] = useState([]);

    const labelMap = (dataArgs.labelMap ? dataArgs.labelMap : {});
    /*
        * Data attributes:
        * labelMap -
        *   name - name without prefix
        *   alias - name to use instead of name
        * */

    // on filter update, filter all the data
    useEffect(() => {
        if (!data.isEmpty()) {
            const df = data;
            if (options.length === 0) {
                let mainOptions = df.getColumns()
                    .map((column) => ({
                        "key": column,
                        "text": Object.prototype.hasOwnProperty.call(labelMap, column) ? labelMap[column.toString()].alias || labelMap[column.toString()] : column,
                        "value": column,
                        "options": uniques(df.getColumn(column).reduce((x, y) => x.concat(y), [])).sort().map((key) => ({
                            key,
                            "text": key,
                            "value": key
                        }))
                    }));
                // filter values with too many unique options
                mainOptions = mainOptions.filter((option) => option.options.length < 100);
                setOptions(mainOptions);
            }
        }
    }, [data]);

    useEffect(() => {
        if (!data.isEmpty()) {
            if (saveInteractionState !== undefined) {
                saveInteractionState(filter);
            }
            const out = action.filter(
                ...Object.keys(filter)
                    .filter((key) => filter[key.toString()].length > 0)
                    .map((key) => fun(key, "contains_any", filter[key.toString()]))
            );
            props.setOutput(out);
        }
    }, [filter, data]);

    if (!data.isEmpty()) {
        return (
            <Element
                {...props}
                setData={setData}>
                <FormFieldHeader>Sihtgrupp</FormFieldHeader>
                <TNestedMultiSelect
                    defaultValue={dataArgs.defaultInteractionState ? dataArgs.defaultInteractionState : undefined}
                    options={options}
                    onChange={(selection) => {
                        setFilter((oldFilter) => {
                            const newFilter = Object.fromEntries(
                                Object.entries(oldFilter)
                                    .filter(([key]) => data.hasColumn(key))
                            );
                            if (selection.values.length === 0) {
                                if (Object.prototype.hasOwnProperty.call(newFilter, selection.key)) {
                                    delete newFilter[selection.key];
                                }
                            } else {
                                newFilter[selection.key] = selection.values;
                            }
                            return newFilter;
                        });
                    }}
                />
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                setData={setData}/>
        );
    }
};

export { ElementFilterNested as FilterNested };
