import React, { useEffect, useState } from "react";
import { sortingOrder } from "../UtilityFunctions";
import { Table } from "react-daisyui";

export const SimpleDataTable = (props) => {
    const [column, setColumn] = useState(null);
    const { rows, header } = props;
    const [data, setData] = useState(rows);
    const [direction, setDirection] = useState(null);

    useEffect(() => {
        setData(rows);
    }, [rows]);

    const handleSort = (clickedColumn) => {
        if (column !== clickedColumn) {
            setColumn(clickedColumn);
            setDirection(sortingOrder.DESC);
            setData((old) => {
                old.sort((a, b) => a[clickedColumn] > b[clickedColumn]);
                return old;
            });
        } else {
            setDirection(direction === sortingOrder.ASC ? sortingOrder.DESC : sortingOrder.ASC);
            setData((old) => {
                old.sort((a, b) => a[clickedColumn] < b[clickedColumn]);
                return old;
            });
        }
    };

    return (
        <Table zebra className="w-full">
            <Table.Head>
                {header.map((h) => <span
                    key={h}
                    sorted={column === h ? direction : null}
                    onClick={() => handleSort(h)}
                >
                    <a
                        href="javascript: void(0);"
                    >{h}</a>
                </span>)}
            </Table.Head>
            <Table.Body>
                {data.map((r, i) => (
                    <Table.Row key={i}>
                        {r.map((c, j) =>
                            <span
                                key={j}
                                style={{
                                    overflow: "visible"
                                }}
                            >
                                {c}
                            </span>
                        )}
                    </Table.Row>))}
            </Table.Body>
        </Table>
    );
};
