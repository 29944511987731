import { fun } from "./aggregatorModels";

class Transform extends Function {
    constructor(wrapper) {
        super("...args", "return this.wrapper(...args)");
        this.wrapper = wrapper;
        const bind = this.bind(this);
        bind.wrapper = wrapper;
        return bind;
    }

    /**
     * Concatenates multiple input string columns together into a single string column.
     *
     * @param {{function: string, pattern: *}|string} match - the column that will store the concatenated strings.
     * @param {string} sep - string separator
     * @param {string} joinColumns - columns to concatenate together.
     * @return {Object} - function object.
     */
    stringConcat(match, sep, ...joinColumns) {
        return this.wrapper(fun(match, "string_concat", [sep, ...joinColumns]));
    }

    /**
     * Returns a boolean column based on whether a row contained a particular substring.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {string} substring - the substring to be checked against each row.
     * @return {Object} - function object.
     */
    stringContains(match, substring) {
        return this.wrapper(fun(match, "string_contains", [substring]));
    }

    /**
     * Extract parts of a string
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number} start - start character of string split
     * @param {number} end - end character
     * @return {Object} - function object.
     * */
    stringSlice(match, start, end) {
        return this.wrapper(fun(match, "string_slice", [start, end]));
    }

    /**
     * Appends a postfix to every row in a column of strings.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {string} postfix - the postfix to be added.
     * @return {Object} - function object.
     */
    postfixAdd(match, postfix) {
        return this.wrapper(fun(match, "postfix_add", [postfix]));
    }

    /**
     * Prepends a prefix to every row in a column of strings.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {string} prefix - the prefix to be added.
     * @return {Object} - function object.
     */
    prefixAdd(match, prefix) {
        return this.wrapper(fun(match, "prefix_add", [prefix]));
    }

    /**
     * Replaces all occurrences of a regex pattern with replacement.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {string} pattern - the regex pattern to test against.
     * @param {string} replacement - replacement string.
     * @return {Object} - function object.
     */
    stringRegexpReplace(match, pattern, replacement) {
        return this.wrapper(fun(match, "string_regexp_replace", [pattern, replacement]));
    }

    /**
     * Makes a column of strings lowercase.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    stringLower(match) {
        return this.wrapper(fun(match, "string_lower"));
    }

    /**
     * Makes a column of strings uppercase.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    stringUpper(match) {
        return this.wrapper(fun(match, "string_upper"));
    }

    /**
     * Returns the smallest value in a row for each column.
     *
     * @param {{function: string, pattern: *}|string} match - the column that will store the result.
     * @param {string} columns - the columns to compare.
     * @return {Object} - function object.
     */
    minOfColumns(match, ...columns) {
        return this.wrapper(fun(match, "min_of_columns", columns));
    }

    /**
     * Returns the largest value in a row for each column.
     *
     * @param {{function: string, pattern: *}|string} match - the column that will store the result.
     * @param {string} columns - the columns to compare.
     * @return {Object} - function object.
     */
    maxOfColumns(match, ...columns) {
        return this.wrapper(fun(match, "max_of_columns", columns));
    }

    /**
     * Adds a scalar to every value in a column.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number} value - the addend.
     * @return {Object} - function object.
     */
    add(match, value) {
        return this.wrapper(fun(match, "add", [value]));
    }

    /**
     * Adds two columns together.
     *
     * @param {{function: string, pattern: *}|string} match - the column to be added to. Will store the result.
     * @param {string} column - the column that is added to the matched column.
     * @return {Object} - function object.
     */
    addColumn(match, column) {
        return this.wrapper(fun(match, "add_column", [column]));
    }

    /**
     * Scales a column's values such that they end up between 0 and 1.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    normalize(match) {
        return this.wrapper(fun(match, "normalize", []));
    }

    /**
     * Exponentiates a column's values.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number} number - the exponent.
     * @return {Object} - function object.
     */
    power(match, number) {
        return this.wrapper(fun(match, "power", [number]));
    }

    /**
     * Subtracts a scalar from a column's values.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number} value - the subtrahend.
     * @return {Object} - function object.
     */
    subtract(match, value) {
        return this.wrapper(fun(match, "subtract", [value]));
    }

    /**
     * Subtracts a column from another..
     *
     * @param {{function: string, pattern: *}|string} match - the column to be subtracted from, the minuend.
     * @param {string} column - the column being subtracted, the subtrahend.
     * @return {Object} - function object.
     */
    subtractColumn(match, column) {
        return this.wrapper(fun(match, "subtract_column", [column]));
    }

    /**
     * Multiplies a column's values by a scalar.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number} value - the factor by which the column's values are multiplied.
     * @return {Object} - function object.
     */
    multiply(match, value) {
        return this.wrapper(fun(match, "multiply", [value]));
    }

    /**
     * Multiplies two columns together.
     *
     * @param {{function: string, pattern: *}|string} match - the first column of factors. Stores the result.
     * @param {string} column - the second column of factors.
     * @return {Object} - function object.
     */
    multiplyColumn(match, column) {
        return this.wrapper(fun(match, "multiply_column", [column]));
    }

    /**
     * Divides a column's values by a scalar.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number} value - the divisor.
     * @return {Object} - function object.
     */
    divide(match, value) {
        return this.wrapper(fun(match, "divide", [value]));
    }

    /**
     * Divides a column by another column.
     *
     * @param {{function: string, pattern: *}|string} match - the dividend column. Stores the result.
     * @param {string} column - the divisor column.
     * @return {Object} - function object.
     */
    divideColumn(match, column) {
        return this.wrapper(fun(match, "divide_column", [column]));
    }

    /**
     * Copies a column, creating a new column with a new name or overriding an old one. If contains or regex are used,
     * newColumn is used as prefix.
     *
     * @param {{function: string, pattern: *}|string} match - the column to be copied.
     * @param {string} newColumn - the name of the column to be created.
     * @return {Object} - function object.
     */
    copyColumn(match, newColumn) {
        return this.wrapper(fun(match, "copy_column", [newColumn]));
    }

    /**
     * Stores the dataset filepath (S3 path currently) as a column. This should preferably
     * be called right after loading a dataset, because some actions can make it impossible
     * to retrieve the path. If it's impossible, the function will return an empty string.
     *
     * Internally this function uses Spark's input_file_name() SQL function. Refer that
     * to learn more when the function returns "".
     *
     * @param {{function: string, pattern: *}|string} match - the column to store dataset path in
     * @return {Object} - function object.
     */
    inputDatasetName(match) {
        return this.wrapper(fun(match, "input_dataset_name", []));
    }

    /**
     * Gets the last modification date for dataset files. The dataset must either be the study
     * name ("reitingud", "iseloom_2021_05") or full S3A path ("s3a://kuk-private-data/parquet/reitingud.parquet").
     * If either .parquet or s3a bucket path + /parquet is missing, those will be added to paths.
     *
     * Important: This function is very slow and should be avoided if possible. If used, it's suggested to
     * call it on data up to some hundred rows.
     *
     * @param {{function: string, pattern: *}|string} match - column to store time in as timestamp
     * @param {String} datasetPathColumn - column that stores dataset path(s)
     * @return {Object} - function object.
     */
    datasetModificationTime(match, datasetPathColumn) {
        return this.wrapper(fun(match, "dataset_modification_time", [datasetPathColumn]));
    }

    /**
     * Counts the number of columns in the dataframe
     *
     * @param {{function: string, pattern: *}|string} match - column to store column counts in
     * @return {Object} - function object.
     */
    columnCount(match) {
        return this.wrapper(fun(match, "column_count", []));
    }

    /**
     * Applied to columns of array or map type. Returns the size of the array or map type column at each row.
     *
     * @param {{function: string, pattern: *}|string} match - array/map column and the column that size will be stored in
     * @return {Object} - function object.
     */
    size(match) {
        return this.wrapper(fun(match, "size", []));
    }

    /**
     * Converts column to boolean where true means the previous value was null and false that it wasn't
     *
     * @param {{function: string, pattern: *}|string} match - matched column
     * @return {Object} - function object.
     */
    isNull(match) {
        return this.wrapper(fun(match, "is_null", []));
    }

    /**
     * Converts column to boolean where true means the previous value was not null and false that it was
     *
     * @param {{function: string, pattern: *}|string} match - matched column
     * @return {Object} - function object.
     */
    notNull(match) {
        return this.wrapper(fun(match, "not_null", []));
    }

    /**
     * Converts column to boolean where true means the previous value was NaN and false that it wasn't
     *
     * @param {{function: string, pattern: *}|string} match - matched column
     * @return {Object} - function object.
     */
    isNan(match) {
        return this.wrapper(fun(match, "is_nan", []));
    }

    /**
     * Applies a unary "not" operator to a boolean column, flipping the bits.
     *
     * @param {{function: string, pattern: *}|string} match - matched column
     * @return {Object} - function object.
     */
    not(match) {
        return this.wrapper(fun(match, "not", []));
    }

    /**
     * Creates an array type column where the values of the array are added from the columns arguments.
     * So if columns are a and b, match is c then c=[a, b] on each row. Columns can either be strings
     * or match objects.
     *
     * @param {{function: string, pattern: *}|string} match - matched, array column
     * @param {{function: string, pattern: *}|string} columns - columns to concat into the array
     * @return {Object} - function object.
     */
    columnsToArray(match, ...columns) {
        return this.wrapper(fun(match, "columns_to_array", columns));
    }

    /**
     * Converts an array type column into the sum of values stored in the array. Supports arrays
     * of floats, doubles, ints and longs.
     *
     * @param {{function: string, pattern: *}|string} match - array column
     * @return {Object} - function object.
     */
    arraySum(match) {
        return this.wrapper(fun(match, "array_sum", []));
    }

    /**
     * Deletes a column.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    dropColumn(match) {
        return this.wrapper(fun(match, "drop_column"));
    }

    /**
     * Takes the absolute value of a column.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    abs(match) {
        return this.wrapper(fun(match, "abs"));
    }

    /**
     * Converts a column's values to float.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    toFloat(match) {
        return this.wrapper(fun(match, "to_float"));
    }

    /**
     * Converts a column's values to string.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    toString(match) {
        return this.wrapper(fun(match, "to_string"));
    }

    /**
     * Converts a column's values to integer.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    toInteger(match) {
        return this.wrapper(fun(match, "to_integer"));
    }

    /**
     * Converts a column's values to long.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    toLong(match) {
        return this.wrapper(fun(match, "to_long"));
    }

    /**
     * Converts a column's values to string where the value on each row is the name of the original columns type
     * For example: float, string, double, array(string, true), timestamp etc
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    toTypename(match) {
        return this.wrapper(fun(match, "to_typename"));
    }

    /**
     * Converts a string or TimeStamp column to date.
     *
     * @param {{function: string, pattern: *}|string} match - the column to be converted.
     * @param {?string} format - if string, format of the current string. Assumes `yyyy-MM-dd HH:mm:ss` by default.
     * @return {Object} - function object.
     * */
    toDate(match, format) {
        const args = [];
        if (format) { args.push(format); }
        return this.wrapper(fun(match, "to_date", args));
    }

    /**
     * Converts a string column to TimeStamp.
     *
     * @param {{function: string, pattern: *}|string} match - the column to be converted.
     * @param {string} format - format of the timestamp. `yyyy-MM-dd HH:mm:ss` by default.
     * @return {Object} - function object.
     * */
    toTimestamp(match, format) {
        const args = [];
        if (format) { args.push(format); }
        return this.wrapper(fun(match, "to_timestamp", args));
    }

    /**
     * Rounds / truncates a date value to the chosen interval.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {string} interval - interval (‘year’, ‘yyyy’, ‘yy’, ‘month’, ‘mon’, ‘mm’, ‘day’, ‘dd’, ‘hour’, ‘minute’, ‘second’, ‘week’, ‘quarter’)
     * @return {Object} - function object.
     * */
    dateTrunc(match, interval) {
        return this.wrapper(fun(match, "date_trunc", [interval]));
    }

    /**
     * Rounds values to a certain precision.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number} precision - the precision to which the floats should be rounded in places after comma.
     * @return {Object} - function object.
     */
    round(match, precision) {
        return this.wrapper(fun(match, "round", [precision]));
    }

    /**
     * Ceils values to a certain precision.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number} precision - the precision to which the floats should be rounded in places after comma.
     * @return {Object} - function object.
     */
    ceil(match, precision) {
        return this.wrapper(fun(match, "ceil", [precision]));
    }

    /**
     * Floors values.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number} precision - the precision of the floor.
     * @return {Object} - function object.
     */
    floor(match, precision) {
        return this.wrapper(fun(match, "floor", [precision]));
    }

    /**
     * Takes the modulo of the values (remainder of an euclidean division).
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number} number - the divisor.
     * @return {Object} - function object.
     */
    mod(match, number) {
        return this.wrapper(fun(match, "modulo", [number]));
    }

    /**
     * Takes a continuous range and turns it into a stepped, floored range
     * where the steps are fixed to, start from the maximum value.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number} step - step size.
     * @return {Object} - function object.
     * */
    stepFromMax(match, step) {
        return this.wrapper(fun(match, "step_from_max", [step]));
    }

    /**
     * Sets the column to a monotonically increasing index starting with 1.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    increasingIndex(match) {
        return this.wrapper(fun(match, "increasing_index"));
    }

    /**
     * Creates one-hot vectors based on the values of the matched column.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {string} prefix - the prefix of the one-hot vector columns that will store the result.
     * @return {Object} - function object.
     */
    toOneHot(match, prefix) {
        return this.wrapper(fun(match, "to_onehot", [prefix]));
    }

    /**
     * Returns a boolean column which checks for equality with a specified value.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number|string} value - the value to check against.
     * @return {Object} - function object.
     */
    equals(match, value) {
        return this.wrapper(fun(match, "equals", [value]));
    }

    /**
     * Returns a boolean column by comparing the matched and the specified columns for equality.
     *
     * @param {{function: string, pattern: *}|string} match - the column that is compared with the specified column. Stores the result.
     * @param {string} column - the column that is compared with the matched column.
     * @return {Object} - function object.
     */
    equalsColumn(match, column) {
        return this.wrapper(fun(match, "equals_column", [column]));
    }

    /**
     * Returns a boolean column which checks for non-equality with a specified value.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number|string} value - the value to check against.
     * @return {Object} - function object.
     */
    notEquals(match, value) {
        return this.wrapper(fun(match, "not_equals", [value]));
    }

    /**
     * Returns a boolean column by comparing the matched and the specified columns for non-equality.
     *
     * @param {{function: string, pattern: *}|string} match - the column that is compared with the specified column. Stores the result.
     * @param {string} column - the column that is compared with the matched column.
     * @return {Object} - function object.
     */
    notEqualsColumn(match, column) {
        return this.wrapper(fun(match, "not_equals_column", [column]));
    }

    /**
     * Returns a boolean column, where row values are based on whether their prior value was contained in the provided list of values.
     *
     * @param {string} match - the column on which the contain operation is run. Stores the result.
     * @param {Array} values - list of values.
     * @return {Object} - function object.
     */
    containsAny(match, values) { return this.wrapper(fun(match, "contains_any", values)); }

    /**
     * Returns a boolean column, where row values are based on whether their prior value was not contained in the provided list of values.
     *
     * @param {string} match - the column on which the contain operation is run. Stores the result.
     * @param {Array} values - list of values.
     * @return {Object} - function object.
     */
    notContainsAny(match, values) { return this.wrapper(fun(match, "not_contains_any", values)); }

    /**
     * Returns a boolean column which checks whether the column's value is greater than or equal to the specified value.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @param {number} value - compared value.
     * @return {Object} - function object.
     */
    greaterOrEqual(match, value) {
        return this.wrapper(fun(match, "greater_or_equal", [value]));
    }

    /**
     * Returns a boolean column which checks whether the column's value is greater than the specified value.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @param {number} value - compared value.
     * @return {Object} - function object.
     */
    greaterThan(match, value) {
        return this.wrapper(fun(match, "greater_than", [value]));
    }

    /**
     * Returns a boolean column which checks whether the column's value is lesser than or equal to the specified value.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @param {number} value - compared value.
     * @return {Object} - function object.
     */
    lessOrEqual(match, value) {
        return this.wrapper(fun(match, "less_or_equal", [value]));
    }

    /**
     * Returns a boolean column which checks whether the column's value is lesser than the specified value.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @param {number} value - compared value.
     * @return {Object} - function object.
     */
    lessThan(match, value) {
        return this.wrapper(fun(match, "less_than", [value]));
    }

    /**
     * Sets the column to the specified value.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @param {number|string} value - specified value.
     * @return {Object} - function object.
     */
    set(match, value) {
        return this.wrapper(fun(match, "set", [value]));
    }

    /**
     * Sets the column to its sum.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object
     */
    sum(match) {
        return this.wrapper(fun(match, "sum", []));
    }

    /**
     * Sets the column to its max value.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object
     */
    max(match) {
        return this.wrapper(fun(match, "max", []));
    }

    /**
     * Sets all the values in the matched column to the target column value at the row with the max matched column value.
     *
     * given df:
     * a   b
     * ------
     * 1   2
     * 2   1
     * 3   0
     *
     * argmax(a, b) sets a to 1 everywhere, because max(b)=2 and a at b=2 is 1.
     * In other words, argmax(a, b) is equivalent to b[np.argmax(a)], as we don't work with indices.
     * argmax(b, a) sets b to 0 everywhere, because max(a)=3 and b at a=3 is 0
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @param {string} column - target column from which to take the value in the argmax row.
     * @return {Object} - function object
     */
    argmax(match, column) {
        return this.wrapper(fun(match, "argmax", [column]));
    }

    /**
     * Sets the column to its min value.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object
     */
    min(match) {
        return this.wrapper(fun(match, "min", []));
    }

    /**
     * Sets all the values in the matched column to the target column value at the row with the min matched column value.
     *
     * given df:
     * a   b
     * ------
     * 1   2
     * 2   1
     * 3   0
     *
     * argmin(a, b) sets a to 3 everywhere, because min(b)=0 and a at b=0 is 3.
     * In other words, argmin(a, b) is equivalent to b[np.argmin(a)], as we don't work with indices.
     * argmin(b, a) sets b to 2 everywhere, because min(a)=1 and b at a=1 is 2
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @param {string} column - target column from which to take the value in the argmin row.
     * @return {Object} - function object
     */
    argmin(match, column) {
        return this.wrapper(fun(match, "argmin", [column]));
    }

    /**
     * Sets the column to its mean value.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object
     */
    mean(match) {
        return this.wrapper(fun(match, "mean", []));
    }

    /**
     * Sets the column to the count of its values.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object
     */
    count(match) {
        return this.wrapper(fun(match, "count", []));
    }

    /**
     * Takes a sliding average of the matched column with equal weights.
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowWidth - window size.
     * @param {string} windowType - window type, can be 'number', 'weeks', 'days'.
     * @param {?string} partitionColumn - partition by this column.
     * @return {Object} - function object.
     * */
    slidingAverage(match, idxCol, windowWidth, windowType, partitionColumn = null) {
        const args = [idxCol, windowWidth, windowType];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_average", args));
    }

    /**
     * Takes a sliding sum of the matched column with equal weights.
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowWidth - window size.
     * @param {string} windowType - window type, can be 'number', 'weeks', 'days'.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     * */
    slidingSum(match, idxCol, windowWidth, windowType, partitionColumn = null) {
        const args = [idxCol, windowWidth, windowType];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_sum", args));
    }

    /**
     * Takes a sliding triangle average of the matched column, with the weights inversely proportional to the
     * distance from the current row.
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowWidth - window size.
     * @param {string} windowType - window type, can be 'number', 'weeks', 'days'.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     * */
    slidingTriangleAverage(match, idxCol, windowWidth, windowType, partitionColumn = null) {
        const args = [idxCol, windowWidth, windowType];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_triangle_average", args));
    }

    /**
     * Takes a sliding triangle sum of the matched column, with the weights inversely proportional to the
     * distance from the current row.
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowWidth - window size.
     * @param {string} windowType - window type, can be 'number', 'weeks', 'days'.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     * */
    slidingTriangleSum(match, idxCol, windowWidth, windowType, partitionColumn = null) {
        const args = [idxCol, windowWidth, windowType];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_triangle_sum", args));
    }

    /**
     * Takes a sliding weighted average of the matched column.
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowWidth - window size.
     * @param {string} windowType - window type, can be 'number', 'weeks', 'days'.
     * @param {string} weightColumn - the column holding the weights.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     */
    slidingWeightedAverage(match, idxCol, windowWidth, windowType, weightColumn, partitionColumn = null) {
        const args = [idxCol, windowWidth, windowType, weightColumn];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_weighted_average", args));
    }

    /**
     * Takes a sliding weighted sum of the matched column.
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowWidth - window size.
     * @param {string} windowType - window type, can be 'number', 'weeks', 'days'.
     * @param {string} weightColumn - the column holding the weights.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     */
    slidingWeightedSum(match, idxCol, windowWidth, windowType, weightColumn, partitionColumn = null) {
        const args = [idxCol, windowWidth, windowType, weightColumn];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_weighted_sum", args));
    }

    /**
     * Takes a sliding weighted average with the specified window function.
     * Different window functions: https://en.wikipedia.org/wiki/Window_function
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowWidth - window size.
     * @param {string} indexType - window type, can be 'number', 'weeks', 'days'.
     * @param {string} weightColumn weight column
     * @param {string} windowFunction - the custom window function. Currently supported window functions: 'hann-poisson', 'triangle'.
     * @param {number|string} windowParameter - the window parameter, needed for some custom window functions.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     * */
    slidingWeightedAverageWindowed(match, idxCol, windowWidth, indexType, weightColumn, windowFunction, windowParameter, partitionColumn = null) {
        const args = [idxCol, windowWidth, indexType, weightColumn, windowFunction, windowParameter];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_weighted_average_windowed", args));
    }

    /**
     * Takes a sliding weighted average with the specified window function.
     * Different window functions: https://en.wikipedia.org/wiki/Window_function
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowWidth - window size.
     * @param {string} indexType - window type, can be 'number', 'weeks', 'days'.
     * @param {string} weightColumn weight column
     * @param {string} windowFunction - the custom window function. Currently supported window functions: 'hann-poisson', 'triangle'.
     * @param {number|string} windowParameter - the window parameter, needed for some custom window functions.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     * */
    slidingWeightedSumWindowed(match, idxCol, windowWidth, indexType, weightColumn, windowFunction, windowParameter, partitionColumn = null) {
        const args = [idxCol, windowWidth, indexType, weightColumn, windowFunction, windowParameter];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_weighted_sum_windowed", args));
    }

    /**
     * Takes a sliding triangle weighted average of the matched column, with the weights in turn scaled inversely
     * in proportion to the distance from the current row.
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowWidth - window size.
     * @param {string} windowType - window type, can be 'number', 'weeks', 'days'.
     * @param {string} weightColumn - weight column.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     * */
    slidingTriangleWeightedAverage(match, idxCol, windowWidth, windowType, weightColumn, partitionColumn = null) {
        const args = [idxCol, windowWidth, windowType, weightColumn];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_triangle_weighted_average", args));
    }

    /**
     * Takes a sliding average that ensures each window has a certain minimum sample size.
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowSampleSize - window minimum sample size.
     * @param {string} sizeColumn - sample size column.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     */
    slidingAverageBySampleSize(match, idxCol, windowSampleSize, sizeColumn, partitionColumn = null) {
        const args = [idxCol, windowSampleSize, sizeColumn];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_average_by_sample_size", args));
    }

    /**
     * Takes a sliding sum that ensures each window has a certain minimum sample size.
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowSampleSize - window minimum sample size.
     * @param {string} sizeColumn - sample size column.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     */
    slidingSumBySampleSize(match, idxCol, windowSampleSize, sizeColumn, partitionColumn = null) {
        const args = [idxCol, windowSampleSize, sizeColumn];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_sum_by_sample_size", args));
    }

    /**
     * Takes a sliding with the specified window function that ensures each window has a certain minimum sample size.
     * Different window functions: https://en.wikipedia.org/wiki/Window_function
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowSampleSize - the minimum sample size of each window.
     * @param {string} windowFunction - the custom window function. Currently supported window functions: 'hann-poisson', 'triangle'.
     * @param {number|string} windowParameter - the window parameter, needed for some custom window functions.
     * @param {string} sizeColumn column of sample sizes.
     * @param {?string} partitionColumn - partition by this column. Optional.
     * @return {Object} - function object.
     * */
    slidingAverageBySampleSizeWindowed(match, idxCol, windowSampleSize, windowFunction, windowParameter, sizeColumn, partitionColumn = null) {
        const args = [idxCol, windowSampleSize, windowFunction, windowParameter, sizeColumn];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_average_by_sample_size_windowed", args));
    }

    /**
     * Takes a sliding weighted average that ensures each window has a certain minimum sample size.
     *
     * @param {{function: string, pattern: *}|string} match - slide on the values in this column.
     * @param {string} idxCol - slide over the indexes in this column.
     * @param {number} windowSampleSize - min sample size.
     * @param {string} windowType - window type, can be 'number', 'weeks', 'days'.
     * @param {string} weightColumn - weight column.
     * @param {string} sizeColumn - sample size column.
     * @param {?string} partitionColumn - partition by this column. Optional.
     * @return {Object} - function object.
     * */
    slidingWeightedAverageBySampleSize(match, idxCol, windowSampleSize, windowType, weightColumn, sizeColumn, partitionColumn = null) {
        const args = [idxCol, windowSampleSize, windowType, weightColumn, sizeColumn];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "sliding_weighted_average_by_sample_size", args));
    }

    /**
     * Renames a column.
     *
     * @param {{function: string, pattern: *}|string} match - The column to be renamed.
     * @param {string} newColumnName - The new name of the renamed column.
     * @return {Object} - function object.
     */
    rename(match, newColumnName) {
        return this.wrapper(fun(match, "rename", [newColumnName]));
    }

    /**
     * Appends a string to the column name.
     *
     * @param {{function: string, pattern: *}|string} match - The column to be renamed.
     * @param {string} postfix - The string to be appended.
     * @return {Object} - function object.
     */
    columnNameAppend(match, postfix) {
        return this.wrapper(fun(match, "column_name_append", [postfix]));
    }

    /**
     * Prepends a string to the column name.
     *
     * @param {{function: string, pattern: *}|string} match - The column to be renamed.
     * @param {string} prefix - The string to be prepended.
     * @return {Object} - function object.
     */
    columnNamePrepend(match, prefix) {
        return this.wrapper(fun(match, "column_name_prepend", [prefix]));
    }

    // TODO: broken in the back-end.
    imputeMean(match, value) {
        return this.wrapper(fun(match, "impute_mean", [value]));
    }

    /**
     * Imputes a matched column over an index column.
     * @param {{function: string, pattern: *}|string} match - matched column, has its values imputed.
     * @param {string} indexColumn - column that provides the ordering for the imputation.
     * @param {?string} partitionColumn - column to separate the process into parallel calculated partitions
     * @return {Object} - function object.
     **/
    imputeInterpolate(match, indexColumn, partitionColumn = null) {
        const args = [indexColumn];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(fun(match, "impute_interpolate", args));
    }

    /**
     * Replaces the values in a column according to the js map object provided.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {Object} replaceMap - a js object with key: value pairs.
     * @return {Object} - function object.
     */
    replace(match, replaceMap) {
        return this.wrapper(fun(match, "replace", [replaceMap]));
    }

    /**
     * Replaces nulls with a provided @value.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {number|string} value - replace nulls by this.
     * @return {Object} - function object.
     */
    replaceNulls(match, value) {
        return this.wrapper(fun(match, "replace_nulls", [value]));
    }

    /**
     * Replaces nulls with the content of another column.
     * Allows the other column to not exist, then the step is skipped.
     *
     * @param {{function: string, pattern: *}|string} match - transformed column.
     * @param {string} column - replace nulls with this column
     * @return {Object} - function object.
     */
    replaceNullsWithColumn(match, column) {
        return this.wrapper(fun(match, "replace_nulls_with_column", [column]));
    }

    /**
     * Calculates the confidence interval for a proportion.
     * https://web.sonoma.edu/users/w/wilsonst/courses/math_165/Solns2/p4/default.html
     *
     * @param {{function: string, pattern: *}|string} proportionSizeColumn - proportion size column.
     *      IMPORTANT: if sample is 1k, val must be between 0-1000. Not 0-1
     * @param {number} confidenceInterval - confidence interval (80-100).
     * @param {string} sampleSizeColumn - total sample size column.
     * @return {Object} - function object.
     * */
    confidenceIntervalProportion(proportionSizeColumn, confidenceInterval, sampleSizeColumn) {
        return this.wrapper(fun(proportionSizeColumn, "confidence_interval_proportion", [confidenceInterval, sampleSizeColumn]));
    }
}

export default Transform;
