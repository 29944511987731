import React, { useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";

const ElementDataframeFilter = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const { children, setOutput, "data": dataArgs, filterColumn } = props;

    const filter = (data, greaterThan, value) => {
        const operator = greaterThan ? (a, b) => a >= b : (a, b) => a <= b;
        if (typeof value === "number") {
            return data.filter(r => operator(r.get(filterColumn), value));
        } else {
            const index = data.getColumn(filterColumn).indexOf(value);
            return data.filter((r, i) => operator(i, index));
        }
    };

    if (data instanceof DataFrame && !data.isEmpty()) {
        const dataOut = data.copy();

        if (dataArgs.minValue || dataArgs.minValue === 0) {
            filter(dataOut, true, dataArgs.minValue);
        }
        if (dataArgs.maxValue || dataArgs.maxValue === 0) {
            filter(dataOut, false, dataArgs.maxValue);
        }
        if (dataArgs.keepValues instanceof Array) {
            const vals = new Set(dataArgs.keepValues);
            dataOut.filter(r => vals.has(r.get(filterColumn)));
        }
        setOutput(dataOut);
    }

    return (
        <Element
            {...props}
            primary
            setData={setData}
        >
            <div>{children}</div>
        </Element>
    );
};

export { ElementDataframeFilter as DataframeFilter };
