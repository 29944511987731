import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Image } from "semantic-ui-react";
import SidebarBottomMenu from "./SidebarBottomMenu";
import { permittedDashboardsMetadata } from "../screens/DashboardSelectScreen";
import { capitalize, uniques } from "../UtilityFunctions";
import { Button, Divider, Menu, useTheme } from "react-daisyui";
import MenuItemHor from "./MenuItemHor";

function SidebarWrapper({ children, maxWidth }) {
    const { theme } = useTheme();

    const menuTextColor = theme === "light" ? "text-primary-content" : "text-base-300-content";

    const [layout, setLayout] = useState({
        isMobile: window.innerWidth < 1024,
        sideWidth: window.innerWidth < 1024 ? 55 : 250,
        mainWidth: window.innerWidth - (window.innerWidth < 1024 ? 55 : 250)
    });

    const [ignored, setActiveItemIgnored] = useState(null);
    const [lastDrag, setLastDrag] = useState(new Date());

    const updateSizes = (newSideWidth) => {
        const currMobile = (window.innerWidth < 1024);

        setLayout((old) => {
            const mobileChanged = currMobile.toString() !== old.isMobile.toString();

            if (mobileChanged) {
                console.log("mobile changed to", currMobile);
                if (currMobile) {
                    newSideWidth = 55;
                } else {
                    newSideWidth = 250;
                }
            }
            const finalSideWidth = newSideWidth !== undefined ? newSideWidth : old.sideWidth;
            return {
                isMobile: currMobile,
                sideWidth: finalSideWidth,
                mainWidth: window.innerWidth - finalSideWidth
            };
        });
    };

    useEffect(() => {
        window.addEventListener("resize", () => updateSizes());
    }, []);

    const resize = (e, skipZero = true) => {
        if (e.clientX !== 0 || !skipZero) {
            let currPos = e.clientX;
            if (currPos < 20) {
                currPos = 0;
            }
            const time = new Date();
            if (Math.abs(lastDrag - time) > 100) {
                console.log(currPos);
                updateSizes(currPos);
                setLastDrag(time);
            }
        }
    };

    const MainMenuLogo = () => (
        <Menu.Item
            style={{ "whiteSpace": "nowrap" }}
            as="div"
        >
            <a href="/">
                <Image
                    src="/media/kuk-logo-medium-white.png"
                    size="tiny"
                    centered
                />
            </a>
        </Menu.Item>
    );

    const sideMenu = (
        <Menu>
            <MainMenuLogo/>
            <MenuItemHor href="/roadmap" icon="map" text="Teekaart" isMobile={layout.isMobile}/>
            <MenuItemHor href="/public_data" icon="table-cells" text="Andmestikud" isMobile={layout.isMobile}/>
            <MenuItemHor href="/rapid-survey" icon="forward" text="Kiiruuringud" isMobile={layout.isMobile}/>
            <MenuItemHor href="/dashboard/view" icon="computer-desktop" text="Töölauad" isMobile={layout.isMobile}/>
            <div className="scrollbar-auto">
                {!layout.isMobile && uniques([...permittedDashboardsMetadata].map(([_, meta]) => meta.category))
                    .map(c => <React.Fragment key={c}>
                        <Menu.Title className={"ml-5 mb-2 mt-8 lg:mt-3 font-semibold " + menuTextColor}>{capitalize(c)}</Menu.Title>
                        <div className="">
                            { [...permittedDashboardsMetadata]
                                .filter(([_, m]) => m.category === c)
                                .map(([n, _m]) => <Menu.Item key={n} className="hover-bordered bordered border-l ml-6 border-secondary-100">
                                    <a
                                        href={`/dashboard/view?name=${n}`}
                                        className={"block border-l pl-2 pb-2 pt-2 -ml-px border-transparent hover:border-slate-400 " + menuTextColor}
                                    >{_m.title}</a>
                                </Menu.Item>)
                            }
                        </div>
                    </React.Fragment>)}
            </div>
            <SidebarBottomMenu width={layout.sideWidth} isMobile={layout.isMobile}/>
        </Menu>
    );

    return (
        <Router>
            <div
                className="w-full w-screen bg-base-200"
            >
                <div
                    style={{
                        zIndex: 100,
                        width: layout.sideWidth
                    }}
                    className={`
                        ${layout.sideWidth === 0 && "hidden"} 
                        min-h-screen h-screen scroll-auto pr-3 hover:pr-0 fixed flex
                        ${theme === "light" ? "bg-primary" : "bg-base-300"}`}>
                    {sideMenu}
                </div>
                {layout.sideWidth === 0 && <Button
                    className={`bg-primary fixed z-40 p-2 ${theme === "light" ? "bg-primary" : "bg-base-300"} round`}
                    style={{
                        top: "47%"
                    }}
                    onClick={() => updateSizes(250)}
                >&gt;</Button>}
                {!layout.isMobile && <Divider
                    style={{
                        left: layout.sideWidth
                    }}
                    className="float-right relative h-screen absolute z-40"
                    draggable={true}
                    onDrag={resize}
                    onDragEnd={(e) => resize(e, false)}
                />}
                <div
                    className="grid pb-16 w-auto overflow-x-scroll relative fixed min-h-screen"
                    style={{
                        left: layout.sideWidth,
                        width: layout.mainWidth,
                        position: "relative",
                        paddingLeft: 16,
                        paddingRight: 16
                    }}
                >
                    <div
                        className={maxWidth !== undefined && "ml-auto mr-auto"}
                        style={{
                            maxWidth: layout.mainWidth - 32
                        }}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default SidebarWrapper;
