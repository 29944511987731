import * as AWS from "aws-sdk";
import React, { useEffect, useState } from "react";
import { user } from "../auth/User";
import env from "@beam-australia/react-env";
import { ArrowDownTrayIcon, ArrowPathIcon } from "@heroicons/react/24/solid";
import { SimpleDataTable } from "./SimpleDataTable";

const loadingRows = [[<ArrowPathIcon className="w-6 h-6 animate-spin" key="loader"/>, "", ""]];

function saveFile(data, fileName) {
    const blob = new Blob([data]);
    const fileUrl = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = fileUrl;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
}

const PublicDataScreen = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const downloadObject = (key, date) => {
        if (authenticated) {
            const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
            const downloadParams = {
                Bucket: "kuk-staging-datasets",
                Key: key
            };
            s3.getObject(downloadParams, function(err, data) {
                if (err) {
                    console.log(err, err.stack);
                } else {
                    let fileName = key.split("/").filter(s => s.length > 0).at(-1);
                    fileName = fileName
                        .replace(".csv", `-${date}.csv`)
                        .replace(".xlsx", `-${date}.xlsx`);
                    saveFile(
                        data.Body.toString("utf-8"),
                        fileName
                    );
                }
            });
        } else {
            console.log("Can only download data when authenticated!");
        }
    };

    const listObjects = () => {
        const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
        const bucketParams = {
            Bucket: "kuk-staging-datasets",
            Prefix: "cognito/"
        };
        s3.listObjects(bucketParams, function(err, data) {
            if (err) {
                console.log("List Objects error", err, err.stack);
                setRows([[`Viga andmete laadimisel: ${err.message}`]]);
            } else {
                const newRows = data.Contents
                    .filter(row => row.Key.endsWith(".csv") || row.Key.endsWith(".xlsx"))
                    .filter(row => /^([^/]*\/){2}latest\./.test(row.Key))
                    .map((row) => ([
                        row.Key
                            .replace("cognito/", "")
                            .replace("_aggregated", "")
                            .replace("latest.", ""),
                        row.LastModified.toISOString().substring(0, 16).replace("T", " "),
                        <ArrowDownTrayIcon
                            key={row.Key}
                            className="h-6 w-6 justify-center hover:cursor-pointer hover:animate-pulse m-auto"
                            onClick={() => downloadObject(
                                row.Key,
                                row.LastModified.toISOString().substring(0, 16).replace("T", " ")
                            )}
                        />
                    ]));
                setRows(newRows);
            }
        });
    };

    useEffect(() => {
        user.getCognitoIdToken((jwtToken) => {
            const loginHost = `cognito-idp.eu-central-1.amazonaws.com/${env("COGNITO_USER_POOL_ID")}`;
            AWS.config.update({
                region: "eu-central-1",
                apiVersion: "latest",
                credentials: new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: "eu-central-1:fa536205-b3e0-4b3e-b6bb-a4e672292fc4",
                    Logins: {
                        [loginHost]: jwtToken
                    }
                }, {
                    "region": "eu-central-1"
                })
            });
            AWS.config.credentials.refresh(error => {
                if (error) {
                    console.error("Credentials update error", error);
                    setAuthenticated(false);
                } else {
                    setAuthenticated(true);
                }
            });
        });
    }, []);

    useEffect(() => {
        if (authenticated) {
            listObjects();
        } else {
            setRows(loadingRows);
        }
    }, [authenticated]);

    const [rows, setRows] = useState(loadingRows);
    const [header] = useState(["Fail", "Viimati uuendatud", "Lae alla"]);

    return (
        <div className="pt-4">
            <h1>Allalaetavad andmestikud</h1>
            <p>Agregeeritud andmestikud, mida uuendatakse andmetorustikus automaatselt.</p>
            <p>Funktsionaalsus on veel eksperimentaalne, mistõttu on soovitatav andmete
                kuupäev allalaetud tabelis üle kontrollida.</p>
            <SimpleDataTable
                rows={rows}
                header={header}/>
        </div>
    );
};

export default PublicDataScreen;
