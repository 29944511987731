import React, { useState } from "react";
import { DataFrame } from "../../../DSL/DataFrame";
import { Element } from "../../Element";
import { nameToImageUrl } from "../../../../UtilityFunctions";
import { CardPopup, cardTypes } from "./ElementCard";
import { Card } from "react-daisyui";

const imageSizeByType = (type) => {
    let size;
    switch (type) {
    case cardTypes.SMALL:
        size = "w-12 h-12";
        break;
    case cardTypes.LARGE:
        size = "w-40 h-40 lg:w-56 lg:h-56 hidden md:block";
        break;
    default:
        size = "w-24 h-24";
        break;
    }
    return size;
};

const headerStyleByType = (type) => {
    switch (type) {
    case cardTypes.SMALL:
        return { "fontSize": "0.9em", "fontWeight": "bold" };
    case cardTypes.LARGE:
        return { "fontSize": "1.5em", "fontWeight": "bold", "paddingBottom": "0.2em" };
    default:
        return {};
    }
};

const ElementCardContent = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const { "data": dataArgs, style } = props;
    const unit = !(data.isEmpty()) ? data.getRow(0) : null; // new Map();

    const sentencesToString = (sentences) => {
        return sentences.map((sentence) =>
            sentence.map((word) => (
                word.startsWith("%") ? unit.get(word.substring(1)).toString() : word))
                .join(""))
            .join(" ");
    };

    if (unit) {
        return (
            <Element
                {...props}
                primary
                setData={setData}>
                <Card.Body
                    style={style}
                    className="flex flex-wrap flex-row justify-center"
                    href={dataArgs.link && `view?name=${dataArgs.link.dashboard}&${dataArgs.link.element}=${unit.get(dataArgs.link.column)}`}
                >
                    {"image" in dataArgs &&
                        <div className={`avatar self-center ml-4 mt-4 
                            self-stretch rounded-box flex-shrink-0
                            ${imageSizeByType(dataArgs.type)}`}>
                            <img
                                style={{
                                    "object-fit": "cover",
                                    ...dataArgs.image.style
                                }}
                                className="rounded-box"
                                src={nameToImageUrl(unit.get(dataArgs.image.imageReferenceColumn))}
                                onError={(e) => (e.target.src = nameToImageUrl(dataArgs.image.defaultImagePath, false))}
                            />
                        </div>
                    }
                    <div
                        style={{
                            "display": "flex",
                            "flexFlow": "column",
                            "flexGrow": 1,
                            "flexBasis": dataArgs.type === cardTypes.SMALL_CARD ? "100%" : "67%"
                        }}>
                        {"header" in dataArgs &&
                            <Card.Title
                                className="p-4 flex flex-row flex-nowrap overflow-x-hidden items-center"
                                style={{
                                    ...headerStyleByType(dataArgs.type),
                                    ...dataArgs.header.style
                                }}
                            >
                                <span className="mr-auto whitespace-nowrap overflow-x-clip overflow-ellipsis">
                                    {unit.get(dataArgs.header.headerReferenceColumn)}
                                </span>
                                {"meta" in dataArgs.header &&
                                    <span
                                        className="font-normal float-right whitespace-nowrap ml-1"
                                        style={{
                                            "fontSize": ".9em",
                                            ...dataArgs.header.meta.style
                                        }}
                                    >{(dataArgs.header.meta.prefix ? dataArgs.header.meta.prefix : "") + unit.get(dataArgs.header.meta.metaReferenceColumn)}
                                    </span>
                                }
                                {"meta" in dataArgs &&
                                    <span
                                        className="font-normal float-right whitespace-nowrap ml-1"
                                        style={{
                                            "fontSize": ".9em"
                                        }}
                                    >
                                        {(dataArgs.meta.prefix ? dataArgs.meta.prefix : "") + unit.get(dataArgs.meta.metaReferenceColumn)}
                                    </span>
                                }
                            </Card.Title>
                        }
                        {"description" in dataArgs &&
                            <>
                                <p>
                                    {"sentences" in dataArgs.description &&
                                sentencesToString(dataArgs.description.sentences)
                                    }
                                </p>
                                {props.children}
                            </>
                        }
                    </div>
                    {"popups" in dataArgs &&
                        <Card.Actions
                            style={{
                                "display": "flex",
                                "justifyContent": "space-evenly",
                                "textAlign": "center",
                                ...dataArgs.popups.style
                            }}>
                            {dataArgs.popups.list.map((popupContentProps, i) => (
                                <CardPopup
                                    key={i}
                                    content={popupContentProps.content}
                                    icon={popupContentProps.icon}
                                    conditionalIcon={popupContentProps.conditionalIcon}
                                    text={popupContentProps.text}
                                    data={unit}
                                />))}
                        </Card.Actions>
                    }
                </Card.Body>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>
        );
    }
};

export { ElementCardContent as CardContent };
