import React, { useEffect, useRef, useState } from "react";
import styles from "./TNestedMultiSelect.module.css";
import { NewFieldSearchable } from "./TMultiSelect";
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from "react-dom";

const SelectLevel = ({
    text, onChange, options, className, level,
    onMouseEnter, onMouseLeave, onRemove, defaultValue
}) => {
    if (level === undefined) {
        level = 0;
    }
    let bgColor;
    if (level === 0) {
        bgColor = "bg-neutral text-neutral-content";
    } else if (level === 1) {
        bgColor = "bg-neutral hover:bg-neutral-focus text-neutral-content";
    } else {
        bgColor = "bg-primary text-primary-content";
    }
    const [selected, setSelected] = useState([]);
    const [optionsLeft, setOptionsLeft] = useState(options);
    const [clicked, setClicked] = useState(false);
    const [hovered, setHovered] = useState(false);
    const selectRef = useRef();

    useEffect(() => {
        // select defaultValue
        if (defaultValue === null || defaultValue === undefined) {
            setOptionsLeft(options);
            return;
        }
        let defaultSelected;
        if (Array.isArray(defaultValue)) {
            defaultSelected = options.filter(
                opt => defaultValue.some(key => opt.key === key)
            );
        } else {
            const keys = Object.keys(defaultValue);
            defaultSelected = options.filter(
                opt => keys.some(key => opt.key === key)
            );
        }
        const defaultLeft = options.filter(opt =>
            defaultSelected.every(sel => opt.key !== sel.key)
        );
        setSelected(defaultSelected);
        setOptionsLeft(defaultLeft);
    }, [options]);

    useEffect(() => {
        if (onChange) {
            onChange(selected.map(opt => opt.value));
        }
    }, [selected]);

    const selectOption = (option) => {
        const value = option.value;
        setOptionsLeft(optionsLeft
            .filter(opt => opt.value !== value));
        setSelected([...selected, option]);
    };

    const unSelectOption = (option) => {
        const value = option.value;
        setSelected(selected
            .filter(opt => opt.value !== value));
        onChange({
            key: option.key,
            values: []
        });
        setOptionsLeft([...optionsLeft, option]);
    };

    const unSelectAll = () => {
        selected.forEach(opt => onChange({
            key: opt.key,
            values: []
        }));
        setSelected([]);
        setOptionsLeft(options);
    };

    const optionEls = selected.map(opt =>
        <SelectLevel
            key={opt.key}
            onMouseEnter={() => setHovered(false)}
            onMouseLeave={() => setHovered(true)}
            onRemove={() => unSelectOption(opt)}
            defaultValue={defaultValue && defaultValue[opt.key]}
            onChange={(selection) => {
                if (level === 0) {
                    if (onChange) {
                        onChange({
                            key: opt.key,
                            values: selection
                        });
                    }
                }
            }}
            text={opt.text}
            options={opt.options}
            level={level + 1}
        />
    );

    return <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => {
            setHovered(false);
            if (onMouseLeave) {
                onMouseLeave();
            }
        }}
        className={`
            ${className} 
            ${styles.nmsCategory} 
            level_${level}
            ${level !== 0 && "m-1"}
            flex input-bordered  flex-grow
            ${clicked && styles.nmsClicked}`}
    >
        <label
            onMouseEnter={() => setHovered(true)}
            ref={selectRef}
            onClick={() => {
                if (hovered && !clicked) {
                    setClicked(true);
                }
            }}
            className={`
                ${styles.nmsButton}
                ${bgColor}
                h-full 
                rounded-btn 
                min-h-12 hover:cursor-pointer flex-grow flex`}
        >
            {level === 0 && selected.length > 1 && <div
                className="btn m-auto h-full font-bold"
                onClick={unSelectAll}
            >
                X
            </div>}
            <div className="flex flex-row flex-grow flex-wrap">
                {text && <p className="m-2 pl-2 pr-2">{text}</p>}
                <>{optionEls}</>
                {options && optionsLeft.length > 0 &&
                    <NewFieldSearchable
                        open={clicked}
                        options={optionsLeft}
                        allowAdditions={false}
                        selectOption={selectOption}
                        onCloseField={() => {
                            unstable_batchedUpdates(() => {
                                setClicked(false);
                                setHovered(false);
                            });
                        }}
                    />}
                {level !== 0 &&
                    <div
                        onClick={onRemove}
                        className={`${bgColor} btn p-0 pl-2 pr-2 border-none m-auto mr-0`}
                    >
                        X
                    </div>}
            </div>
        </label>
    </div>;
};

const TMultiSelect = ({ options, onChange, defaultValue }) => {
    return <SelectLevel
        className="w-full"
        options={options}
        onChange={onChange}
        defaultValue={defaultValue}
    />;
};

export default TMultiSelect;
