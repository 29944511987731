import React from "react";
import { List } from "semantic-ui-react";
import { Modal } from "react-daisyui";
import { useDetectAdBlock } from "adblock-detect-react";

export const AdBlockDetector = () => {
    const adBlockDetected = useDetectAdBlock();

    return (
        <Modal open={false}>
            <div className="bg-base-100 rounded-box p-8 max-w-6xl">
                <Modal.Header className="text-2xl font-bold mb-4">
                    Kasutad reklaamiblokeerijat!
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Keskkonna kasutamiseks lülita reklaamiblokeerija välja. Sisekeskkonnas ei ole reklaame,
                        kuid blokeerija takistab keskkonna kasutamise statistika kogumist, mis aitab meil tuvastada
                        keskkonna puudujääke ja tugevusi.
                    </p>
                    <p>Loe, kuidas lülitada sisekeskkonna lehel välja reklaamiblokeerija:</p>
                    <List>
                        <List.Item
                            as="a"
                            href="https://help.getadblock.com/support/solutions/articles/6000055743-how-to-disable-adblock-on-specific-sites"
                            target="_blank"
                        >
                            AdBlock
                        </List.Item>
                        <List.Item
                            as="a"
                            href="https://kb.nsd.org/article-783.html"
                            target="_blank"
                        >
                            UBlock
                        </List.Item>
                    </List>
                </Modal.Body>
            </div>
        </Modal>
    );
};
