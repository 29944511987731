import React, { useState } from "react";
import { Element } from "../Element";
import ReactEcharts from "echarts-for-react";
import { DataFrame } from "../../DSL/DataFrame";
import {
    uniques
} from "../../../UtilityFunctions";
import { useTheme } from "react-daisyui";
import TSegment from "../../../components/TSegment";

const ElementChartHeatmap = (props) => {
    const { theme } = useTheme();
    const [data, setData] = useState(new DataFrame([]));
    const { title, options, colorArray } = props;

    /*
    * Dataframe is expected to have the following columns:
    * x -
    * y -
    * value - size of part of the stacked bar
    * */

    if (!data.isEmpty() && data.hasColumn("x") && data.hasColumn("y") && data.hasColumn("value")) {
        const series = [{
            type: "heatmap",
            data: data.rows().map(r => [r.get("x"), r.get("y"), Math.round(r.get("value")) || "-"]),
            label: {
                show: true
            },
            emphasis: {
                itemStyle: {
                    shadowBlur: 3,
                    shadowColor: "rgba(0, 0, 0, 0.5)"
                }
            }
        }];
        const xUniques = uniques(data.getColumn("x"));
        const yUniques = uniques(data.getColumn("y"));
        const vMin = Math.min.apply(null, data.getColumn("value").filter(v => !Number.isNaN(v)));
        const vMax = Math.max.apply(null, data.getColumn("value").filter(v => !Number.isNaN(v)));

        const longestYLabelLen = Math.max(...(xUniques.map(el => el.length)));
        const height = yUniques.length * 30 + longestYLabelLen * 4 + 50;

        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}>
                <TSegment>
                    <ReactEcharts
                        style={{ "width": "100%", "height": `${height}px` }}
                        theme={theme === "dark" ? "dark" : "default"}
                        option={{
                            "backgroundColor": "transparent",
                            "tooltip": {
                                "trigger": "axis",
                                "axisPointer": {
                                    "type": "shadow",
                                    "label": {
                                        "formatter": "some text"
                                    }
                                },
                                "formatter": function(column) {
                                    return `<b>${column.map(c => c.axisValue)[0]}</b><table><tr>` +
                                        column.sort((a, b) => b.data[2] - a.data[2]).map(cell =>
                                            `<th>${cell.marker}</th>` +
                                            `<th>${cell.data[1]}</th>` +
                                            `<th>${cell.data[2]}</th>`
                                        ).join("</tr><tr>") +
                                    "</tr></table>";
                                }
                            },
                            "title": {
                                "text": title,
                                "textStyle": { "fontFamily": "Oswald", "fontWeight": 400 }
                            },
                            "legend": {},
                            "grid": {
                                "left": "3%",
                                "right": "4%",
                                "bottom": "3%",
                                "containLabel": true
                            },
                            "toolbox": {
                                "feature": {
                                    "saveAsImage": {}
                                }
                            },
                            "visualMap": {
                                "min": vMin,
                                "max": vMax,
                                "calculable": true,
                                "realtime": false,
                                "inRange": {
                                    "color": colorArray || ["#313695", "#4575b4", "#74add1", "#abd9e9", "#e0f3f8", "#ffffbf", "#fee090", "#fdae61", "#f46d43"]
                                },
                                "show": false
                            },
                            "xAxis": {
                                "type": "category",
                                "axisLabel": {
                                    "rotate": 45
                                }
                            },
                            "yAxis": {
                                "type": "category"
                            },
                            series,
                            ...options
                        }}/>
                </TSegment>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>
        );
    }
};

export { ElementChartHeatmap as ChartHeatmap };
