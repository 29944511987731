import React, { useEffect, useState } from "react";
import styles from "./TMultiSelect.module.css";
import { NewFieldSearchable } from "./TMultiSelect";
import { classNames } from "../UtilityFunctions";

const TSelect = ({ options, onChange, defaultValue, setIsOpen }) => {
    const [selected, setSelected] = useState(undefined);
    const [adding, setAdding] = useState(false);

    useEffect(() => {
        if (setIsOpen) {
            setIsOpen(adding);
        }
    }, [adding]);

    useEffect(() => {
        // select defaultValue if it is in options
        const matching = options.filter(o => o.value === defaultValue);
        if (matching.length > 0) {
            setSelected(matching[0]);
        }
    }, [options]);

    useEffect(() => {
        if (selected && onChange) {
            onChange(selected.value);
            setAdding(false);
        }
    }, [selected]);

    const selectOption = (option) => {
        setSelected(option);
    };

    return <div className="w-full input-bordered">
        <label
            onClick={() => {
                if (!selected) {
                    setAdding(true);
                }
            }}
            className={`
                ${styles.selectionContainer}
                rounded-btn p-1 bg-neutral hover:bg-neutral-focus 
                flex flex-wrap min-h-12 hover:cursor-text`}
        >
            {selected && <div
                className={classNames(
                    "badge rounded-btn h-8 gap-2 m-1 hover:cursor-pointer justify-between w-full",
                    adding && "hidden"
                )}
                key={selected.key}
                onClick={() => setAdding(true)}
            >
                {selected.text}
            </div>}
            <NewFieldSearchable
                style={{ width: "100%" }}
                open={adding}
                options={options}
                allowAdditions={false}
                selectOption={selectOption}
                onCloseField={() => setAdding(false)}
            />
        </label>
    </div>;
};

export default TSelect;
