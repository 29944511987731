import { user } from "../auth/User";
import { DashboardElementBuilderContext } from "./DashboardElementBuilder";
import React, { useContext, useEffect, useState } from "react";
import { DebugContext } from "../debug/DebugModeProvider";
import { loadingStates } from "./stateConstants";

const containerStyle = {
    zIndex: 50,
    left: "auto",
    marginLeft: "-2.5rem",
    paddingLeft: "1rem",
    paddingBottom: "1rem",
    marginBottom: "-1rem"
};

export default () => {
    const elementBuilderContext = useContext(DashboardElementBuilderContext);

    useEffect(() => {
        initStatistics(elementBuilderContext);
    }, [elementBuilderContext]);

    const initStatistics = () => {
        if (elementBuilderContext) {
            const subscriptionHandler = elementBuilderContext.getSubscriptionHandler();
            const elementIdRegistry = elementBuilderContext.getElementIdRegistry();

            return {
                elementIds: elementIdRegistry.size(),

                elementsExisting: Array.from(subscriptionHandler.subscribers,
                    ([_, subscriber]) => subscriber.source.getLoadingState() && subscriber.source.getLoadingState() !== loadingStates.REMOVED)
                    .reduce((sum, isLoaded) => isLoaded + sum, 0),

                elementsLoading: Array.from(subscriptionHandler.sources,
                    ([_, source]) =>
                        ![loadingStates.FINISHED, loadingStates.WAITING_FOR_DEPENDENCIES,
                            loadingStates.INITIALISED, loadingStates.REMOVED]
                            .includes(source.getLoadingState())
                ).reduce((sum, isLoaded) => isLoaded + sum, 0),

                elementsWaiting: Array.from(subscriptionHandler.sources,
                    ([_, source]) =>
                        source.getLoadingState() === loadingStates.WAITING_FOR_DEPENDENCIES
                ).reduce((sum, isLoaded) => isLoaded + sum, 0)
            };
        } else {
            return undefined;
        }
    };
    const [statistics, setStatistics] = useState(undefined);

    useEffect(() => {
        const timer = setInterval(() => {
            setStatistics(initStatistics());
        }, 1000);
        // deconstructor
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <DebugContext.Consumer>
            {debug => debug.isEnabled() && user.hasElevatedAccess(true) &&
                <div
                    className="bg-base-300 fixed bottom-0 left-auto rounded-box"
                    style={containerStyle}
                >
                    {statistics &&
                        <div className="stats bg-base-300 text-base-content">

                            <div className="stat p-2">
                                <div className="stat-title">Elements</div>
                                <div className="stat-value text-xl">{statistics.elementsExisting}</div>
                            </div>

                            <div className="stat p-2">
                                <div className="stat-title">Unique IDs</div>
                                <div className="stat-value text-xl">{statistics.elementIds}</div>
                            </div>

                            <div className="stat p-2">
                                <div className="stat-title">Waiting</div>
                                <div className="stat-value text-xl">{statistics.elementsWaiting}</div>
                            </div>

                        </div>
                    }
                </div>
            }
        </DebugContext.Consumer>
    );
};
