import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";

const ElementConditionalDisplay = (props) => {
    const [ignored, setData] = useState(new DataFrame([]));
    const { children } = props;
    const [condition, setCondition] = useState(props.data.condition);

    useEffect(() => {
        props.subscribeInput(() => {
            setCondition(props.data.condition);
        });
    }, []);

    return (
        <Element
            {...props}
            primary
            setData={setData}
        >
            {condition && <>{children}</>}
        </Element>
    );
};

export { ElementConditionalDisplay as ConditionalDisplay };
