import html2canvas from "html2canvas";
import { default as JsPDF } from "jspdf";

export default (elementHTML, filename, renderCondition) => {
    const hiddenElements = [];
    const boxShadowCache = new Map();
    const all = document.getElementsByTagName("*");
    for (let i = 0, max = all.length; i < max; i++) {
        const item = all.item(i);
        if (!renderCondition(item)) {
            item.style.visibility = "hidden";
            hiddenElements.push(item);
        } else if (item.style.boxShadow) {
            boxShadowCache.set(item, item.style.boxShadow);
            item.style.boxShadow = "";
        }
    }

    const startingWidth = elementHTML.style.width;
    const width = Math.round(585 * (96 / 72) * 2);

    elementHTML.style.width = String(width) + "px";

    setTimeout(() => {
        const bounds = elementHTML.getBoundingClientRect();

        const elementWidth = bounds.width;
        const elementHeight = bounds.height;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        const widthPt = (elementWidth / (96 / 72)); // 585
        const heightPt = (elementHeight / (96 / 72));

        const renderWidth = widthPt * (96 / 72);
        const renderHeight = heightPt * (96 / 72);

        html2canvas(elementHTML, {
            "allowTaint": false,
            "logging": true,
            "letterRendering": 1,
            "useCORS": true,
            "width": renderWidth,
            "height": renderHeight,
            windowWidth,
            windowHeight,
            "scrollX": bounds.x,
            "scrollY": bounds.y
        }).then((canvas) => {
            const pdf = new JsPDF({
                "orientation": "portrait",
                "unit": "pt",
                "format": [585, heightPt / 2],
                "precision": 2
            });

            const ratio = elementWidth / elementHeight;
            const scaledWidth = Math.round(pdf.internal.pageSize.getWidth());
            const scaledHeight = Math.round(pdf.internal.pageSize.getWidth() / ratio);

            pdf.addImage(
                canvas.toDataURL("image/jpeg", 0.99), "JPEG",
                0, 0,
                scaledWidth,
                scaledHeight
            );
            pdf.save(filename);
        });

        setTimeout(() => {
            hiddenElements.forEach((item) => (item.style.visibility = "visible"));
            elementHTML.style.width = startingWidth;
            // console.log(boxShadowCache);
            boxShadowCache.forEach((value, key) => {
                key.style.boxShadow = boxShadowCache.get(key);
            });
        }, 50);
    }, 1000);
};
