import { Accordion, Sticky } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { DataFrame } from "../../DSL/DataFrame";
import { Element } from "../Element";
import { Button } from "react-daisyui";
import TIcon from "../../../components/TIcon";

const buttonStyle = { "marginBottom": "0", "marginTop": "4px", "float": "right" };

const ElementSection = (props) => {
    const { title, hasLoadButton, description, children, setOutput, headerType, open } = props;
    const [ignored, setData] = useState(new DataFrame([]));
    const [isOpen, toggleOpen] = useState(!!open);
    const [n, setN] = useState(0);

    useEffect(() => {
        // open and load on start if isOpen
        if (isOpen) {
            setN((i) => i + 1);
            setOutput(n);
            toggleOpen(isOpen);
        }
    }, []);

    const DropdownButton = () => {
        return (<Button
            style={buttonStyle}
            color="ghost"
            className="text-base-content"
            onClick={() => {
                setN((i) => i + 1);
                setOutput(n);
                toggleOpen(!isOpen);
            }}
        >
            <TIcon name={isOpen ? "arrow-up" : "arrow-down"} size={6}/>
        </Button>);
    };

    const LoadButton = () => {
        return (<Button
            color="ghost"
            className="text-base-content"
            style={buttonStyle}
            onClick={() => {
                setN((i) => i + 1);
                setOutput(n);
                toggleOpen(true);
            }}
        >Lae sektsioon</Button>);
    };

    const SectionHeader = () => {
        return (<div
            style={{ "marginBottom": "8px" }}>
            <h2 className={(headerType && "headerStyled")} style={{ "display": "inline", "top": "50%", "position": "relative" }}>
                {title}
            </h2>
            <DropdownButton/>
            { hasLoadButton && <LoadButton/>}
        </div>);
    };

    const descElement = <p className="whitespace-pre-wrap mb-0 text-base-content">{description}</p>;
    const header = (
        <div
            className="bg-base-200 section-header rounded-box p-2 pl-2 pt-5"
            style={{
                "margin": "0px",
                "marginLeft": "-1.5rem",
                "marginRight": "-1.5rem",
                "marginTop": "-1rem"
            }}
        >
            <SectionHeader/>
            <p>{isOpen && description && descElement}</p>
        </div>);

    return (
        <Element
            width="100%"
            {...props}
            primary
            setData={setData}>
            <Accordion
                fluid
                className="mt-4"
            >
                <Accordion.Title active={isOpen}>
                    {isOpen
                        ? <Sticky context={props.contextReference} pushing>
                            {header}
                        </Sticky>
                        : header}
                </Accordion.Title>
                <Accordion.Content
                    active={isOpen}>
                    {children}
                </Accordion.Content>
            </Accordion>
        </Element>
    );
};

export { ElementSection as Section };
