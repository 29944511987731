import TIcon from "./TIcon";
import React from "react";
import { useTheme } from "react-daisyui";

export default ({ href, icon, text, isMobile, children, className, ...props }) => {
    const { theme } = useTheme();

    const menuTextColor = theme === "light" ? "text-primary-content" : "text-base-300-content";
    let menuColor = "";
    if (!className || !className.includes("bg-")) {
        menuColor = theme === "light" ? "bg-primary hover:bg-primary-focus" : "bg-base-300 hover:bg-base-200";
    }
    const classes = "flex flex-row items-center w-full " +
        ` ${menuColor} p-4 pt-3 pb-3 font-bold hover:cursor-pointer ${menuTextColor} ${className}` +
        (isMobile ? "ml-auto mr-auto" : "");

    if (href) {
        return (
            <a className={classes} href={href} {...props}>
                <TIcon
                    name={icon}
                    size={isMobile ? 8 : 4}
                    className={`p-0 ${!isMobile && "mr-2"}`}
                />
                {!isMobile && <span>{text}</span>}
            </a>
        );
    } else {
        return (
            <span className={classes} {...props}>
                <TIcon
                    name={icon}
                    size={isMobile ? 8 : 4}
                    className={`p-0 ${!isMobile && "mr-2"}`}
                />
                {!isMobile && <span>{text || children}</span>}
            </span>
        );
    }
};
