import React, { useContext, useEffect, useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";
import { DashboardElementBuilderContext } from "../../DashboardElementBuilder";
import { $in } from "../../DSL/dashboardModels";
import { DataChildren } from "../ElementDataChildren";

const ElementDataframeConcatenator = (props) => {
    const [ignored, setData] = useState(new DataFrame([]));
    const { children, setOutput } = props;
    const [dataMap, setDataMap] = useState(new Map());
    const [updatedChildren, setUpdatedChildren] = useState([]);
    const subscriptionHandler = useContext(DashboardElementBuilderContext).getSubscriptionHandler();

    function broadcastFunction(elementId, state) {
        if (state === "finished") {
            setDataMap((prev) => {
                const newMap = new Map(prev);
                newMap.set(elementId, subscriptionHandler.getData($in(elementId)).copy());
                return newMap;
            });
        }
    }

    useEffect(() => {
        const filteredData = Array.from(dataMap.values()).filter(v => v instanceof DataFrame && !v.isEmpty());
        if (filteredData.length > 0) {
            let df = filteredData[0].copy();
            for (let i = 1; i < filteredData.length; i++) {
                df = df.extend(filteredData[i].data);
            }
            setOutput(df);
        }
    }, [dataMap]);

    useEffect(() => {
        setUpdatedChildren(children.map(c => {
            let extraProps;
            if (c.type === DataChildren) {
                extraProps = {
                    "childTemplate": {
                        "props": {},
                        ...c.props.childTemplate
                    }
                };
                extraProps.childTemplate.props.broadcastChange = broadcastFunction;
            } else {
                extraProps = { "broadcastChange": broadcastFunction };
            }
            return React.cloneElement(c, extraProps);
        }));
    }, []);

    return (
        <Element
            {...props}
            primary
            setData={setData}
        >
            <div>{updatedChildren}</div>
        </Element>
    );
};

export { ElementDataframeConcatenator as DataframeConcatenator };
