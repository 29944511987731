import * as echarts from "echarts";
import ResizeObserver from "resize-observer-polyfill";

export default new ResizeObserver(entries => {
    entries.forEach(({ target }) => {
        const instance = echarts.getInstanceByDom(target);
        if (instance) {
            instance.resize();
        }
    });
});
