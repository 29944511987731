import { sha512 } from "js-sha512";

export default class MessageAggregate {
    constructor(json) {
        this.data = json;
        this.metadata = null;
    }

    toBody() {
        return {
            "aggregation": JSON.stringify(this.data)
        };
    }

    hash() {
        return sha512(JSON.stringify(this.data));
    }
}
