import React, { createRef, useEffect, useState } from "react";
import DashboardBuilder from "../dashboard/DashboardBuilder";
import queryString from "query-string";
import {
    Header,
    Ref,
    Sticky
} from "semantic-ui-react";
import { user } from "../auth/User";
import { capitalize, uniques } from "../UtilityFunctions";
import dashboardMetadataReference from "../dashboard/dashboardMetadataReference";
import { dashboard } from "../dashboard/DSL/dashboardModels";
import { favoriteDashboard, isDashboardFavorite, removeDashboardFromFavorites } from "../dashboard/favoriteDashboards";
import { Button, Card } from "react-daisyui";
import { StarIcon } from "@heroicons/react/24/solid";
import { StarIcon as OStarIcon } from "@heroicons/react/24/outline";

const permittedDashboardsMetadata = new Map();
const unpermittedDashboardsMetadata = new Map();

const currentUserGroups = user.getUserGroups();
if (Array.isArray(currentUserGroups) && currentUserGroups.length > 0) {
    const isAdmin = currentUserGroups.includes("admin");
    [...dashboardMetadataReference.entries()].forEach(([dashboardName, meta]) => {
        if (isAdmin || meta.userGroups.some((dashboardGroup) => currentUserGroups.includes(dashboardGroup))) {
            permittedDashboardsMetadata.set(dashboardName, meta);
        } else {
            unpermittedDashboardsMetadata.set(dashboardName, meta);
        }
    });
}

export { permittedDashboardsMetadata, unpermittedDashboardsMetadata };

const tagCounts = new Map();
dashboardMetadataReference.forEach((meta) => {
    meta.tags.forEach((tag) => {
        if (tagCounts.get(tag) === undefined) {
            tagCounts.set(tag, 0);
        }
        tagCounts.set(tag, tagCounts.get(tag) + 1);
    });
});
Array.from(tagCounts)
    .sort((a, b) => b[1] - a[1])
    .map(([key, value]) => ({ key, value: key, text: `${key} (${value})` }));

const categories = uniques([...permittedDashboardsMetadata].map(([_, meta]) => meta.category));
const DashboardSelectHeader = ({ onSearch }) => (
    <div className="navbar bg-base-200">
        <div className="flex-1">
            <a className="btn btn-ghost normal-case text-4xl pl-0">Töölauad</a>
        </div>
        <div className="form-control relative">
            <input
                type="text"
                placeholder="Otsi"
                onInput={(e) => onSearch(e.target.value)}
                className="input input-bordered"/>
        </div>
    </div>
);

const DashboardBuilderWrapper = ({ meta, params }) => {
    const [json, setJson] = useState(undefined);
    const loadDashboardJson = (module) => setJson(dashboard(meta, module.default));
    if (json === undefined) {
        // this "../dashboard/json/" HAS to be declared explicitly for dynamic imports to work
        import("../dashboard/json/" + meta.elementsModulePath)
            .catch(console.log)
            .then(loadDashboardJson);

        return (
            <main style={{
                "height": "100%"
            }}>
            </main>
        );
    } else {
        return (
            <main style={{
                "height": "100%",
                "maxWidth": "inherit",
                "position": "relative"
            }}>
                <DashboardBuilder
                    json={json}
                    name={params.name}
                />
            </main>
        );
    }
};

export const DashboardCard = ({ id, data, compact }) => {
    const [favorite, setFavorite] = useState(isDashboardFavorite(id));

    useEffect(() => {
        if (favorite === true) {
            favoriteDashboard(id);
        } else {
            removeDashboardFromFavorites(id);
        }
    }, [favorite]);

    return (
        <div
            className="card bg-base-100 m-2 hover:bg-base-300 transition-all duration-200 hover:-translate-y-1"
        >
            {!compact && <a href={"/dashboard/view?name=" + id}><Card.Image
                src={data.image ? data.image : "https://react.semantic-ui.com/images/wireframe/image.png"}
                alt={"Select this dashboard"}
                className="rounded-t-2xl"
                style={{
                    height: "150px",
                    width: "100%",
                    objectFit: "cover"
                }}
            /></a>}
            {favorite
                ? <StarIcon
                    className={`
                    text-accent
                    hover:cursor-pointer
                    hover:text-accent-focus
                    float-right absolute w-10 h-10
                    right-0 top-0 pr-2 pt-2
                `}
                    onClick={() => setFavorite(!favorite)}
                />
                : <OStarIcon
                    className={`
                    text-accent
                    hover:cursor-pointer
                    hover:text-accent-focus
                    float-right absolute w-10 h-10
                    right-0 top-0 pr-2 pt-2
                `}
                    onClick={() => setFavorite(!favorite)}
                />
            }
            <a href={"/dashboard/view?name=" + id}>
                <Card.Body
                    className="flex-col p-4 h-full flex"
                >
                    <Card.Title tag="h2" className="text-xl pb-2 font-bold">{capitalize(data.title)}</Card.Title>
                    <p className="text-">
                        {data.subtitle}
                    </p>
                    <Card.Actions
                        className="mt-auto relative bottom-0 pt-2"
                    >
                        {!compact && data.tags.map((tag) => (
                            <div key={tag} className="badge badge-primary mr-1">{tag}</div>)
                        )}
                        {compact && <div className="badge badge-primary mr-1">
                            {data.category}
                        </div>}
                    </Card.Actions>
                </Card.Body>
            </a>
        </div>
    );
};

export const SelectFavoritesScreen = ({ permittedMeta }) => {
    const [favorites, setFavorites] = useState([]);

    useEffect(() => {
        setFavorites(
            Array.from(permittedMeta.entries())
                .filter(([key]) => isDashboardFavorite(key))
        );
    }, []);

    if (favorites.length > 0) {
        return (
            <div
                className="not-prose grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-2 lg:grid-cols-3 p-2"
            >
                {favorites
                    .map(([key, m]) =>
                        <DashboardCard
                            key={key}
                            id={key}
                            data={m}
                            compact
                        />
                    )}
            </div>
        );
    } else {
        return (
            <div
                className="text-center p-4 pt-8"
                style={{
                    gridArea: "none"
                }}
            >
                <p>Sa ei ole ühtegi töölauda oma lemmikutesse lisanud!
                    Vajuta töölaudade lehel tähtedele, et märkida töölaud lemmikutesse.</p>
                <Button
                    href="/dashboard/view"
                    className="mt-4 bg-base-300 border-none hover:bg-base-200"
                >Töölaudade juurde</Button>
            </div>
        );
    }
};

const SelectScreen = ({ params, allMeta, permittedMeta }) => {
    const [filterOptions, setFilterOptions] = useState({
        search: "",
        tags: []
    });
    const contextRef = createRef();
    let headerContent;

    if (params.name) {
        if (allMeta.has(params.name)) {
            headerContent = `No permission to view dashboard ${params.name}.`;
        } else {
            headerContent = `No dashboard called ${params.name}.`;
        }
    }
    return (
        <main>
            {params.name &&
                <Header>
                    {headerContent}
                </Header>}
            <DashboardSelectHeader onSearch={(search) => setFilterOptions(old => ({
                ...old,
                search
            }))}/>

            <Ref innerRef={contextRef}>
                <div className="pt-0 max-w-6xl m-auto relative">
                    {categories
                        .map((category) =>
                            [...permittedMeta]
                                .filter(([_, meta]) =>
                                    meta.category === category &&
                                    (
                                        meta.title.includes(filterOptions.search) ||
                                        meta.subtitle.includes(filterOptions.search)
                                    ) && (
                                        filterOptions.tags.length === 0 ||
                                        meta.tags.some(tag => filterOptions.tags.includes(tag))
                                    )
                                )
                        )
                        .filter((dashboards) => dashboards.length > 0)
                        .map((filteredDashboards) => {
                            const category = filteredDashboards[0][1].category;

                            return (
                                <div
                                    key={category}
                                    className="bg-base-300 rounded-box mb-4"
                                >
                                    <Sticky context={contextRef} className="pt-4">
                                        <h2 className="pl-4 bg-base-300 rounded-box">{capitalize(category)}</h2>
                                    </Sticky>

                                    <div
                                        className="grid grid-cols-1 pl-2 pr-2 pb-2 gap-y-2 gap-x-2 sm:grid-cols-2 lg:grid-cols-3"
                                    >
                                        {filteredDashboards
                                            .map(([key, m]) =>
                                                <DashboardCard
                                                    key={key}
                                                    id={key}
                                                    data={m}
                                                />
                                            )}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </Ref>
        </main>);
};

const DashboardSelectScreen = () => {
    const params = queryString.parse(window.location.search);
    const hasPermittedDashboardNameInPath = permittedDashboardsMetadata.has(params.name);

    if (hasPermittedDashboardNameInPath) {
        return <DashboardBuilderWrapper
            params={params}
            meta={permittedDashboardsMetadata.get(params.name)}
        />;
    } else {
        return <SelectScreen
            params={params}
            allMeta={dashboardMetadataReference}
            permittedMeta={permittedDashboardsMetadata}
        />;
    }
};

export default DashboardSelectScreen;
