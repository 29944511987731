import { fun } from "./aggregatorModels";

class Filter extends Function {
    constructor(wrapper) {
        super("...args", "return this.wrapper(...args)");
        this.wrapper = wrapper;
        const bind = this.bind(this);
        bind.wrapper = wrapper;
        return bind;
    }

    /**
     * Filters rows which are greater than or equal to the provided value.
     *
     * @param {string} match - matched column.
     * @param {number} value - compared value.
     * @return {Object} - function object.
     */
    greaterOrEqual(match, value) { return this.wrapper(fun(match, "greater_or_equal", [value])); }

    /**
     * Filters rows which are greater than the provided value.
     *
     * @param {string} match - matched column.
     * @param {number} value - compared value.
     * @return {Object} - function object.
     */
    greaterThan(match, value) { return this.wrapper(fun(match, "greater_than", [value])); }

    /**
     * Filters rows which are less than or equal to the provided value.
     *
     * @param {string} match - matched column.
     * @param {number} value - compared value.
     * @return {Object} - function object.
     */
    lessOrEqual(match, value) { return this.wrapper(fun(match, "less_or_equal", [value])); }

    /**
     * Filters rows which are less than the provided value.
     *
     * @param {string} match - matched column.
     * @param {number} value - compared value.
     * @return {Object} - function object.
     */
    lessThan(match, value) { return this.wrapper(fun(match, "less_than", [value])); }

    /**
     * Filters rows which are equal to the provided value.
     *
     * @param {string} match - matched column.
     * @param {number|string|boolean} value - compared value.
     * @return {Object} - function object.
     */
    equals(match, value) { return this.wrapper(fun(match, "equals", [value])); }

    /**
     * Filters rows which are not equal to the provided value.
     *
     * @param {string} match - matched column.
     * @param {number|string} value - compared value.
     * @return {Object} - function object.
     */
    notEquals(match, value) { return this.wrapper(fun(match, "not_equals", [value])); }

    /**
     * Filters rows which are not nulls.
     *
     * @param {string} match - matched column.
     * @return {Object} - function object.
     */
    notNull(match) { return this.wrapper(fun(match, "not_null")); }

    /**
     * Filters rows which are contained in the provided list of values.
     *
     * @param {string} match - filtered column.
     * @param {Array} values - list of values.
     * @return {Object} - function object.
     */
    containsAny(match, values) { return this.wrapper(fun(match, "contains_any", values)); }

    /**
     * Filters rows which are not contained in the provided list of values. In other words, excludes provided values.
     *
     * @param {string} match - filtered column.
     * @param {Array} values - list of values.
     * @return {Object} - function object.
     */
    notContainsAny(match, values) { return this.wrapper(fun(match, "not_contains_any", values)); }

    /**
     * Filters rows which are numbers.
     *
     * @param {string} match - matched column.
     * @return {Object} - function object.
     */
    isNumber(match) { return this.wrapper(fun(match, "is_number")); }

    /**
     * Gets the first n rows.
     *
     * @param {string} match - filtered column.
     * @param {number} count - rows from 0 up until count-1 are included.
     * @return {Object} - function object.
     */
    head(match, count) { return this.wrapper(fun(match, "head", [count])); }

    /**
     * Gets the last n rows.
     *
     * @param {string} match - filtered column.
     * @param {number} count - rows from length-count up until length-1 are included.
     * @return {Object} - function object.
     */
    tail(match, count) { return this.wrapper(fun(match, "tail", [count])); }

    /**
     * Drops all rows where the value of this column is a duplicate until only uniques remain.
     *
     * @param {string} match - matched column.
     * @return {Object} - function object.
     */
    dropDuplicates(match) { return this.wrapper(fun(match, "drop_duplicates")); }

    /**
     * Filters rows which contain the provided substring.
     *
     * @param {string} match - matched column.
     * @param {string} substring - compared substring.
     * @return {Object} - function object.
     */
    stringContains(match, substring) { return this.wrapper(fun(match, "string_contains", [substring])); }

    /**
     * Filters rows which do not contain the provided substring.
     *
     * @param {string} match - matched column.
     * @param {string} substring - compared substring.
     * @return {Object} - function object.
     */
    stringNotContains(match, substring) { return this.wrapper(fun(match, "string_not_contains", [substring])); }

    /**
     * Filters rows which are longer than the provided length.
     *
     * @param {string} match - matched column.
     * @param {number} length - compared length.
     * @return {Object} - function object.
     */
    stringLongerThan(match, length) { return this.wrapper(fun(match, "string_longer_than", [length])); }

    /**
     * Filters rows which are shorter than the provided length.
     *
     * @param {string} match - matched column.
     * @param {number} length - compared length.
     * @return {Object} - function object.
     */
    stringShorterThan(match, length) { return this.wrapper(fun(match, "string_shorter_than", [length])); }

    /**
     * Samples a fraction of the data.
     *
     * @param {string} match - sampled column.
     * @param {number} fraction - the desired fraction is a float in the range [0, n].
     * @return {Object} - function object.
     */
    sample(match, fraction) { return this.wrapper(fun(match, "sample", [fraction])); }

    /**
     * Selects "count" number of columns starting from the nth column at "startPos". So startPos=0, count=20 will
     * give you 20 first columns in a dataset. startPos 10 will give you the 20 after the first 10. Note that
     * if there are less columns than asked for, the max available is returned. startPos must be inside
     * total column count.
     *
     * Note: This function will be turned into an action at a later date.
     *
     * @param {string} match - a column to match against. Empty string will work.
     * @param {number} startPos - column index to start selecting columns from
     * @param {number} count - count of columns to select
     * @return {Object} - function object.
     */
    selectColumnRange(match, startPos, count) { return this.wrapper(fun(match, "select_column_range", [startPos, count])); }

    /**
     * Selects the columns in order given in columns argument. Supports strings and Match objects.
     *
     * Note: This function will be turned into an action at a later date.
     *
     * @param {string} match - a column to match against. Empty string will work.
     * @param {Array} columns - columns to choose. Matches are allowed.
     * @return {Object} - function object.
     */
    selectColumns(match, columns) { return this.wrapper(fun(match, "select_columns", columns)); }
}
export default Filter;
