import React, { Component } from "react";
import { user } from "../auth/User";
import MenuItemHor from "./MenuItemHor";

class SidebarCountdown extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.timeLeft = 300;
        this.state = {
            timeString: "Väljalogimiseni 5:00",
            active: false,
            shown: false
        };
        this.countdownInterval = setInterval(this.countdown, 1000);
        setTimeout(this.show, user.getLogoutWarnTimeLeft());
    }

    countdown = () => {
        this.timeLeft = Math.floor(user.getLogoutTimeLeft() / 1000);
        if (this.timeLeft >= 0) {
            if (this.timeLeft <= 45) {
                this.setState({ active: true });
            }
            this.setState({ timeString: "Väljalogimiseni " + Math.floor(this.timeLeft / 60) + ":" + (this.timeLeft % 60).toString().padStart(2, "0") });
        } else {
            clearInterval(this.countdownInterval);
            user.logout();
        }
    };

    show = () => {
        this.setState({ shown: true });
    };

    render() {
        if (this.state.shown) {
            return <MenuItemHor
                icon="shield-exclamation"
                className="bg-red-500/70"
                active={this.state.active}
            >
                {this.state.timeString}
            </MenuItemHor>;
        } else {
            return <></>;
        }
    }
}

export default SidebarCountdown;
