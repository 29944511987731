import env from "@beam-australia/react-env";
import { dashboardLoadingStates } from "../DashboardBuilder";
import StreamHandler from "./StreamHandler";
import { user } from "../../auth/User";
import axios from "axios";

class ExecutionService {
    constructor(setDashboardLoadingState) {
        this.streamHandlers = new Map();
        this.dashboardLoadingStateCallback = setDashboardLoadingState;
        this._endpoint = env("EXECUTION_SERVICE_URI");
        this.dashboardLoadingStateCallback(dashboardLoadingStates.CONNECTED);
    };

    getStreamHandler = (elementId) => {
        return this.streamHandlers.get(elementId);
    };

    cancelAggregationStream = (elementId) => {
        if (this.streamHandlers.has(elementId)) {
            this.getStreamHandler(elementId).cancelStream();
        }
    };

    requestAggregationStream = (payload, elementId, subscriptionHandler) => {
        if (this.streamHandlers.has(elementId)) {
            throw new Error(`Cannot request new aggregation stream: stream already ongoing for element ${elementId}`);
        }
        const streamHandler = new StreamHandler(elementId, subscriptionHandler, () => this.streamHandlers.delete(elementId));
        this.streamHandlers.set(elementId, streamHandler);
        const enc = new TextEncoder();

        user.getCognitoIdToken((jwtToken) => {
            const hash = payload.hash();
            const url = `${this._endpoint}?hash=${hash}`;

            const dummySubscription = {
                "cancel": () => true,
                "request": () => true
            };
            streamHandler.handleSubscribed(dummySubscription);

            const onResponse = response => {
                if (response.status !== 200) {
                    const data = JSON.parse(response.text());
                    const errorText = `Message:\n${data.message}\n\nStacktrace:\n${data.stacktrace}`;
                    throw Error(errorText);
                }
                return response.data;
            };

            const onData = (data) => {
                data.forEach(row =>
                    streamHandler.handleStreamMessage({
                        "data": enc.encode(JSON.stringify({
                            "event": "row",
                            "data": {
                                "row": Object.entries(row).map(
                                    ([key, value]) => ({ "name": key, "value": value })
                                )
                            }
                        }))
                    })
                );
                streamHandler.handleStreamComplete({
                    "data": enc.encode(JSON.stringify({
                        "event": "finished",
                        "data": null
                    }))
                });
            };

            const onError = (axiosError) => {
                let errorInfo = axiosError?.response?.data;
                if (errorInfo) {
                    errorInfo = {
                        "message": `Message\n${errorInfo.message}\n\n${errorInfo.stacktrace}`
                    };
                } else {
                    errorInfo = axiosError;
                }
                streamHandler.handleStreamError(errorInfo);
            };

            const onConnection = () => {
                streamHandler.handleStreamMessage({
                    "data": enc.encode(JSON.stringify({
                        "event": "started",
                        "data": null
                    }))
                });
            };

            axios.post(url, payload.toBody(), {
                headers: {
                    "Accept": "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    "Authorization": jwtToken
                },
                onUploadProgress: ({ progress }) => {
                    if (progress >= 1) {
                        onConnection();
                    }
                }
            })
                .then(onResponse)
                .then(onData)
                .catch(onError);
        });
    };
};

export default ExecutionService;
