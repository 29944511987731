import React, { useState } from "react";
import { user } from "../auth/User";
import { Form, Message } from "semantic-ui-react";
import env from "@beam-australia/react-env";
import { Button, Toggle } from "react-daisyui";

const formStates = {
    FILLABLE: "FILLABLE",
    SENDING: "SENDING",
    SENT: "SENT",
    ERROR: "ERROR"
};

const options = [
    { key: "bug", text: "Midagi on katki", value: "bug" },
    { key: "proposal", text: "Funktsiooni ettepanek", value: "proposal" },
    { key: "understanding", text: "Töölaud arusaamatu / keeruline", value: "understanding" },
    { key: "other", text: "Muu", value: "other" }
];

const msgTimeoutMs = 4000;

const DashboardFeedbackForm = ({ dashboardName }) => {
    const [type, setType] = useState(options[0].key);
    const [emailRequired, setEmailRequired] = useState(false);
    const [email, setEmail] = useState(user.getEmail());
    const [formState, setFormState] = useState(formStates.FILLABLE);
    const [details, setDetails] = useState("");
    const name = user.getUsername();

    const resetForm = () => {
        setType("");
        setEmailRequired(false);
        setEmail(user.getEmail());
        setDetails("");
        setFormState(formStates.FILLABLE);
    };

    const feedbackSuccess = (res) => {
        if (res.ok) {
            setFormState(formStates.SENT);
            setTimeout(resetForm, msgTimeoutMs);
            // console.log("Request complete! response:", res);
        } else {
            feedbackFailure(res);
        }
    };

    const feedbackFailure = (error) => {
        setFormState(formStates.ERROR);
        setTimeout(resetForm, msgTimeoutMs);
        console.log("Error sending feedback:", error);
    };

    const sendFeedback = () => {
        const feedbackObject = {
            dashboardName,
            name,
            type,
            details,
            emailRequired,
            email
        };
        const postFeedback = (data) => {
            const url = env("FEEDBACK_URI");
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Origin": window.location.origin
            };
            const request = {
                method: "POST",
                headers,
                body: JSON.stringify(data)
            };
            // console.log(JSON.stringify(request));
            return fetch(url, request);
        };
        setFormState(formStates.SENDING);
        postFeedback(feedbackObject)
            .then(feedbackSuccess)
            .catch(feedbackFailure);
    };

    let msg;
    switch (formState) {
    case formStates.FILLABLE:
        msg = undefined;
        break;
    case formStates.SENDING:
        msg = <Message icon="spinner" content="Tagasiside saadetakse..."/>;
        break;
    case formStates.SENT:
        msg = <Message icon="check" content="Tagasiside saadetud!"/>;
        break;
    case formStates.ERROR:
        msg = <Message icon="times circle outline" content="Viga tagasiside saatmisel!"/>;
        break;
    }

    return (<Form onSubmit={sendFeedback}>
        <div className="flex flex-row">
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Nimi</span>
                </label>
                <input type="text" value={name} className="input input-bordered bg-neutral hover:bg-neutral-focus w-full max-w-xs" disabled/>
            </div>
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Tagasiside tüüp</span>
                </label>
                <select
                    className="w-full bg-neutral hover:bg-neutral-focus rounded-btn min-h-12 mb-2"
                    required={false}
                    value={type}
                    disabled={formState === formStates.SENDING}
                    onChange={(e) => setType(e.target.value)}
                >
                    {options.map(o => <option key={o.key} value={o.value}>{o.text}</option>)}
                </select>
            </div>
        </div>
        <label className="label">
            <span className="label-text">Detailid</span>
        </label>
        <Form.TextArea
            required
            disabled={formState === formStates.SENDING}
            placeholder='Kirjuta täpsemalt...'
            value={details}
            onChange={
                (e, { value }) => {
                    setDetails(value);
                }
            }/>
        <div>
            <Toggle
                className="mr-2"
                active={emailRequired}
                disabled={formState === formStates.SENDING}
                onChange={() => {
                    setEmailRequired(!emailRequired);
                }}
            />
            <span>Saada tagasiside koopia mulle meilile</span>
        </div>
        { emailRequired === true && <div>
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">E-mail</span>
                </label>
                <input
                    type="text"
                    name="email"
                    value={email}
                    disabled={formState === formStates.SENDING}
                    required={emailRequired}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    className="input input-bordered bg-neutral hover:bg-neutral-focus w-full max-w-xs"/>
            </div>
        </div>}
        <Button
            type="submit"
            className="mt-4"
            disabled={formState === formStates.SENDING}
        >Saada</Button>
        { msg }
    </Form>);
};

export default DashboardFeedbackForm;
