import React, { useEffect, useState } from "react";
import { Image, Popup } from "semantic-ui-react";
import { DataFrame } from "../../DSL/DataFrame";
import { Element } from "../Element";
import { nameToImageUrl, partyColorMap } from "../../../UtilityFunctions";
import TIcon from "../../../components/TIcon";

const transparentize = (color, transparency = 0.4) => {
    return color && (color.slice(0, 3) + "a" + color.slice(3, -1) + "," + transparency.toString() + ")");
};

const ElementClosableCarousel = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const { dataColPrefix } = props.data.dataColPrefix;
    const closedCount = props.closedCount;
    const [closed, setClosed] = useState(true);
    const [items, setItems] = useState([]);
    const unit = !data.isEmpty() ? data.copy().getRow(0) : null;
    let min;
    let max;

    useEffect(() => {
        if (!data.isEmpty()) {
            unit.delete("var_name");
            const allValues = [...unit.values()];
            min = Math.min(...allValues);
            max = Math.max(...allValues);
            const sortedData = [...unit.entries()].sort(([_, v1], [__, v2]) => v1 - v2);
            setItems(constructItems(sortedData));
        }
    }, [closed, data]);

    function constructNext(prev, curr, next, opacity, index, items) {
        const sep = prev.get("value") - curr.get("value");
        const different = (
            Math.round(prev[1]) !== Math.round(curr.get("value")) ||
            Math.round(next[1]) !== Math.round(curr.get("value"))
        );

        if (sep > 0) {
            items.push(
                <div
                    className="vertical-separator"
                    style={{
                        "height": "2px",
                        "background": "gray",
                        "width": String((sep) / (max - min) * 100) + "%"
                    }}
                />);
        }
        const numberStyle = {
            "textAlign": "center",
            "marginBottom": "0px",
            "marginTop": "10px",
            "background": transparentize(partyColorMap.get(curr.get("party"))) || null
        };
        if (!different) {
            numberStyle.color = "rgba(0,0,0,0)";
        }
        const maxNameLength = 15;
        const nameLabel = (
            <p
                style={{
                    "textAlign": "center",
                    "fontSize": "9px",
                    "color": "black",
                    "position": "absolute",
                    "left": "50%",
                    "transform": "translateX(-50%)",
                    "width": "max-content"
                }}
                data-testid="carousel-item-name"
                className="carousel-item-name">
                {curr.get("name").length > maxNameLength ? curr.get("name").substring(0, maxNameLength - 2) + ".." : curr.get("name")}
            </p>);
        const trigger = (
            <div style={{
                "position": "relative"
            }}>
                {index % 2 === 0 && nameLabel}
                <Image
                    src={curr.get("image")}
                    size="mini"
                    rounded
                    style={{
                        "height": "35px",
                        "objectFit": "cover",
                        opacity,
                        "marginTop": "14px"
                    }}
                    onError={(i) => (i.target.src = props.defaultImage)}
                />
                {index % 2 === 1 && nameLabel}
                <p style={numberStyle}>{Math.round(curr.get("value"))}</p>
            </div>);
        return (
            <Popup
                key={index}
                content={curr.get("name")}
                trigger={trigger}
            />);
    }

    function constructItems(sortedData) {
        let prev;
        const rows = sortedData.map((row) => new Map([["name", row[0].split("_")[0]], ["party", row[0].split("_")[1]], ["value", row[1]], ["image", nameToImageUrl(row[0].split("_")[0], true, "64")]]));
        if (rows.length > 0) {
            prev = rows[0];
        }
        const items = [];
        if (closed && rows.length > closedCount) {
            const renderCount = Math.round(closedCount * 0.5);

            for (let i = 0; i < renderCount; i++) {
                const row = rows[parseInt(i, 10)];
                const next = rows[(i + 1) % rows.length];
                const opacity = Math.min(1, (renderCount - i) / 2);

                items.push(constructNext(prev, row, next, opacity, i, items));
                prev = row;
            }
            items.push(
                <TIcon
                    key="separator"
                    data-testid="carousel-separator"
                    name="arrows-expand"
                    style={{
                        "height": "100%",
                        "margin": "5px"
                    }}
                    size={8}/>
            );

            prev = rows[rows.length - renderCount - 1];
            const start = rows.length - renderCount;
            for (let i = start; i < rows.length; i++) {
                const row = rows[parseInt(i, 10)];
                const next = rows[(i + 1) % rows.length];
                const opacity = Math.min((i + 1 - start) / 2, 1);

                items.push(constructNext(prev, row, next, opacity, i, items));
                prev = row;
            }
        } else {
            for (let i = 0; i < rows.length; i++) {
                const row = rows[parseInt(i, 10)];
                const next = rows[(i + 1) % rows.length];

                items.push(constructNext(prev, row, next, 1.0, i, items));
                prev = row;
            }
        }
        return items;
    }
    if (!data.isEmpty()) {
        // strip dataColPrefix
        const title = data.getRow(0).get("var_name").replace(dataColPrefix, "");
        return (
            <Element
                {...props}
                primary
                setData={setData}>
                <div
                    className="carousel-container"
                    data-testid="closable-carousel"
                    style={{
                        "marginBottom": "24px"
                    }}>
                    <b
                        data-testid="closable-carousel-header">
                        {title}
                    </b>
                    <div
                        className="ranking-carousel"
                        data-testid="ranking-carousel"
                        style={{
                            "display": "flex",
                            "flexFlow": "wrap",
                            "alignItems": "center",
                            "marginBottom": "8px",
                            "marginLeft": "16px"
                        }}
                        onClick={() => setClosed((prev) => !prev)}
                    >
                        {items}
                    </div>
                </div>
            </Element>);
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>
        );
    }
};

export { ElementClosableCarousel as ClosableCarousel };
