import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { Button, Dropdown } from "semantic-ui-react";
import { DataFrame } from "../../DSL/DataFrame";
import { orderIcon, sortingOrder } from "../../../UtilityFunctions";

const ElementDataSort = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const [sortingColumn, setSortingColumn] = useState("");
    const [sortOrder, setSortingOrder] = useState(sortingOrder.DESC);

    const [options, setOptions] = useState([]);
    const { args, "data": dataArgs, setOutput } = props;

    const handleSortOrderToggle = () => {
        if (sortOrder === sortingOrder.DESC) {
            setSortingOrder(sortingOrder.ASC);
        } else {
            setSortingOrder(sortingOrder.DESC);
        }
    };
    // on filter update, filter all the data
    useEffect(() => {
        if (!data.isEmpty()) {
            if (options.length === 0) {
                if (dataArgs.defaultInteractionState) {
                    const defaultValue = dataArgs.defaultInteractionState;
                    setSortingColumn(typeof defaultValue === "object" ? defaultValue : [defaultValue]);
                }
                let mainOptions;
                if (args.sortingOptions) {
                    mainOptions = args.sortingOptions.map(({ key, value, text }) => ({
                        "key": key || value, "text": text || value, value
                    }));
                } else {
                    mainOptions = data.getColumns().map((value) => ({
                        "key": value, "text": value, value
                    }));
                }

                setOptions(mainOptions);
            }

            setOutput(data.copy().sortBy(sortingColumn, sortOrder));
        }
    }, [sortingColumn, data]);

    if (!data.isEmpty()) {
        return (
            <Element
                {...props}
                setData={setData}>
                <Dropdown
                    labeled
                    {...args.options}
                    fluid
                    className="icon"
                    closeOnChange
                    options={options}
                    value={sortingColumn}
                    onChange={(event, dropdownProps) => {
                        setSortingColumn((_) => {
                            return dropdownProps.value;
                        });
                    }}
                >
                </Dropdown>
                <Button
                    icon
                    onClick={handleSortOrderToggle}
                >
                    {orderIcon[sortOrder.toString()]}
                </Button>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                setData={setData}/>);
    }
};

export { ElementDataSort as DataSort };
