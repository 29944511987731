import ElementIdRegistry from "./ElementIdRegistry";
import elementReference from "./elements/elementReference";
import ElementDataWrapper from "./ElementDataWrapper";
import queryString from "query-string";
import { $in, $out } from "./DSL/dashboardModels";
import React from "react";
import Cookies from "js-cookie";

export const autoId = "autoId";
export const parentId = "parentId";
export const hashCode = "hashCode";

export default class DashboardElementBuilder {
    constructor(subscriptionHandler, contextReference, dashboardName, dashboardVersion) {
        this.subscriptionHandler = subscriptionHandler;
        this.elementIdRegistry = new ElementIdRegistry();
        this.contextReference = contextReference;
        this.dashboardName = dashboardName;
        this.dashboardVersion = dashboardVersion;
    }

    getSubscriptionHandler = () => this.subscriptionHandler;

    getElementIdRegistry = () => this.elementIdRegistry;

    populateElementId = (element, index) => {
        // handle no id
        let id = element.props.id;
        if (!element.props.id) {
            id = `${element.type}_${index}`;
        }
        // handle autoId
        if (element.props.id.includes(autoId)) {
            // make it so that child element autoIds are incremented so that we can give them custom names
            id = id.replace(autoId, index);
        }
        // handle parentId
        if (element.props.id.includes(parentId)) {
            if (!element.parentId) {
                console.log(element);
                throw new Error(`Cannot replace parentId template with actual parent Id with element ${id} and parent ${element.parentId}.`);
            }
            id = id.replace(parentId, element.parentId);
        }
        // handle hashCode
        if (element.props.id.includes(hashCode)) {
            if (!element.hashCode) {
                console.log(element);
                throw new Error(`Cannot replace hashCode template with actual hashCode with element ${id} and hashCode ${element.hashCode}.`);
            }
            id = id.replace(hashCode, element.hashCode);
        }
        return id;
    };

    setParentIdToCurrentId = (el, id) => {
        el.parentId = id;
        return el;
    };

    getDashboardElementCookiePath = (id) => `${this.dashboardName}__v${this.dashboardVersion}__${id}`;

    getDashboardElementCookie = (id) => {
        let cookie = Cookies.get(this.getDashboardElementCookiePath(id));
        if (cookie !== undefined) {
            try {
                cookie = JSON.parse(cookie);
            } catch (e) {
                console.log(`Error parsing cookie ${id} - '${cookie}' on dashboard ${this.dashboardName}`);
                cookie = undefined;
            }
        }
        if (Array.isArray(cookie)) {
            // do nothing right now
        } else if (typeof cookie === "object" && cookie !== null) {
            // do nothing right now
        } else if (!Number.isNaN(Number.parseInt(cookie, 10))) {
            cookie = parseInt(cookie, 10);
        }
        // console.log("Cookie get for", id, "with value", cookie, "on dashboard", this.dashboardName);
        return cookie;
    };

    setDashboardElementCookie = (id, data) => {
        Cookies.set(this.getDashboardElementCookiePath(id), JSON.stringify(data));
        // console.log("Cookie set for", id, "with value", data, "on dashboard", this.dashboardName, "stringified as", JSON.stringify(data));
    };

    build = (element, index, clearId = false) => {
        // Bad asserts, have to replace with schema validation
        if (!(element.type in elementReference)) {
            throw new Error("Unknown element type: " + element.type);
        }

        element.props.id = this.populateElementId(element, index);
        if (clearId) {
            this.elementIdRegistry.clearId(element.props.id, element.parentId);
        }
        this.elementIdRegistry.registerId(element.props.id, element.parentId);

        element.props.dataWrapper = new ElementDataWrapper(element.props.data, element.parentId, this.subscriptionHandler.getData);
        if (!element.props.outputWith) {
            element.props.outputWith = element.props.id;
        }

        this.subscriptionHandler.registerSubscriptions(element.props.id, element.props.outputWith, element.props.dataWrapper.getReferences());

        // initialize dataWrapper, pre-populating it with some data, but ignoring empty data
        element.props.dataWrapper.getParsed(true);

        // Create element and its children
        const elClass = elementReference[element.type];
        const elChildren = element.children
            .map((el) => this.setParentIdToCurrentId(el, element.props.id))
            .map((el, idx) => this.build(el, idx));

        // if the element contains an id, it will be used as a "subscribable"
        const outputKey = $out(element.props.id);
        const inputKey = $in(element.props.id);
        element.props.setOutput = (val) => this.subscriptionHandler.setData(outputKey, val);
        /** @function subscribeInput
         * It is important to use data object instead of local React component state data,
         * as there is no guarantee that subscribeInput will be called after setData
         * update has been propagated.
         * @param {(data: Object) => void} method to call on subscribe input
         * */
        element.props.subscribeInput = (method) => {
            this.subscriptionHandler.addObserver(inputKey, method);
        };
        // setOutput will be used to tell the aggregator there is an update,
        // which will be distributed to all subscribers of the element in aggregator.setData

        if (element.props.saveInteractionState) {
            const filterStateCookie = this.getDashboardElementCookie(element.props.id);
            // cookies also override the default filter state
            if (filterStateCookie !== undefined) {
                element.props.data.defaultInteractionState = filterStateCookie;
            }
            element.props.saveInteractionState = (data) => this.setDashboardElementCookie(element.props.id, data);
        }

        // url utm params take priority over cookies, making links possible
        const urlOutputOverride = queryString.parse(window.location.search)[element.props.id];
        if (urlOutputOverride !== undefined) {
            element.props.data.defaultInteractionState = urlOutputOverride;
        }

        element.props.key = element.props.key || `key_${element.props.id}`;

        if (element.props.childTemplate) {
            element.props.childTemplate.parentId = element.props.id;
        }
        element.props.contextReference = this.contextReference;

        return React.createElement(elClass, element.props, elChildren);
    };
}

export const DashboardElementBuilderContext = React.createContext(null);
