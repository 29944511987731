import React from "react";
import styles from "./TStepGroup.module.css";
import { classNames } from "../UtilityFunctions";

export const TStepContent = ({ children }) => {
    return <div className={styles.stepContent}>{children}</div>;
};

export const TStepTitle = ({ children }) => {
    return <div className={styles.stepTitle}>{children}</div>;
};

export const TStepDescription = ({ children }) => {
    return <div className={styles.stepDescription}>{children}</div>;
};

export default ({
    completed,
    disabled,
    active,
    onClick,
    children,
    ...props
}) => {
    return (
        <div
            onClick={() => {
                if (!disabled && onClick) {
                    onClick();
                }
            }}
            className={classNames(
                styles.step,
                completed && styles.stepCompleted,
                active && styles.stepActive,
                (disabled || !onClick) && !active && styles.stepDisabled,
                !active && !disabled && onClick && "hover:cursor-pointer",
                (disabled || !onClick) && !active && "pointer-events-none"
            )}
            {...props}
        >
            {children}
        </div>
    );
};
