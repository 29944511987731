import React, { useEffect, useState } from "react";
import versionList from "./versionList";
import { marked } from "marked";

const VersionArticle = ({ title, date, file }) => {
    const [content, setContent] = useState("<p>Laeb...</p>");

    useEffect(() => {
        fetch(file)
            .then(response => {
                return response.text();
            })
            .then(text => {
                setContent(marked(text));
            });
    }, []);

    return (
        <div className="p-6 mb-6 bg-base-300 rounded-box">
            <h3 className="pb-2">{title}</h3>
            {date && <b>{date}</b>}
            <article
                className="release"
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    );
};

export default (props) => {
    return (
        <div>
            {versionList.map(v => <VersionArticle
                key={v.file}
                {...v}
            />)}
        </div>
    );
};
