import { actions } from "../dashboard/DSL/aggregatorModels2";
import { user } from "./User";

const dataAccessGetCompetitors = () => {
    const currentParty = user.getParty();

    const filters = [];
    if (!user.hasElevatedAccess()) {
        switch (currentParty) {
        case "EKRE":
            ["Isamaa", "Erakond Isamaa", "ISA"].forEach(v => filters.push(v));
            break;
        case "ISA":
            ["Eesti Konservatiivne Rahvaerakond", "EKRE"].forEach(v => filters.push(v));
            break;
        case "E200":
            ["Isamaa", "ISA", "Erakond Isamaa", "Eesti Konservatiivne Rahvaerakond", "EKRE"]
                .forEach(v => filters.push(v));
            break;
        default:
            [
                "Isamaa", "ISA", "Erakond Isamaa",
                "Eesti Konservatiivne Rahvaerakond", "EKRE",
                "Eesti 200", "E200"
            ].forEach(v => filters.push(v));
            break;
        }
    }
    return filters;
};

const dataAccessFilterCompetitors = (column) => {
    const filters = [];
    if (!user.hasElevatedAccess()) {
        let parties;
        switch (user.getParty()) {
        case "EKRE":
            parties = ["Isamaa", "ISA"];
            break;
        case "ISA":
            parties = ["Eesti Konservatiivne Rahvaerakond", "EKRE"];
            break;
        case "E200":
            parties = ["Eesti Konservatiivne Rahvaerakond", "EKRE", "Isamaa", "ISA"];
            break;
        default:
            parties = ["Eesti Konservatiivne Rahvaerakond", "EKRE", "Isamaa", "ISA", "Eesti 200", "E200"];
            break;
        }
        parties.forEach(v => filters.push(actions.filter.notEquals(column, v)));
    }
    return filters;
};

export const dataAccessFilterCompetitorsList = (list) => {
    if (user.isLoggedIn()) {
        const competitors = dataAccessGetCompetitors();
        return list.filter(v => !competitors.includes(v));
    } else {
        return [];
    }
};

const dataAccessFilterParty = (column, party) => {
    const filters = [];
    if (!user.hasElevatedAccess()) {
        filters.push(actions.filter.notEquals(column, party));
    }
    return filters;
};

const dataAccessFilterOtherParties = (column) => {
    const filters = [];
    if (!user.hasElevatedAccess()) {
        const filterExcept = (array) => filters.push(actions.filter.containsAny(column, array));
        switch (user.getParty()) {
        case "EKRE":
            filterExcept(["Eesti Konservatiivne Rahvaerakond", "EKRE"]);
            break;
        case "ISA":
            filterExcept(["Isamaa", "ISA"]);
            break;
        case "E200":
            filterExcept(["Eesti 200", "E200"]);
            break;
        default:
            // dont allow anything
            filterExcept(["@@@@@@"]);
            break;
        }
    }
    return filters;
};

export {
    dataAccessFilterCompetitors, dataAccessFilterOtherParties, dataAccessFilterParty,
    dataAccessGetCompetitors
};
