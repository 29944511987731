import env from "@beam-australia/react-env";
import React, { useCallback, useState } from "react";
import { marked } from "marked";
import { DataFrame } from "./dashboard/DSL/DataFrame";
import { colord } from "colord";
import { BarsArrowDownIcon, BarsArrowUpIcon } from "@heroicons/react/24/solid";

const fixCombinedUmlauts = (str) => str.replace(/ü/g, "u%CC%88").replace(/ö/g, "o%CC%88").replace(/õ/g, "o%CC%83");
const publicS3URI = require("node-s3-public-url");
const urlify = (str) => publicS3URI(fixCombinedUmlauts(str));

// exports
const streamRowToDfRow = ({ row }) => {
    return new Map(row.map((e) => [e.name, e.value === null ? undefined : e.value]));
};

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const sortingOrder = {
    "NONE": "none",
    "ASC": "ascending",
    "DESC": "descending"
};

const orderIcon = {
    [sortingOrder.ASC]: <BarsArrowDownIcon className="w-6 h-6 hover:cursor-pointer"/>,
    [sortingOrder.DESC]: <BarsArrowUpIcon className="w-6 h-6 hover:cursor-pointer"/>
};

const mapsEqual = (map1, map2) => {
    let testVal;
    if (map1.size !== map2.size) {
        return false;
    }
    for (const [key, val] of map1) {
        testVal = map2.get(key);
        // in cases of an undefined value, make sure the key
        // actually exists on the object so there are no false positives
        // fortunately stringify is fast
        if (JSON.stringify(testVal) !== JSON.stringify(val) || (testVal === undefined && !map2.has(key))) {
            return false;
        }
    }
    return true;
};

/**
 * @param {string} name - name of the politician
 * @param {boolean} fix - fixes umlaut issues in urls.
 * @param {string} quality - max image resolution. Allowed: hq, 64, 500
 * */
const nameToImageUrl = (name, fix = true, quality = "500") => {
    if (fix === true) {
        return `${env("IMAGE_URL_BASE")}politicians/${quality}/${urlify(name)}.jpg`;
    } else {
        return `${env("IMAGE_URL_BASE")}politicians/${quality}/${name}.jpg`;
    }
};
const remapValues = (values, map) => {
    return map !== undefined ? values.map((v) => map.has(v) ? map.get(v) : v) : values;
};

const insertAt = (array, index, ...elements) => {
    array.splice(index, 0, ...elements);
};

const roundNumber = (value, digits = 0) => {
    if (typeof value !== "number") {
        return value;
    }
    return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
};

const intersection = (first, second) => {
    return first.filter((element) => second.includes(element));
};

const findMatchingStackType = (stacks, stackTypes) => {
    let bestStackReference = { index: -1, score: -1, length: 0 };
    for (const [index, stackType] of Object.entries(stackTypes)) {
        if (stackType.length >= stacks.length && stacks.every((value) => stackType.includes(value))) {
            const score = intersection(stacks, stackType).length;
            const length = stackType.length;
            if (score > bestStackReference.score || (score === bestStackReference.score && length < bestStackReference.length)) {
                bestStackReference = { index, score, length };
            }
        }
    }
    return bestStackReference;
};

const partyColorMap = new Map([
    ["REF", "rgb(255,196,0)"],
    ["KESK", "rgb(0, 128, 0)"],
    ["ROH", "rgb(64, 255, 48)"],
    ["EKRE", "rgb(0, 0, 0)"],
    ["SDE", "rgb(255, 0, 0)"],
    ["ISA", "rgb(0, 0, 255)"],
    ["E200", "rgb(0, 128, 128)"],
    ["AEL", "rgb(255, 165, 0)"],
    ["AET", "rgb(255, 165, 0)"],
    ["Ei oska öelda", "rgb(128, 0, 128)"],
    ["Ei tea", "rgb(128, 0, 128)"],
    ["mittevalija", "rgb(105,93,71)"],
    ["Ei vali", "rgb(105,93,71)"],
    ["LIB", "rgb(255, 0, 0)"],
    ["KON", "rgb(0, 0, 255)"],
    ["Eelistuseta", "rgb(128,0,128)"]
]);

const partyLongColorMap = new Map([
    ["Eesti Reformierakond", "rgb(255, 255, 0)"],
    ["Eesti Keskerakond", "rgb(0, 128, 0)"],
    ["Eesti Konservatiivne Rahvaerakond", "rgb(0, 0, 0)"],
    ["Sotsiaaldemokraatlik Erakond", "rgb(255, 0, 0)"],
    ["Isamaa", "rgb(0, 0, 255)"],
    ["Eesti 200", "rgb(0, 128, 128)"],
    ["Ei oska öelda", "rgb(128, 0, 128)"],
    ["Ei taha öelda", "rgb(128, 0, 128)"],
    ["Muu", "rgb(128, 0, 128)"],
    ["MUU", "rgb(128, 0, 128)"],
    ["Ei oska öelda / ei mäleta", "rgb(128, 0, 128)"],
    ["Ei läheks valima", "rgb(128, 0, 128)"]
]);

const partyColorRGBAMap = (a) => new Map([
    ["REF", `rgba(255, 255, 0, ${a})`],
    ["KESK", `rgba(0, 128, 0, ${a})`],
    ["EKRE", `rgba(0, 0, 0, ${a})`],
    ["SDE", `rgba(255, 0, 0, ${a})`],
    ["ISA", `rgba(0, 0, 255, ${a})`],
    ["E200", `rgba(0, 128, 128, ${a})`],
    ["AEL", `rgba(255, 165, 0, ${a})`],
    ["Ei oska öelda", `rgba(128, 0, 128, ${a})`],
    ["Ei tea", `rgba(128, 0, 128, ${a})`],
    ["mittevalija", `rgba(255, 165, 0, ${a})`],
    ["Ei vali", `rgba(255, 165, 0, ${a})`],
    ["LIB", `rgba(255, 0, 0, ${a})`],
    ["KON", `rgba(0, 0, 255, ${a})`],
    ["AET", `rgba(255, 165, 0, ${a})`]
]);

const appColorScheme = {
    darkBg: "#242A6C",
    dark: "#424895",
    medium: "#797fd0",
    light: "rgb(232, 235, 255)",
    veryLight: "#f8fafd",
    whiteBg: "#FFFFFF",
    tileColor: "#2C3490",
    relevancePalette: {
        max: "#626CD9",
        min: "#DFE3FF",
        arr: ["#DFE3FF", "#B5BBF2", "#8A91E6", "#626CD9"]
    },
    // blue-yellow
    polarityPaletteBY: {
        max: "#FCBF3F",
        min: "#626CD9",
        arr: ["#626CD9", "#8A91E6", "#B5BBF2", "#DFE3FF", "#FFE3AB", "#FFD070", "#FCBF3F"]
    },
    polarityPalletteRB: {
        max: "#626CD9",
        min: "#FC6E3F",
        arr: ["#FC6E3F", "#FF9370", "#FFBEAB", "#DFE3FF", "#B5BBF2", "#8A91E6", "#626CD9"]
    },
    polarityPalletteRG: {
        max: "#80E339",
        min: "#FC6E3F",
        arr: ["#FC6E3F", "#FF9370", "#FFBEAB", "#DFE3FF", "#CDFAA7", "#A8F071", "#80E339"]
    }
};

const sumReduce = (acc, curr) => acc + curr;
const limitStringLength = (str, maxLength = 24) =>
    str.length > maxLength ? str.substring(0, maxLength) + "..." : str;

const htmlStringToNumber = (v) => {
    if (typeof v !== "string") { return v; }
    const n = parseFloat(marked(v).replace(/<[^>]*>?/gm, ""));
    if (!Number.isNaN(n)) { return n; }
    return v;
};

const responseColorMap = new Map([
    ["Ei oska öelda", "#a5a5a5"],
    ["Raske öelda", "#c8c8c8"],
    ["Neutral", "#a5a5a5"],
    ["Don't Know", "#c8c8c8"],
    ["No Answer", "#c8c8c8"],
    ["Ei ühte ega teist", "#c8c8c8"],
    ["Ei suurenda ega vähenda", "#c8c8c8"],
    ["3_neutraalne", "#a5a5a5"],
    ["4_neutraalne", "#a5a5a5"],
    ["Neutraalne", "#a5a5a5"],
    ["3_ei_tunne", "#c8c8c8"],
    ["Ei tunne", "#c8c8c8"],
    ["Ei tunne/Ei oska öelda", "#c8c8c8"],
    ["7_ei_tunne", "#c8c8c8"],
    ["3_ei_tea", "#a5a5a5"],
    ["Ei tea", "#a5a5a5"],
    ["Ei valitud", "#a5a5a5"],
    ["Ei näidatud", "white"],
    ["50 Neutraalne", "#a5a5a5"],
    // religions
    ["Baptism", "#874EFF"],
    ["Islam", "#009900"],
    ["Judaism", "#991966"],
    ["Katoliiklus", "#CCCC03"],
    ["Luterlus", "#0700B3"],
    ["Maausk, Taarausk või Paganlus", "#407374"],
    ["Metodism", "#4D4DE5"],
    ["Mitte ükski loetletutest", "#c8c8c8"],
    ["Muu", "#a5a5a5"],
    ["Õigeusk (Konstantinoopoli alluvuses, Eesti Apostlik Õigeusu Kirik)", "#B38000"],
    ["Õigeusk (vene, Moskva alluvuses)", "#F43708"],
    // religions eng
    ["Pagan", "#407374"],
    ["Christian", "#CCCC03"],
    ["Buddhist", "#B38000"],
    ["Other", "#a5a5a5"],
    ["Agnostic", "#874EFF"],
    ["Atheist", "#0700B3"],
    // ideo quadrant
    ["AuthRight", "#00aaff"],
    ["AuthLeft", "#ff0000"],
    ["Centrist", "#a1a1a1"],
    ["LibLeft", "#aaf22b"],
    ["LibRight", "#ffff00"],
    ["Auth", "#aa00ff"],
    ["Lib", "#f5ae32"],
    ["Left", "#ff0000"],
    ["Right", "#00aaff"],
    // gender
    ["Female", "#ff0000"],
    ["Male", "#00aaff"]
]);

/**
 * @typedef {string} Maps
 */

/**
 * @enum {Maps}
 */
const maps = {
    CONSTITUENCIES: "valimisringkonnad"
};

const stackTypes = [
    ["KON", "KESK", "Muu", "LIB"],
    ["Üldse ei usalda", "Pigem ei usalda", "Ei oska öelda", "Pigem usaldan", "Usaldan täiesti"],
    ["Ei ole valitud", "Ei oska öelda", "Jah"],
    ["Ei", "Pigem ei", "Ei oska öelda", "Pigem jah", "Jah"],
    ["Ei", "Pigem ei", "Ei oska öelda", "Riik ei peaks üldse tegelema väärtuste edendamisega", "Pigem jah", "Jah"],
    ["Kindlasti mitte", "Pigem mitte", "Ei oska öelda", "Pigem jah", "Jah, kindlasti"],
    ["Kindlasti mitte", "Tõenäoliselt mitte", "Ei oska öelda", "Jah, tõenäoliselt", "Jah, kindlasti"],
    ["Ei ole üldse nõus", "Pigem ei ole nõus", "Ei oska öelda", "Raske öelda", "Pigem nõus", "Täiesti nõus"],
    ["Üldse ei ole nõus", "Pigem ei ole nõus", "Ei oska öelda", "Pigem nõus", "Täiesti nõus"],
    ["Üldse ei ole nõus", "Pigem ei ole nõus", "Ei oska öelda", "Raske öelda", "Pigem nõus", "Täiesti nõus"],
    ["Kaotanud", "Pigem kaotanud", "Ei oska öelda", "Pigem võitnud", "Võitnud"],
    ["1_halb_mulje", "2_pigem_halb_mulje", "3_neutraalne", "4_pigem_hea_mulje", "5_hea_mulje"],
    ["1_halb_mulje", "2_pigem_halb_mulje", "3_ei_tunne", "4_neutraalne", "5_pigem_hea_mulje", "6_hea_mulje"],
    ["Väga halb mulje", "Halb mulje", "Pigem halb mulje", "Ei tunne/Ei oska öelda", "Neutraalne", "Pigem hea mulje", "Hea mulje", "Väga hea mulje"],
    ["Halb mulje", "Pigem halb mulje", "Ei tunne/Ei oska öelda", "Neutraalne", "Pigem hea mulje", "Hea mulje"],
    ["1_mitteusaldusväärne", "2_pigem_mitteusaldusväärne", "3_pigem_usaldusväärne", "4_usaldusväärne"],
    ["1_mitteusaldusväärne", "2_pigem_mitteusaldusväärne", "3_ei_tea", "4_pigem_usaldusväärne", "5_usaldusväärne"],
    ["6 Mitte kunagi", "5 Veel harvem", "4 Vähemalt kord kuus", "3 Kord nädalas", "2 Rohkem kui kord nädalas", "1 Iga päev"],
    ["6 Mitte kunagi", "5 Veel harvem", "4 Vähemalt kord kuus", "3 Kord nädalas", "2 Rohkem kui kord nädalas", "1 Iga päev", "0 Mitu korda päevas"],
    ["Ei oska öelda", "Mitte kunagi", "Veel harvem", "Ainult erilistel pühadel", "Vähemalt kord kuus", "Kord nädalas", "Rohkem kui kord nädalas", "Iga päev"],
    ["Liberaalsemaks", "Pigem liberaalsemaks", "Ei oska öelda", "Ei ühte ega teist", "Pigem konservatiivsemaks", "Konservatiivsemaks"],
    ["Üldse ei ole rahul", "Pigem ei ole rahul", "Ei oska öelda", "Pigem olen rahul", "Olen täiesti rahul"],
    ["Üldse mitte lubama", "Lubama väga vähestel siia elama tulla", "Ei oska öelda", "Lubama piiratud arvul siia elama tulla", "Lubama paljudel siia elama tulla"],
    ["Kohalikud inimesed peaks tegema ära kõik tööd", "Ei oska öelda", "Madalama staatusega töödele peaks palkama võõrtöölised"],
    ["Lubama paljudel siia tööle tulla", "Lubama piiratud arvul siia tööle tulla", "Ei oska öelda", "Lubama väga vähestel siia tööle tulla", "Üldse mitte lubama"],
    ["Lubama paljudel siia õppima tulla", "Lubama piiratud arvul siia õppima tulla", "Ei oska öelda", "Lubama väga vähestel siia õppima tulla", "Üldse mitte lubama"],
    ["Ei oska öelda", "Üldse mitte", "väga harva", "harva", "sageli", "Väga sageli"],
    ["Ei oska öelda", "Üldse mitte", "Väga harva", "Harva", "Sageli", "Väga sageli"],
    ["Ei oska öelda", "Üldse mitte", "Väga harva", "Harva", "Tihti", "Väga tihti"],
    ["Hääletaksin vastu", "Pigem hääletaksin vastu", "Ei oska öelda", "Pigem hääletaksin poolt", "Hääletaksin poolt"],
    ["Ei ole üldse huvitatud", "Pigem mitte huvitatud", "Ei oska öelda", "Pigem huvitatud", "Väga huvitatud"],
    // ["halvad", "pigem halvad", "nii ja naa", "pigem head", "head"],
    ["Halvad", "Pigem halvad", "Nii ja naa", "Pigem head", "Head"],
    ["Halvad", "Pigem halvad", "Ei oska öelda", "Nii ja naa", "Pigem head", "Head"],
    ["Väga halb", "Pigem halb", "Ei oska öelda", "Pigem hea", "Väga hea"],
    ["Poliitikud", "Pigem poliitikud", "Ei oska öelda", "Pigem rahvas", "Rahvas"],
    ["1_erinev", "2_pigem_erinev", "3_neutraalne", "4_pigem_sarnane", "5_sarnane"],
    ["1_erinev", "2_pigem_erinev", "3_ei_tunne", "4_pigem_sarnane", "5_sarnane"],
    ["Erinev", "Pigem erinev", "Ei tunne/Ei oska öelda", "Pigem sarnane", "Sarnane"],
    ["Vales suunas", "Pigem vales suunas", "Ei oska öelda", "Pigem õiges suunas", "Õiges suunas"],
    ["Vasakpoolsemaks", "Pigem vasakpoolsemaks", "Ei oska öelda", "Ei ühte ega teist", "Pigem parempoolsemaks", "Parempoolsemaks"],
    ["Üldse ei ole uhke", "Mitte eriti uhke", "Ei oska öelda", "Küllaltki uhke", "Väga uhke"],
    ["Üldse mitte tähtis", "Mitte eriti tähtis", "Ei oska öelda", "Üsna tähtis", "Väga tähtis"],
    ["Koostöö on läinud liiga kaugele", "Ei oska öelda", "Praegune koostöö on piisav", "Koostöö peab suurenema"],
    ["Head suhted Venemaaga", "Ei oska öelda", "Tugev liitlassuhe Ameerika Ühendriikidega"],
    ["Ma ei saa aru ega räägi", "Veidi saan aru, ise ei räägi", "Ei oska öelda", "Saan aru, räägin veidi", "Saan hästi aru, räägin vabalt"],
    ["Sugu on täielikult kultuuriline konstruktsioon", "Sugu on pigem kultuuriline konstruktsioon", "Ei oska öelda", "Sugu on pigem bioloogiline nähtus", "Sugu on täielikult bioloogiline nähtus"],
    ["Sugu on kultuuriline konstruktsioon", "Ei oska öelda", "Sugu on bioloogiline nähtus"],
    ["Ühiskondlikest ootustest", "Pigem ühiskondlikest ootustest", "Ei oska öelda", "Pigem bioloogilistest erinevustest", "Bioloogilistest erinevustest"],
    ["Vähendab", "Ei oska öelda", "Ei suurenda ega vähenda", "Suurendab"],
    ["Kaasaegset", "Pigem kaasaegset", "Ei oska öelda", "Meeldib nii üks kui teine", "Pigem traditsioonilist", "Traditsioonilist"],
    ["Ei oska öelda", "Üldse mitte", "Vähem kui kord aastas", "Vähemalt kord aastas", "Mõned korrad aastas", "Tihedamini"],
    ["Üksikisik", "Pigem üksikisik", "Ei oska öelda", "Ei kumbki", "Pigem perekond", "Perekond"],
    ["18-24", "25-34", "35-49", "50-64", "65-74", "75+"],
    ["15-24", "25-34", "35-49", "50-64", "65-74", "75+"],
    ["Alg- või põhiharidus/Kutseharidus ilma keskhariduseta", "Kesk- või keskeriharidus (sh kutseharidus keskhariduse baasil)",
        "Kõrgharidus (sh rakendus- ja kutsekõrgharidus, diplomiõpe)"],
    ["Pakkuda Eestis eestikeelset kõrgharidust", "Ei oska öelda", "Pakkuda Eestis ingliskeelset kõrgharidust"],
    ["Luterlus", "Metodism", "Baptism", "Katoliiklus", "Õigeusk (Konstantinoopoli alluvuses, Eesti Apostlik Õigeusu Kirik)", "Õigeusk (vene, Moskva alluvuses)", "Judaism", "Islam", "Maausk, Taarausk või Paganlus", "Mitte ükski loetletutest", "Muu"],
    ["Lahutamatu osa inimeseks olemisest", "Ei oska öelda", "Poliitiline kokkulepe"],
    ["Linnastumine on positiivne", "Ei oska öelda", "Linnastumist tuleks pidurdada"],
    ["Ei oska öelda", "Inimene", "Eurooplane", "Rahvus (eestlane, venelane, muu rahvus)"],
    ["Ei ole lapsi", "1 laps", "2 last", "3 või rohkem last"],
    ["Ei soovi avaldada", "Ei tea", "Kuni 500 €", "501-1000 €", "1001-1500 €", "1501-2000 €", "Üle 2000 €"],
    ["Ei oska öelda", "Keeldun avaldamast", "Sissetulekut ei ole", "Kuni 200 €", "201 - 300 €", "301 - 400 €", "401 - 500 €", "401 - 650 €", "501 - 650 €", "651+ €", "651 - 800 €", "801 - 1000 €", "1001 - 1300 €", "1301 - 1600 €", "Üle 1600 €"],
    ["Tallinn", "Linn", "Väikelinn", "Maa"],
    ["Ei oska öelda", "Tarbite ainult taimset toitu", "Tarbite lisaks taimsele toidule mune ning piimatooteid", "Muu", "Ei tarbi liha, kuid tarbite kalatooteid", "Tarbite kõiki toiduaineid"],
    ["Ei tea", "0 Väga ebaoluline", "16", "33", "50", "66", "83", "100 Väga oluline"],
    ["Ei valitud", "5", "4", "3", "2", "1"],
    ["Ei näidatud", "0 Üldse ei ole nõus", "16", "33", "Ei tea", "50 Neutraalne", "66", "83", "100 Täiesti nõus"],
    ["0", "1", "2", "3", "4+"],
    Array.from(Array(10), (v, i) => i + 1).map((n) => n.toString()), // 1-10
    [...Array.from(Array(9), (v, i) => i + 1).map((n) => n.toString()), "10+"], // 1-9, 10+
    Array.from(Array(7), (v, i) => i + 1).map((n) => n.toString()), // 1-7
    Array.from(Array(7), (v, i) => i + 1.0).map((n) => n.toFixed(1).toString()), // 1.0-7.0
    Array.from(Array(11).keys()).map((n) => n.toString()) // 0-10
];

const smallPartiesToOtherOverrides = new Map([
    ["Eesti Vabaduspartei - Põllumeeste kogu", "MUU"],
    ["Eestimaa Ühendatud Vasakpartei", "MUU"],
    ["Elurikkuse erakond", "MUU"],
    ["ELU", "MUU"],
    ["Muu", "MUU"],
    ["Muu erakond", "MUU"],
    ["Eesti Vabaerakond", "MUU"],
    ["VAB", "MUU"],
    ["Erakond Eestimaa Rohelised", "MUU"],
    ["Rohelised", "MUU"],
    ["ROH", "MUU"],
    ["Eesti Tulevikuerakond", "MUU"],
    ["TULE", "MUU"],
    ["Eesti Kristlikud Demokraadid", "MUU"],
    ["Mõni muu erakond", "MUU"],
    ["Rahva Ühtsuse Erakond", "MUU"],
    ["Eesti Iseseisvuspartei", "MUU"],
    ["Eestimaa Ühendatud Vasakpartei", "MUU"],
    ["Eestimaa Ühendatud Vasakpartei/KOOS", "MUU"],
    ["Üksikkandidaadi poolt", "MUU"],
    ["Eesti Tulevikuerakond (endised Vabaerakond ja Elurikkuse Erakond)", "MUU"]
]);

const partyToLong = new Map([
    ["REF", "Eesti Reformierakond"],
    ["EKRE", "Eesti Konservatiivne Rahvaerakond"],
    ["KESK", "Keskerakond"],
    ["SDE", "Sotsiaaldemokraatlik erakond"],
    ["ISA", "Isamaa erakond"],
    ["E200", "Eesti 200"],
    ["MUU", "Muud erakonnad"],
    ["VAB", "Eesti Vabaerakond"],
    ["ROH", "Erakond Eestimaa Rohelised"],
    ["TULE", "Eesti Tulevikuerakond"],
    ["ELU", "Elurikkuse erakond"]
]);

const valueOverrides = new Map([
    ["Üldse mitte rahul 0", "0"],
    ["Väga rahul10", "10"],
    ["Konservatiivne10", "10"],
    ["Liberaalne0", "0"],
    ["Konservatiivsemaks10", "10"],
    ["Liberaalsemaks0", "0"],
    ["Parempoolne10", "10"],
    ["Vasakpoolne0", "0"],
    ["Parempoolsemaks10", "10"],
    ["Vasakpoolsemaks0", "0"],
    ["Parempoolsemaks 10", "10"],
    ["Vasakpoolsemaks 0", "0"],
    ["Üldse ei ole nõus", "Ei ole üldse nõus"],
    ["Pigem olen nõus", "Pigem nõus"],
    ["Olen Täiesti nõus", "Täiesti nõus"],
    ["Olen täiesti nõus", "Täiesti nõus"],
    ["Täiesti vastuvõetav 10", "10"],
    ["Täiesti vastuvõetamatu 1", "1"],
    ["On väga hästi tagatud10", "10"],
    ["Üldse ei ole tagatud0", "0"],
    ["Eesti Konservatiivne Rahvaerakond", "EKRE"],
    ["Eesti Konservatiivne Rahvaerakond (EKRE)", "EKRE"],
    ["Eesti Reformierakond", "REF"],
    ["Eesti Keskerakond", "KESK"],
    ["Keskerakond", "KESK"],
    ["Reformierakond", "REF"],
    ["Konservatiivne Rahvaerakond", "EKRE"],
    ["Sotsiaaldemokraatlik Erakond", "SDE"],
    ["Erakond Isamaa", "ISA"],
    ["Isamaa", "ISA"],
    ["Mihhail Stalnuhhin", "Muu"],
    ["Üksikkandidaat", "Muu"],
    ["Isamaa erakond", "ISA"],
    ["Isamaa ja Res Publica Liit", "ISA"],
    ["Isamaa ja Res Publica Liit (IRL)", "ISA"],
    ["Erakond Eestimaa Rohelised", "ROH"],
    ["Rohelised", "ROH"],
    ["Eesti 200", "E200"],
    ["Ei oska öelda / ei mäleta", "Ei tea"],
    ["Ei oska öelda/keeldumine", "Ei tea"],
    ["Ei oska öelda.", "Ei tea"],
    ["Mitte ühegi erakonna ega üksikkandidaadi poolt", "Ei kellegi"],
    ["ei vali", "Ei kellegi"],
    ["Ei läheks valima", "Ei vali"],
    ["keeldumine", "Ei kellegi"],
    ["Keeldumine", "Ei kellegi"],
    ["Ei taha öelda", "Ei ütle"],
    ["Põhiharidus (põhikooli 9 klassi)", "Põhiharidus"],
    ["Magister või sellega võrdsustatud haridus", "Magister"],
    ["Kutseharidus keskhariduse baasil", "Kutseharidus"],
    ["Keskeri- või tehnikumiharidus keskhariduse baasil", "Keskeri-/Tehnikum"],
    ["Bakalaureus või sellega võrdsustatud haridus", "Bakalaureus"],
    ["Eesti Vabaerakond", "VAB"],
    ["Elurikkuse Erakond", "ELU"],
    ["Eesti Tulevikuerakond", "TULE"],
    ["Eesti Vabaduspartei – Põllumeeste Kogu", "Muu"],
    ["Eestimaa Ühendatud Vasakpartei", "Muu"],
    ["Üksikkandidaadi poolt", "Muu"],
    ["Üksikkandidaadi poolt (kes ei kandideeri ühegi erakonna nimekirjas)", "Muu"],
    ["Eesti Iseseisvuspartei", "Muu"],
    ["Muu erakond", "Muu"],
    ["Eesti Tulevikuerakond (endised Vabaerakond ja Elurikkuse Erakond)", "Muu"]
]);

const partyValueOverrides = new Map([
    ["Eesti Konservatiivne Rahvaerakond", "EKRE"],
    ["Eesti Konservatiivne Rahvaerakond (EKRE)", "EKRE"],
    ["Eesti Reformierakond", "REF"],
    ["Eesti Keskerakond", "KESK"],
    ["Keskerakond", "KESK"],
    ["Erakond Eesti 200", "E200"],
    ["Reformierakond", "REF"],
    ["Konservatiivne Rahvaerakond", "EKRE"],
    ["Sotsiaaldemokraatlik Erakond", "SDE"],
    ["Erakond Isamaa", "ISA"],
    ["Isamaa", "ISA"],
    ["Isamaa erakond", "ISA"],
    ["Isamaa Erakond", "ISA"],
    ["Isamaa ja Res Publica Liit", "ISA"],
    ["Isamaa ja Res Publica Liit (IRL)", "ISA"],
    ["Erakond Eestimaa Rohelised", "ROH"],
    ["Eestimaa Rohelised", "ROH"],
    ["Rohelised", "ROH"],
    ["Eesti 200", "E200"],
    ["Ei oska öelda / ei mäleta", "Ei oska öelda"],
    ["Ei oska öelda/keeldumine", "Ei oska öelda"],
    ["Ei oska öelda.", "Ei oska öelda"],
    ["Mitte ühegi erakonna ega üksikkandidaadi poolt", "Ei kellegi"],
    ["ei vali", "Ei kellegi"],
    ["Eesti Vabaerakond", "VAB"],
    ["Elurikkuse Erakond", "ELU"],
    ["Eesti Tulevikuerakond", "TULE"],
    ["Eesti Vabaduspartei – Põllumeeste Kogu", "Muu"],
    ["Eestimaa Ühendatud Vasakpartei", "Muu"],
    ["Üksikkandidaadi poolt", "Muu"],
    ["Üksikkandidaadi poolt (kes ei kandideeri ühegi erakonna nimekirjas)", "Muu"],
    ["Eesti Iseseisvuspartei", "Muu"],
    ["Parempoolsed", "PAR"],
    ["Erakond Parempoolsed", "PAR"],
    ["Mihhail Stalnuhhin", "Muu"],
    ["Üksikkandidaat", "Muu"]
]);

export const demSimplifications = Object.fromEntries(new Map([
    ["Kutseharidus keskhariduse baasil", "Kutsekesk"],
    ["Bakalaureus või sellega võrdsustatud haridus", "Baka"],
    ["Magister või sellega võrdsustatud haridus", "Magister"],
    ["Üldkeskharidus", "Keskharidus"],
    ["Keskeri- või tehnikumiharidus keskhariduse baasil", "Keskeriharidus"],
    ["Põhiharidusenõudeta kutseharidus ja kutseharidus põhihariduse baasil", "Kutseharidus"],
    ["Kutsekeskharidus põhihariduse baasil", "Kutsekesk"],
    ["Doktor või sellega võrdsustatud haridus", "Doktor"],

    ["Kõrgharidus (sh rakendus- ja kutsekõrgharidus, diplomiõpe)", "Kõrg"],
    ["Suur linn (Tartu, Pärnu, Narva, Kohtla-Järve)", "Suur linn"],
    ["Ei ole lapsi", "0 last"],
    ["Venelane/Muu", "Muu"],
    ["Põhiharidus (põhikooli 9 klassi)", "Põhiharidus"],
    ["Harjumaa (va Tallinn)", "Harjumaa"],
    ["3 last või rohkem last", "3+ last"],

    ["Alg- või põhiharidus/Kutseharidus ilma keskhariduseta", "Alg- või põhi"],
    ["Tallinna Haabersti, Põhja-Tallinna ja Kristiine linnaosa", "Haab, Põh-Tln, Krist"],
    ["Kesk- või keskeriharidus (sh kutseharidus keskhariduse baasil)", "Kesk"],
    ["Jõgeva- ja Tartumaa (v.a Tartu linn)", "Jõgeva- ja Tartu"]

]));

const uniques = (array) => [...new Set(array)];
export const uniquesOrdered = (array) => {
    const us = [];
    array.forEach(v => !us.includes(v) && us.push(v));
    return us;
};

const colorMix = (c1, c2, a) => {
    // works for all dimensions
    const weightedInterpolate = (f1, f2, b) => {
        return f1 * (1 - b) + f2 * b;
    };
    if (c1.length !== c2.length) {
        throw new Error(`Cannot mix arrays of different dimensions, passed dimensions were ${c1.length} and ${c2.length}`);
    }
    const outputArray = Array(c1.length);
    for (let i = 0; i < c1.length; i++) {
        outputArray[parseInt(i, 10)] = Math.floor(weightedInterpolate(c1[parseInt(i, 10)], c2[parseInt(i, 10)], a));
    }
    return outputArray;
};

const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
};

const rgbToHex = (r, g, b) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

const isRgb = (color) => {
    return Array.isArray(color) & color.length === 3;
};

const hexToRgb = (c) => {
    const c1 = colord(c).toRgb();
    return [c1.r, c1.g, c1.b];
};

const colorGradient = (colors, steps) => {
    let rgbColors;
    if (!colors.every(isRgb)) {
        rgbColors = colors.map(hexToRgb);
    } else {
        rgbColors = colors;
    }
    if (steps < 2) {
        return rgbColors[0];
    } else if (steps === rgbColors.length) {
        return rgbColors.map((c) => rgbToHex(...c));
    }
    const stepsInt = parseInt(steps, 10);
    const maxIndex = stepsInt - 1;
    const stepFrac = 1 / maxIndex;
    const colorsMaxIndex = rgbColors.length - 1;

    let currentFrac, colorIndex, currentColorIndexFrac, nextColorIndex, nextColorIndexFrac;
    return Array.from(Array(steps), (_, i) => {
        currentFrac = stepFrac * i;
        colorIndex = Math.floor(currentFrac * colorsMaxIndex);
        currentColorIndexFrac = colorIndex / colorsMaxIndex;
        nextColorIndex = Math.min(colorIndex + 1, colorsMaxIndex);
        nextColorIndexFrac = nextColorIndex / colorsMaxIndex;
        const c1 = rgbColors[parseInt(colorIndex, 10)];
        if (colorIndex === nextColorIndex) {
            return rgbToHex(...c1);
        }
        const c2 = rgbColors[parseInt(nextColorIndex, 10)];
        const weight = (currentFrac - currentColorIndexFrac) / (nextColorIndexFrac - currentColorIndexFrac);
        const newColor = colorMix(c1, c2, weight);
        return rgbToHex(...newColor);
    });
};

const useToggle = (initialValue = false) => {
    const [value, setValue] = useState(initialValue);
    const toggle = useCallback(() => {
        setValue(v => !v);
    }, []);
    return [value, toggle];
};

const pipe = (...fns) => fns.reduce((f, g) => (...args) => g(f(...args)));
function bindTrailing(fn, ...boundArgs) {
    return function(...args) {
        return fn(...args, ...boundArgs);
    };
}
/*
 * Easing Functions - inspired from http://gizma.com/easing/
 * only considering the t value for the range [0, 1] => [0, 1]
 */
const easing = {
    // no easing, no acceleration
    "linear": (t) => t,
    // accelerating from zero velocity
    "easeInQuad": (t) => Math.pow(t, 2),
    // decelerating to zero velocity
    "easeOutQuad": (t) => t * (2 - t),
    // acceleration until halfway, then deceleration
    "easeInOutQuad": (t) => t < 0.5 ? 2 * Math.pow(t, 2) : -1 + 2 * (2 - t) * t,
    // accelerating from zero velocity
    "easeInCubic": (t) => Math.pow(t, 3),
    // decelepoliticiansFilterrating to zero velocity
    "easeOutCubic": (t) => Math.pow(t - 1, 3) + 1,
    // acceleration until halfway, then deceleration
    "easeInOutCubic": (t) => t < 0.5 ? 4 * Math.pow(t, 3) : 1 + 4 * Math.pow(t - 1, 3),
    // accelerating from zero velocity
    "easeInQuart": (t) => Math.pow(t, 4),
    // decelerating to zero velocity
    "easeOutQuart": (t) => 1 - Math.pow(t - 1, 4),
    // acceleration until halfway, then deceleration
    "easeInOutQuart": (t) => t < 0.5 ? 8 * Math.pow(t, 4) : 1 - 8 * Math.pow(t - 1, 4),
    // accelerating from zero velocity
    "easeInQuint": (t) => Math.pow(t, 5),
    // decelerating to zero velocity
    "easeOutQuint": (t) => 1 + Math.pow(t - 1, 5),
    // acceleration until halfway, then deceleration
    "easeInOutQuint": (t) => t < 0.5 ? 16 * Math.pow(t, 5) : 1 + 16 * Math.pow(t - 1, 5)
};
const clamp = (v, min, max) => Math.min(Math.max(min, v), max);

const politicalEventsWithAxisForWaves = [
    { "xAxis": 8, "label": { "formatter": "RK 2019" } },
    { "xAxis": 19, "label": { "formatter": "EP 2019", "position": "start" } },
    { "xAxis": 16, "label": { "formatter": "EKREIKE" } },
    { "xAxis": 22, "label": { "formatter": "Indrek Saar\nSDE esimees" } },
    { "xAxis": 36, "label": { "formatter": "Kert Kingo\nlahkub" } },
    { "xAxis": 45, "label": { "formatter": "Mart Järvik\nlahkub" } },
    { "xAxis": 60, "label": { "formatter": "eriolukord" } },
    { "xAxis": 65, "label": { "formatter": "Kaimar Karu\nlahkub" } },
    { "xAxis": 74, "label": { "formatter": "Martin Helme\nEKRE esimees" } },
    { "xAxis": 87, "label": { "formatter": "Martin Helme\numbusaldamine" } }
];

const politicalEventsWithDates = [
    { "name": "RK 2019", "date": "2019-03-03" },
    { "name": "EKREIKE", "date": "2019-04-24" },
    { "name": "EP 2019", "date": "2019-05-26" },
    { "name": "eriolukord", "date": "2020-03-11" },
    { "name": "Martin Helme\nEKRE esimees", "date": "2020-07-04" },
    { "name": "Kaja Kallase\nvalitsus", "date": "2021-01-26" },
    { "name": "Ukraina sõda", "date": "2022-02-21" }, // in reality 24th but it puts it to 28 instead of 21
    { "name": "R+I+S\nvalitsus", "date": "2022-07-18" },
    { "name": "RK2023", "date": "2023-03-04" },
    { "name": "Idavedude\nskandaal", "date": "2023-08-23" }
];

const politicalEventsWithAxis = [
    { "xAxis": 8, "label": { "formatter": "RK 2019" } },
    { "xAxis": 19, "label": { "formatter": "EP 2019", "position": "start" } },
    { "xAxis": 16, "label": { "formatter": "EKREIKE" } },
    { "xAxis": 22, "label": { "formatter": "Indrek Saar\nSDE esimees" } },
    { "xAxis": 61 + (4 / 7), "label": { "formatter": "eriolukord" } },
    { "xAxis": 77.71, "label": { "formatter": "Martin Helme\nEKRE esimees" } },
    { "xAxis": 105 + (3 / 7), "label": { "formatter": "EKREIKE\nlõpp" } }
].map((r) => {
    r.xAxis = Math.round(r.xAxis * 7);
    return r;
});

const TimeInSeconds = {
    "DAY": 86400,
    "WEEK": 86400 * 7,
    "MONTH": 86400 * 30,
    "YEAR": 86400 * 365
};

const isEmptyDataFrame = (data) => data instanceof DataFrame && data.isEmpty();
const isNonEmptyDataFrame = (data) => data instanceof DataFrame && !data.isEmpty();
const isEmptyArray = (data) => typeof data === "object" && Array.isArray(data) && data.length === 0;
const isNonEmptyArray = (data) => typeof data === "object" && Array.isArray(data) && data.length !== 0;
const isEmptyNestedFilter = (data) => typeof data === "object" && Object.prototype.hasOwnProperty.call(data, "type") && data.type === "FILTER" && isEmptyArray(data.mappings);
const isNonEmptyNestedFilter = (data) => typeof data === "object" && Object.prototype.hasOwnProperty.call(data, "type") && data.type === "FILTER" && isNonEmptyArray(data.mappings);

const prettyPrintValue = (val) => {
    if (typeof val === "object") {
        return JSON.stringify(val);
    } else if (typeof val === "boolean") {
        return val.toString();
    } else if (typeof val === "string") {
        return val;
    } else if (!Number.isNaN(val)) {
        return Math.round(parseFloat(val) * 100) / 100;
    } else {
        return val;
    }
};

function classNames(...classes) {
    return classes.filter(e => !!e)
        .join(" ");
}

const weightedAverage = (nums, weights) => {
    const [sum, weightSum] = weights.reduce(
        (acc, w, i) => {
            acc[0] = acc[0] + nums[i] * w;
            acc[1] = acc[1] + w;
            return acc;
        },
        [0, 0]
    );
    return sum / weightSum;
};

export {
    weightedAverage,
    classNames,
    streamRowToDfRow,
    sortingOrder,
    orderIcon,
    capitalize,
    nameToImageUrl,
    remapValues,
    insertAt,
    roundNumber,
    intersection,
    maps,
    partyColorMap,
    partyLongColorMap,
    partyColorRGBAMap,
    partyToLong,
    appColorScheme,
    sumReduce,
    responseColorMap,
    stackTypes,
    valueOverrides,
    partyValueOverrides,
    smallPartiesToOtherOverrides,
    findMatchingStackType,
    colorGradient,
    easing,
    clamp,
    mapsEqual,
    politicalEventsWithAxis,
    politicalEventsWithAxisForWaves,
    politicalEventsWithDates,
    TimeInSeconds,
    uniques,
    limitStringLength,
    useToggle,
    htmlStringToNumber,
    isEmptyDataFrame,
    isNonEmptyDataFrame,
    isEmptyArray,
    isNonEmptyArray,
    isEmptyNestedFilter,
    isNonEmptyNestedFilter,
    prettyPrintValue,
    pipe,
    bindTrailing
};
