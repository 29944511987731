import { fun } from "./aggregatorModels";

class Group extends Function {
    constructor(wrapper, matches) {
        super("...args", "return this.wrapper(this.matches, ...args)");
        this.matches = matches;
        this.wrapper = wrapper;
        const bind = this.bind(this);
        bind.matches = matches;
        bind.wrapper = wrapper;
        return bind;
    }

    /**
     * Sums the rows in the same group.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    sum(match) { return this.wrapper(this.matches, fun(match, "sum")); }

    /**
     * Takes the mean of the rows in the same group.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    mean(match) { return this.wrapper(this.matches, fun(match, "mean")); }

    /**
     * Takes the min of the rows in the same group.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    min(match) { return this.wrapper(this.matches, fun(match, "min")); }

    /**
     * Takes the max of the rows in the same group.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    max(match) { return this.wrapper(this.matches, fun(match, "max")); }

    /**
     * Takes the first value of the columns in the same group.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    first(match) { return this.wrapper(this.matches, fun(match, "first")); }

    /**
     * Takes the last value of the columns in the same group.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    last(match) { return this.wrapper(this.matches, fun(match, "last")); }

    /**
     * Takes values in the same group and collects them into a list/array.
     *
     * If only unique values are required, see collectSet
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    collectList(match) { return this.wrapper(this.matches, fun(match, "collect_list")); }

    /**
     * Takes values in the same group and collects them into a set of unique values.
     *
     * If non-unique values are required, see collectList
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    collectSet(match) { return this.wrapper(this.matches, fun(match, "collect_set")); }

    /**
     * Takes the nth percentile of the rows in the same group.
     *
     * @param {{function: string, pattern: *}|string} match - grouped column.
     * @param {number} percentile - float in the range [0, 1].
     * @return {Object} - function object.
     */
    percentile(match, percentile) { return this.wrapper(this.matches, fun(match, "percentile", [percentile])); }

    /**
     * Takes the mean of the rows in the same group weighted by the provided weightColumn.
     *
     * @param {{function: string, pattern: *}|string} match - grouped column.
     * @param {string} weightColumn - the column containing the weights.
     * @return {Object} - function object.
     */
    weightedMean(match, weightColumn) { return this.wrapper(this.matches, fun(match, "weighted_mean", [weightColumn])); }

    /**
     * Takes the sum of the rows in the same group weighted by the provided weightColumn.
     *
     * @param {{function: string, pattern: *}|string} match - grouped column.
     * @param {string} weightColumn - the column containing the weights.
     * @return {Object} - function object.
     */
    weightedSum(match, weightColumn) { return this.wrapper(this.matches, fun(match, "weighted_sum", [weightColumn])); }

    /**
     * Counts the occurrences of the rows in the same group.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    count(match) { return this.wrapper(this.matches, fun(match, "count")); }

    /**
     * Groups each row in a group into a vector.
     *
     * @param {{function: string, pattern: *}|string} match - matched column.
     * @return {Object} - function object.
     */
    distinctValues(match) { return this.wrapper(this.matches, fun(match, "distinct_values")); }
}

export default Group;
