import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { Toggle } from "react-daisyui";
import FormFieldHeader from "./FormFieldHeader";

const ElementCheckbox = (props) => {
    const [ignored, setData] = useState([]);
    const { setOutput, "data": dataArgs, saveInteractionState, label, ...otherProps } = props;
    const [filter, setFilter] = useState(dataArgs.defaultInteractionState);

    useEffect(() => {
        setOutput(filter);
        if (saveInteractionState !== undefined) {
            saveInteractionState(filter);
        }
    }, [filter]);

    return (
        <Element
            {...otherProps}
            setData={setData}>
            <div className="inline-block w-full relative">
                <FormFieldHeader>{label}</FormFieldHeader>
                <Toggle
                    className="toggle-lg mt-3.5 mb-3.5 toggle-primary"
                    {...{ ...otherProps, "children": null }}
                    onClick={
                        () => {
                            setFilter(!filter);
                        }
                    }
                    checked={filter}
                />
            </div>
        </Element>
    );
};

export { ElementCheckbox as Checkbox };
