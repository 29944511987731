import React, { useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";
import EchartsChart from "../../echarts/EchartsChart";
// Do not remove echarts-wordcloud import
import ignoredWordcloud from "echarts-wordcloud";
import resizeObserver from "../../echarts/resizeObserver";
import { useTheme } from "react-daisyui";

const ElementWordcloud = (props) => {
    const { theme } = useTheme();

    let colorGenerator;
    if (theme === "light") {
        colorGenerator = function() {
            return "rgb(" + [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160)
            ].join(",") + ")";
        };
    } else {
        colorGenerator = function() {
            return "rgb(" + [
                Math.round(90 + Math.random() * 160),
                Math.round(90 + Math.random() * 160),
                Math.round(90 + Math.random() * 160)
            ].join(",") + ")";
        };
    }

    const [data, setData] = useState(new DataFrame([]));
    const { "data": dataArgs, "height": chartHeight } = props;
    /**
     * dataArgs params
     *
     * @param options - ReactWordcloud options. See documentation at https://react-wordcloud.netlify.app/props#options
     * @param callbacks - callbacks. Supported callbacks: getWordColor, getWordTooltip, onWordClick, onWordMouseOut, onWordMouseOver
     * @param size - a tuple of element sizes. Supported format: [horizontal, vertical]. Size is [600, 400] by default.
     * */

    if (!data.isEmpty()) {
        const words = data.copy().map((row) => ({
            name: row.get("text"),
            value: row.get("value")
        }));

        const series = {
            type: "wordCloud",
            left: "center",
            top: "center",
            width: "100%",
            height: "100%",
            sizeRange: [8, 60],
            shape: "diamond",

            rotationRange: [-60, 60],
            rotationStep: 45,

            // size of the grid in pixels for marking the availability of the canvas
            // the larger the grid size, the bigger the gap between words.

            gridSize: 8,

            // set to true to allow word being draw partly outside of the canvas.
            // Allow word bigger than the size of the canvas to be drawn
            drawOutOfBound: false,

            // If perform layout animation.
            // NOTE disable it will lead to UI blocking when there is lots of words.
            layoutAnimation: true,

            // Global text style
            textStyle: {
                fontFamily: "sans-serif",
                fontWeight: "bold",
                // Color can be a callback function or a color string
                color: colorGenerator
            },
            emphasis: {
                focus: "self"
            },
            data: words
        };

        const chart = (
            <EchartsChart
                style={{
                    "width": "100%",
                    "height": chartHeight || "500px"
                }}
                resizeObserver={resizeObserver}
                option={{
                    backgroundColor: "transparent",
                    series
                }}
            />
        );

        return (
            <Element
                {...props}
                primary
                setData={setData}>
                <div
                    className="inline-block relative"
                    style={{
                        width: "99%"
                    }}
                >
                    {chart}
                </div>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                primary
                setData={setData}/>);
    }
};

export { ElementWordcloud as Wordcloud };
