import React from "react";
import styles from "./TStepGroup.module.css";
import { classNames } from "../UtilityFunctions";

export default ({ children, ...props }) => {
    return (
        <div className={classNames(
            styles.stepGroup,
            "flex-col lg:flex-row "
        )} {...props}>
            {children}
        </div>
    );
};
