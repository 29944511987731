import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import { DataFrame } from "../../DSL/DataFrame";
import { Element } from "../Element";

const ElementGridRow = (props) => {
    const [ignored, setData] = useState(new DataFrame([]));
    const { children, ...otherProps } = props;

    return (
        <Element
            {...props}
            primary
            setData={setData}>
            <Grid.Row
                style={{ "minWidth": "300px" }}
                {...otherProps}>
                {children}
            </Grid.Row>
        </Element>);
};

export { ElementGridRow as GridRow };
