import React from "react";

const ElementContainer = (props) => {
    const { children, args, style } = props;
    return (
        <div
            className="h-full bg-base-200 max-w-6xl justify-self-center m-auto"
            style={{
                borderRadius: "10px",
                ...style
            }}
            {...args}>
            {children}
        </div>
    );
};

export { ElementContainer as Container };
