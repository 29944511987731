import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { Input } from "semantic-ui-react";
import { DataFrame } from "../../DSL/DataFrame";

const ElementDataInputFilter = (props) => {
    const { "data": { filterColumn, defaultInteractionState }, setOutput, formProps, saveInteractionState } = props;
    const [data, setData] = useState(new DataFrame([]));
    const [filter, setFilter] = useState(defaultInteractionState || []);

    const updateOutput = () => {
        let filterFunction;
        if (filter.length > 0) {
            filterFunction = (row) => {
                if (filter[0] === "-") {
                    const actualFilterValue = filter.slice(1);
                    return !row.get(filterColumn).toLowerCase().includes(actualFilterValue.toLowerCase());
                } else {
                    return row.get(filterColumn).toLowerCase().includes(filter.toLowerCase());
                }
            };
            const out = data.copy().filter(filterFunction);
            setOutput(out);
        } else {
            setOutput(data.copy());
        }
    };

    // on filter update, filter all the data
    useEffect(() => {
        if (!data.isEmpty()) {
            updateOutput();
        }
    }, [data]);

    useEffect(() => {
        if (!data.isEmpty()) {
            updateOutput();
        }
    }, [filter]);

    return (
        <Element
            {...props}
            setData={setData}>
            <Input
                transparent
                className="icon"
                label={formProps.labelText}
                fluid
                defaultValue={defaultInteractionState}
                onChange={
                    (e, { value }) => {
                        setFilter(value);
                        if (saveInteractionState !== undefined) {
                            saveInteractionState(value);
                        }
                    }
                }
                {...formProps}
            />
        </Element>
    );
};

export { ElementDataInputFilter as DataInputFilter };
