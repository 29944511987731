import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";
import { uniques } from "../../../UtilityFunctions";
import FormFieldHeader from "./FormFieldHeader";
import TMultiSelect from "../../../components/TMultiSelect";
import TSelect from "../../../components/TSelect";

const ElementSelect = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const [options, setOptions] = useState([]);
    const { setOutput, formProps, "data": dataArgs, saveInteractionState } = props;
    const { defaultInteractionState, title } = dataArgs;
    const [filter, setFilter] = useState(defaultInteractionState);
    const [isOpen, setIsOpen] = useState(false);

    /*
    * This is a drop-down select element.
    *
    * dataArgs:
    *   filterColumn   - column in data from which filter keys and values will be chosen
    *   textColumn     - column in data from which the text displayed in the filter will be chosen. Optional.
    *   defaultInteractionState  - the placeholder value for the selected element. Optional.
    * */

    const updateDataWithSavedAdditions = () => {
        const dataValues = data.getColumn(dataArgs.filterColumn);
        const nonContainedValues = defaultInteractionState.filter((a) => !dataValues.includes(a));
        if (nonContainedValues.length === 0) {
            return;
        }
        // for each value not in filterColumn
        const nonContainedValuesRowList = nonContainedValues.map((value) => new Map([[dataArgs.filterColumn, value], dataArgs.textColumn && [dataArgs.textColumn, value]].filter((e) => e !== undefined)));
        setData(data.extend(nonContainedValuesRowList));
    };

    const getUpdatedOptions = (data) => {
        // if allowAdditions and some filter elements are not in df
        if (formProps.allowAdditions && defaultInteractionState) {
            updateDataWithSavedAdditions();
        }
        let mainOptions;
        if ("textColumn" in dataArgs) {
            mainOptions = data.selectColumns([dataArgs.filterColumn, dataArgs.textColumn]).uniqueRows().map((row) => {
                return {
                    "key": row.get(dataArgs.filterColumn),
                    "text": row.get(dataArgs.textColumn),
                    "value": row.get(dataArgs.filterColumn)
                };
            });
        } else {
            mainOptions = uniques(data.getColumn(dataArgs.filterColumn)).flatMap((v) => v).map((value) => ({
                "key": value, "text": value, value
            }));
        }
        return mainOptions;
        // filter values with too many unique options
    };

    useEffect(() => {
        if (!data.isEmpty()) {
            setOptions(getUpdatedOptions(data));
        }
    }, [data]);

    // on filter update, filter all the data
    useEffect(() => {
        if (filter) {
            if (saveInteractionState !== undefined) {
                saveInteractionState(filter);
            }
        }
    }, [data, filter]);

    useEffect(() => {
        if (!isOpen && filter) {
            if (formProps.multiple) {
                if (filter.length > 0) {
                    setOutput(filter);
                }
            } else {
                setOutput(filter);
            }
        }
    }, [filter, isOpen]);

    return (
        <Element
            {...props}
            setData={setData}>
            <FormFieldHeader>{title}</FormFieldHeader>
            {!formProps.multiple && <TSelect
                defaultValue={defaultInteractionState}
                options={options}
                onChange={(val) => setFilter(val)}
                setIsOpen={setIsOpen}
            />}
            {formProps.multiple && <TMultiSelect
                allowAdditions={formProps.allowAdditions}
                defaultValue={defaultInteractionState}
                options={options}
                onChange={(values) => setFilter(values)}
                setIsOpen={setIsOpen}
            />}
        </Element>
    );
};

export { ElementSelect as Select };
