import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";
import { Segment } from "semantic-ui-react";
import _ from "lodash";
import {
    colorGradient,
    findMatchingStackType,
    limitStringLength,
    remapValues,
    responseColorMap,
    stackTypes,
    sumReduce
} from "../../../UtilityFunctions";
import EchartsChart from "../../echarts/EchartsChart";
import resizeObserver from "../../echarts/resizeObserver";
import { colord, extend } from "colord";
import namesPlugin from "colord/plugins/names";
import mixPlugin from "colord/plugins/mix";

extend([namesPlugin, mixPlugin]);

const reorder = (sortableArray, referenceArray) => {
    return sortableArray.sort((a, b) => referenceArray.findIndex(v => String(a).startsWith(v)) !== -1
        ? referenceArray.findIndex(v => String(a).startsWith(v)) - referenceArray.findIndex(v => String(b).startsWith(v))
        : referenceArray.findIndex(v => String(b).startsWith(v)) !== -1
            ? -1 : String(a).toString().localeCompare(b.toString())
    );
};

const chartDataInitialized = (d) => !d.isEmpty() && d.hasColumn("stack") && d.hasColumn("category");

const ElementChartStackedBar = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const [selectedStacks, setSelectedStacks] = useState(undefined);
    const [options, setOptions] = useState(undefined);

    const {
        remap, categoryThreshold, gradientOptions, defaultOtherOption = "Ei oska öelda", normalize, title, sampleCol,
        reorderStack, reorderCategory, reorderGroup, labelTruncateLength,
        horizontalChart = false, groupCol = undefined,
        categoryColours = undefined
    } = props;

    /*
    * This is a stacked bar chart, where
    *
    * args:
    * title         - title of the chart
    * normalize     -   boolean. If true, sum of a single category is scaled so that the total sums to 100.
    * sampleCol     -   string. If present, summed values from this column over a category are appended to every such category's title.
    * remap         -   map. If present, values are remapped according to this map.
    * reorderStack      - array. If present, stack values are reordered according to this array
    * reorderCategory   - array. If present, category values are reordered according to this array
    * reorderGroup      - array. If present, group values are reordered according to this array
    * horizontalChart - if the chart is displayed horisontally
    * groupCol        - Group stacked bars together by this value
    *
    * Dataframe is expected to have the following columns:
    * stack - categories to stack on. Stacked horizontally.
    * category - different bars. Grouped vertically.
    * value - size of part of the stacked bar
    * */

    useEffect(() => {
        if (chartDataInitialized(data)) {
            // console.log("initializing data with selected stacks", selectedStacks);
            data.setColumn("stack", remapValues(data.getColumn("stack"), remap));
            data.setColumn("category", remapValues(data.getColumn("category"), remap));

            if (categoryThreshold) {
                // filter out categories where the sum of a category is less than the stackThreshold
                const cats = data.uniques("category").sort();
                const catSizeMap = new Map([]);
                cats.forEach((cat) => {
                    catSizeMap.set(cat, data.copy().filter((row) => row.get("category") === cat).sum("value"));
                });
                data.filter((row) => [...catSizeMap.keys()].includes(row.get("category")) && catSizeMap.get(row.get("category")) >= categoryThreshold);
            }

            let responses = data.uniques("stack").sort(); // responses
            let categories = data.uniques("category").sort(); // grouped over
            const chartHeight = 100 + (title ? 40 : 0) + 30 * categories.length;

            if (reorderStack) {
                responses = reorder(responses, reorderStack);
            }
            if (reorderCategory) {
                categories = reorder(categories, reorderCategory);
            }

            const stacks = stackTypes[findMatchingStackType(responses, stackTypes).index] || responses;

            let gradient;
            if (gradientOptions) {
                if (stacks.includes(defaultOtherOption)) {
                    const idx = stacks.indexOf(defaultOtherOption);
                    stacks.splice(idx, 1);
                    gradient = colorGradient(gradientOptions.arr, stacks.length);
                    stacks.splice(idx, 0, defaultOtherOption);
                    gradient.splice(idx, 0, responseColorMap.get(defaultOtherOption) || "#a5a5a5");
                } else {
                    gradient = colorGradient(gradientOptions.arr, stacks.length);
                }
            }

            let groups;
            if (groupCol) {
                groups = data.uniques(groupCol);
                if (reorderGroup) {
                    groups = reorder(groups, reorderGroup);
                }
            }

            const categoryShades = {};
            if (categoryColours) {
                const shadeCount = stacks.length;
                [...Object.entries(categoryColours)].forEach(([category, colour]) => {
                    categoryShades[category] = Array(shadeCount).fill(0).map((_, i) =>
                        colord(colour).mix("white", i / shadeCount).toHex()
                    );
                });
                categoryShades.unknown = Array(shadeCount).fill(0).map((_, i) =>
                    colord("gray").mix("white", i / shadeCount).toHex()
                );
            }

            const categorySums = new Map([...data.copy()
                .bucketize("category")]
                .map(([k, v]) => {
                    return [k, v.filter((row) => selectedStacks === undefined || selectedStacks.includes(row.get("stack"))).sum("value")];
                }));
            let maxValue;
            if (categorySums.size > 0) {
                maxValue = [...categorySums.entries()]
                    .reduce((a, e) => e[1] > a[1] ? e : a)[1];
            } else {
                maxValue = 0;
            }

            console.log("stacks", stacks);

            const constructSeries = (name, stack, stackData, idx, shadeStartIndex) => ({
                "name": name,
                "type": "bar",
                "stack": stack,
                "label": {
                    "show": true,
                    "position": horizontalChart ? "inside" : "insideRight",
                    "opacity": 0.75,
                    "formatter": function(data) {
                        // filter out labels that are wider than bars themselves
                        if (!horizontalChart) {
                            return data.data > maxValue * 0.04 ? _.round(data.data, 1) : "";
                        } else {
                            return _.round(data.data, 1);
                        }
                    }
                },
                "itemStyle": {
                    color: categoryColours !== undefined ? function(param) {
                        return categoryShades?.[param.name]?.[parseInt(idx) + shadeStartIndex] ||
                            categoryShades.unknown[parseInt(idx) + shadeStartIndex];
                    } : responseColorMap.get(stack) || (gradient?.[parseInt(idx)])
                },
                "colorBy": "data",
                "data": categories.map((category) => {
                    // category is the horizontal group
                    let rowValue;
                    try {
                        rowValue = stackData.copy().filter((row) => row.get("category") === category).getRow(0).get("value");
                        return 100.0 * rowValue / (normalize && categorySums.has(category) ? categorySums.get(category) : 100.0) || null;
                    } catch (e) {
                        return null;
                    }
                })
            });

            const series = stacks.map((stack, idx) => {
                const stackData = data.copy().filter((row) => row.get("stack") === stack);

                if (groupCol) {
                    return groups.map((group) => {
                        const groupData = stackData.copy().filter((row) => row.get(groupCol) === group);
                        return constructSeries(
                            stack, group, groupData,
                            idx, 0); // (groups.length - j - 1) * 2
                    });
                } else {
                    return [constructSeries(
                        stack, "stack", stackData,
                        idx, 0)];
                }
            }).flat();

            if (groupCol) {
                const adds = groups.map((group, j) => {
                    let groupData = data.copy().filter((row) => row.get(groupCol) === group);

                    groupData = _.chain(groupData.data)
                        .groupBy(function(b) {
                            return b.get("category") + b.get("time");
                        })
                        .map(group => group.reduce(
                            (p, c) => {
                                p.set("value", p.get("value") + c.get("value"));
                                return p;
                            })
                        )
                        .value();
                    groupData = new DataFrame(groupData);

                    let sTotal = constructSeries(
                        "total", group, groupData,
                        j, 0);
                    sTotal = Object.assign(sTotal, {
                        label: {
                            show: true,
                            position: "top",
                            formatter: param => param.data.displayValue
                        },
                        itemStyle: {
                            barWidth: 0
                        },
                        data: sTotal.data.map((v) => {
                            return {
                                "value": 0,
                                "displayValue": v || 0
                            };
                        })
                    });
                    return sTotal;
                });
                series.push(...adds);
            }

            const option = {
                chartHeight,
                "tooltip": {
                    "trigger": "axis",
                    "axisPointer": {
                        "type": "shadow"
                    },
                    "extraCssText": "max-width: 400px; white-space:pre-wrap;",
                    "formatter": (params) => {
                        const categoryName = params[0].axisValueLabel;
                        return `<table>
                                    <tr><th>${categoryName}</th></tr>` + params.map((p) => {
                            return `<tr><td>${p.marker}${p.seriesName}:</td><td>${_.round(p.data, 1)}</td></tr>`;
                        }).join("\n") + `
                                </table>`;
                    }
                },
                "brush": {
                    "toolbox": ["download"],
                    "xAxisIndex": 0
                },
                "title": {
                    "text": title,
                    "left": "center",
                    "textStyle": { "fontFamily": "Oswald", "fontWeight": 400 }
                },
                "legend": {
                    "bottom": "10px"
                },
                "grid": {
                    "top": title ? "25px" : undefined,
                    "left": "3%",
                    "right": "4%",
                    "containLabel": true
                },
                "toolbox": {
                    "feature": {
                        "saveAsImage": {}
                    }
                },
                [horizontalChart ? "yAxis" : "xAxis"]: {
                    "type": "value",
                    "max": v => Math.round(v.max * 1.1)
                },
                [horizontalChart ? "xAxis" : "yAxis"]: {
                    "type": "category",
                    "data": sampleCol && data.hasColumn(sampleCol)
                        ? categories
                            .map((cat) => `${cat} (${data.copy()
                                .filter((row) => row.get("category") === cat)
                                .getColumn(sampleCol)
                                .reduce(sumReduce)})`)
                        : categories,
                    "axisLabel": {
                        "formatter": (label) => {
                            const maxLen = labelTruncateLength || 24;
                            if (sampleCol && label.includes("(")) {
                                const parts = label.split(" ");
                                return `${limitStringLength(parts.slice(0, parts.length - 1).join(" "), maxLen)} ${parts[parts.length - 1]}`;
                            } else {
                                return limitStringLength(label, maxLen);
                            }
                        }
                    }
                },
                series,
                "fullSeries": series
            };
            setOptions(option);
        }
    }, [data]);

    if (chartDataInitialized(data) && options) {
        const chartHeight = options.chartHeight;
        const onEvents = {
            "brushSelected": (params, eventChart) => {
                const newSelected = params.batch[0].selected.map((e) => e.seriesName);
                if (!(_.isEqual(newSelected, selectedStacks))) {
                    const fullSeries = eventChart.getOption().fullSeries;
                    if (newSelected.length === fullSeries.length) {
                        eventChart.setOption({ series: fullSeries });
                    } else {
                        let series = _.cloneDeep(fullSeries);
                        if (normalize) {
                            const categoryLength = series[0].data.length;
                            const categoryResizeCoefs = new Array(categoryLength)
                                .fill(0.0)
                                .map((c, catIdx) => 100.0 / series.reduce((acc, s) => acc + (newSelected.includes(s.name) ? s.data[catIdx] : 0.0), 0.0));
                            series = series.map((s) => {
                                s.data = s.data.map((n, idx) => n * categoryResizeCoefs[parseInt(idx)]);
                                return s;
                            });
                        }
                        eventChart.setOption({ series });
                    }
                    setSelectedStacks(selectedStacks);
                }
            }
        };

        const chart = (<EchartsChart
            style={{ "width": "100%", "height": `${chartHeight}px` }}
            onEvents={onEvents}
            resizeObserver={resizeObserver}
            option={options}/>);

        return (
            <Element
                {...props}
                width="100%"
                height={`${chartHeight}px`}
                primary
                setData={setData}>
                {chart}
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>
        );
    }
};

export { ElementChartStackedBar as ChartStackedBar };
