import env from "@beam-australia/react-env";
import { streamEventTypes } from "../stateConstants";

class StreamMessage {
    constructor(streamMessage) {
        this.message = JSON.parse(new TextDecoder("utf-8").decode(streamMessage.data));
        this.event = this.message.event;
        this.data = this.message.data;
        this.isEmptyFinished = (this.data === null && this.event === streamEventTypes.finished);
        this.isCancelling = (this.event === streamEventTypes.cancelling);
        this.hasData = (this.data !== null);
        this.isValid = Boolean(!this.isEmptyFinished & !this.isCancelling);
    }

    getErrorMessage() {
        if (this.isEmptyFinished) {
            return "Arvutuskeskus tagastas tühja vastuse. Selliste parameetritega andmeid ei leitud.";
        } else if (this.isCancelling) {
            return "Andmevoog tühistatud";
        } else {
            return null;
        }
    }
}

class StreamHandler {
    constructor(elementId, subscriptionHandler, cleanupFun) {
        this.elementId = elementId;
        this.subscriptionHandler = subscriptionHandler;
        this.timer = null;
        this.accepting = true;
        this.subscription = null;
        this.streamMessageTimeout = parseInt(env("RSOCKET_STREAM_MESSAGE_TIMEOUT_MS"), 10);
        this.defaultRequest = parseInt(env("RSOCKET_STREAM_DEFAULT_REQUEST"), 10);
        this.requestsLeft = 0;
        this.timedOut = () => {
            this.timer = null;
            this.accepting = false;
            this.subscriptionHandler.error(this.elementId, new Error("Data stream timed out!"));
        };
        this.cleanupFun = cleanupFun;

        this.handleStreamMessageByValidity = {
            [true]: this.handleValidMessage,
            [false]: this.handleInvalidMessage
        };
    }

    resetStreamTimerAccepting = () => {
        clearTimeout(this.timer);
        this.timer = null;
        this.accepting = false;
    }

    cancelSubscription = () => {
        if (this.subscription !== null) {
            this.subscription.cancel();
        }
    }

    cancelStream = () => {
        if (this.accepting) {
            this.resetStreamTimerAccepting();
            this.cancelSubscription();
        }
        this.cleanupFun();
    };

    handleStreamComplete = () => {
        if (this.accepting) {
            this.resetStreamTimerAccepting();
            this.subscriptionHandler.closeStream(this.elementId);
        }
        this.cleanupFun();
    };

    handleStreamError = (error) => {
        if (this.accepting) {
            this.subscriptionHandler.error(this.elementId, error);
        }
        this.cleanupFun();
    };

    updateRequests = () => {
        this.requestsLeft -= 1;
        if (this.requestsLeft <= 0) {
            this.subscription.request(this.defaultRequest);
            this.requestsLeft = this.defaultRequest;
        }
    }

    updateTimer = () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.timedOut, this.streamMessageTimeout);
    }

    handleInvalidMessage = (streamMessage) => {
        this.resetStreamTimerAccepting();
        this.subscriptionHandler.error(this.elementId, new Error(streamMessage.getErrorMessage()));
        this.cleanupFun();
    }

    handleValidMessage = (streamMessage) => {
        this.updateRequests();
        if (streamMessage.hasData) { this.updateTimer(); }
        this.subscriptionHandler.propagateStreamMessageToSource(this.elementId, streamMessage);
    }

    handleStreamMessage = (streamMessage) => {
        if (!this.accepting) return;
        streamMessage = new StreamMessage(streamMessage);
        this.handleStreamMessageByValidity[streamMessage.isValid](streamMessage);
    };

    handleSubscribed = (subscription) => {
        if (this.accepting) {
            this.subscription = subscription;
            this.updateRequests();
            this.subscriptionHandler.openStream(this.elementId);
        } else {
            subscription.cancel();
        }
    }
}

export default StreamHandler;
export { StreamMessage };
