import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import * as echarts from "echarts";
import { DataFrame } from "../../DSL/DataFrame";
import EchartsChart from "../../echarts/EchartsChart";
import resizeObserver from "../../echarts/resizeObserver";
import { colord, extend } from "colord";
import namesPlugin from "colord/plugins/names";
import mixPlugin from "colord/plugins/mix";
import _ from "lodash";

extend([namesPlugin, mixPlugin]);

const ElementChartMap = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const [mapData, setMapData] = useState(null);
    const { "data": dataArgs, map, title } = props;
    const typeConfig = dataArgs?.typeConfig;

    /*
    * args:
    * map - map name
    * typeConfig - how and what values to display
    *
    * data args:
    * regionColumn -
    * valueColumn -
    * */

    useEffect(() => {
        if (map !== undefined) {
            const basepath = window.location.protocol + "//" + window.location.host + "/";

            fetch(`${basepath}/media/geojson/${map}.geojson`)
                .then((r) => r.json())
                .then((r) => {
                    echarts.registerMap(map, r);
                    setMapData((_) => setMapData(r));
                })
                .catch((err) => {
                    throw err;
                });
        }
    }, []);

    if (mapData !== null && !data.isEmpty()) {
        let seriesData;
        let visualMap;
        if (typeConfig) {
            if (typeConfig.type === "value") {
                const seriesDF = data.copy().notNullRows()
                    .selectColumns([dataArgs.regionColumn, typeConfig.valueColumn])
                    .renameColumn(dataArgs.regionColumn, "name")
                    .renameColumn(typeConfig.valueColumn, "value");
                seriesData = seriesDF.rows().map((row) => (Object.fromEntries(row.entries())));
                visualMap = {
                    "left": "right",
                    "min": Math.min(...seriesDF.getColumn("value")),
                    "max": Math.max(...seriesDF.getColumn("value")),
                    "calculable": true,
                    "inRange": {
                        "color": typeConfig.colourGradient
                    }
                };
            } else if (typeConfig.type === "category") {
                const seriesDF = data.copy()
                    .renameColumn(dataArgs.regionColumn, "name");
                const catColumns = seriesDF.getColumns().filter(c => c.startsWith(typeConfig.categoryPrefix));
                seriesData = seriesDF.rows().map((row) => {
                    let maxCat = catColumns[0];
                    let maxValue = _.min(catColumns.map(col => row.get(col)));
                    const secondMaxValue = catColumns
                        .map(col => row.get(col))
                        .sort((a, b) => b - a)[1];

                    catColumns.forEach(cat => {
                        if (row.get(cat) > maxValue) {
                            maxCat = cat;
                            maxValue = row.get(cat);
                        }
                    });
                    maxCat = maxCat.replace(typeConfig.categoryPrefix, "");
                    const color = colord(typeConfig.colorMap[maxCat] || "gray")
                        .lighten(secondMaxValue / maxValue / 2.0);
                    return {
                        name: row.get("name"),
                        value: maxValue,
                        itemStyle: {
                            areaColor: color.toHex()
                        },
                        label: {
                            formatter: `${maxValue}\n(+${_.round(maxValue - secondMaxValue, 1)})`
                        },
                        tooltip: {
                            formatter: (data) => {
                                return `
                                <table>
                                <tr><th/><th>${data.name}</th></tr>` +
                                    catColumns.map((col) => {
                                        const cat = col.replace(typeConfig.categoryPrefix, "");
                                        return `<tr>
                                            <td><div style="display: block; border-radius: 6px; 
                                                background: ${typeConfig.colorMap[cat]}; height: 12px; width: 12px;"/></td>
                                            <td>${cat} </td>
                                            <td>${_.round(row.get(col), 1)}</td>
                                        </tr>`;
                                    }).join("\n") +
                                    "</table>";
                            }
                        }
                    };
                });
            } else {
                throw new Error("Unspecified type for chart map");
            }
        }

        const chartOptions = {
            "title": {
                "text": title,
                "textStyle": { "fontFamily": "Oswald", "fontWeight": 400 }
            },
            "tooltip": {
                "trigger": "item",
                "showDelay": 0,
                "transitionDuration": 0.2
            },
            visualMap,
            "toolbox": {
                "show": true,
                // orient: "vertical",
                "left": "right",
                "top": "top",
                "feature": {
                    "dataView": {
                        "readOnly": false,
                        "title": "Andmevaade",
                        "lang": ["Andmevaade", "Lülita välja", "Värskenda"]
                    },
                    "restore": { "title": "Taasta" },
                    "saveAsImage": { "title": "Salvesta" }
                }
            },
            "series": [
                {
                    "name": map,
                    "colorBy": "data",
                    "type": "map",
                    "roam": false,
                    "map": map,
                    "zoom": 1.1,
                    "label": {
                        "show": true,
                        "formatter": (params) => {
                            // console.log(params);
                            if (params.formattedValue) {
                                return params.formattedValue;
                            } else if (params.value) {
                                return params.value;
                            } else {
                                return "";
                            }
                        },
                        "textBorderColor": "white",
                        "textBorderWidth": 3
                    },
                    "emphasis": {
                        "label": {
                            "show": true
                        }
                    },
                    "data": seriesData
                }
            ]
        };

        return (
            <Element
                {...props}
                primary
                setData={setData}
                width="100%"
            >
                <div
                    style={{
                        "width": "100%",
                        "height": "300px"
                    }}
                >
                    <EchartsChart
                        option={chartOptions}
                        resizeObserver={resizeObserver}
                    />
                </div>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>);
    }
};

export { ElementChartMap as ChartMap };
