import React from "react";
import * as IconsSolid from "@heroicons/react/24/solid";
import * as IconsOutline from "@heroicons/react/24/outline";
import _ from "lodash";

export default ({ name, outline, color, size, ...props }) => {
    const nameCamelCase = _.startCase(_.camelCase(name));
    const iconName = `${nameCamelCase}Icon`.replaceAll(" ", "");

    let E;
    if (outline) {
        E = IconsOutline[iconName];
    } else {
        E = IconsSolid[iconName];
    }
    if (E === undefined) {
        console.log(`ERROR: Could not find icon ${iconName}`);
        E = IconsSolid.XMarkIcon;
    }
    // eslint-disable-next-line eqeqeq
    if (size == undefined) {
        size = 4;
    }

    return <E
        {...props}
        className={
            props.className
                ? ` w-${size} h-${size} ${props.className}`
                : ` w-${size} h-${size}`
        }
        style={{
            ...(props.style || {}),
            color
        }}
    ></E>;
};
