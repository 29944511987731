import Cookies from "js-cookie";
import env from "@beam-australia/react-env";
import { debugCurrentPartyGet, debugElevatedAccessGet, debugModeEnabled } from "../debug/debugConfig";
import React from "react";

/**
 * Turn a jwt token to json.
 * @param token
 */
const parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));

    return JSON.parse(jsonPayload);
};
const getIdToken = () => Cookies.get("idToken");
const getAccessToken = () => Cookies.get("accessToken");
const cookiePrefix = "userProperty";
const onGetPropertyError = (e) => {
    console.log(`User property error: \n${e}`);
};


class User {
    allowedProperties = ["email", "cognito:groups", "cognito:username"];
    constructor() {
        this.logoutWarnTimeLeft = null;
    }

    detectNewSession() {
        if (window.location.toString().includes("#id_token")) {
            console.log("Detected new session. Storing it!");
            const urlParams = new URLSearchParams(window.location.toString().split("#")[1]);
            if (urlParams.get("token_type") === "Bearer") {
                Cookies.set("idToken", urlParams.get("id_token"));
                Cookies.set("accessToken", urlParams.get("access_token"));
                //to debug with 1 minute use: const authExpireTime = (new Date().getTime() / 1000) + 60;
                const authExpireTime = (new Date().getTime() / 1000) + parseInt(urlParams.get("expires_in"), 10);
                Cookies.set("authExpireTime", authExpireTime);
                Cookies.set("logoutWarnClicked", 0);
                this.registerNewIdToken(parseJwt(getIdToken()));
                // to avoid double-calls
                window.history.replaceState({}, document.title, window.location.toString().split("#")[0]);
            } else {
                throw new Error(`Unknown OAuth 2.0 token_type: ${urlParams.get("token_type")}`);
            }
        }
        this.setLogoutWarnTimeLeft();
    }

    setLogoutWarnTimeLeft = () => {
        const authExpireTime = Cookies.get("authExpireTime");
        this.logoutWarnTimeLeft = (authExpireTime-300)*1000 - new Date().getTime()
    };

    setLogoutWarnClicked = () => {
        Cookies.set("logoutWarnClicked", 1);
    };

    getLogoutWarnTimeLeft = () => {
        return this.logoutWarnTimeLeft;
    };

    getLogoutWarnClicked = () => {
        return Cookies.get("logoutWarnClicked") === "1";
    };

    getLogoutTimeLeft = () => {
        const authExpireTime = Cookies.get("authExpireTime");
        return (authExpireTime*1000) - new Date().getTime();
    };

    authExpired = () => {
        return Cookies.get("authExpireTime") <= new Date().getTime() / 1000;
    };

    isLoggedIn = () => {
        this.detectNewSession();
        return !!getIdToken() && !this.authExpired();
    };

    registerNewIdToken(idTokenObj) {
        console.log("Registering new id token");
        for (const key in idTokenObj) {
            if (Object.prototype.hasOwnProperty.call(idTokenObj, key) && this.allowedProperties.includes(key)) {
                Cookies.set(`${cookiePrefix}_${key}`, typeof idTokenObj[key] === "string" ? idTokenObj[key] : JSON.stringify(idTokenObj[key]));
            }
        }
    }

    getEmail(errorCallback = onGetPropertyError) {
        if (this.isLoggedIn()) {
            try {
                return Cookies.get(`${cookiePrefix}_email`);
            } catch (error) {
                errorCallback(`Can't parse or get user property email. Check cookie ${cookiePrefix}_email`);
            }
        } else {
            console.log("Can't get user property userGroups when the user is not logged in!");
        }
    }

    getUserGroups(errorCallback = onGetPropertyError) {
        if (this.isLoggedIn()) {
            try {
                return JSON.parse(Cookies.get(`${cookiePrefix}_cognito:groups`));
            } catch (error) {
                errorCallback(`Can't parse or get user property userGroups. Check cookie ${cookiePrefix}_cognito:groups`);
            }
        } else {
            console.log("Can't get user property userGroups when the user is not logged in!");
        }
    };

    getUsername(errorCallback = onGetPropertyError) {
        if (this.isLoggedIn()) {
            return Cookies.get(`${cookiePrefix}_cognito:username`);
        }
        errorCallback("Can't get user property username when the user is not logged in!");
    }

    getCognitoAccessToken = (callback, errorCallback = onGetPropertyError) => {
        if (this.isLoggedIn()) {
            callback(getAccessToken());
        } else {
            errorCallback("Can't get access to accessToken when the user is not logged in!");
        }
    };

    getCognitoIdToken = (callback, errorCallback = onGetPropertyError) => {
        if (this.isLoggedIn()) {
            callback(getIdToken());
        } else {
            errorCallback("Can't get access to idToken when the user is not logged in!");
        }
    };

    login = () => {
        const r = Math.random().toString(36).substring(7);
        const authenticationUrl = "https://login.kuk.ee/login?" +
            `client_id=${env("COGNITO_CLIENT_ID")}&` +
            "response_type=token&" +
            "scope=openid&" +
            `state=${r}&` +
            `redirect_uri=${window.location.origin}/`;
        console.log(`redirecting to login: ${authenticationUrl}`);
        window.location.assign(authenticationUrl);
    };

    clearUserCookies = () => {
        Cookies.remove("idToken");
        Cookies.remove("accessToken");
        Cookies.remove("authExpireTime");
        Cookies.remove("logoutWarnClicked");
    };

    logoutRedirect = () => {
        const logoutUrl = "https://login.kuk.ee/logout?" +
            `client_id=${env("COGNITO_CLIENT_ID")}&` +
            `redirect_uri=${window.location.origin}/&` +
            "response_type=token&" +
            `logout_uri=${window.location.origin}/`;

        window.location.assign(logoutUrl);
    };

    logout = () => {
        this.clearUserCookies();
        this.logoutRedirect();
    };

    hasElevatedAccess = (ignoreOverrides = false) => {
        if (!ignoreOverrides && debugModeEnabled()) {
            if (debugElevatedAccessGet() !== undefined) {
                return debugElevatedAccessGet();
            }
        }
        const ug = this.getUserGroups();
        // TODO: logout should be handled more prettily, maybe with a modal + "extend session" btn
        return ug ? (ug.includes("admin") || ug.includes("dev")) : false;
    }

    getParty = (ignoreOverrides = false) => {
        if (debugModeEnabled() && !ignoreOverrides) {
            if (debugCurrentPartyGet() !== undefined) {
                return debugCurrentPartyGet();
            }
        }

        const currentUserGroups = this.getUserGroups();
        if (currentUserGroups.includes("ekre")) {
            return "EKRE";
        }
        if (currentUserGroups.includes("isa")) {
            return "ISA";
        }
        if (currentUserGroups.includes("e200")) {
            return "E200";
        }
    };
}

export { User };
export const user = new User();

export const UserContext = React.createContext(null);
