import React, { useState } from "react";
import ReactJson from "react-json-view";
import { Button, Modal } from "react-daisyui";
import { CommandLineIcon } from "@heroicons/react/24/solid";

export const DataDefinitionModal = ({ dataDefinition, setKeepOpen }) => {
    const [toggled, setToggled] = useState(false);

    const toggle = (val) => {
        setKeepOpen(val);
        setToggled(val);
    };

    return (
        <>
            <Button className="btn-ghost btn-sm" onClick={() => toggle(true)}>
                <CommandLineIcon className="w-auto h-full m-auto p-0"/>
            </Button>
            <Modal
                open={toggled}
                onClose={() => toggle(false)}
                onOpen={() => toggle(true)}
            >
                <div className="bg-base-100 rounded-box p-8 max-w-6xl flex flex-column items-stretch"
                    style={{
                        maxHeight: "80vh",
                        flexDirection: "column",
                        minWidth: "60vw"
                    }}
                >
                    <Modal.Header className="text-2xl font-bold mb-4">
                        Agregatsioon
                    </Modal.Header>
                    <Modal.Body
                        className="overflow-auto"
                        style={{
                            minHeight: "60vh"
                        }}
                    >
                        <ReactJson
                            src={dataDefinition}
                            indentWidth={3}
                            enableClipboard={false}
                            displayDataTypes={false}
                            quotesOnKeys={false}
                            theme={"ocean"}
                        />
                    </Modal.Body>
                    <Modal.Actions>
                        <Button className="bg-primary" onClick={() => toggle(false)}>
                            Sulge
                        </Button>
                    </Modal.Actions>
                </div>
            </Modal>
        </>
    );
};
