export default [
    {
        "title": "Töölaud: Teine eelistus",
        "date": "2022-12-19",
        "file": "/releases/2022-12-19.md"
    },
    {
        "title": "Teekaardid ja vaba riigikogu simulatsioon",
        "date": "2022-11-06",
        "file": "/releases/2022-11-06.md"
    },
    {
        "title": "Kiiruuringud",
        "date": "2022-10-28",
        "file": "/releases/2022-10-28-2.md"
    },
    {
        "title": "Tuntuse oktoobri laine",
        "date": "2022-10-28",
        "file": "/releases/2022-10-28.md"
    },
    {
        "title": "Poliitteemade oktoobri laine",
        "date": "2022-10-26",
        "file": "/releases/2022-10-26.md"
    },
    {
        "title": "Poliitteemade parandused",
        "date": "2022-09-22",
        "file": "/releases/2022-09-22.md"
    },
    {
        "title": "Poliitikud: ringkondlikud nimed",
        "date": "2022-09-12",
        "file": "/releases/2022-09-12.md"
    },
    {
        "title": "Reitingute ja tuntuse andmetorustik",
        "date": "2022-09-03",
        "file": "/releases/2022-09-03.md"
    },
    {
        "title": "Eksperimentaalne töölaud: poliitteemad ajas",
        "date": "2022-08-30",
        "file": "/releases/2022-08-30.md"
    },
    {
        "title": "Poliitteemade töölaudadade uuendused",
        "date": "2022-08-29",
        "file": "/releases/2022-08-29.md"
    },
    {
        "title": "Töölaua 'Häälte otseliikumised' ümberkujundus ja uued funktsionaalsused",
        "date": "2022-08-28",
        "file": "/releases/2022-08-28.md"
    },
    {
        "title": "Uued kvartaalsed reitingud",
        "date": "2022-08-12",
        "file": "/releases/2022-08-11-2.md"
    },
    {
        "title": "Kujunduse suurpuhastus",
        "date": "2022-08-12",
        "file": "/releases/2022-08-11.md"
    }
];
