import React, { useState } from "react";
import { Element } from "../Element";
import ReactEcharts from "echarts-for-react";
import { DataFrame } from "../../DSL/DataFrame";
import distinctColors from "distinct-colors";
import { uniques } from "../../../UtilityFunctions";
import { colord } from "colord";

const ElementChartOverlayBoxPlot = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const { barHeight = 35, title, options = {}, "data": dataArgs } = props;

    // plots multiple seethrough boxplot graphs on top of each other
    // in case we are receiving properly formatted data
    // where there are columns present:
    // axis - values that will each get their own boxplot row
    // category - which category it belongs in, categories are for overlaying
    // lower - 1.5 IQR
    // Q1 - 25th percentile
    // median
    // Q3 - 75th percentile
    // upper - upper 1.5 IQR

    if (data.getLength() > 0) {
        const categoriesData = [];

        const categoryValues = uniques(data.getColumn("category")).filter((val) => val !== undefined);
        const axisValues = uniques(data.getColumn("axis"));
        let minValue = Number.MAX_VALUE;
        let maxValue = Number.MIN_VALUE;

        const palette = distinctColors({ "count": categoryValues.length });

        categoryValues.forEach((category) => {
            const categoryDF = data.copy();
            categoryDF.filter((row) => row.get("category") === category);
            const subAxisValues = categoryDF.getColumn("axis");
            const mask = axisValues.map((item, _) => subAxisValues.indexOf(item));
            const boxData = mask.map((i) => {
                let row;
                if (i !== -1) {
                    row = categoryDF.getRow(i);
                }
                if (row !== undefined) {
                    const up = parseFloat(row.get("upper"));
                    const low = parseFloat(row.get("lower"));
                    minValue = !Number.isNaN(low) ? Math.min(minValue, low) : minValue;
                    maxValue = !Number.isNaN(up) ? Math.max(maxValue, up) : maxValue;
                    return [
                        row.get("lower") || row.get("Q1"),
                        row.get("Q1"),
                        row.get("median"),
                        row.get("Q3"),
                        row.get("upper") || row.get("Q3")
                    ];
                } else {
                    return [0, 0, 0, 0, 0];
                }
            });
            const boxPlotData = {
                "axisData": axisValues,
                boxData,
                "outliers": []
            };
            categoriesData.push(boxPlotData);
        });

        const gridTop = 14;
        const gridBottom = 0;
        let optimalHeight = axisValues.length * (barHeight * 1.05) + barHeight;
        optimalHeight *= (1 + (gridTop + gridBottom) / 100);

        const horisontalLabelsWidth = Math.max(...categoriesData[0].axisData.map(s => s.length)) * 6.5;

        const chartOptions = {
            "title": {
                "text": title,
                "top": "0%",
                "itemGap": 0,
                "z": 200,
                "textStyle": { "fontFamily": "Oswald", "fontWeight": 400 }
            },
            "tooltip": {
                "trigger": "axis"
            },
            "legend": {
                "top": "7%"
            },
            "grid": {
                "left": `${horisontalLabelsWidth}px`,
                "right": "0%",
                "bottom": `${gridBottom}`,
                "top": `${gridTop}%`,
                "containLabel": false
            },
            "toolbox": {
                "feature": {
                    "saveAsImage": {}
                }
            },
            "xAxis": {
                "type": "value",
                "splitArea": {
                    "show": false
                },
                "min": "dataMin",
                "max": "dataMax"
            },
            "yAxis": categoriesData.map((item, i) => (
                {
                    "type": "category",
                    "data": axisValues,
                    "boundaryGap": true,
                    "nameGap": 0,
                    "show": (i === 0),
                    "position": "left",
                    "splitArea": {
                        "show": false
                    },
                    "splitLine": {
                        "show": false
                    }
                })
            ),
            "series": categoriesData.flatMap((item, i) => {
                let color = palette[parseInt(i, 10)].rgb();
                const name = categoryValues[i];
                if (dataArgs.colorMap && name in dataArgs.colorMap) {
                    color = colord(dataArgs.colorMap[name]).toRgb();
                    color = [color.r, color.g, color.b];
                }
                const renderBoxData = categoriesData[parseInt(i, 10)].boxData.map(box => {
                    if (box[1] === box[3]) {
                        // if no box is shown, make box wider
                        box[1] = Math.max(minValue, box[1] - 0.1);
                        box[3] = Math.min(maxValue, box[3] + 0.1);
                    }
                    return box;
                });
                return [
                    {
                        "name": name,
                        "type": "boxplot",
                        "yAxisIndex": i,
                        "data": renderBoxData,
                        "boxWidth": [
                            barHeight * 0.5,
                            barHeight - barHeight * 0.7 * (i / categoriesData.length)
                        ],
                        "itemStyle": {
                            "color": `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${0.4 + i / categoriesData.length * 0.3})`,
                            "borderColor": `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.3)`,
                            "borderWidth": (categoriesData.length <= 2) ? 2 : 0
                        },
                        "tooltip": {
                            formatter(param) {
                                return [
                                    `Experiment ${param.name}: `,
                                    `upper: ${param.data[5]}`,
                                    `Q3: ${param.data[4]}`,
                                    `median: ${param.data[3]}`,
                                    `Q1: ${param.data[2]}`,
                                    `lower: ${param.data[1]}`
                                ].join("<br/>");
                            }
                        }
                    }
                ];
            }),
            ...options
        };

        return (
            <Element
                width="100%"
                {...props}
                primary
                setData={setData}>
                {<ReactEcharts
                    style={{
                        "width": "100%",
                        "height": `${optimalHeight}px`
                    }}
                    notMerge={true}
                    theme="shine"
                    lazyUpdate
                    option={chartOptions}/>}
            </Element>
        );
    } else {
        return (
            <Element
                width="100%"
                {...props}
                primary
                setData={setData}>
            </Element>
        );
    }
};

export { ElementChartOverlayBoxPlot as ChartOverlayBoxPlot };
