import React, { useState } from "react";
import { Button, Pagination } from "react-daisyui";

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
}

export default ({
    defaultActivePage,
    ellipsisItem,
    totalPages,
    onPageChange,
    ...otherProps
}) => {
    const [activePage, setActivePage] = useState(defaultActivePage);

    const setPage = (i) => {
        onPageChange(i);
        setActivePage(i);
    };
    let start;
    if (activePage >= 4) {
        start = <>
            <Button
                key="first"
                className="align-middle"
                onClick={() => setPage(0)}
            >
                <span>1</span>
            </Button>
            <Button
                key="first-ellipsis"
                className="align-middle"
            >
                <span>{ellipsisItem?.content}</span>
            </Button>
        </>;
    }
    let end;
    if (totalPages - 1 - activePage >= 3) {
        end = <>
            <Button
                key="last-ellipsis"
                className="align-middle"
            >
                {ellipsisItem?.content}
            </Button>
            <Button
                key="last"
                className="align-middle"
                onClick={() => setPage(totalPages - 1)}
            >
                {totalPages}
            </Button>
        </>;
    }
    const mid = range(5, activePage - 2)
        .filter(i => i >= 0 && i < totalPages)
        .map(i =>
            <Button
                key={i}
                onClick={() => setPage(i)}
                className="align-middle"
                active={activePage === i}
            >
                {i + 1}
            </Button>
        );
    const pages = <>{start}{mid}{end}</>;
    return (
        <Pagination
            className="inline-block"
            {...otherProps}
        >
            {pages}
        </Pagination>
    );
};
