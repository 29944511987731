import React, { useState, useCallback } from "react";
import { user } from "../auth/User";
import {
    debugCurrentPartyGet,
    debugCurrentPartySet,
    debugElevatedAccessGet,
    debugElevatedAccessSet,
    debugModeEnabled,
    debugModeEnabledSet
} from "./debugConfig";

const parties = [
    "EKRE", "ISA", "E200", "REF", "KESK", "SDE", "puudub"
];

export const DebugContext = React.createContext({
    isEnabled: () => {},
    // eslint-disable-next-line no-unused-vars
    setEnabled: (bool) => {}
});

export const DebugModeProvider = ({ children }) => {
    if (!user.isLoggedIn()) {
        return <>{children}</>;
    }
    const [state, setState] = useState({
        debugMode: debugModeEnabled(),
        elevatedAccess: debugElevatedAccessGet(),
        currentParty: debugCurrentPartyGet()
    });

    const contextValue = {
        isEnabled: useCallback(() => debugModeEnabled(), []),
        setEnabled: useCallback((bool) => {
            console.log("debug to", bool);
            const enabled = bool;
            setState(prev => ({
                ...prev,
                debugMode: enabled
            }));
            debugModeEnabledSet(enabled);
        })
    };

    const FormFields = () => <React.Fragment>
        <div className="form-control flex-row flex">
            <label className="flex flex-row items-center whitespace-nowrap mr-4">
                <span className="text-base-content mr-2">Elevated access</span>
                <input
                    type="checkbox"
                    className="toggle"
                    style={{
                        borderRadius: 0
                    }}
                    onChange={() => {
                        debugElevatedAccessSet(!debugElevatedAccessGet());
                        window.location.reload();
                    } }
                    checked={debugElevatedAccessGet()}
                />
            </label>
            <label className="flex flex-row items-center whitespace-nowrap">
                <span className="text-base-content">Party</span>
                <div className="dropdown dropdown-end dropdown-top">
                    <label tabIndex="0" className="btn btn-neutral btn-sm m-1">{user.getParty()}</label>
                    <ul tabIndex="0" className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                        {parties.map(p => <li
                            key={p}
                            className="text-base-content"
                            onClick={() => {
                                debugCurrentPartySet(p);
                                window.location.reload();
                            }}
                        >
                            <a>{p}</a>
                        </li>)}
                    </ul>
                </div>
            </label>
        </div>
    </React.Fragment>;

    return (
        <DebugContext.Provider value={contextValue}>
            {children}
            {state.debugMode && user.hasElevatedAccess(true) && <div
                className="fixed bg-base-300 bottom-0 right-0 rounded-box p-4"
                style={{
                    zIndex: 50,
                    marginRight: "-1rem",
                    marginBottom: "-1rem",
                    paddingRight: "2rem",
                    paddingBottom: "2rem"
                }}
            >
                <FormFields/>
            </div>}
        </DebugContext.Provider>
    );
};
