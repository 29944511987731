export default { // module.exports
    ...require("./Element"),
    ...require("./elementTable/ElementTable"),
    ...require("./ElementMousePopup"),
    ...require("./ElementData"),
    ...require("./ElementDummy"),
    ...require("./special/ElementResponderOverviewRow"),
    ...require("./ElementIndex"),
    ...require("./ElementDataChildren"),

    ...require("./dataManipulation/ElementDataframeConcatenator"),
    ...require("./dataManipulation/ElementDataframeFilter"),
    ...require("./dataManipulation/ElementDataTransformer"),

    ...require("./composition/card/ElementCard"),
    ...require("./composition/card/ElementCardContent"),
    ...require("./composition/card/ElementCardPopupRow"),
    ...require("./composition/card/ElementCardPopupBar"),
    ...require("./composition/ElementGrid"),
    ...require("./composition/ElementGridColumn"),
    ...require("./composition/ElementGridRow"),
    ...require("./composition/ElementClosableCarousel"),
    ...require("./composition/ElementSection"),
    ...require("./composition/ElementTab"),
    ...require("./composition/ElementWhiteBackground"),
    ...require("./composition/ElementSticky"),
    ...require("./composition/ElementWordcloud"),
    ...require("./composition/ElementGroup"),
    ...require("./composition/ElementConditionalDisplay"),

    ...require("./chart/ElementChartLine"),
    ...require("./chart/ElementChartLineRanking"),
    ...require("./chart/ElementChartScatter"),
    ...require("./chart/ElementChartBar"),
    ...require("./chart/ElementChartSankey"),
    ...require("./chart/ElementChartOverlayBoxPlot"),
    ...require("./chart/ElementChartStackedBar"),
    ...require("./chart/ElementChartHeatmap"),
    ...require("./chart/ElementChartMap"),
    ...require("./chart/ElementChartGraph"),
    ...require("./chart/ElementChartKDEplot"),

    ...require("./form/ElementStepGroup"),
    ...require("./form/ElementStep"),

    ...require("./form/ElementSelect"),
    ...require("./form/ElementButtonSelect"),
    ...require("./form/ElementDataDoubleRangeFilter"),
    ...require("./form/ElementListDoubleRangeFilter"),
    ...require("./form/ElementFilterNested"),
    ...require("./form/ElementForm"),
    ...require("./form/ElementFormRow"),
    ...require("./form/ElementInput"),
    ...require("./form/ElementCheckbox"),
    ...require("./form/ElementDataFilter"),
    ...require("./form/ElementDataFilterNested"),
    ...require("./form/ElementDataInputFilter"),
    ...require("./form/ElementDataSort"),
    ...require("./form/ElementFileTree"),
    ...require("./form/ElementFilterTargetGroup"),

    ...require("./hollow/ElementMessage"),
    ...require("./hollow/ElementHeader"),
    ...require("./hollow/ElementText"),
    ...require("./hollow/ElementBadge"),
    ...require("./hollow/ElementContainer"),
    ...require("./hollow/ElementSortingOrderToggle")

};
