import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import Slider from "rc-slider";
import { DataFrame } from "../../DSL/DataFrame";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const ElementDataDoubleRangeFilter = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const { setOutput, saveInteractionState, filterColumn, "data": dataArgs } = props;
    const { defaultInteractionState } = dataArgs;
    const [filter, setFilter] = useState(undefined);

    const list = [];
    if (data instanceof DataFrame && data.hasColumn(filterColumn)) {
        data.getColumn(filterColumn).forEach(v => list.push(v));
        if (!filter) {
            setFilter(defaultInteractionState || [0, list.length - 1]);
            setFilter((prev) => [Math.max(0, prev[0]), Math.min(list.length - 1, prev[1])]);
        }
    }

    useEffect(() => {
        if (filter instanceof Array) {
            const slicedList = list.slice(filter[0], filter[1] + 1);
            setOutput(slicedList);
            if (saveInteractionState !== undefined) {
                saveInteractionState(filter);
            }
        }
    }, [filter]);

    return (
        <Element
            width="100%"
            {...props}
            primary
            setData={setData}>
            <div
                style={{
                    "width": "100%",
                    "position": "relative",
                    "display": "flex"
                }}
            >
                <div className="text-base-content font-bold m-4 ml-0 mr-0">{props.description}</div>
                <Range
                    ariaValueTextFormatterGroupForHandles={() => "asdf"}
                    style={{ "margin": "auto 1rem" }}
                    defaultValue={defaultInteractionState || [0, list.length - 1]}
                    max={list.length - 1}
                    tipFormatter={i => list[parseInt(i)]}
                    tipProps={{
                        placement: "top",
                        visible: true
                    }}
                    onAfterChange={(val) => setFilter(val)}
                    pushable={true}
                    className="rc-slider-primary"
                />
            </div>
        </Element>
    );
};

export { ElementDataDoubleRangeFilter as DataDoubleRangeFilter };
