export default class ElementIdRegistry {
    constructor() {
        this.registry = new Map();
    }

    size = (subRegistry = undefined) =>
        Array.from(
            subRegistry || this.registry,
            ([_, currRegistry]) => this.size(currRegistry) + 1
        ).reduce((sum, curr) => curr + sum, 0);

    findById = (subRegistry, id) => {
        if (subRegistry.has(id)) {
            return subRegistry.get(id);
        } else {
            for (const k of subRegistry.keys()) {
                const result = this.findById(subRegistry.get(k), id);
                if (result) { return result; }
            }
        }
        return false;
    }

    registerId = (id, parentId) => {
        if (this.findById(this.registry, id)) {
            throw new Error(`Cannot register id, element with ID ${id} already exists with children: ${JSON.stringify(Array.from(this.findById(this.registry, id)).entries())}. ParentId was ${parentId}`);
        }
        const parentReference = this.findById(this.registry, parentId); // append to parent
        if (parentReference) {
            parentReference.set(id.toString(), new Map());
        } else {
            this.registry.set(id.toString(), new Map());
        }
    }

    clearId = (id, parentId) => {
        const found = this.findById(this.registry, id);
        if (!found) {
            return; // it's possible for an ID not to exist in a rowsToChildren update
        }
        if (parentId) {
            const parent = this.findById(this.registry, parentId);
            parent.delete(id.toString());
        } else {
            this.registry.delete(id.toString());
        }
    }
}
