export const loadingStates = {
    "INITIALISED": "initialised",
    "WAITING_FOR_DEPENDENCIES": "waiting for dependencies",
    "WAITING_FOR_CONNECTION": "waiting for connection",
    "WAITING_FOR_DATA": "waiting for data",
    "STREAMING": "streaming",
    "FINISHED": "finished",
    "ERROR": "error",
    "REMOVED": "removed"
};

export const streamEventTypes = {
    "cancelling": "cancelling",
    "cancelled": "cancelled",
    "failed": "failed",
    "finished": "finished",
    "row": "row",
    "started": "started",
    "job-file-downloading": "job-file-downloading",
    "worker-assigned": "worker-assigned",
    "queued": "queued",
    "initialized": "initialized"
};

export const eventToLoadingStateMap = new Map([
    [streamEventTypes.started, loadingStates.WAITING_FOR_DATA],
    [streamEventTypes.row, loadingStates.WAITING_FOR_DATA],
    [streamEventTypes.finished, loadingStates.STREAMING],
    [streamEventTypes["worker-assigned"], loadingStates.WAITING_FOR_DATA],
    [streamEventTypes["job-file-downloading"], loadingStates.WAITING_FOR_DATA],
    [streamEventTypes.initialized, loadingStates.WAITING_FOR_DATA],
    [streamEventTypes.queued, loadingStates.WAITING_FOR_DATA],
    [streamEventTypes.cancelling, loadingStates.ERROR],
    [streamEventTypes.cancelled, loadingStates.ERROR]]);

export const eventToLoadingMessage = new Map([
    [streamEventTypes.initialized, "Arvutustöö registreeriti"],
    [streamEventTypes.queued, "Arvutustöö ootab vaba masinat"],
    [streamEventTypes["worker-assigned"], "Vaba masin leitud"],
    [streamEventTypes["job-file-downloading"], "Masin laeb arvutustöö faile"],
    [streamEventTypes.started, "Arvutustööd täidetakse. Võib minna aega."],
    [streamEventTypes.row, "Arvutustöö osaliselt valmis. Võib minna aega."],
    [streamEventTypes.finished, "Arvutustöö on valmis"],
    [streamEventTypes.failed, "Arvutustöö ebaõnnestus"],
    [streamEventTypes.cancelling, "Arvutustööd peatatakse"],
    [streamEventTypes.cancelled, "Arvutustöö peatati"]]);

export const loadingMessageToPercentage = new Map([
    [eventToLoadingMessage.get("initialized"), 0],
    [eventToLoadingMessage.get("queued"), 10],
    [eventToLoadingMessage.get("worker-assigned"), 20],
    [eventToLoadingMessage.get("job-file-downloading"), 30],
    [eventToLoadingMessage.get("started"), 50],
    [eventToLoadingMessage.get("row"), 70],
    [eventToLoadingMessage.get("finished"), 90],
    [eventToLoadingMessage.get("failed"), 0],
    [eventToLoadingMessage.get("cancelling"), 0],
    [eventToLoadingMessage.get("cancelled"), 0]
]);
