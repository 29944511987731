import { action } from "./aggregatorModels";
import Transform from "./actionTransformModels";
import Filter from "./actionFilterModels";
import Group from "./actionGroupModels";
import Unstack from "./actionUnstackModels";
import _ from "lodash";

import TransformMatch from "./actionTransformMatchModels";
import { DataFrame } from "./DataFrame";
import { ActionsArray, AggregationDataReference } from "./dashboardModels";

class Match extends Function {
    constructor() {
        super("...args", "return this.equals(...args)");
        return this.bind(this);
    }

    regex(pattern) {
        return {
            "function": "regex",
            pattern
        };
    }

    contains(pattern) {
        return {
            "function": "contains",
            pattern
        };
    }

    equals(pattern) {
        return {
            "function": "equals",
            pattern
        };
    }
}
const match = new Match();

const fun = (_match, fun, args = []) => ({
    "match": typeof _match === "object" ? _match : match.equals(_match),
    "function": fun,
    args
});

const createColumn = (columnName, defaultValue) => action.createColumn(columnName, defaultValue);
const actionCopyColumn = action.copyColumn;
const actionSort = action.sort;
const actionResample = action.resample;
const actionMelt = action.melt;
const actionUnstack = action.unstack;
const actionTransformMatch = action.transformMatch;
const actionDebug = action.debug;

/**
 * @param {Transform} transform - transform action
 * @param {Filter} filter - filter action
 * @param {Group} group - group action
 * @param {createColumn} createColumn - createColumn action
 * @param {copyColumn} copyColumn - copyColumn action
 * @param {actionSort} sort - sort action
 * @param {actionResample} resample - resample action
 * @param {actionMelt} melt - melt action
 * @param {actionUnstack} unstack - unstack action
 * @param {actionSort} sort - sort action
 * @param setActionCallback -
 */
class ActionsBuilder {
    #actionCallback;

    constructor(actionCallback) {
        this.#actionCallback = actionCallback;
        this.transform = new Transform((...functions) => {
            return this.#actionCallback(action.transform(...functions));
        });
        this.filter = new Filter((...functions) => {
            return this.#actionCallback(action.filter(...functions));
        });
        this.group = (...by) => {
            return new Group((by, ...functions) => {
                return this.#actionCallback(action.group(by, ...functions));
            }, by);
        };
        this.transformMatch = (from, to, permutation) => {
            return new TransformMatch((...funs) => {
                return this.#actionCallback(actionTransformMatch(from, to, permutation, ...funs));
            });
        };
        this.createColumn = (columnName, defaultValue) => {
            return this.#actionCallback(createColumn(columnName, defaultValue));
        };
        this.copyColumn = (_match, newPattern) => {
            return this.#actionCallback(actionCopyColumn(
                typeof _match === "object" ? _match : match.equals(_match),
                newPattern));
        };
        this.sort = (ascending, ...by) => {
            return this.#actionCallback(actionSort(ascending, ...by));
        };
        this.resample = (indexColumn, indexType, frequency) => {
            return this.#actionCallback(actionResample(indexColumn, indexType, frequency));
        };
        this.melt = (idVars, valueVars, varName, valueName) => {
            return this.#actionCallback(actionMelt(idVars, valueVars, varName, valueName));
        };
        this.unstack = (indexColumns, pivotColumn, valueColumn) => {
            return new Unstack((aggregateFunction) => {
                return this.#actionCallback(actionUnstack(indexColumns, pivotColumn, valueColumn, aggregateFunction));
            });
        };
        this.cache = () => {
            return this.#actionCallback(action.cache());
        };
        this.debug = (sample = 50) => {
            return this.#actionCallback(actionDebug(sample));
        };
    }

    _setActionCallback(actionCallback) {
        this.#actionCallback = actionCallback;
    }
}

class AggregationBuilder extends ActionsBuilder {
    #source; #actions;

    constructor(source) {
        super();
        this.#source = source;
        this.#actions = [];
        this._setActionCallback(this.append);
        return this;
    }

    append(action) {
        if (action !== undefined) {
            this.#actions.push(action);
        }
        return this;
    }

    /**
     * Has 2 functions: extends the array of actions when passed an array of actions, and appends a new ActionsArray
     * instance when passed a AggregationDataReference type object (such a reference callback must return an array of
     * actions)
     * @param {array|AggregationDataReference} extensionActions
     * @returns {AggregationBuilder}
     */
    extend(extensionActions) {
        if (extensionActions instanceof AggregationDataReference) {
            this.#actions.push(new ActionsArray(extensionActions));
        } else {
            extensionActions.filter((a1) => a1 !== undefined).forEach((a2) => this.#actions.push(a2));
        }
        return this;
    }

    clone() {
        const copy = new AggregationBuilder(_.cloneDeep(this.#source));
        this.#actions.forEach(action => copy.append(action));
        return copy;
    }

    build = () => ({
        "source": this.#source,
        "actions": this.#actions
    })
}

const matchFun = (prefix, fun, args = []) => ({
    prefix,
    "function": fun,
    args
});

const actions = new ActionsBuilder((action) => {
    return action;
});
const sanitizeVariables = (variables) => variables.filter((col) => col !== undefined).map((col) => typeof col === "object" ? col : match(col));
const source = {
    "catalogue": () => new AggregationBuilder({
        "type": "CATALOGUE"
    }),
    /**
     * Grabs the dataset as study path. This path can contain folders, for example for articles you'd request something like:
     * "articles/articles-2006-01-01". Specifying variables makes sure only the necessary parts of the data file are actually
     * loaded into the aggregation backend. allowMissingVariables makes the aggregation slower, but will allow columns that
     * are not represent.
     *
     * Note that wildcards are supported in study paths. Some wildcards will then make database source actually load in
     * multiple studies. Specifying "*" will load in all datasets at the parent path in our S3 database. "articles/*"
     * will load all articles etc. If wildcards are used, you should avoid specifying variables or use allowMissingVariables.
     *
     * Note: If you are having issues with allowMissingVariables, it might not be working properly at the moment! Report it
     * if so.
     *
     * @param {string} study
     * @param {Array} variables
     * @param {boolean} allowMissingVariables
     * @return {AggregationBuilder}
     * @constructor
     */
    "database": (study, variables = [], allowMissingVariables = false) => new AggregationBuilder({
        "type": "DATABASE",
        study,
        "variables": sanitizeVariables(variables),
        "allowMissingVariables": allowMissingVariables
    }),
    "databases": (studies, variables = [], allowMissingVariables = false) => new AggregationBuilder({
        "type": "DATABASES",
        studies,
        "variables": sanitizeVariables(variables),
        "allowMissingVariables": allowMissingVariables
    }),
    "dataset": (folder, dataset, variables = [], version = "latest", allowMissingVariables = false) =>
        new AggregationBuilder({
            "type": "DATASET",
            folder,
            dataset,
            "variables": sanitizeVariables(variables),
            version,
            "allowMissingVariables": allowMissingVariables
        }),
    "datasets": (folder, datasets, variables = [], version = "latest", allowMissingVariables = false) =>
        new AggregationBuilder({
            "type": "DATASETS",
            folder,
            datasets,
            "variables": sanitizeVariables(variables),
            version,
            "allowMissingVariables": allowMissingVariables
        }),
    "join": (by, items, joinType) => new AggregationBuilder({
        "type": "JOIN",
        "by": by.map((col) => typeof col === "object" ? col : match(col)),
        "items": items.map((item) => item.build()),
        joinType
    }),
    "concat": (items, joinType) => new AggregationBuilder({
        "type": "CONCAT",
        "items": items.map((item) => item.build()),
        joinType
    }),
    "raw": (data) => {
        if (typeof data === "object" && !(data instanceof DataFrame) && !(data instanceof AggregationDataReference)) {
            data = JSON.stringify(data);
        }
        return new AggregationBuilder({
            "type": "RAW",
            data
        });
    }
};

const data = {
    "definition": (source, actions = []) => ({
        source,
        actions: actions.filter((a) => a !== undefined)
    })
};

const tr = new Transform((v) => v);
const fi = new Filter((v) => v);
const gr = new Group((matches, ...functions) => {
    return functions[0];
}, []);
const trm = new TransformMatch((f) => f);
export { source, data, fun, match, actions, matchFun, tr, fi, gr, trm, AggregationBuilder };
