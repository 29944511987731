import React, { useState } from "react";
import { DataFrame } from "../../DSL/DataFrame";
import { Element } from "../Element";
import styles from "./ElementFormRow.module.css";

const ElementFormRow = (props) => {
    const [ignored, setData] = useState(new DataFrame([]));
    const { children, ...otherProps } = props;

    return (
        <Element
            {...props}
            primary
            setData={setData}>
            <div
                className={styles.formRow}
                {...otherProps}>
                {children.map((c, i) =>
                    <div
                        className={styles.formRowCol}
                        key={i}
                    >{c}</div>
                )}
            </div>
        </Element>);
};

export { ElementFormRow as FormRow };
