import React, { useState } from "react";
import { Sticky } from "semantic-ui-react";
import { DataFrame } from "../../DSL/DataFrame";
import { Element } from "../Element";

const ElementSticky = (props) => {
    const [ignored, setData] = useState(new DataFrame([]));
    const { children, contextReference, ...otherProps } = props;

    return (
        <Element
            width="100%"
            {...props}
            primary
            setData={setData}>
            <Sticky
                pushing
                {...otherProps}
                context={contextReference}>
                {children}
            </Sticky>
        </Element>);
};

export { ElementSticky as Sticky };
