import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { Input } from "react-daisyui";
import FormFieldHeader from "./FormFieldHeader";

const ElementInput = (props) => {
    // TODO: will not work with data as df
    const [data, setData] = useState([]);
    const { setOutput, "data": dataArgs, saveInteractionState, label, type, ...otherProps } = props;
    const [filter, setFilter] = useState(dataArgs.defaultInteractionState);
    const [defaultState, setDefaultState] = useState(dataArgs.defaultInteractionState);

    useEffect(() => {
        props.subscribeInput(() => {
            if (props.data.defaultInteractionState !== undefined) {
                setFilter(props.data.defaultInteractionState);
                setDefaultState(props.data.defaultInteractionState);
            }
        });
    }, []);

    useEffect(() => {
        setOutput(filter);
        if (saveInteractionState !== undefined) {
            saveInteractionState(filter);
        }
    }, [filter]);

    let classes = "w-full hover:bg-neutral-focus border-none ";
    if (type === "range") {
        classes += "range range-primary";
    } else {
        classes += "bg-neutral";
    }

    return (
        <Element
            {...otherProps}
            setData={setData}>
            <FormFieldHeader>{label}</FormFieldHeader>

            <Input
                className={classes}
                type={type}
                {...{ ...otherProps, "children": null }}
                placeholder={otherProps.placeholder || defaultState}
                options={data}
                defaultValue={defaultState}
                onChange={
                    ({ target }) => {
                        if (target.value !== undefined && target.value !== "") {
                            setFilter(target.value);
                        }
                    }
                }/>

        </Element>
    );
};

export { ElementInput as Input };
