import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { DataChildren } from "../ElementDataChildren";

const renderModes = {
    children: "children",
    dataChildren: "dataChildren"
};

const ElementTab = (props) => {
    const { children, "data": dataArgs } = props;
    const [ignored, setData] = useState(undefined);
    const [renderMode, setRenderMode] = useState(renderModes.children);

    const onTabChangeFunction = (setState, key, activeIndex) => {
        setState(old => {
            const newArray = [...old];
            newArray[activeIndex] = true;
            return newArray;
        });
    };

    const childrenToPanes = (tabsLoaded, setTabsLoaded, childrenArray) => {
        const isTabLoaded = (i) => tabsLoaded.length > i && tabsLoaded[parseInt(i, 10)];

        const { tabNames } = dataArgs;
        if (tabsLoaded.length !== childrenArray.length) {
            setTabsLoaded(childrenArray.map((c, i) => i === 0));
        }

        let finalTabNames;
        if (tabNames) {
            finalTabNames = childrenArray.map((child, i) => tabNames.length > i ? tabNames[parseInt(i, 10)] : child.props.id);
        } else {
            finalTabNames = childrenArray.map(child => child.props.id);
        }

        return childrenArray.map((child, i) => ({
            "key": finalTabNames[parseInt(i, 10)],
            "content": isTabLoaded(i) ? child : <div>You should not be able to see this.</div>
        }));
    };

    useEffect(() => {
        if (children.length === 1 && children[0].type === DataChildren) {
            const dataChildrenElement = children[0];
            const extraProps = {
                DataChildrenWrapper: ({ dataChildren }) => {
                    const [tabsLoaded, setTabsLoaded] = useState([]);
                    const panes = childrenToPanes(tabsLoaded, setTabsLoaded, dataChildren);
                    const [active, setActive] = useState(undefined);
                    const isActive = (key) => { return active === key; };

                    useEffect(() => {
                        if (panes && panes.length > 0) {
                            const pane = panes[0];
                            onTabChangeFunction(setTabsLoaded, pane.key, 0);
                            setActive(pane.key);
                        }
                    }, [dataChildren]);

                    return (
                        <>
                            <div className="tabs">
                                {panes
                                    .map((pane, i) => (
                                        <div
                                            className={`tab tab-lifted tab-lg ${isActive(pane.key) && "tab-active"}`}
                                            key={pane.key}
                                            value={pane.key}
                                            onClick={() => {
                                                onTabChangeFunction(setTabsLoaded, pane.key, i);
                                                setActive(pane.key);
                                            }}
                                        >
                                            {pane.key}
                                        </div>
                                    ))}
                            </div>
                            <div>
                                {panes.map(p =>
                                    <div key={p.key + "_pane"} className={isActive(p.key) ? "" : "hidden"}>{p.content}</div>
                                )}
                            </div>
                        </>
                    );
                }
            };
            children[0] = React.cloneElement(dataChildrenElement, extraProps);
            setRenderMode(renderModes.dataChildren);
        }
    }, []);

    const TabOfChildren = ({ children }) => {
        const [tabsLoaded, setTabsLoaded] = useState([]);
        const panes = childrenToPanes(tabsLoaded, setTabsLoaded, children);
        const [active, setActive] = useState(undefined);
        const isActive = (key) => { return active === key; };

        useEffect(() => {
            if (panes && panes.length > 0) {
                const pane = panes[0];
                onTabChangeFunction(setTabsLoaded, pane.key, 0);
                setActive(pane.key);
            }
        }, [children]);

        return (
            <>
                <div className="tabs">
                    {panes
                        .map((pane, i) => (
                            <div
                                className={`tab tab-lifted tab-lg ${isActive(pane.key) && "tab-active"}`}
                                key={pane.key}
                                value={pane.key}
                                onClick={() => {
                                    onTabChangeFunction(setTabsLoaded, pane.key, i);
                                    setActive(pane.key);
                                }}
                            >
                                {pane.key}
                            </div>
                        ))}
                </div>
                <div>
                    {panes.map(p =>
                        <div key={p.key + "_pane"} className={isActive(p.key) ? "" : "hidden"}>{p.content}</div>
                    )}
                </div>
            </>
        );
    };

    return (
        <Element
            {...props}
            width="100%"
            primary
            setData={setData}>
            {renderMode === renderModes.children && <TabOfChildren>{children}</TabOfChildren>}
            {renderMode === renderModes.dataChildren && children[0]}
        </Element>
    );
};

export { ElementTab as Tab };
