import React, { useState } from "react";
import { Element } from "../Element";
import ReactEcharts from "echarts-for-react";
import { DataFrame } from "../../DSL/DataFrame";
import { roundNumber, uniques } from "../../../UtilityFunctions";
import _ from "lodash";
import TSegment from "../../../components/TSegment";
import { useTheme } from "react-daisyui";

const ElementChartSankey = (props) => {
    const { theme } = useTheme();
    const [data, setData] = useState(new DataFrame([]));
    const { "data": dataArgs, options } = props;

    const removePostfix = (item, separator) => item !== undefined ? item.split(separator)[0] : "_";
    const getWave = (item, separator) => {
        if (item !== undefined) {
            const parts = item.split(separator);
            return parts.splice(-parts.length + 1).join(separator);
        }
        return -1;
    };
    const separator = dataArgs.separator || "_";

    if (!data.isEmpty()) {
        /*
        * Data attributes:
        * separator - separator string between the column values and index
        * colorMap - Map object which holds the mappings of data point values to colors
        * nameMap - Map object which holds the mappings of data point values to their full names
        * onlyChanged - boolean which decides whether to only display values where source does not match target.
        * treshold - numerical treshold for at least how big the value of a sankey link has to be to be displayed.
        * tresholdPercent - percentual treshold option for treshold. Use 100 for 100%, 5 for 5%.
        * */

        let df = data.copy().filter((row) =>
            row.get("source") !== undefined &&
            row.get("target") !== undefined &&
            !isNaN(row.get("value")) && row.get("value") > 0
        );

        const maxValue = _.max(data.getColumn("value"));
        const minValue = _.min(data.getColumn("value"));
        const valueDifference = maxValue - minValue;

        if (dataArgs.onlyChanged) {
            df = df.filter((row) => removePostfix(row.get("source"), separator) !== removePostfix(row.get("target"), separator));
        }
        const sourceValues = new Map(
            [...df.bucketize("source").entries()]
                .map(([key, df1]) => [key, df1.sum("value")])
        );
        const targetValues = new Map(
            [...df.bucketize("target").entries()]
                .map(([key, df1]) => [key, df1.sum("value")])
        );
        const sourceSums = new Map(
            [...df.bucketize("sourceWave").entries()]
                .map(([key, df1]) => [key, df1.sum("value")])
        );
        const targetSums = new Map(
            [...df.bucketize("targetWave").entries()]
                .map(([key, df1]) => [key, df1.sum("value")])
        );
        const sourceSupport = new Map(
            [...df.bucketize("source").entries()]
                .map(([key, df1]) => [
                    key,
                    Math.round(df1.sum("value") / sourceSums.get(df1.getColumn("sourceWave")[0]) * 100)
                ])
        );
        const targetSupport = new Map(
            [...df.bucketize("target").entries()]
                .map(([key, df1]) => [
                    key,
                    Math.round(df1.sum("value") / targetSums.get(df1.getColumn("targetWave")[0]) * 100)
                ])
        );

        if (!df.hasColumn("color")) {
            const colors = df.map((row) => removePostfix(row.get("source"), separator))
                .map(s1 => dataArgs.colorMap.has(s1) ? dataArgs.colorMap.get(s1) : "gray");
            df.setColumn("color", colors);
            df.setColumn("colorDistinctFromSource", colors.map(() => false));
        } else if (dataArgs.colorMap) {
            const colorDistinctFromSource = df.map((row) =>
                removePostfix(row.get("source"), separator) !== row.get("color"));
            df.setColumn("colorDistinctFromSource", colorDistinctFromSource);
            const colors = df.map((row) => row.get("color"))
                .map(s1 => dataArgs.colorMap.has(s1) ? dataArgs.colorMap.get(s1) : "gray");
            df.setColumn("color", colors);
        }

        let links = df.getRange().map((i) => {
            const row = df.getRow(i);
            const value = row.get("value");
            const percent = (value - minValue) / valueDifference * 100;
            let isAbovePercentage = true;
            if (dataArgs.tresholdPercent) {
                isAbovePercentage = percent >= dataArgs.tresholdPercent;
            }
            const colorDistinctFromSource = row.get("colorDistinctFromSource");
            const isSourceTarget =
                row.get("source").split("_")[0] === row.get("target").split("_")[0];
            const shouldRender =
                isAbovePercentage || (isSourceTarget);
            const shouldHighlight = (
                !isSourceTarget && percent > 0.2
            ) || colorDistinctFromSource;

            return {
                "source": row.get("source"),
                "target": row.get("target"),
                "value": row.get("value"),
                "tooltip": row.has("tooltip") ? row.get("tooltip") : undefined,
                // "period": row.get("period"),
                "lineStyle": {
                    "color": row.get("color"), // "source",
                    "opacity": shouldRender ? (!shouldHighlight ? 0.15 : 0.8) : 0,
                    "shadowColor": "rgba(0, 0, 0, 0.3)"
                }
            };
        });

        const waves = uniques([...df.uniques("sourceWave"), ...df.uniques("targetWave")]).sort();

        // filter out node from links that dont have source AND target
        const nodes = uniques([...df.uniques("source"), ...df.uniques("target")]).sort();
        const keepNodes = nodes.filter((node) => {
            const isSource = links.some((link) => link.source === node);
            const isTarget = links.some((link) => link.target === node);
            // exception for last wave
            if (node.includes(waves.at(-1)) || node.includes(waves.at(0))) {
                return true;
            }
            return isSource && isTarget;
        });
        links = links.filter((link) => keepNodes.includes(link.source) && keepNodes.includes(link.target));


        // generate fake links in order to display period
        links.push(...waves.slice(0, -1).map((wave, i) => {
            return {
                "source": wave,
                "target": waves[i + 1],
                "value": 50,
                "lineStyle": {
                    "color": "transparent"
                },
                "itemStyle": {
                    "color": "transparent"
                },
                "emphasis": { "disabled": true },
                "select": { "disabled": true }
            };
        }));
        // sort so the small movements are seen above in z ordering
        links.sort((a, b) => b.value - a.value);

        const sankeySeries = {
            "type": "sankey",
            "layout": "none",
            "focus": "adjacency",
            "data": Array.from(new Set(links.flatMap((obj) => [obj.source, obj.target])))
                .map((val) => ({
                    "name": val,
                    "itemStyle": {
                        "color": dataArgs.colorMap.has(removePostfix(val, separator)) ? dataArgs.colorMap.get(removePostfix(val, separator)) : "gray",
                        "opacity": waves.includes(val) ? 0 : 1
                    },
                    "emphasis": { "disabled": waves.includes(val) },
                    "select": { "disabled": waves.includes(val) }
                })),
            "links": links,
            "lineStyle": {
                "color": "source",
                "curveness": 0.25
            },
            "label": {
                "formatter": (data) => {
                    return targetSupport.get(data.name) || sourceSupport.get(data.name) || data.name;
                },
                "position": "inside"
            },
            "left": "5%",
            "right": "5%",
            "nodeAlign": "justify",
            "nodeWidth": 25,
            "nodeGap": 4,
            "layoutIterations": 128
        };

        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}>
                <TSegment>
                    <ReactEcharts
                        theme={theme === "dark" ? "dark" : "default"}
                        style={{ "width": "100%", "height": "600px" }}
                        option={{
                            "backgroundColor": "transparent",
                            "title": {
                                "text": props.title,
                                "textStyle": { "fontFamily": "Oswald", "fontWeight": 400 }
                            },
                            "legend": {},
                            "grid": {
                                "left": "3%",
                                "right": "4%",
                                "bottom": "3%",
                                "containLabel": false
                            },
                            "tooltip": {
                                "trigger": "item",
                                "triggerOn": "mousemove",
                                "formatter": ({ _, data }) => {
                                    if (!data.source && !data.target) { // not link
                                        const wave = getWave(data.name, separator);
                                        const waveValue = sourceValues.get(data.name) || targetValues.get(data.name);
                                        const waveSum = sourceSums.get(wave) || targetSums.get(wave);
                                        let name = removePostfix(data.name, dataArgs.separator);
                                        name = dataArgs.nameMap && dataArgs.nameMap.has(name) ? dataArgs.nameMap.get(name) : name;
                                        return `${name} ${wave}: ${roundNumber(100 * waveValue / waveSum, 2)}%`;
                                    } else { // link
                                        const name = `${data.source} -> ${data.target}`;
                                        const wave = getWave(data.source, separator);
                                        const waveSum = sourceSums.get(wave);
                                        const percentage = `${roundNumber(100 * data.value / waveSum, 2)}`;
                                        // tooltip is currently presumed to be an array of strings
                                        const tooltip = data.tooltip ? `\n${data.tooltip.join("\n")}` : "";
                                        return `${name}: ${percentage}%${tooltip}`;
                                    }
                                },
                                "extraCssText": "white-space: pre-line;max-width: 50%;"
                            },
                            "xAxis": {
                                "type": "value",
                                "name": dataArgs.x,
                                "axisLine": { "lineStyle": { "width": 0 } },
                                "axisTick": { "lineStyle": { "width": 0 } }
                            },
                            "yAxis": {
                                "type": "value",
                                "name": dataArgs.y,
                                "axisLine": { "lineStyle": { "width": 0 } },
                                "axisTick": { "lineStyle": { "width": 0 } }
                            },
                            "series": sankeySeries,
                            ...options
                        }}/>
                </TSegment>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>);
    }
};

export { ElementChartSankey as ChartSankey };
