import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as echarts from "echarts";
import { useTheme } from "react-daisyui";

function Chart({ option, resizeObserver, onEvents, style }) {
    const myChart = useRef(null);
    const { theme } = useTheme();

    useEffect(() => {
        const chart = echarts.init(myChart.current, theme === "dark" ? "dark" : undefined);
        if (option) {
            option.backgroundColor = "transparent";
            chart.setOption(option);
            if (onEvents) {
                for (const [event, fun] of Object.entries(onEvents)) {
                    chart.on(event, (comp) => fun(comp, chart));
                }
            }
        }
        if (resizeObserver) resizeObserver.observe(myChart.current);
    }, [option, resizeObserver, theme]);

    return (<div
        ref={myChart}
        style={{
            width: "100%",
            height: "100%",
            ...style
        }}
    />);
}

Chart.propTypes = {
    options: PropTypes.any,
    resizeObserver: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export default Chart;
