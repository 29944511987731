import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const ElementListDoubleRangeFilter = (props) => {
    const [ignored, setData] = useState(null);
    const { list, labels = list, setOutput, saveInteractionState, "data": dataArgs } = props;
    const { defaultInteractionState } = dataArgs;
    const [filter, setFilter] = useState(defaultInteractionState || [0, list.length - 1]);

    useEffect(() => {
        const slicedList = list.slice(filter[0], filter[1] + 1);
        setOutput(slicedList);
        if (saveInteractionState !== undefined) {
            saveInteractionState(filter);
        }
    }, [filter]);

    return (
        <Element
            width="100%"
            {...props}
            primary
            setData={setData}>
            <div
                style={{
                    "width": "100%",
                    "position": "relative",
                    "display": "flex"
                }}
            >
                <div className="text-base-content font-bold m-4 ml-0 mr-0">{props.description}</div>
                <Range
                    ariaValueTextFormatterGroupForHandles={() => ""}
                    style={{ "margin": "auto 1rem" }}
                    defaultValue={defaultInteractionState || [0, list.length - 1]}
                    max={list.length - 1}
                    count={1}
                    tipFormatter={i => labels[parseInt(i)]}
                    tipProps={{
                        placement: "top",
                        visible: true
                    }}
                    allowCross={false}
                    onChange={(val) => setFilter(val)}
                    pushable={false}
                    className="rc-slider-primary"
                />
            </div>
        </Element>
    );
};

export { ElementListDoubleRangeFilter as ListDoubleRangeFilter };
