import * as AWS from "aws-sdk";
import React, { useEffect, useState } from "react";
import { user } from "../auth/User";
import env from "@beam-australia/react-env";
import _ from "lodash";
import { Table } from "react-daisyui";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

const loadingRows = [[<ArrowPathIcon className="w-6 h-6 animate-spin" key="loader"/>, ""]];

export const DataTable = (props) => {
    const { rows, header } = props;
    const [data, setData] = useState(rows);

    useEffect(() => {
        setData(() => {
            let sorted = _.cloneDeep(rows);
            sorted.sort((a, b) => b[1].localeCompare(a[1]));
            sorted = sorted.slice(0, Math.min(10, sorted.length));
            return sorted;
        });
    }, [rows]);

    return (
        <div className="w-full" style={{ overflow: "auto", maxHeight: "500px" }}>
            <Table className="w-full table-zebra">
                <Table.Head
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                >
                    {header.map((h) => <span
                        key={h}
                    >
                        <a
                            href="javascript: void(0);"
                        >{h}</a>
                    </span>)}
                </Table.Head>
                <Table.Body>
                    {data.map((r, i) => (
                        <Table.Row key={i}>
                            {r.map((c, j) => <span key={j}>{c}</span>)}
                        </Table.Row>))}
                </Table.Body>
            </Table>
        </div>
    );
};

const LastUpdatedDatasets = () => {
    const [authenticated, setAuthenticated] = useState(false);

    const listObjects = () => {
        const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
        const bucketParams = {
            Bucket: "kuk-staging-datasets",
            Prefix: "output/"
        };
        s3.listObjects(bucketParams, function(err, data) {
            if (err) {
                console.log("List Objects error", err, err.stack);
                setRows([[`Viga andmete laadimisel: ${err.message}`]]);
            } else {
                const newRows = data.Contents
                    .filter(row =>
                        row.Key.endsWith(".csv") ||
                        row.Key.endsWith(".xlsx") ||
                        row.Key.endsWith(".parquet"))
                    .filter(row => row.Key.split("/").length - 1 <= 2)
                    .map((row) => ([
                        row.Key
                            .replace("output/", "")
                            .replace(".parquet", "").replace(".csv", "")
                            .replace("ratings", "reitingud")
                            .replace("aggregated", "koondatud")
                            .replace("persona", "isikud")
                            .replace("latest.", "uusim-"),
                        row.LastModified.toISOString().substring(0, 16).replace("T", " ")
                    ]));
                setRows(newRows);
            }
        });
    };

    useEffect(() => {
        user.getCognitoIdToken((jwtToken) => {
            const loginHost = `cognito-idp.eu-central-1.amazonaws.com/${env("COGNITO_USER_POOL_ID")}`;
            AWS.config.update({
                region: "eu-central-1",
                apiVersion: "latest",
                credentials: new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: "eu-central-1:fa536205-b3e0-4b3e-b6bb-a4e672292fc4",
                    Logins: {
                        [loginHost]: jwtToken
                    }
                }, {
                    "region": "eu-central-1"
                })
            });
            AWS.config.credentials.refresh(error => {
                if (error) {
                    console.error("Credentials update error", error);
                    setAuthenticated(false);
                } else {
                    setAuthenticated(true);
                }
            });
        });
    }, []);

    useEffect(() => {
        if (authenticated) {
            listObjects();
        } else {
            setRows(loadingRows);
        }
    }, [authenticated]);

    const [rows, setRows] = useState(loadingRows);
    const [header] = useState(["Andmestik", "Viimati uuendatud"]);

    return (
        <DataTable
            rows={rows}
            header={header}
        />
    );
};

export default LastUpdatedDatasets;
