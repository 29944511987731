import React, { useCallback, useEffect, useState } from "react";
import { Element } from "./Element";
import { DataFrame } from "../DSL/DataFrame";
import { Popup } from "semantic-ui-react";
import TSegment from "../../components/TSegment";
import { Button } from "react-daisyui";

const ElementMousePopup = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const [self, setSelf] = useState(undefined);
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
    const [pos, setPos] = useState(undefined);
    const [shown, setShown] = useState(false);
    const { children, style } = props;

    const close = () => {
        setShown(false);
        setPos(undefined);
    };

    const callBackRef = useCallback(domNode => {
        if (domNode) {
            setSelf(domNode);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("mousemove", (e) => {
            setMousePos({
                x: e.clientX,
                y: e.clientY
            });
        });
    }, []);

    useEffect(() => {
        if (data && self) {
            const offset = self.getBoundingClientRect();
            const coord = [
                Math.round(mousePos.x - offset.x),
                Math.round(mousePos.y - offset.y)
            ];
            console.log("coord", coord);
            setPos(() => coord);
            setShown(true);
        } else {
            setShown(false);
        }
    }, [data]);

    return (<>
        <div ref={callBackRef}/>
        <Element
            {...props}
            primary
            setData={setData}
            style={{
                display: shown ? "block" : "none"
            }}
        >
            <Popup
                open={shown && pos}
                offset={pos}
                trigger={<div/>}
                position="bottom left"
                basic
                flowing
                pinned={true}
                size="small"
                style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    margin: 0
                }}
            >
                <TSegment>
                    <b className="text-lg font-bold text-base-content">{data && `${data[1]} - ${data[2]}`}</b>
                    <Button
                        className="
                        btn btn-sm btn-warning text-warning-content right-4
                        pl-2 pr-2 absolute float-right
                        "
                        onClick={() => close()}
                    >X</Button>
                    <div style={style}>{children}</div>
                </TSegment>
            </Popup>
        </Element>
    </>);
};

export { ElementMousePopup as MousePopup };
