import React, { useState } from "react";
import { Element } from "./Element";
import { List, Search } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { DataFrame } from "../DSL/DataFrame";

const ElementIndex = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    let { children } = props;

    if (!data.isEmpty()) {
        if (props.args) {
            children = children.concat(
                props.args.map((cat, i) => {
                    return (
                        <List.Item
                            key={i}>
                            <Link
                                to={cat.path}>
                                {cat.name}
                            </Link>
                            <List.List>
                                {cat.items.map((item, j) => (
                                    <List.Item
                                        key={j}>
                                        <Link
                                            to={item.path}>
                                            {item.name}
                                        </Link>
                                    </List.Item>
                                ))}
                            </List.List>
                        </List.Item>
                    );
                })
            );
        }

        return (
            <Element
                {...props}
                primary
                setData={setData}>
                <Search/>
                <List>
                    {children}
                </List>
            </Element>
        );
    } else {
        return (
            <Element
                {...props}
                width="100%"
                primary
                setData={setData}/>
        );
    }
};

export { ElementIndex as Index };
