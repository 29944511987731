import Cookies from "js-cookie";
import { user } from "../auth/User";

export const debugModeEnabled = () => user.hasElevatedAccess(true) &&
    Cookies.get("debug-mode-enabled") === "true";
export const debugModeEnabledSet = (enabled) => user.hasElevatedAccess(true) &&
    Cookies.set("debug-mode-enabled", enabled);

export const debugElevatedAccessSet = (enabled) => user.hasElevatedAccess(true) &&
    Cookies.set("debug-elevated-access", enabled);
export const debugElevatedAccessGet = () => user.hasElevatedAccess(true)
    ? Cookies.get("debug-elevated-access") === "true"
    : user.hasElevatedAccess();
export const debugCurrentPartySet = (party) => user.hasElevatedAccess(true) &&
    Cookies.set("debug-current-party", party);
export const debugCurrentPartyGet = () => user.hasElevatedAccess(true)
    ? Cookies.get("debug-current-party") : user.getParty();
