import * as AWS from "aws-sdk";
import React, { cloneElement, useEffect, useLayoutEffect, useRef, useState } from "react";
import { user } from "../auth/User";
import env from "@beam-australia/react-env";
import { classNames } from "../UtilityFunctions";
import roadmapStyles from "./Roadmap.module.css";

import { Document, Page } from "react-pdf";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

const roadmaps = [
    {
        "date": "2022.10",
        "type": "iframe"
    },
    {
        "date": "2022.09",
        "type": "pdf"
    },
    {
        "date": "2022.08",
        "type": "pdf"
    }
];

const Accordion = ({ children }) => {
    const [open, setOpen] = useState(undefined);

    return <div className="mt-2">
        {children.map((c, i) => cloneElement(c, {
            open: open === i,
            setOpen: (open) => setOpen(open ? i : undefined)
        }))}
    </div>;
};

const PdfViewer = ({ file }) => {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    const [numPages, setNumPages] = useState(null);

    function updateSize() {
        let w = ref.current.offsetWidth;
        if (w > 1000) {
            w *= 0.75;
        }
        setWidth(w);
    }

    useLayoutEffect(() => {
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    const scrollIntoView = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        ref.current?.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <div ref={ref} className="w-full content-center justify-center justify-items-center flex">
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess} >
                {Array(numPages).fill(0).map((_, i) =>
                    <div key={i}>
                        <Page
                            pageNumber={i + 1}
                            width={width}
                            onRenderSuccess={() => {
                                updateSize();
                                scrollIntoView();
                            }}
                            className="rounded-2xl overflow-hidden width-full height-fit"
                        />
                        <h4 className="text-center">{i + 1}</h4>
                    </div>
                )}
            </Document>
        </div>
    );
};

const IframeViewer = ({ src }) => {
    const ref = useRef(null);
    const [height, setHeight] = useState(window.innerHeight);

    const scrollIntoView = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    function updateSize() {
        setHeight(window.innerHeight);
        scrollIntoView();
    }

    useLayoutEffect(() => {
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return (
        <div
            className="rounded-2xl overflow-hidden width-full height-fit"
        >
            <iframe
                ref={ref}
                width="100%"
                height={height}
                frameBorder="0"
                src={src}
                onLoad={scrollIntoView}
            />
        </div>
    );
};

const RoadmapItem = ({ date, open, setOpen, type }) => {
    const [content, setContent] = useState(undefined);

    const downloadObject = (key) => {
        console.log(key);
        const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
        const downloadParams = {
            Bucket: "kuk-staging-rapid-survey",
            Key: key
        };
        s3.getObject(downloadParams, function(err, data) {
            if (err) {
                console.log(err, err.stack);
            } else {
                if (key.endsWith(".pdf")) {
                    const hex = data.Body.toString("base64");
                    const c = <PdfViewer file={"data:application/pdf;base64," + hex}/>;
                    setContent(c);
                } else {
                    const url = data.Body.toString("utf-8");
                    setContent(
                        <IframeViewer src={url}/>
                    );
                }
            }
        });
    };

    useEffect(() => {
        if (open && content === undefined) {
            const party = user.getParty();
            if (type === "pdf") {
                const key = `teekaart/teekaart ${party} ${date}.pdf`;
                downloadObject(key);
            } else if (type === "iframe") {
                const key = `teekaart/teekaart ${party} ${date}.txt`;
                downloadObject(key);
            }
        }
    }, [open]);

    return <div
        className={classNames(roadmapStyles.roadmapItem, open && "open")}
    >
        <h3
            className="mb-0"
            onClick={() => setOpen(!open)}
        >
            {date}
            {open
                ? <ChevronDownIcon className="w-8 h-8 float-right"/>
                : <ChevronUpIcon className="w-8 h-8 float-right"/>
            }
        </h3>
        <div className="content">
            {!open && "Laeb..."}
            {open && content}
        </div>
    </div>;
};

const RoadmapScreen = () => {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        user.getCognitoIdToken((jwtToken) => {
            const loginHost = `cognito-idp.eu-central-1.amazonaws.com/${env("COGNITO_USER_POOL_ID")}`;
            AWS.config.update({
                region: "eu-central-1",
                apiVersion: "latest",
                credentials: new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: "eu-central-1:fa536205-b3e0-4b3e-b6bb-a4e672292fc4",
                    Logins: {
                        [loginHost]: jwtToken
                    }
                }, {
                    "region": "eu-central-1"
                })
            });
            AWS.config.credentials.refresh(error => {
                if (error) {
                    console.error("Credentials update error", error);
                    setAuthenticated(false);
                } else {
                    setAuthenticated(true);
                }
            });
        });
    }, []);

    return (
        <div className="pt-4">
            <h1>Teekaart</h1>
            <p>Teekaart on perioodiline ja lihtne tegevuste soovituspakett erakonnale. Soovitused
                koondavad endas meie uuringute (poliitikute tuntus ja meeldivus, teemauuring, reitingud,
                kvartaalsed reitingud jm) olulisemaid leide ning soovitused on prioriteetsuse järjekorras.
                Mida rohkem eespool, seda rohkem on võita soovituse täitmisest.</p>
            {authenticated &&
                <Accordion>
                    {roadmaps.map(item => <RoadmapItem
                        key={item.date}
                        date={item.date}
                        type={item.type}
                    />)}
                </Accordion>
            }
            {!authenticated && <h2>Teid ei õnnestunud valideerida!</h2>}
        </div>
    );
};

export default RoadmapScreen;
