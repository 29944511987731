import React, { useLayoutEffect, useRef, useState } from "react";
import { DataFrame } from "../../DSL/DataFrame";
import { Element } from "../Element";
import styles from "./ElementResponderOverviewRow.module.css";
import { partyColorMap } from "../../../UtilityFunctions";
import { Table } from "semantic-ui-react";
import { colord, extend } from "colord";
import mixPlugin from "colord/plugins/mix";

extend([mixPlugin]);

const StepperItem = ({
    text, subText, small,
    color, secondaryText, secondaryColor,
    needsWide
}) => {
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const ref = useRef(null);

    if (subText === "None" || subText === "NaN") {
        subText = undefined;
    }
    if (secondaryText === "None" || secondaryText === "NaN") {
        secondaryText = undefined;
    }

    useLayoutEffect(() => {
        if (ref.current.offsetWidth > 1) {
            // in case the element is hidden, dont set size
            setHeight(ref.current.offsetHeight);
            const w = Math.max(subText.length * 1.5, ref.current.offsetWidth);
            setWidth(Math.min(w, 140));
        }
    });

    return (<div
        style={{ minWidth: needsWide ? width : 0 }}
        className={`${styles.stepperItem} ${small && styles.small}`}
    >
        {!secondaryText
            ? <div
                className={styles.stepCounter}
                style={{
                    background: color,
                    marginBottom: height + 10,
                    minWidth: "50px"
                }}
            >{text}</div>
            : <div
                className={styles.stepDoubleCounter}
                style={{
                    marginBottom: height + 10, minWidth: "50px"
                }}
            >
                <div style={{ background: color }}>{text}</div>
                <div style={{ background: secondaryColor }}>{secondaryText}</div>
            </div>
        }
        <div
            className={styles.stepSubtext}
            ref={ref}
            style={{
                minWidth: width
            }}
        >{subText || ""}</div>
    </div>);
};

const ElementResponderOverviewRow = ({
    prefixCurrentPreference, prefixReason, prefixPrevPreference, prefixSecondaryPreference,
    prefixCurrentChance, prefixSecondaryChance,
    colsDem, colorsDem, ...props
}) => {
    const [data, setData] = useState(new DataFrame([]));

    if (!(data instanceof DataFrame) || data.isEmpty()) {
        return (
            <Element
                {...props}
                primary
                setData={setData}
            >
            </Element>
        );
    }

    const row = data.getRow(0);

    const columns = data.getColumns();
    const colsPeriod = columns
        .filter(c => c.startsWith(prefixCurrentPreference));

    let countFromText = 1000;

    const stepperWrapper = (
        <div
            className={styles.stepperWrapper}
        >
            {colsPeriod.map(c => {
                const secondaryPreference = row.get(c.replace(prefixCurrentPreference, prefixSecondaryPreference));
                let valuePrev = row.get(c.replace(prefixCurrentPreference, prefixPrevPreference));
                if (valuePrev === "NaN" || valuePrev === "None") {
                    valuePrev = undefined;
                }
                const value = row.get(c);
                const reason = row.get(c.replace(prefixCurrentPreference, prefixReason));

                const chancePrimary = row.get(c.replace(prefixCurrentPreference, prefixCurrentChance));
                const chanceSecondary = row.get(c.replace(prefixCurrentPreference, prefixSecondaryChance));

                const color = colord(partyColorMap.get(value) || "gray")
                    .mix(colord("white"), 0.3 + 0.07 * (10 - chancePrimary || 0))
                    .toHex();
                const colorSecondary = colord(partyColorMap.get(secondaryPreference) || "gray")
                    .mix("white", 0.3 + 0.07 * (10 - chanceSecondary || 0))
                    .toHex();
                const colorPrev = colord(partyColorMap.get(valuePrev) || "gray")
                    .mix("white", 0.5)
                    .toHex();

                const el = <React.Fragment key={c}>
                    {valuePrev && valuePrev !== value && <StepperItem
                        text={valuePrev}
                        subText=""
                        small
                        color={colorPrev}
                    />}
                    <StepperItem
                        text={chancePrimary ? `${value} (${chancePrimary})` : value}
                        secondaryText={chanceSecondary
                            ? `${secondaryPreference} (${chanceSecondary})`
                            : secondaryPreference}
                        secondaryColor={colorSecondary}
                        subText={`${c.replace(prefixCurrentPreference, "")}${reason ? "\n" + reason : ""}`}
                        color={color}
                        needsWide={countFromText <= 0}
                    />
                </React.Fragment>;

                countFromText += 1;

                if (reason !== undefined && reason.length > 20) {
                    countFromText = 0;
                }

                return el;
            }
            )}
        </div>
    );

    const demographicSection = (
        <Table collapsing className={styles.demographicSection}>
            <Table.Header>
                {colsDem
                    .reduce((resultArray, item, index) => {
                        const chunkIndex = Math.floor(index / 4);

                        if (!resultArray[chunkIndex]) {
                            resultArray[chunkIndex] = [];
                        }

                        resultArray[chunkIndex].push(item);

                        return resultArray;
                    }, [])
                    .map((ar, i) =>
                        <Table.Row key={i} className="text-base-content">{
                            ar.map((c, j) => {
                                let style = {};
                                const val = row.get(c);
                                if (colorsDem.has(val)) {
                                    const color = colorsDem.get(val);
                                    let textColor;
                                    if (colord(color).isLight()) {
                                        textColor = colord(color).darken(0.7).desaturate(0.3);
                                    } else {
                                        textColor = colord(color).lighten(0.7).desaturate(0.3);
                                    }
                                    style = {
                                        "background": colord(color).toHex(),
                                        "color": textColor.toHex(),
                                        "fontWeight": 600,
                                        "textAlign": "center"
                                    };
                                }
                                return <Table.Cell style={style} key={j}>{row.get(c)}</Table.Cell>;
                            })
                        }</Table.Row>
                    )
                }
            </Table.Header>
        </Table>
    );

    return (
        <Element
            {...props}
            primary
            setData={setData}
        >
            <div className={styles.rowWrapper}>
                {demographicSection}
                {stepperWrapper}
            </div>
        </Element>
    );
};

export { ElementResponderOverviewRow as ResponderOverviewRow };
