import { DataFrame } from "./DataFrame";

const match = (fun, pattern) => ({
    "function": fun,
    pattern
});

const fun = (_match, fun, args = []) => ({
    "match": typeof _match === "object" ? _match : match("equals", _match),
    "function": fun,
    args
});

const matchFun = (prefix, fun, args = []) => ({
    prefix,
    "function": fun,
    args
});

const actions = (...actions) => actions;
const source = {
    "catalogue": () => ({
        "type": "CATALOGUE"
    }),
    "database": (study, variables = [], allowMissingVariables = false) => ({
        "type": "DATABASE",
        study,
        "variables": variables.map((col) => typeof col === "object" ? col : match("equals", col)),
        "allowMissingVariables": allowMissingVariables
    }),
    "databases": (studies, variables = [], allowMissingVariables = false) => ({
        "type": "DATABASES",
        studies,
        "variables": variables.map((col) => typeof col === "object" ? col : match("equals", col)),
        "allowMissingVariables": allowMissingVariables
    }),
    "dataset": (folder, dataset, variables = [], version = "latest", allowMissingVariables = false) => ({
        "type": "DATASET",
        folder,
        dataset,
        "variables": variables.map((col) => typeof col === "object" ? col : match("equals", col)),
        version,
        "allowMissingVariables": allowMissingVariables
    }),
    "datasets": (folder, datasets, variables = [], version = "latest", allowMissingVariables = false) => ({
        "type": "DATASETS",
        folder,
        datasets,
        "variables": variables.map((col) => typeof col === "object" ? col : match("equals", col)),
        version,
        "allowMissingVariables": allowMissingVariables
    }),
    "join": (by, items, joinType) => ({
        "type": "JOIN",
        "by": by.map((col) => typeof col === "object" ? col : match("equals", col)),
        items,
        joinType
    }),
    "concat": (items, joinType) => ({
        "type": "CONCAT",
        items,
        joinType
    }),
    "raw": (data) => {
        if (data instanceof DataFrame) {
            data = data.map((row) => {
                const newRow = {};
                row.entries().forEach(([key, value]) => Object.defineProperty(newRow, key, value));
                return newRow;
            });
            return JSON.stringify(data);
        } else if (typeof data === "object") {
            // Take subscriptions into account
            if (!data.reference || !data.dependencies) {
                data = JSON.stringify(data);
            }
        }
        return ({
            "type": "RAW",
            data
        });
    }
};

const data = {
    "definition": (source, actions = []) => ({
        source,
        actions: actions.filter((a) => a !== undefined)
    })
};

const action = {
    "transform": (...funs) => ({
        "type": "TRANSFORM",
        "mappings": funs.filter((f) => f !== undefined)
    }),
    "transformMatch": (from, to, permutation, ...matchFuns) => ({
        "type": "TRANSFORM_MATCH",
        from,
        to,
        permutation,
        "mappings": matchFuns
    }),
    "filter": (...funs) => ({
        "type": "FILTER",
        "mappings": funs
    }),
    "group": (by, ...funs) => ({
        "type": "GROUP",
        "by": by.map((col) => typeof col === "object" ? col : match("equals", col)),
        "mappings": funs
    }),
    "sort": (ascending, ...by) => ({
        "type": "SORT",
        "by": by.map((col) => typeof col === "object" ? col : match("equals", col)),
        ascending
    }),
    "transmute": (fun, ...args) => ({
        "type": "TRANSMUTE",
        "function": fun,
        args
    }),
    "createColumn": (columnName, defaultValue) => ({
        "type": "CREATE_COLUMN",
        "name": columnName,
        "value": defaultValue
    }),
    "copyColumn": (match, newPattern) => ({
        "type": "COPY_COLUMN",
        "match": match,
        "new_pattern": newPattern
    }),
    "melt": (idVars, valueVars, varName, valueName) => ({
        "type": "MELT",
        "idVars": idVars.map((col) => typeof col === "object" ? col : match("equals", col)),
        "valueVars": valueVars.map((col) => typeof col === "object" ? col : match("equals", col)),
        "varName": varName,
        "valueName": valueName
    }),
    "unstack": (indexColumns, pivotColumn, valueColumn, aggregateFunction) => ({
        "type": "UNSTACK",
        "indexColumns": indexColumns.map((col) => typeof col === "object" ? col : match("equals", col)),
        "pivotColumn": typeof pivotColumn === "object" ? pivotColumn : match("equals", pivotColumn),
        "valueColumn": typeof valueColumn === "object" ? valueColumn : match("equals", valueColumn),
        aggregateFunction
    }),
    "resample": (indexColumn, indexType, frequency) => ({
        "type": "RESAMPLE",
        "index": typeof indexColumn === "object" ? indexColumn : match("equals", indexColumn),
        indexType,
        frequency
    }),
    "cache": () => ({
        "type": "CACHE"
    }),
    "debug": (sample = 50) => ({
        "type": "DEBUG",
        sample
    })
};

export { source, data, fun, action, match, actions, matchFun };
