import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";
import {
    colorGradient,
    findMatchingStackType,
    responseColorMap,
    stackTypes, sumReduce,
    uniques
} from "../../../UtilityFunctions";
import resizeObserver from "../../echarts/resizeObserver";
import EchartsChart from "../../echarts/EchartsChart";
import chartElementHelpers from "./chartElementHelpers";
import { getEventsArray } from "./lineHelpers";

const ElementChartLine = (props) => {
    /*
    * dataArgs attributes:
    *   period - the time variable
    *   prefix - if specified, prefix of dataFrame column names to be used. Mutually exclusive with displayedColumns and lineSplit!
    *   confidenceIntervalPrefix -
    *   labelMap -
    *       name - name of the line without prefix
    *       alias - name to use instead of column name (eg pop_ekre -> EKRE populaarsus)
    *       color - color for the line
    *
    * args attributes:
    *   scale - if specified, multiply all values in used columns by this value
    *   title - title of the bar chart
    *   options - echart options
    *   eventsArray - events to be shown on X axis
    *   verticalLines - labeled lines to be shown on Y axis
    *   height - chartHeight
    *   disableBackground - remove white background and shadow outline
    *   legend - whether legend is disabled or enabled
    * */
    const [data, setData] = useState(new DataFrame([]));
    const {
        "data": dataArgs, scale, title, "options": lineOptions, eventsArray, verticalLines,
        "height": chartHeight, disableBackground, legend, style, defaultOtherOption = "Ei oska öelda",
        toggleableChartType, defaultChartType = chartElementHelpers.ChartTypes.LINE,
        xAxisType = chartElementHelpers.XAxisTypes.category, dataUnit = "%"
    } = props;
    const [chartType, setChartType] = useState(defaultChartType);
    const [chartOptions, setChartOptions] = useState(null);

    const canRender = !data.isEmpty() && chartOptions != null;

    useEffect(() => {
        if (!data.isEmpty()) {
            const period = (dataArgs.period || "");

            const df = data.copy().filter((row) => row.has(period));

            // replace nans with -, echarts missing value
            df.getColumns().forEach(col => df.replaceValue(col, undefined, null));

            if (dataArgs.remapColumns) {
                df.renameColumns(dataArgs.remapColumns);
            }
            const ciEnabled = dataArgs.confidenceIntervalPrefix !== undefined;
            const ciPrefix = dataArgs.confidenceIntervalPrefix;

            const labelMap = (dataArgs.labelMap ? dataArgs.labelMap : {});
            Object.keys(labelMap).forEach(
                (key) => {
                    const object = labelMap[key.toString()];
                    // makes alias optional
                    object.alias = (object.alias ? object.alias : key);
                }
            );

            // find the element column names, remove prefixes
            let elementRawNames = [];
            if (dataArgs.prefix !== undefined) {
                elementRawNames = df.getColumns().filter((key) => key.startsWith(dataArgs.prefix));
                elementRawNames = elementRawNames.map((name) => {
                    const newName = name.replace(dataArgs.prefix, "");

                    if (!df.hasColumn(newName)) {
                        df.renameColumn(name, newName);
                    } else {
                        const newVals = df.getColumn(newName);
                        const oldVals = df.getColumn(name);
                        df.setColumn(newName, oldVals.map((val, i) => val + newVals[parseInt(i, 10)]));
                        df.removeColumn(name);
                    }

                    return newName;
                });
            }

            // replace elements with their corresponding aliases
            const elementGetAlias = (name) => {
                name = dataArgs.prefix ? name.replace(dataArgs.prefix, "") : name;
                return Object.prototype.hasOwnProperty.call(labelMap, name) ? labelMap[name.toString()].alias : name;
            };

            const aliasGetOriginal = (name) => {
                name = dataArgs.prefix ? name.replace(dataArgs.prefix, "") : name;
                const original = Object.keys(labelMap).find(key => labelMap[key.toString()].alias === name);
                return original || name;
            };

            const elementNames = uniques(elementRawNames.map(
                (name) => {
                    const newName = elementGetAlias(name);
                    if (newName !== name) {
                        if (Object.prototype.hasOwnProperty.call(labelMap, name)) {
                            labelMap[newName.toString()] = Object.assign({}, labelMap[name.toString()]);
                        }

                        if (!df.hasColumn(newName)) {
                            df.renameColumn(name, newName);
                        } else {
                            const newVals = df.getColumn(newName);
                            const oldVals = df.getColumn(name);
                            // in case the user wants to combine two aliases into one
                            df.setColumn(newName, oldVals.map((val, i) => val + newVals[parseInt(i, 10)]));
                            df.removeColumn(name);
                        }
                    }
                    return newName;
                }
            ));

            if (scale) {
                elementNames.forEach((column) => df.multiply(column, scale));
            }

            const xAxis = {
                "type": xAxisType || chartElementHelpers.XAxisTypes.category,
                "boundaryGap": false,
                "min": dataArgs.periodNameCol ? undefined : Math.min(...df.getColumn(period)),
                "max": dataArgs.periodNameCol ? undefined : Math.max(...df.getColumn(period)),
                "splitNumber": 1
            };

            if (xAxisType === chartElementHelpers.XAxisTypes.category) {
                // if categorical, set xAxis data to either aliased or raw period data
                xAxis.data = dataArgs.periodNameCol ? df.getColumn(dataArgs.periodNameCol) : df.getColumn(period);
            }

            let minValue = Number.MAX_SAFE_INTEGER;
            let maxValue = Number.MIN_SAFE_INTEGER;

            elementNames.forEach((name) => {
                const values = df.getColumn(name);
                values.forEach((val) => {
                    minValue = Math.min(minValue, val);
                    maxValue = Math.max(maxValue, val);
                });
            });
            minValue = Math.floor(minValue / 5) * 5;
            maxValue = Math.ceil(maxValue / 5) * 5;

            const times = df.getColumn(period);
            const lineOptionSeries = lineOptions?.series || [];

            let customGradient;
            let stacks;
            if (props.gradientOptions) {
                stacks = stackTypes[findMatchingStackType(elementNames, stackTypes).index];
                const stacksFound = stacks !== undefined;
                if (stacksFound) {
                    if (stacks.includes(defaultOtherOption)) {
                        const idx = stacks.indexOf(defaultOtherOption);
                        stacks.splice(idx, 1);
                        customGradient = colorGradient(props.gradientOptions.arr, stacks.length);
                        stacks.splice(idx, 0, defaultOtherOption);
                        customGradient.splice(idx, 0, responseColorMap.get(defaultOtherOption) || "#a5a5a5");
                    } else {
                        customGradient = colorGradient(props.gradientOptions.arr, stacks.length);
                    }
                } else {
                    stacks = elementNames;
                }
            }

            stacks = stacks || elementNames;

            const series = [...stacks.filter(s => df.hasColumn(s)).map((name, i) => {
                let color;
                if (Object.prototype.hasOwnProperty.call(labelMap, name)) {
                    color = labelMap[name.toString()].color;
                } else if (customGradient && stacks) {
                    color = customGradient[stacks.findIndex((n) => (n === name))];
                } else {
                    color = chartElementHelpers.genericPalette[i % chartElementHelpers.genericPalette.length];
                }

                if (responseColorMap.has(name)) {
                    color = responseColorMap.get(name);
                }
                const values = df.getColumn(name);

                const seriesData = values.map((value, j) => [times[j], value]);
                const element = {
                    name,
                    "type": "line",
                    "showSymbol": false,
                    "symbolSize": 6,
                    "symbol": "circle",
                    "seriesLayoutBy": "row",
                    "data": seriesData,
                    "xAxisIndex": 0,
                    "yAxisIndex": 0,
                    "color": color,
                    "lineStyle": {
                        "color": color,
                        "shadowColor": ciEnabled && "rgba(0, 0, 0, 0.4)",
                        "shadowBlur": ciEnabled && 2.5
                    },
                    "connectNulls": true,
                    "emphasis": {
                        "focus": chartType === chartElementHelpers.ChartTypes.LINE ? "series" : "none",
                        "scale": false
                    },
                    "markPoint": dataArgs.markPointFun !== undefined ? dataArgs.markPointFun(seriesData, name) : {
                        "silent": true,
                        "data": [{
                            name,
                            "coord": [seriesData.length - 1, seriesData[seriesData.length - 1][1]],
                            "symbol": "circle",
                            "symbolSize": 1,
                            "animation": false,
                            "emphasis": {
                                "label": {
                                    "fontSize": 14,
                                    "fontWeight": "bold",
                                    "show": false
                                }
                            },
                            "label": {
                                "position": "inside",
                                "fontWeight": "bolder",
                                "distance": 2,
                                formatter(_) {
                                    return name;
                                },
                                "color": color,
                                "textBorderColor": "white",
                                "textBorderWidth": 3
                            }
                        }]
                    },
                    "smooth": false
                };
                if (chartType === chartElementHelpers.ChartTypes.AREA) {
                    element.stack = "main";
                    element.areaStyle = {};
                }
                return element;
            }), ...lineOptionSeries];

            if (ciEnabled) {
                // add confidence bands
                const names = elementNames.map((name) => dataArgs.prefix ? name.replace(dataArgs.prefix, "") : name);
                names.forEach((name, i) => {
                    // console.log(name, aliasGetOriginal(name));
                    let color = chartElementHelpers.genericPalette[i % chartElementHelpers.genericPalette.length];
                    if (Object.prototype.hasOwnProperty.call(labelMap, name)) {
                        color = labelMap[name.toString()].color;
                    }
                    const values = df.getColumn(elementNames[parseInt(i, 10)]);
                    let ciValues;
                    try {
                        ciValues = df
                            .getColumn(ciPrefix + aliasGetOriginal(name))
                            .map(v => (v === 100) ? "-" : v); // if no value is present for col, the ci grows to 100. Remove ci then.
                    } catch (e) {
                        console.log(`Failed getting column ${ciPrefix + aliasGetOriginal(name)}. All columns: ${df.columns}`);
                        throw e;
                    }
                    if (ciValues === undefined) {
                        console.error(`Confidence interval column ${ciPrefix + name} missing from columns: ${df.getColumns()}`);
                    } else {
                        const upperData = values.map((value, j) => [times[parseInt(j, 10)], ciValues[parseInt(j, 10)] * 2]);
                        const lowerData = values.map((value, j) => [times[parseInt(j, 10)], value - ciValues[parseInt(j, 10)]]);

                        // insert lower bound before the line
                        series.push({
                            "name": elementNames[i],
                            "type": "line",
                            "data": lowerData,
                            "lineStyle": {
                                "opacity": 0,
                                "color": "transparent"
                            },
                            "connectNulls": true,
                            "stack": `${name}_confidence_band`,
                            "symbol": "none",
                            "animation": false
                        });
                        series.push({
                            "name": elementNames[i],
                            "type": "line",
                            "data": upperData,
                            "lineStyle": {
                                "opacity": 0,
                                "color": "transparent"
                            },
                            "connectNulls": true,
                            "areaStyle": {
                                "color": color,
                                "opacity": 0.15
                            },
                            "stack": `${name}_confidence_band`,
                            "symbol": "none",
                            "animation": false
                        });
                    }
                });
            }

            if (eventsArray && times.length >= 2) {
                series.push(getEventsArray(times, eventsArray));
            }

            if (verticalLines) {
                const lines = verticalLines.map(line => (
                    {
                        name: line.text,
                        yAxis: line.y,
                        label: {
                            formatter: "{b}",
                            position: line.alignment || "middle"
                        }
                    }
                ));
                series.push({
                    "type": "line",
                    "smooth": 0,
                    "markLine": {
                        "symbol": ["none", "none"],
                        "label": { "show": true },
                        "data": lines,
                        "lineStyle": {
                            "type": "solid",
                            "color": "gray"
                        }
                    }
                });
            }

            const options = {
                "tooltip": {
                    "show": true,
                    "trigger": "axis",
                    "position": (point, params, dom, rect, size) => {
                        // at the bottom, centered
                        const [xSize, xMax] = [size.contentSize[0], size.viewSize[0]];
                        const xPosition = point[0] - xSize / 2;
                        let y = size.viewSize[1] - size.viewSize[1] * 0.03 - 16; // because bottom grid is 3%
                        if (props.zoomableXAxis) {
                            y -= 2; // font size difference
                        }
                        return [Math.min(Math.max(xPosition, 0), xMax - xSize), y];
                    },
                    "formatter": (data) => {
                        return data[0].axisValueLabel;
                    },
                    "axisPointer": {
                        "type": "line",
                        "animation": false,
                        "label": {
                            "backgroundColor": "#505765"
                        }
                    },
                    "borderColor": "rgba(0, 0, 0, 0)",
                    "textStyle": {
                        "fontWeight": "bold",
                        "fontSize": props.zoomableXAxis ? 16 : 12
                    },
                    "extraCssText": "box-shadow: none; " +
                        "text-shadow: -3px 0 white, 0 3px white, 3px 0 white, 0 -3px white;" +
                        "padding: 1px 4px 4px 4px;" +
                        "border-radius: 0px;"
                },
                "grid": {
                    "borderWidth": 25,
                    ...(!legend && { "top": "15px" }),
                    "left": "3%",
                    "right": "4%",
                    "bottom": "3%",
                    "containLabel": true
                },
                "xAxis": xAxis,
                "yAxis": {
                    "type": "value",
                    "min": (v) => props.yMin !== undefined
                        ? props.yMin
                        : (chartType === chartElementHelpers.ChartTypes.LINE
                            ? Math.round(Math.max(v.min, minValue))
                            : 0
                        ),
                    "max": (v) => props.yMax !== undefined ? props.yMax
                        : (chartType === chartElementHelpers.ChartTypes.LINE
                            ? Math.round(Math.min(v.max, maxValue))
                            : stacks.map((s) => s[0]).reduce(sumReduce)
                        ),
                    "splitNumber": maxValue - minValue > 50 ? 10 : 5
                },
                "legend": legend && {
                    "data": (stacks || elementNames).map((name) => dataArgs.prefix ? name.replace(dataArgs.prefix, "") : name)
                },
                "dataZoom": [
                    {
                        "show": props.zoomableXAxis || false,
                        "realtime": true
                    }
                ],
                ...lineOptions,
                "series": series
            };

            if (toggleableChartType) {
                options.toolbox = {
                    itemSize: 25,
                    show: true,
                    top: 0,
                    right: 0,
                    feature: {
                        myToggleType: {
                            show: true,
                            title: "Vaheta graafikut",
                            icon: `image://${chartType === chartElementHelpers.ChartTypes.LINE ? chartElementHelpers.areaIconPath : chartElementHelpers.lineIconPath}`,
                            onclick: () => {
                                setChartOptions(undefined);
                                if (chartType === chartElementHelpers.ChartTypes.LINE) {
                                    setChartType(chartElementHelpers.ChartTypes.AREA);
                                } else {
                                    setChartType(chartElementHelpers.ChartTypes.LINE);
                                }
                            }
                        }
                    }
                };
            }

            setChartOptions(() => options);
        }
    }, [data, chartType]);

    if (canRender) {
        const onEvents = {
            "updateAxisPointer": (comp, chart) => {
                if (!Number.isNaN(comp.dataIndex)) {
                    chart.lastMouseX = comp.dataIndex;
                }

                if (!("lastMouseX" in chart)) {
                    return;
                }

                const x = chart.lastMouseX;
                if (!("renderedMouseX" in chart) || chart.renderedMouseX !== x) {
                    const option = chart.getOption();
                    const height = chart.getHeight();
                    const fontHeight = (12 + 6) / (height / 100) * 0.5;

                    const xCount = xAxisType !== "category" ? option.series[0].data.length : option.xAxis[0].data.length;

                    let xMin, xMax;
                    if (typeof option.xAxis[0].min === "number") {
                        // min and max are not present with dates, those use counts instead
                        xMin = option.xAxis[0].min;
                        xMax = option.xAxis[0].max;
                    } else {
                        xMin = 0;
                        xMax = xCount;
                    }

                    const seriesHasPoint = (s) => (
                        "markPoint" in s && "data" in s &&
                        s.markPoint.data.length > 0 && s.data.length > x);

                    let positions = option.series.map((s) => (seriesHasPoint(s) ? s.data[x][1] : -1000));
                    const originalPositions = [...positions];
                    if (chartType === chartElementHelpers.ChartTypes.AREA) {
                        // closure https://stackoverflow.com/a/47095386. Keep accumulating a to s, output s at
                        // particular moment. Puts positions "on top of each other", so instead of
                        // [1, 2, 3], we get [1, 3, 6]
                        positions = positions.map((s => a => Math.floor(s += a))(0));
                    }
                    for (let j = 0; j < 8; j++) {
                        const forces = positions.map(p1 => {
                            return positions
                                .map(p2 => {
                                    return Math.abs(p1 - p2) < fontHeight ? (fontHeight - Math.abs(p2 - p1)) * Math.sign(p2 - p1) : 0;
                                })
                                .reduce((a, b) => (a + b), 0) / positions.length;
                        });

                        positions = positions.map((p, i) => p - forces[parseInt(i)]);
                    }

                    const xPos = ((x) / xCount * (xMax - xMin) + xMin);
                    const newSeries = option.series.map((s, i) => {
                        const seriesValue = Math.round((chartType === chartElementHelpers.ChartTypes.LINE ? positions[parseInt(i)] : originalPositions[parseInt(i)]) * 10) / 10;
                        if (seriesHasPoint(s)) {
                            s.markPoint.data[0].label.show = chartType === chartElementHelpers.ChartTypes.LINE || seriesValue > 1;
                            // x-coordinate of help text
                            s.markPoint.data[0].coord[0] = xAxisType !== "category" ? s.data[xPos][0] : xPos;
                            // alignement. If x < 50% left, otherwise right
                            const align = xAxisType !== "category"
                                ? Number(s.data[xPos][0] > (xMin * 0.25 + xMax * 0.75)) -
                                Number(s.data[xPos][0] < (xMin * 0.75 + xMax * 0.25))
                                : Number(xPos > (xMin * 0.25 + xMax * 0.75)) -
                                Number(xPos < (xMin * 0.75 + xMax * 0.25));
                            if (align === 0) {
                                s.markPoint.label.align = "center";
                            } else {
                                s.markPoint.label.align = align > 0 ? "right" : "left";
                            }
                            // y-coordinate of the help text
                            s.markPoint.data[0].coord[1] = positions[parseInt(i)];
                            if (s.data[xPos]) {
                                const yVal = Math.round(s.data[xPos][1] * 10) / 10;
                                s.markPoint.data[0].label.formatter = (c) => {
                                    return `${c.data.name} ${yVal}${dataUnit}`;
                                };
                            }
                        }
                        return s;
                    });
                    chart.setOption({
                        ...option,
                        newSeries
                    }, false, true);
                    chart.renderedMouseX = x;
                }
            },
            "globalOut": (ev, chart) => {
                const option = chart.getOption();

                const seriesHasPoint = (s) => (
                    "markPoint" in s && "data" in s &&
                    s.markPoint.data.length > 0);

                const newSeries = option.series.map((s) => {
                    if (seriesHasPoint(s)) {
                        s.markPoint.data[0].label.show = false;
                    }
                    return s;
                });
                chart.setOption({
                    ...option,
                    newSeries
                }, false, true);
            }
        };

        const chart = (
            <React.Fragment>
                {title && <h3 className="text-base-content text-2xl p-4">{title}</h3>}
                <EchartsChart
                    style={{
                        "width": "100%",
                        "height": chartHeight || "500px",
                        ...(disableBackground === true ? style : {})
                    }}
                    resizeObserver={resizeObserver}
                    option={chartOptions}
                    onEvents={onEvents}
                />
            </React.Fragment>
        );

        return (
            <Element
                width="100%"
                {...props}
                primary
                setData={setData}>
                {disableBackground === true
                    ? chart
                    : <div
                        style={style}
                        className="p-2 bg-base-100 rounded-box mt-4 mb-4"
                    >{chart}</div>}
            </Element>
        );
    } else {
        return (
            <Element
                width="100%"
                {...props}
                primary
                setData={setData}/>);
    }
};

export { ElementChartLine as ChartLine };
