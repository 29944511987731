import React, { useState } from "react";
import { DataFrame } from "../../DSL/DataFrame";
import { Element } from "../Element";

const ElementWhiteBackground = (props) => {
    const [ignored, setData] = useState(new DataFrame([]));
    const { children, ...otherProps } = props;

    return (
        <Element
            width="100%"
            {...props}
            primary
            setData={setData}>
            <div
                className="white-background p-4 bg-base-100 rounded-box mb-4"
                {...otherProps}>
                {children}
            </div>
        </Element>);
};

export { ElementWhiteBackground as WhiteBackground };
