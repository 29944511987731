import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Modal } from "react-daisyui";

export const LogoutWarnDetector = ({ user }) => {
    const [shown, setShown] = useState(true);
    const [isGettingLoggedOut, setLogOut] = useState(false);
    const [hasClicked, setClicked] = useState(false);

    const showModal = () => {
        if (user.getLogoutWarnTimeLeft()) {
            setLogOut(true);
        }
    };

    useEffect(() => {
        setTimeout(showModal, user.getLogoutWarnTimeLeft());
    }, []);

    useEffect(() => {
        if (user.getLogoutWarnClicked()) {
            setClicked(true);
        }
    }, []);

    const onClose = () => {
        setShown(false);
        user.setLogoutWarnClicked();
    };

    const logout = () => {
        user.logout();
    };

    return (
        <Modal open={shown && isGettingLoggedOut && !hasClicked}>
            <div className="bg-base-100 rounded-box p-8">
                <Modal.Header className="text-2xl font-bold mb-4">Tähelepanu!</Modal.Header>
                <Modal.Body className="mb-4">
                    <p className="mb-2">
                        Sessioon aegub 5 minuti pärast.
                    </p>
                    <p className="mb-2">
                        Teil on võimalus praegu välja logida ning alustada uut seanssi. Samuti on teil võimalus seanssi jätkata, kuid 5 minuti pärast logib süsteem teid automaatselt välja.
                    </p>
                    <p className="mb-2">
                        Teie konto ja andmete turvalisuse tagamiseks saate olla korraga sisse logitud kuni tund aega.
                    </p>
                </Modal.Body>
                <Modal.Actions>
                    <ButtonGroup>
                        <Button onClick={onClose}>
                            Jätka
                        </Button>
                        <Button color="warning" onClick={logout}>
                            Logi välja
                        </Button>
                    </ButtonGroup>
                </Modal.Actions>
            </div>
        </Modal>
    );
};
