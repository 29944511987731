import React from "react";
import { Route } from "react-router-dom";
import SidebarWrapper from "./SideBarWrapper";
import { user } from "../auth/User";
import { MD5 as md5 } from "object-hash";
import TagManager from "react-gtm-module";
import env from "@beam-australia/react-env";

function PrivateRoute({ component, maxWidth, ...rest }) {
    return (
        <Route
            {...rest}
            component={() => {
                if (user.isLoggedIn()) {
                    const tagManagerArgs = {
                        gtmId: env("GTM_ID"),
                        dataLayer: {
                            userId: md5(user.getUsername())
                        }
                    };
                    TagManager.initialize(tagManagerArgs);
                    return <SidebarWrapper maxWidth={maxWidth}>{React.createElement(component)}</SidebarWrapper>;
                } else {
                    user.login();
                    return <></>;
                }
            }}
        />
    );
}

export default PrivateRoute;
