import React, { useCallback, useEffect, useState } from "react";
import { LinkIcon } from "@heroicons/react/24/solid";

export const NotificationContext = React.createContext({
    // eslint-disable-next-line no-unused-vars
    notify: (message, type, href) => {}
});

export default ({ children }) => {
    const [alerts, setAlerts] = useState([]);

    // Clean notifications
    useEffect(() => {
        const cleanInterval = setInterval(() => {
            if (alerts.length > 0 && Date.now() - alerts[0].createdAt > 10000) {
                setAlerts((old) => {
                    return old.slice(1);
                });
            }
        }, 1000);
        return () => clearInterval(cleanInterval);
    }, [alerts]);

    const notify = (message, type, href) => setAlerts((old) => ([
        ...old,
        {
            "type": type,
            "message": message,
            href,
            "createdAt": Date.now()
        }
    ]));

    const contextValue = {
        notify: useCallback((message, type, href) => notify(message, type, href), [])
    };

    return (
        <NotificationContext.Provider value={contextValue}>{children}
            <div className="toast toast-end mb-6 hover:cursor-pointer">
                {alerts.map((alert, i) => (
                    <div
                        className={`alert alert-${alert.type}`}
                        key={`${i} ${alert.message}`}
                        onClick={() => {
                            setAlerts((old) => old.filter((v, j) => j !== i));
                        }}
                    >
                        {alert.href
                            ? <a href={alert.href} target="_blank" rel="noreferrer">
                                <span><LinkIcon className="w-auto h-full"/>{alert.message}</span>
                            </a>
                            : <div>
                                <span>{alert.message}</span>
                            </div>
                        }
                    </div>
                ))}
            </div>
        </NotificationContext.Provider>
    );
};
