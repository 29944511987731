/**
 * @typedef {string} XAxisTypes
 * @enum {XAxisTypes}
 */
exports.XAxisTypes = {
    category: "category",
    value: "value",
    time: "time",
    log: "log"
};

/**
 * @typedef {string} ChartTypes
 * @enum {ChartTypes}
 */
exports.ChartTypes = {
    LINE: "LINE",
    AREA: "AREA"
};

exports.lineIconPath = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQ" +
    "yAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZ" +
    "HRkJz48c3ZnIGhlaWdodD0iMTAwJSIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1sa" +
    "W5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxLjU7IiB2ZXJzaW9uPSIxLjEiIHZpZ" +
    "XdCb3g9IjAgMCAyMjUzIDIyNTAiIHdpZHRoPSIxMDAlIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53M" +
    "y5vcmcvMjAwMC9zdmciIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3L" +
    "nczLm9yZy8xOTk5L3hsaW5rIj48cmVjdCBoZWlnaHQ9IjIyNTAiIGlkPSJsaW5lX2NoYXJ0X3BhcmFsbGVsIiBzdHlsZT0iZmlsb" +
    "Dpub25lOyIgd2lkdGg9IjIyNTAiIHg9IjEuOTk3IiB5PSIwIi8+PGc+PHBhdGggZD0iTTMxMC4zMzEsNDA4LjMzM2wtMTAwLC0yM" +
    "DBsLTEwMCwyMDBsMjAwLDBaIiBzdHlsZT0iZmlsbDojMjAyMDIwOyIvPjxwYXRoIGQ9Ik0xODg1LjMzLDE5ODMuMzNsMjAwLDEwM" +
    "GwtMjAwLDEwMGwtMCwtMjAwWiIgc3R5bGU9ImZpbGw6IzIwMjAyMDsiLz48cGF0aCBkPSJNMjEwLjMzMSwzNjguMzMzbC0wLDE3M" +
    "TVsMTcxNSwwIiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojMjAyMDIwO3N0cm9rZS13aWR0aDo2Ni42N3B4OyIvPjwvZz48cGF0a" +
    "CBkPSJNNDE4LjY2NCwxODc1bDYyNSwtNjI1bDIwOC4zMzMsNDE2LjY2N2w2MjUsLTYyNSIgc3R5bGU9ImZpbGw6bm9uZTtzdHJva" +
    "2U6IzIwMjAyMDtzdHJva2Utd2lkdGg6NjYuNjdweDsiLz48cGF0aCBkPSJNNDE4LjY2NCwxNTgzLjMzbDYyNSwtNzUwbDIwOC4zM" +
    "zMsMzMzLjMzNGw2MjUsLTc1MCIgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6IzIwMjAyMDtzdHJva2Utd2lkdGg6NjYuNjdweDsiL" +
    "z48L3N2Zz4=";

exports.areaIconPath = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQ" +
    "yAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZ" +
    "HRkJz48c3ZnIGhlaWdodD0iMTAwJSIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1sa" +
    "W5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxLjU7IiB2ZXJzaW9uPSIxLjEiIHZpZ" +
    "XdCb3g9IjAgMCAyMjUzIDIyNTAiIHdpZHRoPSIxMDAlIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53M" +
    "y5vcmcvMjAwMC9zdmciIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3L" +
    "nczLm9yZy8xOTk5L3hsaW5rIj48cmVjdCBoZWlnaHQ9IjIyNTAiIGlkPSJhcmVhX2NoYXJ0IiBzdHlsZT0iZmlsbDpub25lOyIgd" +
    "2lkdGg9IjIyNTAiIHg9IjEuOTk3IiB5PSIwIi8+PHBhdGggZD0iTTMxNC40OTcsMTk1OC4zM2wwLC02MjIuOTE2bDQxNi42NjcsL" +
    "TUyMi45MTdsNDE2LjY2NywyMDguMzMzbDYyNSwtNTIwLjgzM2wtMCwxNDU4LjMzIiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojM" +
    "jAyMDIwO3N0cm9rZS13aWR0aDo2Ni42N3B4OyIvPjxwYXRoIGQ9Ik0xNzcyLjgzLDE5NTguMzNsLTE0NTguMzMsMGwwLC00MTYuN" +
    "jY2bDQxNi42NjcsLTQxNi42NjdsNDE2LjY2NywyMjcuNTRsNjI1LC00MzUuODczbC0wLDEwNDEuNjciIHN0eWxlPSJmaWxsOiMyM" +
    "DIwMjA7c3Ryb2tlOiMyMDIwMjA7c3Ryb2tlLXdpZHRoOjY2LjY3cHg7Ii8+PGc+PHBhdGggZD0iTTI4OS40OTcsMzg3LjVsLTEwM" +
    "CwtMjAwbC0xMDAsMjAwbDIwMCwtMFoiIHN0eWxlPSJmaWxsOiMyMDIwMjA7Ii8+PHBhdGggZD0iTTE4NjQuNSwxOTYyLjVsMjAwL" +
    "DEwMGwtMjAwLDEwMGwwLC0yMDBaIiBzdHlsZT0iZmlsbDojMjAyMDIwOyIvPjxwYXRoIGQ9Ik0xODkuNDk3LDM0Ny41bDAsMTcxN" +
    "WwxNzE1LC0wIiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojMjAyMDIwO3N0cm9rZS13aWR0aDo2Ni42N3B4OyIvPjwvZz48L3N2Z" +
    "z4=";

exports.genericPalette = [
    "#970098",
    "#51af00",
    "#fb0099",
    "#65e59a",
    "#cb0037",
    "#037bfe",
    "#edcb55",
    "#d99aff",
    "#817700",
    "#ff578a",
    "#019572",
    "#8d1f00",
    "#4d5927",
    "#6c1448",
    "#ffa063"
];
