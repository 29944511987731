import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";
import { uniques } from "../../../UtilityFunctions";
import FormFieldHeader from "./FormFieldHeader";
import TMultiSelect from "../../../components/TMultiSelect";

const ElementDataFilter = (props) => {
    const { "data": { textColumn, filterColumn, defaultInteractionState }, setOutput, formProps, saveInteractionState } = props;
    const [data, setData] = useState(new DataFrame([]));
    const [filter, setFilter] = useState(defaultInteractionState || []);
    const [options, setOptions] = useState([]);

    const updateOptions = () => {
        let mainOptions;
        if (textColumn) {
            mainOptions = data.selectColumns([filterColumn, textColumn]).uniqueRows().map((row) => {
                return {
                    "key": row.get(filterColumn),
                    "text": row.get(textColumn),
                    "value": row.get(filterColumn)
                };
            });
        } else {
            mainOptions = uniques(data.getColumn(filterColumn)).flatMap((v) => v).map((value) => ({
                "key": value, "text": value, value
            }));
        }
        setOptions(mainOptions);
    };

    const updateOutput = () => {
        let filterFunction;
        if (filter.length > 0) {
            if (formProps.multiple === true) {
                filterFunction = (row) => filter.includes(row.get(filterColumn));
            } else {
                filterFunction = (row) => row.get(filterColumn) === filter;
            }
            const out = data.copy().filter(filterFunction);
            setOutput(out);
        } else {
            setOutput(data.copy());
        }
    };

    // on filter update, filter all the data
    useEffect(() => {
        if (!data.isEmpty()) {
            updateOptions();
            updateOutput();
        }
    }, [data]);

    useEffect(() => {
        if (!data.isEmpty()) {
            updateOutput();
        }
    }, [filter]);

    console.log("defaultInteractionState", defaultInteractionState);

    return (
        <Element
            {...props}
            setData={setData}>
            <FormFieldHeader>{`Filtreeri tunnuse "${filterColumn}" järgi`}</FormFieldHeader>
            <TMultiSelect
                defaultValue={defaultInteractionState}
                options={options}
                onChange={(values) => {
                    setFilter(values);
                    if (saveInteractionState !== undefined) {
                        saveInteractionState(values);
                    }
                }}
            />
        </Element>
    );
};

export { ElementDataFilter as DataFilter };
