import React, { useEffect, useRef, useState } from "react";
import styles from "./TMultiSelect.module.css";
import TIcon from "./TIcon";

export const NewFieldSearchable = ({ options, allowAdditions, selectOption, open, onCloseField, style }) => {
    const [hovered, setHovered] = useState(false);
    const [search, setSearch] = useState("");
    const [needsUpdate, setNeedsUpdate] = useState(false);
    const searchCallbackRef = useRef();
    const dropdownRef = useRef();

    const [dropdownPos, setDropdownPos] = useState({ x: 0, y: 0 });
    const [searchWidth, setSearchWidth] = useState(undefined);
    const [arrowSelection, setArrowSelection] = useState(undefined);
    const [filteredOptions, setFilteredOptions] = useState(options);

    useEffect(() => {
        if (search) {
            setFilteredOptions(
                options.filter(opt => opt.text && opt.text.toString().toLowerCase()
                    .includes(search.toString().toLowerCase()))
            );
        } else {
            setFilteredOptions(options);
        }
    }, [options, search]);

    const close = () => {
        if (onCloseField) {
            onCloseField();
        }
        setArrowSelection(undefined);
        if (searchCallbackRef.current) {
            searchCallbackRef.current.blur();
        }
    };

    const updateSearchDisplay = () => {
        if (searchCallbackRef.current) {
            const rect = searchCallbackRef.current.getBoundingClientRect();
            setSearchWidth(rect.width);
            setDropdownPos({
                x: rect.x,
                y: rect.y + rect.height
            });
        }
    };

    useEffect(() => {
        if (searchCallbackRef.current && open) {
            searchCallbackRef.current.focus();
            updateSearchDisplay();
        }
    }, [open]);

    useEffect(updateSearchDisplay, [search, needsUpdate]);

    useEffect(() => {
        // Focus on arrowselection
        if (arrowSelection && dropdownRef.current) {
            const scrollTo = dropdownRef.current.getElementsByClassName("arrow-selected")[0];
            scrollTo.scrollIntoView(false);
        }
    }, [arrowSelection]);

    const addSearchToSelection = () => {
        if (allowAdditions) {
            if (search !== "") {
                selectOption({
                    "key": search,
                    "text": search,
                    "value": search
                });
                setSearch("");
            }
        }
    };

    const onSearchKeyDown = (e) => {
        if (e.key === "Enter") {
            const idx = filteredOptions.findIndex(opt => opt.key === arrowSelection);
            if (idx === -1) {
                if (allowAdditions) {
                    addSearchToSelection();
                }
            } else {
                selectOption(filteredOptions[idx]);
            }
            setNeedsUpdate(!needsUpdate);
        } else if (e.key === "Escape") {
            close();
        } else if (e.key === "ArrowDown") {
            const idx = filteredOptions.findIndex(opt => opt.key === arrowSelection);
            if (filteredOptions.length > 0) {
                if (idx === -1) {
                    setArrowSelection(filteredOptions[0].key);
                } else {
                    setArrowSelection(filteredOptions[(idx + 1) % filteredOptions.length].key);
                }
            }
        } else if (e.key === "ArrowUp") {
            const idx = filteredOptions.findIndex(opt => opt.key === arrowSelection);
            if (filteredOptions.length > 0) {
                if (idx !== -1) {
                    if (idx === 0) {
                        setArrowSelection(undefined);
                    } else {
                        setArrowSelection(filteredOptions[(idx - 1) % filteredOptions.length].key);
                    }
                }
            }
        } else if (e.key === "Backspace") {
            if (search === "") {
                close();
            }
        }
    };

    return (
        <div
            className="badge hover:cursor-text justify-between m-1 bg-primary/20 hover:bg-primary-focus/50 relative h-auto"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                display: open ? "inline-block" : "none",
                ...style
            }}
        >
            <input
                value={search}
                ref={searchCallbackRef}
                onKeyDown={onSearchKeyDown}
                onBlur={() => !hovered && close()}
                className="bg-transparent gap-2 h-8"
                type="text"
                key="text-input"
                style={{
                    flexGrow: open ? 0.5 : 0,
                    width: !open && 0
                }}
                onInput={(event) => {
                    setSearch(event.target.value);
                }}
            />
            {allowAdditions && <TIcon
                name="plus-sm"
                size={6}
                className="hover:cursor-pointer"
                onClick={addSearchToSelection}
            />}
            <ul
                className={`
                ${!open && "hidden"}
                menu p-2 z-40
                shadow bg-neutral rounded-box w-52
                max-h-80 overflow-y-scroll overflow-x-hidden
                min-w-fit
            `}
                style={{
                    left: dropdownPos.x,
                    top: dropdownPos.y,
                    position: "fixed",
                    width: searchWidth
                }}
                ref={dropdownRef}
            >
                {filteredOptions
                    .map(opt =>
                        <li
                            onClick={() => {
                                selectOption(opt);
                                close();
                            }}
                            key={opt.key}
                            className={`
                            ${arrowSelection === opt.key && "bg-base-100"}
                            ${arrowSelection === opt.key && "arrow-selected"}
                            `}
                        >
                            <a>{opt.text}</a>
                        </li>
                    )
                }
            </ul>
        </div>
    );
};

const TMultiSelect = ({ options, onChange, defaultValue, allowAdditions, setIsOpen }) => {
    const [selected, setSelected] = useState([]);
    const [optionsLeft, setOptionsLeft] = useState(options);
    const [adding, setAdding] = useState(false);

    useEffect(() => {
        if (setIsOpen) {
            setIsOpen(adding);
        }
    }, [adding]);

    useEffect(() => {
        // select defaultValue
        if (Array.isArray(defaultValue)) {
            const defaultSelected = options.filter(
                opt => defaultValue.some(key => opt.key === key)
            );
            const defaultLeft = options.filter(opt =>
                defaultSelected.every(sel => opt.key !== sel.key)
            );
            setSelected(defaultSelected);
            setOptionsLeft(defaultLeft);
        }
    }, [options]);

    useEffect(() => {
        if (selected && onChange) {
            onChange(selected.map(opt => opt.value));
        }
    }, [selected]);

    const selectOption = (option) => {
        const value = option.value;
        setOptionsLeft(optionsLeft
            .filter(opt => opt.value !== value));
        setSelected([...selected, option]);
    };

    const unSelectOption = (option) => {
        const value = option.value;
        setSelected(selected
            .filter(opt => opt.value !== value));
        setOptionsLeft([...optionsLeft, option]);
    };

    return <div className="w-full input-bordered">
        <label
            onClick={() => {
                setAdding(true);
            }}
            className={`
                ${styles.selectionContainer}
                rounded-btn p-1 bg-neutral hover:bg-neutral-focus 
                flex flex-wrap min-h-12 hover:cursor-text`}
        >
            {selected.map(opt => <div
                className="badge badge-primary rounded-btn h-8 gap-2 m-1 hover:cursor-pointer justify-between"
                style={{
                    flexGrow: 0.5
                }}
                onClick={() => unSelectOption(opt)}
                key={opt.key}
            >
                {opt.text}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    className="inline-block w-4 h-4 stroke-current">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </div>)}
            <NewFieldSearchable
                open={adding}
                options={optionsLeft}
                allowAdditions={allowAdditions}
                selectOption={selectOption}
                onCloseField={() => setAdding(false)}
            />
        </label>
    </div>;
};

export default TMultiSelect;
