import ExecutionService from "../streaming/ExecutionService";

class AggregatorAPI {
    constructor(setDashboardLoadingState, subscriptionHandler) {
        this.subscriptionHandler = subscriptionHandler;
        this.executionService = new ExecutionService(setDashboardLoadingState);
        this.requestCount = 0;
    }

    cancelAggregation(elementId) {
        this.requestCount++;
        this.executionService.cancelAggregationStream(elementId);
    }

    async subscribeToAggregation(messageAggregate, elementId) {
        this.requestCount++;
        this.executionService.requestAggregationStream(messageAggregate, elementId, this.subscriptionHandler);
    }
}

export default AggregatorAPI;
