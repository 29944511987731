import * as AWS from "aws-sdk";
import React, { useEffect, useState } from "react";
import { user } from "../auth/User";
import env from "@beam-australia/react-env";
import { ArrowPathIcon, LinkIcon } from "@heroicons/react/24/solid";
import { SimpleDataTable } from "./SimpleDataTable";

const loadingRows = [[<ArrowPathIcon className="w-6 h-6 animate-spin" key="loader"/>, "", ""]];

function openKey(key) {
    const url = `https://kuk-staging-rapid-survey.s3.eu-central-1.amazonaws.com/${key}`;
    window.open(url, "_blank").focus();
}

const PublicDataScreen = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const listObjects = () => {
        const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
        const bucketParams = {
            Bucket: "kiir.kusitlus.com",
            Prefix: "2**"
        };
        s3.listObjects(bucketParams, function(err, data) {
            if (err) {
                console.log("List Objects error", err, err.stack);
                setRows([[`Viga andmete laadimisel: ${err.message}`]]);
            } else {
                const newRows = data.Contents
                    .filter(row => row.Key.endsWith("index.html"))
                    .map((row) => ([
                        row.Key
                            .replace("public/", "")
                            .replace("Välkuuringu ülevaade - ", "")
                            .replaceAll("_", " ")
                            .replace(".html", ""),
                        row.LastModified.toISOString().substring(0, 16).replace("T", " "),
                        <LinkIcon
                            key={row.Key}
                            className="h-6 w-6 justify-center hover:cursor-pointer hover:animate-pulse m-auto"
                            onClick={() => openKey(row.Key)}
                        />
                    ]))
                    .sort((a, b) => a[1] < b[1] ? 1 : -1);
                setRows(newRows);
            }
        });
    };

    useEffect(() => {
        user.getCognitoIdToken((jwtToken) => {
            const loginHost = `cognito-idp.eu-central-1.amazonaws.com/${env("COGNITO_USER_POOL_ID")}`;
            AWS.config.update({
                region: "eu-central-1",
                apiVersion: "latest",
                credentials: new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: "eu-central-1:fa536205-b3e0-4b3e-b6bb-a4e672292fc4",
                    Logins: {
                        [loginHost]: jwtToken
                    }
                }, {
                    "region": "eu-central-1"
                })
            });
            AWS.config.credentials.refresh(error => {
                if (error) {
                    console.error("Credentials update error", error);
                    setAuthenticated(false);
                } else {
                    setAuthenticated(true);
                }
            });
        });
    }, []);

    useEffect(() => {
        if (authenticated) {
            listObjects();
        } else {
            setRows(loadingRows);
        }
    }, [authenticated]);

    const [rows, setRows] = useState(loadingRows);
    const [header] = useState(["Fail", "Viimati uuendatud", "Link"]);

    return (
        <div className="pt-4">
            <h1>Kiiruuringud</h1>
            <p>Oluliste päevakajaliste sündmuste puhul teeme ka kiiruuringuid, mis üldjuhul
                toimuvad sündmusega samal päeval ning jõuavad siia nimekirja automaatselt.</p>
            <SimpleDataTable
                rows={rows}
                header={header}/>
        </div>
    );
};

export default PublicDataScreen;
