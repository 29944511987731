import { htmlStringToNumber } from "../../../UtilityFunctions";
import { colord, extend } from "colord";
import { normal } from "color-blend";
import namesPlugin from "colord/plugins/names";

extend([namesPlugin]);

/**
 * Set the cell style for a particular column.
 * A "heatmap" style fills the column cells with a colour corresponding to the cell value.
 * A "bar" style shows a single horizontal bar in each cell. There are only 2 colors - one for negative, and one for positive values.
 * @type {{
 * heatmap: (function(*=): ColumnStyle),
 * bar: (function(*=): ColumnStyle),
 * none: (function(*=): ColumnStyle)}}
 */
// example usage: tableColumnStyles.bar("Erinevus keskmisest").setMinMax(-100, 100).setBarColors("red", "green")
export const tableColumnStyles = {
    heatmap: (colName = "_all") => {
        const e = { visualType: "heatmap", colName };
        e.setGradient = (gradientArray) => { e.gradient = gradientArray; return e; };
        return e;
    },
    bar: (colName = "_all") => {
        const e = { visualType: "bar", colName };
        e.setMinMax = (min, max) => { e.min = min; e.max = max; return e; };
        e.setBarColors = (negColor, posColor) => { e.negColor = negColor; e.posColor = posColor; return e; };
        return e;
    },
    none: (colName = "_all") => ({ visualType: "none", colName })
};

export const initColumnStyleState = (columnStyleConfig) => {
    // columnStyles and sharedColorScheme
    if (columnStyleConfig && columnStyleConfig.columnStyles) {
        columnStyleConfig.columnStyles = columnStyleConfig.columnStyles.map((style) => (
            {
                ...style,
                ...(style.visualType === "heatmap" && {
                    gradientColors: (style.gradient ||
                        ["#313695", "#4575b4", "#74add1", "#abd9e9", "#e0f3f8", "#ffffbf", "#fee090", "#fdae61", "#f46d43"])
                        .map(colord).map(v => v.toRgb())
                })
            }
        ));
        columnStyleConfig.extremums = new Map();
        columnStyleConfig.includeAllColumns = columnStyleConfig.columnStyles.length > 0 && columnStyleConfig.columnStyles[0].colName === "_all";
        if (columnStyleConfig.sharedColorScheme) {
            columnStyleConfig.global = {
                visualType: "heatmap",
                gradientColors: (columnStyleConfig.sharedColorScheme?.gradient ||
                    ["#313695", "#4575b4", "#74add1", "#abd9e9", "#e0f3f8", "#ffffbf", "#fee090", "#fdae61", "#f46d43"])
                    .map(colord).map(v => v.toRgb())
            };
        }
        return columnStyleConfig;
    }
};

const updateGlobalExtremums = (styleConfigState, subDf) => {
    let [min, max] = [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER];
    subDf.columns.forEach((col) => {
        let arr = subDf.getColumn(col);
        arr = arr.map(htmlStringToNumber).filter((n) => typeof n === "number");
        min = Math.min(min, Math.min(...arr));
        max = Math.max(max, Math.max(...arr));
    });
    styleConfigState.global = {
        ...styleConfigState.global,
        min,
        max
    };
    return styleConfigState;
};

const updateColumnExtremums = (styleConfigState, subDf) => {
    const extremums = new Map();
    subDf.columns.forEach((col) => {
        let arr = subDf.getColumn(col);
        arr = arr.map(htmlStringToNumber).filter((n) => typeof n === "number");
        const min = Math.min(...arr);
        const max = Math.max(...arr);
        extremums.set(col, { min, max });
    });
    styleConfigState.extremums = extremums;
    return styleConfigState;
};

export const recalculateColumnStyle = (columnStyleConfig, df) => {
    let styleConfigState = initColumnStyleState(columnStyleConfig);
    if (styleConfigState && styleConfigState.columnStyles && df && !df.isEmpty()) {
        let colorColumns;
        if (styleConfigState.includeAllColumns === true) {
            colorColumns = df.getColumns()
                .filter(c =>
                    df.getColumn(c).filter(v => typeof htmlStringToNumber(v) === "number").length > df.getLength() * 0.1
                );
        } else if (styleConfigState.columnStyles instanceof Array) {
            colorColumns = df.getColumns().filter(c => styleConfigState.columnStyles.map(style => style.colName).includes(c));
        } else {
            throw Error(`ColorGradient must be true or array of colours! found ${styleConfigState.settings.columns}`);
        }

        styleConfigState.colorColumns = colorColumns;

        if (styleConfigState.sharedColorScheme) {
            styleConfigState = updateGlobalExtremums(styleConfigState, df.selectColumns(colorColumns));
        } else {
            styleConfigState = updateColumnExtremums(styleConfigState, df.selectColumns(colorColumns));
        }

        return styleConfigState;
    }
};

const getColumnExtremums = (state, column) => {
    if (state.sharedColorScheme) {
        return ({
            min: state.global.min,
            max: state.global.max
        });
    } else {
        const colStyle = state.columnStyles.find(colStyle => colStyle.colName === column);
        if (colStyle && colStyle.min && colStyle.max) {
            return ({
                min: colStyle.min,
                max: colStyle.max
            });
        } else {
            return state.extremums.get(column);
        }
    }
};

const getColumnStyleObj = (columnStyleState, column) => {
    let colStyle;
    if (columnStyleState.includeAllColumns) {
        column = "_all";
    }
    if (columnStyleState.sharedColorScheme) {
        colStyle = columnStyleState.global;
    } else {
        colStyle = columnStyleState.columnStyles.find(colStyle => colStyle.colName === column);
    }
    return colStyle;
};

const getColumnStyleCss = {
    "heatmap": (number, colStyle, columnExtremums) => {
        const u = (number - columnExtremums.min) / (columnExtremums.max - columnExtremums.min);
        let colorIndex = (colStyle.gradientColors.length - 1) * u;
        const localU = colorIndex % 1;
        colorIndex = Math.max(0, Math.floor(colorIndex));
        const blended = normal(
            { ...colStyle.gradientColors[parseInt(colorIndex)], "a": 1.0 - localU },
            { ...colStyle.gradientColors[colorIndex + 1], "a": localU });
        blended.a = 0.75;
        return {
            "style": {
                "background": colord(blended).toHex(),
                "color": colord(blended).darken(0.7).desaturate(0.3).toHex(),
                "fontWeight": 600,
                "textAlign": "center"
            }
        };
    },
    "bar": (number, colStyle, columnExtremums) => {
        const u = (number - columnExtremums.min) / (columnExtremums.max - columnExtremums.min);
        let bgColor;
        if (colStyle.negColor && colStyle.posColor) {
            bgColor = number < 0 ? colStyle.negColor : colStyle.posColor;
        } else {
            bgColor = number < 0 ? "rgba(220,94,85,0.75)" : "rgba(86,203,102,0.75)";
        }
        const centreLoc = (0 - columnExtremums.min) / (columnExtremums.max - columnExtremums.min);
        let gradientStart, gradientEnd;
        if (number < 0) {
            gradientStart = u + 0.1 * (centreLoc - u);
            gradientEnd = centreLoc;
        } else {
            gradientStart = centreLoc;
            gradientEnd = u - 0.1 * (u - centreLoc);
        }
        const barBackground = `linear-gradient(to right, transparent 0% ${gradientStart * 100}%,
        ${bgColor} ${gradientStart * 100}% ${gradientEnd * 100}%,
        transparent ${gradientEnd * 100}%)`;

        return {
            "style": {
                "background-image": barBackground,
                "color": "black",
                "fontWeight": 600,
                "textAlign": "center",
                "background-size": "100% 72%",
                "background-repeat": "no-repeat",
                "background-position": "center"
            }
        };
    },
    "none": () => {}
};

export const getColumnStyle = (columnStyleState, column, html) => {
    if ((!columnStyleState?.colorColumns) || !columnStyleState.colorColumns.includes(column)) return {};
    const number = htmlStringToNumber(html);
    if (typeof number !== "number") return {};
    const columnExtremums = getColumnExtremums(columnStyleState, column);
    if (!columnExtremums) return {};
    const colStyle = getColumnStyleObj(columnStyleState, column);
    return getColumnStyleCss[colStyle.visualType](number, colStyle, columnExtremums);
};
