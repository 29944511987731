
export const getEventsArray = (times, eventsArray) => {
    let lastX = 0;
    let labelUp = false;
    const events = eventsArray.map(v => {
        const eventDate = Date.parse(v.date);
        const processedTimes = times.map(Date.parse);
        const x = processedTimes.findIndex(t => t >= eventDate);
        const xDate = Date.parse(times[x]);

        if (x === -1 || (x === 0 && eventDate < xDate)) { return undefined; }
        const xNextDate = Date.parse(times[Math.min(times.length - 1, x + 1)]) || xDate;

        if (eventDate > xNextDate) { return undefined; }

        if (x - lastX < times.length / 10 ? true : (labelUp === false)) { labelUp = !labelUp; }
        const eventOptions = {
            "label": { "formatter": v.name, "position": labelUp ? "end" : "start" },
            "xAxis": x
        };
        lastX = x;
        return eventOptions;
    }).filter(v => v !== undefined);
    return {
        "type": "line",
        "smooth": 0,
        "markLine": {
            "symbol": ["none", "none"],
            "label": { "show": true },
            "data": events,
            "lineStyle": {
                "type": "solid",
                "color": "gray"
            }
        }
    };
};
