import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { DataFrame } from "../../DSL/DataFrame";

const ElementDataTransformer = (props) => {
    const [ignored, setData] = useState(new DataFrame([]));
    const { children, setOutput } = props;

    const transform = (data) => {
        if (props.data !== undefined) {
            const result = props.data.transformer(props.data, data);
            if (result !== undefined) {
                setOutput(result);
            }
        }
    };

    useEffect(() => {
        props.subscribeInput((data) => {
            transform(data);
        });
    }, []);

    return (
        <Element
            {...props}
            primary
            setData={setData}
        >
            <div>{children}</div>
        </Element>
    );
};

export { ElementDataTransformer as DataTransformer };
