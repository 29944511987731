import { matchFun } from "./aggregatorModels2";

class TransformMatch extends Function {
    constructor(wrapper) {
        super("...args", "return this.wrapper(...args)");
        this.wrapper = wrapper;
        const bind = this.bind(this);
        bind.wrapper = wrapper;
        return bind;
    }

    /**
     * Takes a sliding average that ensures each window has a certain minimum sample size. The sample size column is
     * the column_to of the TransformMatch functino.
     *
     * @param {string} newColumnPrefix - prefix of the newly created columns
     * @param {string} idxCol - slide on this column.
     * @param {number} windowSampleSize - window minimum sample size.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     */
    slidingAverageBySampleSize(newColumnPrefix, idxCol, windowSampleSize, partitionColumn = null) {
        const args = [idxCol, windowSampleSize];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(matchFun(newColumnPrefix, "sliding_average_by_sample_size", args));
    }

    /**
     * Takes a sliding sum that ensures each window has a certain minimum sample size. The sample size column is
     * the column_to of the TransformMatch functino.
     *
     * @param {string} newColumnPrefix - prefix of the newly created columns
     * @param {string} idxCol - slide on this column.
     * @param {number} windowSampleSize - window minimum sample size.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     */
    slidingSumBySampleSize(newColumnPrefix, idxCol, windowSampleSize, partitionColumn = null) {
        const args = [idxCol, windowSampleSize];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(matchFun(newColumnPrefix, "sliding_sum_by_sample_size", args));
    }

    /**
     *
     *
     * @param {string} newColumnPrefix - prefix of the newly created columns
     * @param {string} idxCol - slide on this column.
     * @param {number} windowWidth - window size.
     * @param {string} windowType - window type, can be 'number', 'weeks', 'days'.
     * @param {?string} partitionColumn - optional partition column.
     * @return {Object} - function object.
     */
    slidingAverageMatchWeight(newColumnPrefix, idxCol, windowWidth, windowType, partitionColumn = null) {
        const args = [idxCol, windowWidth, windowType];
        if (partitionColumn) {
            args.push(partitionColumn);
        }
        return this.wrapper(matchFun(newColumnPrefix, "sliding_average_match_weight", args));
    }

    /**
     * Divides a group of columns from another group of columns.
     *
     * @param {string} newColumnPrefix - prefix of the newly created columns.
     * @return {Object} - function object.
     */
    subtractColumn(newColumnPrefix) {
        return this.wrapper(matchFun(newColumnPrefix, "subtract_column", []));
    }

    /**
     * Divides a group of columns by another group of columns.
     *
     * @param {string} newColumnPrefix - prefix of the newly created columns.
     * @return {Object} - function object.
     */
    divideColumn(newColumnPrefix) {
        return this.wrapper(matchFun(newColumnPrefix, "divide_column", []));
    }

    /**
     * Multiplies a group of columns by another group of columns.
     *
     * @param {string} newColumnPrefix - prefix of the newly created columns.
     * @return {Object} - function object.
     */
    multiplyColumn(newColumnPrefix) {
        return this.wrapper(matchFun(newColumnPrefix, "multiply_column", []));
    }

    /**
     * Calculates confidence interval for a proportion.
     * https://web.sonoma.edu/users/w/wilsonst/courses/math_165/Solns2/p4/default.html
     *
     * @param {string} newColumnPrefix - prefix of the newly created columns
     * @param {number} confidenceInterval - confidence interval (80-100)
     * @return {Object} - function object.
     * */
    confidenceIntervalProportion(newColumnPrefix, confidenceInterval) {
        return this.wrapper(matchFun(newColumnPrefix, "confidence_interval_proportion", [confidenceInterval]));
    }
}

export default TransformMatch;
