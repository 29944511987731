import React, { useEffect, useState } from "react";
import { Element } from "../Element";
import { Button, ButtonGroup } from "react-daisyui";
import { DataFrame } from "../../DSL/DataFrame";
import { uniques } from "../../../UtilityFunctions";
import FormFieldHeader from "./FormFieldHeader";

const ElementButtonSelect = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const [options, setOptions] = useState([]);
    const { setOutput, "data": dataArgs, saveInteractionState, formProps, style } = props;
    const { defaultInteractionState, title } = dataArgs;
    const { "multiple": isMultiselect } = formProps;
    const [filter, setFilter] = useState(undefined);

    /*
    * This is a drop-down select element.
    *
    * dataArgs:
    *   filterColumn   - column in data from which filter keys and values will be chosen
    *   textColumn     - column in data from which the text displayed in the filter will be chosen. Optional.
    *   defaultInteractionState  - the placeholder value for the selected element. Optional.
    * */

    useEffect(() => {
        let interactionState = defaultInteractionState;
        if (isMultiselect && !Array.isArray(interactionState)) {
            if (interactionState) {
                interactionState = [interactionState];
            } else {
                interactionState = [];
            }
        }
        setFilter(interactionState);
    }, []);

    const isSelected = (option) => {
        if (isMultiselect) {
            if (filter !== undefined) {
                return filter.includes(option.value);
            }
            return false;
        } else {
            return filter === option.value;
        }
    };

    const select = (option) => {
        if (!isMultiselect) {
            setFilter(option.value);
        } else {
            setFilter((old) => {
                if (!Array.isArray(old)) {
                    return [option.value];
                } else {
                    if (!isSelected(option)) {
                        return [option.value, ...old];
                    } else {
                        return old.filter(function(item) {
                            return item !== option.value;
                        });
                    }
                }
            });
        }
    };

    const getUpdatedOptions = (data) => {
        let mainOptions;
        if ("textColumn" in dataArgs) {
            mainOptions = data.selectColumns([dataArgs.filterColumn, dataArgs.textColumn]).uniqueRows().map((row) => {
                return {
                    "key": row.get(dataArgs.filterColumn),
                    "text": row.get(dataArgs.textColumn),
                    "value": row.get(dataArgs.filterColumn)
                };
            });
        } else {
            mainOptions = uniques(data.getColumn(dataArgs.filterColumn)).flatMap((v) => v).map((value) => ({
                "key": value, "text": value, value
            }));
        }
        return mainOptions;
        // filter values with too many unique options
    };

    useEffect(() => {
        if (!data.isEmpty()) {
            setOptions(getUpdatedOptions(data));
        }
    }, [data]);

    // on filter update, filter all the data
    useEffect(() => {
        if (filter) {
            setOutput(filter);
            if (saveInteractionState !== undefined) {
                saveInteractionState(filter);
            }
        }
    }, [data, filter]);

    return (
        <Element
            {...props}
            setData={setData}>
            <div style={{
                position: "relative"
            }}>
                <FormFieldHeader>{title}</FormFieldHeader>
                <ButtonGroup
                    className="w-full flex flex-row flex-wrap"
                    style={{
                        ...style,
                        zIndex: 1
                    }}
                    labeled={true}
                >
                    {options.map((option) => {
                        const btn = <Button
                            className="flex-grow"
                            key={option.key}
                            value={option.value}
                            active={isSelected(option)}
                            onClick={() => select(option)}
                        >{option.text}</Button>;
                        return btn;
                    })}
                </ButtonGroup>
            </div>
        </Element>
    );
};

export { ElementButtonSelect as ButtonSelect };
