import React, { useState } from "react";
import { DataFrame } from "../../DSL/DataFrame";
import { Element } from "../Element";

const ElementGroup = (props) => {
    const [ignored, setData] = useState(new DataFrame([]));
    const { children, ...otherProps } = props;

    return (
        <Element
            width="100%"
            {...props}
            primary
            setData={setData}>
            <div
                style={{ "paddingTop": "1rem" }}
                className="group"
                {...otherProps}>
                {children}
            </div>
        </Element>);
};

export { ElementGroup as Group };
