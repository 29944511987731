import React from "react";
import { Message } from "semantic-ui-react";

const ElementMessage = (props) => {
    const { header, content, list, ...otherProps } = props;
    return (
        <Message
            data-testid="message"
            header={header}
            content={content}
            list={list}
            {...otherProps}>
        </Message>
    );
};

export { ElementMessage as Message };
