import React, { useState } from "react";
import { Element } from "../Element";
import { DataChildren } from "../ElementDataChildren";
import { Step as ElementStep } from "./ElementStep";
import TStepGroup from "../../../components/TStepGroup";
import TStep from "../../../components/TStep";

const ElementStepGroup = (props) => {
    const [ignored, setData] = useState([]);
    const { children, ...otherProps } = props;
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <Element
            {...otherProps}
            setData={setData}>
            <TStepGroup
                style={{
                    minHeight: "55vh",
                    width: "100%"
                }}
            >
                {children.map((c, i) => {
                    if (c.type === DataChildren) {
                        throw Error("Step group does not support DataChildren yet!");
                    } else {
                        return <React.Fragment key={c.props.id}>
                            <ElementStep
                                {...c.props}
                                index={i}
                                activeIndex={activeIndex}
                                setActiveIndex={setActiveIndex}
                                /* eslint-disable-next-line react/no-children-prop */
                                children={[]}
                            />
                            {c.props.children && c.props.children.length > 0 &&
                                <TStep style={{ padding: "0px", flex: 0, flexDirection: "column" }}>
                                    {c.props.children}
                                </TStep>
                            }
                        </React.Fragment>;
                    }
                })}
            </TStepGroup>
        </Element>
    );
};

export { ElementStepGroup as StepGroup };
