import React, { createRef, useEffect, useState } from "react";
import "./Dashboard.css";
import Dashboard from "./Dashboard";
import SubscriptionHandler from "./subscriptions/SubscriptionHandler";
import jsonSchema from "./DSL/jsonSchema";
import DashboardElementBuilder, { DashboardElementBuilderContext } from "./DashboardElementBuilder";
import { Ref } from "semantic-ui-react";
import DashboardDebugStatistics from "./DashboardDebugStatistics";

export const dashboardLoadingStates = {
    "CONNECTING": "Töölaud ühendub arvutuskeskusega",
    "CONNECTED": "Töölaud on ühendatud arvutuskeskusega.",
    "ERROR": "Töölaual ei õnnestunud ühenduda arvutuskeskusega. " +
        "Kui te pole paar tundi keskkonnas olnud, siis oodake paar minutit ja proovige uuesti. " +
        "Ressursi kokkuhoiu eesmärgil lülitub arvutuskeskus automaatselt välja, kui keskkonda ei kasutata."
};

const DashboardBuilder = (props) => {
    const contextReference = createRef();
    const [dashboardLoadingState, setDashboardLoadingState] = useState(dashboardLoadingStates.CONNECTING);
    const { json, name } = props;
    const [dashboardElementBuilder, setElementBuilder] = useState(null);
    const [children, setChildren] = useState(null);

    useEffect(() => {
        if (dashboardElementBuilder == null) {
            setElementBuilder(new DashboardElementBuilder(new SubscriptionHandler(setDashboardLoadingState), contextReference, name, json.meta.version));
        }
    }, []);

    useEffect(() => {
        if (dashboardLoadingState === dashboardLoadingStates.CONNECTED) {
            setChildren(json.elements.map((el, index) => dashboardElementBuilder.build(el, index)));
        } else {
            let progressBar;
            if (dashboardLoadingState === dashboardLoadingStates.ERROR) {
                progressBar = <progress className="progress progress-error" value={100}></progress>;
            } else {
                progressBar = <progress className="progress progress-secondary"></progress>;
            }
            setChildren(
                <div className="p-4 rounded-box bg-base-100 text-center">
                    {progressBar}
                    {dashboardLoadingState}
                </div>
            );
        }
    }, [dashboardLoadingState]);

    // Schema validation
    if (!jsonSchema(json)) {
        console.warn("JSON SCHEMA NOT VALIDATED FOR \n", json);
        const dataPathToObject = (object, path) => {
            if (path.length >= 1) {
                return dataPathToObject(object[path[0]], path.slice(1));
            } else {
                const ret = Object.assign({}, object);
                Object.keys(ret).forEach((key) => {
                    ret[key.toString()] = typeof ret[key.toString()] === "object"
                        ? (Array.isArray(ret[key.toString()]) ? "[...]" : "{...}")
                        : ret[key.toString()];
                });
                return ret;
            }
        };

        const error = `Schema error in: ${json.meta.title}` +
            jsonSchema.errors.map(
                (error) => (`
                message: ${error.message}
                params:  ${JSON.stringify(error.params)}
                path:    ${error.dataPath}
                json:\n${JSON.stringify(dataPathToObject(json, String(error.data).split("/").slice(1)), null, 2)}`)
            ).join("\n");
        throw new Error(error);
    }

    document.title = "KuK - " + json.meta.title;

    return (
        <DashboardElementBuilderContext.Provider value={dashboardElementBuilder}>
            <Ref innerRef={contextReference}>
                <Dashboard
                    {...props}
                    title={json.meta.title}
                    subtitle={json.meta.subtitle}
                >
                    <div
                        style={{
                            padding: ".78571429rem 1rem"
                        }}
                    >
                        {children}
                    </div>
                </Dashboard>
            </Ref>
            <DashboardDebugStatistics/>
        </DashboardElementBuilderContext.Provider>
    );
};

export default DashboardBuilder;
