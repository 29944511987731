// eslint-disable-next-line no-unused-vars
import * as AWS from "aws-sdk";
// necessary so RSocket does not overwrite base Buffer class before AWS requires it
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./index.scss";

import DashboardSelectScreen from "./screens/DashboardSelectScreen";
import PrivateRoute from "./components/PrivateRoute";
import { AdBlockDetector } from "./components/AdBlockDetector";
import { LogoutWarnDetector } from "./components/LogoutWarnModal";
import PublicDataScreen from "./screens/PublicDataScreen";
import HomeScreen from "./screens/HomeScreen";
import { user } from "./auth/User";
import { Theme } from "react-daisyui";
import Cookies from "js-cookie";
import NotificationProvider from "./screens/NotificationProvider";
import { DebugModeProvider } from "./debug/DebugModeProvider";
import RapidSurveyScreen from "./screens/RapidSurveyScreen";
import RoadmapScreen from "./screens/RoadmapScreen";

function Routes() {
    return (
        <Router>
            <Switch>
                <PrivateRoute
                    path="/dashboard/view"
                    component={DashboardSelectScreen}/>
                <PrivateRoute
                    maxWidth={1132}
                    path="/public_data"
                    exact
                    component={PublicDataScreen}/>
                <PrivateRoute
                    maxWidth={1132}
                    path="/roadmap"
                    exact
                    component={RoadmapScreen}/>
                <PrivateRoute
                    maxWidth={1132}
                    path="/rapid-survey"
                    exact
                    component={RapidSurveyScreen}/>
                <PrivateRoute
                    maxWidth={1132}
                    path=""
                    exact
                    component={HomeScreen}/>
                <Route
                    path="/health-check"
                    exact>
                    <div>Health check is fine!</div>
                </Route>
            </Switch>
        </Router>
    );
}

function App() {
    let dataTheme = "light";
    if (Cookies.get("kuk-data-theme")) {
        dataTheme = Cookies.get("kuk-data-theme");
        if (dataTheme !== "dark" && dataTheme !== "light") {
            console.log(`Invalid theme ${dataTheme}, resetting!`);
            dataTheme = "light";
            Cookies.remove("kuk-data-theme");
        }
    }

    const [userVar] = useState(user);
    return (
        <Theme dataTheme={dataTheme}>
            <DebugModeProvider>
                <NotificationProvider>
                    <Routes/>
                    <LogoutWarnDetector user={userVar}/>
                </NotificationProvider>
                <AdBlockDetector/>
            </DebugModeProvider>
        </Theme>
    );
}

export default App;
