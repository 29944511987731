import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import { DataFrame } from "../../DSL/DataFrame";
import { Element } from "../Element";

const ElementGridColumn = (props) => {
    const [ignored, setData] = useState(new DataFrame([]));
    const { children, ...otherProps } = props;

    return (
        <Element
            {...props}
            primary
            setData={setData}>
            <Grid.Column
                data-testid="grid-column"
                className="pt-0 pb-1"
                {...otherProps}>
                {children}
            </Grid.Column>
        </Element>);
};

export { ElementGridColumn as GridColumn };
