import React, { useState } from "react";
import { Header } from "semantic-ui-react";
import { useToggle } from "../../../UtilityFunctions";
import { marked } from "marked";
import TagManager from "react-gtm-module";
import { Button, Form, Modal } from "react-daisyui";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

const helpOpenedEvent = {
    dataLayer: {
        event: "openHelp"
    }
};

const HelpModal = (props) => {
    const { toggled, toggle, helpInfo } = props;

    return (
        <>
            <Button
                key="help_button"
                className="ml-2 btn-primary"
                onClick={() => {
                    TagManager.dataLayer(helpOpenedEvent);
                    toggle(true);
                }}
            >
                Töölaua info
                <InformationCircleIcon className="pl-2 w-6 h-6"/>
            </Button>
            <Modal
                open={toggled}
                onClose={() => toggle(false)}
            >
                <div className="bg-base-100 rounded-box p-8 max-w-6xl">
                    <Modal.Header className="text-2xl font-bold mb-4">Töölaua info</Modal.Header>
                    <Modal.Body>
                        {helpInfo.description && <>
                            <span dangerouslySetInnerHTML={{ __html: marked(String(helpInfo.description)) }}/>
                        </>}
                        {helpInfo.datasetInfo && <>
                            <Header>Kasutatud andmed</Header>
                            <span dangerouslySetInnerHTML={{ __html: marked(String(helpInfo.datasetInfo)) }}/>
                        </>}
                        {helpInfo.errorMargins && <>
                            <Header>Veamäärad</Header>
                            <span dangerouslySetInnerHTML={{ __html: marked(String(helpInfo.errorMargins)) }}/>
                        </>}
                        {helpInfo.accessRestrictions && <>
                            <Header>Ligipääsupiirangud</Header>
                            <span dangerouslySetInnerHTML={{ __html: marked(String(helpInfo.accessRestrictions)) }}/>
                        </>}
                    </Modal.Body>
                    <Modal.Actions>
                        <Button onClick={() => toggle(false)} className="mt-4">
                            Sulge
                        </Button>
                    </Modal.Actions>
                </div>
            </Modal>
        </>
    );
};

const ElementForm = (props) => {
    const { setOutput, children, submitButtonText = "Submit", helpInfo } = props;
    const [update, setUpdate] = useState(0);
    const [helpInfoOn, toggleHelpInfo] = useToggle(false);

    return (
        <div
            className="bg-base-100 p-4 border-4 border-base-100 form-container"
        >
            <Form className="relative">
                {children.map((child, index) => {
                    return (
                        <div
                            className="inline-block w-full"
                            key={`${index}`}>
                            {child}
                        </div>);
                })}
                <div className="mt-4">
                    <Button
                        key={`${children.length}`}
                        className="btn-primary"
                        onClick={() => {
                            const newUpdate = update + 1;
                            setUpdate(newUpdate);
                            setOutput(newUpdate);
                        }}>
                        {submitButtonText}
                    </Button>
                    {helpInfo && <HelpModal
                        toggled={helpInfoOn}
                        toggle={toggleHelpInfo}
                        helpInfo={helpInfo}
                    />}
                </div>
            </Form>
        </div>);
};

export { ElementForm as Form };
