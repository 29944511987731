import React, { useEffect, useState } from "react";
import { Input, List, Segment } from "semantic-ui-react";
import { DataFrame } from "../../DSL/DataFrame";
import { Element } from "../Element";
import TSegment from "../../../components/TSegment";

const FileTreeFolder = (props) => {
    const [open, setOpen] = useState(false);
    const { header, children } = props;

    return <List.Item
    >
        <List.Icon name={open ? "folder open" : "folder"}/>
        <List.Content onClick={() => setOpen((prev) => !prev)}>
            <List.Header>{header}</List.Header>
        </List.Content>
        {open &&
            <List.List>
                {children}
            </List.List>}
    </List.Item>;
};

const ElementFileTree = (props) => {
    const [data, setData] = useState(new DataFrame([]));
    const [selection, setSelection] = useState(null);
    const [search, setSearch] = useState("");
    const { children, setOutput } = props;

    useEffect(() => {
        if (selection !== null) {
            setOutput(selection);
        }
    }, [selection]);

    useEffect(() => {
        console.log("search changed to", search);
    }, [search]);

    const tree = new Map();
    if (!data.isEmpty()) {
        data.getColumn(props.pathColumn ? props.pathColumn : "dataset")
            .sort()
            .forEach(v => {
                let level = tree;
                v.split("/").forEach((name) => {
                    if (!level.has(name)) {
                        level.set(name, new Map());
                    }
                    level = level.get(name);
                });
            });
    }

    let buildTree;
    // eslint-disable-next-line prefer-const
    buildTree = (tree, path) => {
        const els = [];
        const entries = Array.from(tree.entries());
        // skip folders that only contain a single other element
        // instead directly show that other element.
        if (tree.size === 1) {
            const [key, value] = entries[0];
            return buildTree(value, path.concat([key]));
        }
        for (const [key, value] of entries) {
            const elPath = path.concat([key]).join("/");
            if (value.size > 0) {
                // in case the map contains files or other folders,
                // it's definitely a folder
                els.push(
                    <FileTreeFolder header={key} key={elPath}>
                        {buildTree(value, path.concat([key]))}
                    </FileTreeFolder>
                );
            } else if (search === "" || key.includes(search)) {
                // an empty folder or a file.
                els.push(
                    <List.Item
                        key={elPath}
                        active={selection === elPath}
                        onClick={() => setSelection(elPath)}
                    >
                        <List.Icon name="file"/>
                        <List.Content>
                            <List.Header>{key}</List.Header>
                        </List.Content>
                    </List.Item>
                );
            }
        }
        return els;
    };

    return (
        <Element
            width="100%"
            {...props}
            primary
            setData={setData}>
            <TSegment>
                <Input
                    placeholder='Otsi...'
                    icon='search'
                    style={{ "width": "100%" }}
                    onChange={(ev, data) => {
                        setSearch(String(data.value));
                    }}
                />
                <List
                    selection
                    style={{
                        "maxHeight": props.height ? props.height : "80vh",
                        "overflowY": "scroll",
                        "overflowX": "auto"
                    }}
                >
                    {buildTree(tree, [])}
                </List>
                {children}
            </TSegment>
        </Element>);
};

export { ElementFileTree as FileTree };
