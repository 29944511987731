import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Header } from "semantic-ui-react";
import domToPdf from "../utility/domToPdf";
import DashboardFeedbackForm from "./DashboardFeedbackForm";
import { favoriteDashboard, isDashboardFavorite, removeDashboardFromFavorites } from "./favoriteDashboards";
import { Button, ButtonGroup } from "react-daisyui";
import TSegment from "../components/TSegment";
import TIcon from "../components/TIcon";
import { classNames } from "../UtilityFunctions";

const DashboardHeader = ({ title, subtitle, name }) => {
    const [feedbackFormOpen, toggleFeedbackForm] = useState(false);
    const [favorite, setFavorite] = useState(isDashboardFavorite(name));

    useEffect(() => {
        if (favorite) {
            favoriteDashboard(name);
            console.log(`Added ${name} to favorites`);
        } else if (favorite === false) {
            removeDashboardFromFavorites(name);
            console.log(`removed ${name} from favorites`);
        }
    }, [favorite]);

    const toggleFeedback = () => toggleFeedbackForm(!feedbackFormOpen);

    return (
        <div
            className="bg-base-100 pb-4"
            style={{
                margin: "-2rem",
                overflow: "visible",
                padding: "2rem"
            }}
        >
            <div className="flex flex-row flex-wrap justify-between pt-4 w-full">
                <h1
                    className="inline pb-2"
                    style={{
                        lineHeight: "3rem"
                    }}
                >
                    {title}
                </h1>
                <ButtonGroup
                    className="flex float-right mb:h-full pb-2"
                >
                    <Button
                        onClick={() => setFavorite(!favorite)}
                    >
                        <TIcon
                            name="star"
                            size={8}
                            className={classNames(favorite && "text-amber-400")}
                        />
                    </Button>
                    <Button
                        onClick={() => {
                            const filename = title + ".pdf";
                            const elementHTML = document.getElementById("renderarea");

                            const renderCondition = (el) => {
                                const disallowedClasses = ["file pdf", "pdf", "search"];
                                const disallowedTagNames = ["BUTTON"];

                                if (el.className instanceof Array &&
                                    disallowedClasses.some((c) => el.className.includes(c))) {
                                    return false;
                                }
                                return !disallowedTagNames.includes(el.tagName);
                            };
                            domToPdf(elementHTML, filename, renderCondition);
                        }}>
                        <TIcon name="document-arrow-down" size={8}/>
                    </Button>
                    <Button
                        onClick={toggleFeedback}>
                        <span className="hidden sm:block">{feedbackFormOpen ? "Sule tagasiside" : "Tagasiside"}</span>
                        <TIcon name={feedbackFormOpen ? "envelope-open" : "envelope"} size={8} className="ml-2"/>
                    </Button>
                </ButtonGroup>
            </div>
            <Header.Subheader>
                {subtitle}
            </Header.Subheader>
            <TSegment
                style={{ display: feedbackFormOpen ? "block" : "none" }}
                className="white-background feedback-form">
                <DashboardFeedbackForm dashboardName={name}/>
            </TSegment>
        </div>
    );
};

const Dashboard = (props) => {
    const { title, subtitle, children, name } = props;

    return (
        <div
            className="dashboard overlay min-h-screen overflow-visible">
            <DashboardHeader title={title} subtitle={subtitle} name={name}/>
            <div id="renderarea">
                {children}
            </div>
        </div>
    );
};

export default Dashboard;
