import { DataFrame } from "../DSL/DataFrame";
import { streamRowToDfRow } from "../../UtilityFunctions";
import { MD5 as md5 } from "object-hash";
import { eventToLoadingMessage, eventToLoadingStateMap, loadingStates } from "../stateConstants";

export const DATA_UNINITIALIZED = "DATA_UNINITIALIZED";
export class Source {
    constructor(source, outputChannel) {
        this.id = source;
        this.subscribers = new Set();
        this.data = null;
        this.loaded = false;
        this.isBlocking = null;
        this.elementDataCallback = null;
        // if other elements want to send output at the same time as the source
        // the queue will be emptied every time Source has setData called.
        this.outputQueue = [];
        this.outputChannel = outputChannel || this;
        this.observers = [];
    }

    setElementSourceProperties({ elementDataCallback, isBlocking, elementLoadingState }) {
        this.elementLoadingState = elementLoadingState;
        this.elementDataCallback = elementDataCallback;
        this.isBlocking = isBlocking;
    }

    updateLoadingState = (loadingState, text = null) => {
        if (this.elementLoadingState) {
            this.elementLoadingState.update(loadingState, text);
            this.tellObserversFinished();
        }
    };

    tellObserversFinished = () => {
        if (this.elementLoadingState) {
            if (this.elementLoadingState.getLoadingState() === loadingStates.FINISHED) {
                this.observers.forEach(observer => observer(this.data));
            }
        }
    };

    getLoadingState = () => this.elementLoadingState ? this.elementLoadingState.getLoadingState() : loadingStates.INITIALISED;

    runAndClearOutputQueue = (loaded = true) => {
        this.outputQueue.forEach((fun) => fun(loaded));
        this.outputQueue = [];
    };

    pushOutputChannelQueues = () => {
        const fun = (loaded) => {
            this.loaded = loaded;
            if (loaded) {
                this.updateLoadingState(loadingStates.FINISHED);
            }
            this.subscribers.forEach((subscriber) => {
                // console.log("Checking if subscriptions resolved for", subscriber.id, ":", subscriber.subscriptionsResolved(), Array.from(subscriber.subscriptions).map(source => [source.id, source.isLoaded(), source.loaded]));
                const hashCode = this.getHash(this.data);
                if (typeof subscriber.subscriptionCallback === "function") {
                    subscriber.subscriptionCallback(hashCode);
                }
            });
        };
        this.outputChannel.outputQueue.push(fun);
    };

    setData(val) {
        this.data = val;
        this.finishLoadingElementSource();
    }

    closeStream = () => {
        if (this.loaded) {
            return;
        }
        this.finishLoadingElementSource();
    };

    finishLoadingElementSource() {
        this.dataCallback();
        this.pushOutputChannelQueues();
        this.runAndClearOutputQueue();
        this.updateLoadingState(loadingStates.FINISHED);
    }

    openStream = () => {
        this.loaded = false;
        this.data = new DataFrame([]);
        this.updateLoadingState(loadingStates.WAITING_FOR_DATA, "Arvutuskeskuse ühenduse ootel");
        this.pushOutputChannelQueues();
        this.runAndClearOutputQueue(false);
    };

    resetStreaming = () => {
        this.openStream();
    };

    receiveStreamEvent = (event) => {
        this.updateLoadingState(eventToLoadingStateMap.get(event), eventToLoadingMessage.get(event));
    };

    receiveStreamData = (streamData) => {
        this.updateLoadingState(loadingStates.STREAMING);
        const dfRow = streamRowToDfRow(streamData);
        if (!(this.data instanceof DataFrame)) {
            this.data = new DataFrame([]);
        }
        this.data.append(dfRow);
        if (!this.isBlocking) {
            this.dataCallback();
        }
    };

    error = (error) => {
        console.log(error);
        this.updateLoadingState(loadingStates.ERROR, error);
    };

    getHash = (data) => {
        if (data instanceof DataFrame) {
            return data.hashCode();
        } else {
            return md5(data);
        }
    };

    dataCallback = () => {
        if (this.elementDataCallback !== null) {
            if (this.data instanceof DataFrame) {
                this.elementDataCallback(this.data.shallowCopy());
            } else {
                this.elementDataCallback(this.data);
            }
        }
    };

    getData = () => this.data !== null ? this.data : DATA_UNINITIALIZED;
    getId = () => this.id;
    addSubscriber = (subscriber) => this.subscribers.add(subscriber);
    isLoaded = () => {
        return this.loaded;
    };
}
